import { UserCore } from '@/src/__generated__';
import { filters } from '@/src/components/Table';
import MainButton from '@/src/components/parts/MainButton';
import { ColumnDef } from '@tanstack/react-table';
import styles from './styles.module.css';

export type RowData = UserCore;
export const COLUMN_DEFS: Record<string, ColumnDef<RowData>> = {
  id: {
    id: 'id',
    accessorKey: 'account_id',
    size: 620,
    header: 'ID',
    meta: { align: 'left' },
    cell: (cell) => {
      return (
        <>
          <MainButton
            className={styles['detail-button']}
            type="button"
            onClick={() =>
              // @ts-ignore
              cell.table.options.meta?.onClick?.(cell.row.original)
            }
          >
            詳細
          </MainButton>
          <span>{(cell.getValue() as string) || ''}</span>
        </>
      );
    },
  },
  name: {
    id: 'name',
    accessorKey: 'user_name',
    size: 310,
    header: '名前',
  },
  grade: {
    id: 'grade',
    accessorKey: 'grade',
    size: 120,
    header: '年',
    cell: ({ row: { original } }) => original?.grade && `${original.grade}年`,
    enableSorting: true,
    filterFn: filters.equalityFilter,
  },
  class_name: {
    id: 'class_name',
    accessorKey: 'class_name',
    size: 194,
    header: '組',
    enableSorting: true,
    filterFn: filters.equalityFilter,
    cell: (cell) => `${cell.getValue()}組`,
    sortingFn: (a, b) => {
      const aCompareable = toNumberCompareable(a.original.class_name);
      const bCompareable = toNumberCompareable(b.original.class_name);
      return aCompareable < bCompareable
        ? -1
        : aCompareable > bCompareable
        ? 1
        : 0;
    },
  },
  attendance_no: {
    id: 'attendance_no',
    accessorKey: 'attendance_no',
    size: 200,
    header: '出席番号',
    enableSorting: true,
  },
};

const toNumberCompareable = (str: string | null | undefined) =>
  str?.replace(/(\d+)/g, (m) => m.padStart(10, '0')) || '';
