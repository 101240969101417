.body {
  margin-top: 20px;
  margin-left: 40px;
}

.item {
  margin-left: 20px;
}
.button {
  display: block;
  margin-left: 40px !important;
}

.description {
  margin-top: 36px;

  color: var(--letter-black);
  font-family: var(--main-font-family-m);
  font-size: 20px;
  
  line-height: 30px;
  letter-spacing: -0.019em;
}

.warning {
  color: red;
  font-family: var(--main-font-family-db);
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.019em;
}
