import React from 'react'
import './style.scss';
import useContactForm from './hooks';

export const Confirm = (form: any) => {

  return (
    <>
      <h2 className="pagettl">内容のご確認</h2>
      <p className="form_txt">ご入力いただいた内容をご確認のうえ、よろしければ送信してください。</p>

      <table className="FormList Confirm_list">
        <tbody>
          <tr>
            <th>内容</th>
            <td>
              {form.data.type}
            </td>
          </tr>

          <tr>
            <th>お名前</th>
            <td>
              {form.data.name}
            </td>
          </tr>

          <tr>
            <th>年齢</th>
            <td>
              {form.data.age}
            </td>
          </tr>

          <tr>
            <th>ご職業</th>
            <td>
              {form.data.business}
            </td>
          </tr>

          <tr>
            <th>都道府県</th>
            <td>
              {form.data.prefecture_name}
            </td>
          </tr>
          <tr>
            <th>メールアドレス</th>
            <td>
              {form.data.mail_address}
            </td>
          </tr>
          <tr>
            <th>電話番号</th>
            <td>
              {form.data.tel}
            </td>
          </tr>
          <tr>
            <th>メッセージ</th>
            <td>
              {form.data.message}
            </td>
          </tr>
        </tbody>
      </table>

      <div className="caution_box">
        <p className="caution"><span>※</span>お問い合わせへのご回答は、本フォームに入力されたメールアドレス宛に送信いたします。<br />
          ご記入のメールアドレスに間違いがないかご確認ください。</p>

        <p className="caution"><span>※</span>送信された内容の取り扱いにつきましては、当サイトの<a href="rule/index.html">利用規約</a>をご確認ください。</p>
      </div>

    </>

  )
}
