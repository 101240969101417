import {
  DownloadButton,
  SubmitButton,
  TeacherHistorySelect,
  styles as formStyles,
} from '@/src/components/HistoryQueryForm';
import { HistoryQueryFormTeacher } from '@/src/components/HistoryQueryForm/HistoryQueryFormTeacher';
import { Table, TableWithScroll } from '@/src/components/Table';
import { TeacherFrame } from '@/src/components/layouts/TeacherFrame';
import { FormProvider } from 'react-hook-form';
import { useLearningHistoryRomajiSkill } from './hooks';
import styles from './styles.module.css';
import { themeAtom, profileAtom } from '@/src/components/ModalContents/modalAtom';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { useTheme } from 'styled-components';
import { useToppageTeachers } from '../TeacherTop/hooks';


type Props = {
  onThemeChange: (status: number) => void
}

export const TeacherHistoryRomaji = (props: Props) => {
  const { hasLoaded, table, methods, onSubmit, download } =
    useLearningHistoryRomajiSkill();

  const { currentUser } = useToppageTeachers();
  const [themenum, setThemenum] = useRecoilState(themeAtom)
  const [profiles, setProfiles] = useRecoilState(profileAtom)

  useEffect(() => {
    if (currentUser) {
      if (!themenum) {
        setThemenum(currentUser?.preference.theme_color);
      }
      const _profiles = {
        nick_name: profiles.nick_name ? profiles.nick_name : currentUser?.nick_name,
        profile_icon: profiles.profile_icon && profiles.profile_icon !== currentUser?.preference.profile_icon ? profiles.profile_icon : currentUser?.preference.profile_icon
      }
      if (
        _profiles.nick_name !== profiles.nick_name ||
        _profiles.profile_icon !== profiles.profile_icon
      ) {
        setProfiles(_profiles);
      }
    }

  }, [currentUser, profiles, themenum]);

  useEffect(() => {
    if (currentUser && typeof themenum === 'number') {
      props.onThemeChange(themenum);
    }
  }, [themenum, currentUser]);

  const theme = useTheme();

  return (
    <TeacherFrame title="学習履歴 教師用">
      {hasLoaded && (
        <div className={styles.body}>
          <FormProvider {...methods}>
            <HistoryQueryFormTeacher onSubmit={onSubmit}>
              <div className={formStyles.row}>
                <TeacherHistorySelect />
                <div className={styles.buttons}>
                  {/* <SubmitButton noRuby /> */}
                  <DownloadButton onClick={download} />
                </div>
              </div>
            </HistoryQueryFormTeacher>
          </FormProvider>
          <TableWithScroll className={styles.table} table={table} maxHeight={700} maxWidth={1580} />
        </div>
      )}
    </TeacherFrame>
  );
};
