import SubButton from '@/src/components/parts/SubButton';
import { useNavigate } from 'react-router-dom';
import { TwentyTwo } from '../../icons/TwentyTwo';
import './style.css';
import Kanji from '@/src/components/auth/Kanji';

import { useEffect } from 'react';

export const PasswordResetUrl = (): JSX.Element => {
  const navigate = useNavigate();
  return (
    <div className="password-reset-url">
      <div className="overlap-wrapper-9">
        <div className="overlap-45">
          <div className="div-17">
            <div className="group-133">
              <div className="frame-19">
                <div className="frame-20">
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                </div>
                <div className="frame-20">
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                </div>
                <div className="frame-20">
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                </div>
                <div className="frame-20">
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                </div>
                <div className="frame-20">
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                </div>
                <div className="frame-20">
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                </div>
                <div className="frame-20">
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                </div>
                <div className="frame-20">
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                </div>
              </div>
            </div>
            <div className="group-134">
              <div className="frame-19">
                <div className="frame-20">
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                </div>
                <div className="frame-20">
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                </div>
                <div className="frame-20">
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                </div>
                <div className="frame-20">
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                </div>
                <div className="frame-20">
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                </div>
                <div className="frame-20">
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                </div>
                <div className="frame-20">
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                </div>
                <div className="frame-20">
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                </div>
              </div>
            </div>
            <div className="group-135">
              <div className="frame-19">
                <div className="frame-20">
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                </div>
                <div className="frame-20">
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                </div>
                <div className="frame-20">
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                </div>
                <div className="frame-20">
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                </div>
                <div className="frame-20">
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                </div>
                <div className="frame-20">
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                </div>
                <div className="frame-20">
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                </div>
                <div className="frame-20">
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                </div>
              </div>
            </div>
            <div className="group-136">
              <div className="frame-19">
                <div className="frame-20">
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                </div>
                <div className="frame-20">
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                </div>
                <div className="frame-20">
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                </div>
                <div className="frame-20">
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                </div>
                <div className="frame-20">
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                </div>
                <div className="frame-20">
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                </div>
                <div className="frame-20">
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                </div>
                <div className="frame-20">
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                  <div className="ellipse-18" />
                </div>
              </div>
            </div>
          </div>
          <SubButton
            className="sub-button-back"
            onClick={() => {
              navigate('/');
            }}
          >
            <div className="text-wrapper-274">ログインページへもどる</div>
          </SubButton>
          <div className="main-message-4">
            <div className="overlap-group-39">
              <div className="URL">
                パスワード<Kanji kana="さいせっていよう">再設定用</Kanji>メールをお<Kanji kana="おく">送</Kanji>りしました
                <br />
                お<Kanji kana="おく">送</Kanji>りしたメールのURLにアクセスしてパスワードを<Kanji kana="さいせってい">再設定</Kanji>してください
              </div>
            </div>
          </div>
          <div className="main-header-8">パスワードの<Kanji kana="さいせってい">再設定</Kanji></div>
          <TwentyTwo className="instance-4" />
        </div>
      </div>
    </div>
  );
};
