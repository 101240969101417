import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { ThemeProvider } from 'styled-components';
import './App.css';
import { DashboardRouter } from './Router';
import { TeacherContextProvider } from './hooks/useTeacherContext';
import { useTheme } from './hooks/useTheme';
import { CurrentUser, authConfig } from './middleware/auth/AuthContext';
import { isTeacher } from './middleware/auth/AuthContext/AuthContext';
import { AuthProvider } from './middleware/auth/AuthContext/dashboard';
import { ScalingProvider } from './hooks/useScaleHelper';
import { HelmetProvider } from 'react-helmet-async';

let basename = '';
if (process.env.REACT_APP_MODE == 'mock_raw') {
  basename = '/mock-raw/index.html?';
}
if (process.env.REACT_APP_MODE == 'mock_test') {
  basename = '/mock-test/index.html?';
}

authConfig.referrerApplicationCode = 0; // TODO APIのバグがあるので
authConfig.loginPageUrl = process.env.LOGIN_PAGE_URL;
authConfig.mainPageUrlMethod = (user: CurrentUser) => {
  if (isTeacher(user.user_type)) {
    return '/teachertop';
  } else {
    return '/studenttop';
  }
};
authConfig.noAuthPageUrl = [
  '/login',
  '/logout',
  '/error',
  '/maintenance',
  '/entry',
  '/qrentry',
  '/licenseapplyform',
  '/parentalagreementform',
  '/prelogin',
  '/passwordreseturlsend',
  '/passwordreseturlsenddone',
  '/passwordresettimeout',
  '/passwordresetinput',
  '/passwordresetdone',
  '/studenthelp',
  '/contents/contact',
  '/contents/confirm',
  '/contents/thanks',

];

const queryClient = new QueryClient();
export const App = () => {
  const { theme, handleThemeChange } = useTheme();
  return (
    <BrowserRouter basename={basename}>
      <AuthProvider>
        <RecoilRoot>
          <ThemeProvider theme={theme}>
            <TeacherContextProvider>
              <ScalingProvider>
                <HelmetProvider>
                  <QueryClientProvider client={queryClient}>
                    <DashboardRouter handleThemeChange={handleThemeChange} />
                  </QueryClientProvider>
                </HelmetProvider>
              </ScalingProvider>
            </TeacherContextProvider>
          </ThemeProvider>
        </RecoilRoot>
      </AuthProvider>
    </BrowserRouter>
  );
};
