import SubButton from '@/src/components/parts/SubButton';
import { Link } from 'react-router-dom';
import './style.css';

import { useEffect } from 'react';
import Kanji from '@/src/components/auth/Kanji';

export const PasswordResetDone = (): JSX.Element => {
  return (
    <div className="password-reset-done">
      <div className="overlap-wrapper-6">
        <div className="overlap-41">
          <div className="div-13">
            <div className="group-119">
              <div className="frame-13">
                <div className="frame-14">
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                </div>
                <div className="frame-14">
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                </div>
                <div className="frame-14">
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                </div>
                <div className="frame-14">
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                </div>
                <div className="frame-14">
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                </div>
                <div className="frame-14">
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                </div>
                <div className="frame-14">
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                </div>
                <div className="frame-14">
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                </div>
              </div>
            </div>
            <div className="group-120">
              <div className="frame-13">
                <div className="frame-14">
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                </div>
                <div className="frame-14">
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                </div>
                <div className="frame-14">
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                </div>
                <div className="frame-14">
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                </div>
                <div className="frame-14">
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                </div>
                <div className="frame-14">
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                </div>
                <div className="frame-14">
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                </div>
                <div className="frame-14">
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                </div>
              </div>
            </div>
            <div className="group-121">
              <div className="frame-13">
                <div className="frame-14">
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                </div>
                <div className="frame-14">
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                </div>
                <div className="frame-14">
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                </div>
                <div className="frame-14">
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                </div>
                <div className="frame-14">
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                </div>
                <div className="frame-14">
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                </div>
                <div className="frame-14">
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                </div>
                <div className="frame-14">
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                </div>
              </div>
            </div>
            <div className="group-122">
              <div className="frame-13">
                <div className="frame-14">
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                </div>
                <div className="frame-14">
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                </div>
                <div className="frame-14">
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                </div>
                <div className="frame-14">
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                </div>
                <div className="frame-14">
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                </div>
                <div className="frame-14">
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                </div>
                <div className="frame-14">
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                </div>
                <div className="frame-14">
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                  <div className="ellipse-15" />
                </div>
              </div>
            </div>
          </div>
          <SubButton className="sub-button-2">
            <Link to="/login">
              <div className="text-wrapper-214">ログインページへもどる</div>
            </Link>
          </SubButton>
          <div className="navbar-3">
            <div className="overlap-group-37">
              <div className="text-wrapper-215">
                パスワードの<Kanji kana="さいせってい">再設定</Kanji>が<Kanji kana="かんりょう">完了</Kanji>しました
                <br />
                <Kanji kana="へんこう">変更</Kanji>したパスワードでログインしてください
              </div>
            </div>
          </div>
          <div className="main-header-2">
            <div className="main-header-3">パスワードを<Kanji kana="へんこう">変更</Kanji>しました</div>
          </div>
          <div className="title-3" />
        </div>
      </div>
    </div>
  );
};
