.body {
  margin-top: 33px;
  margin-left: 35px;
  width: 1580px;
}
.body .table {
  margin-top: 28px;
  z-index: 0;
}

.table {
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--line-color);
  overflow: hidden;
}

.table th {
  height: 89px;
  font-size: 20px;
}
.table td {
  font-size: 18px;
}
.table td:nth-child(1) {
  padding: 16px 2px;
}

.history-select-container {
  height: fit-content;
}
