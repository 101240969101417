import './Kanji.css';

const Kanji = (props: any) => {
  const { kana, children, ls } = props;
  return (
    // <span {...props} className={'kanji-wrapper'}>
    //   {children}
    //   <span className="kanji-kana">{kana}</span>
    // </span>
    <ruby>
      {children}<rt style={{'letterSpacing':`${ls}px`}}>{kana}</rt>
    </ruby>
  );
};

export default Kanji;
