import { UserCore } from '@/src/__generated__';
import clsx from 'clsx';
import { useMemo } from 'react';
import styles from '../styles.module.css';
import { FormItemSelect } from './FormItemSelect';

type FormItemStudentSelectProps = {
  childrenList: UserCore[];
  className?: string;
  name?: string;
  disabled?: boolean;
};
export const FormItemStudentSelect = ({
  childrenList,
  className,
  name = 'user_uuid',
  disabled,
}: FormItemStudentSelectProps) => {
  const studentOptions = useMemo(
    () => [
      { label: '全', value: null },
      ...childrenList.map((user) => ({
        label: user.user_name,
        value: user.user_uuid,
      })),
    ],
    [childrenList],
  );
  return (
    <FormItemSelect<string>
      className={clsx(className, styles['student-select'])}
      name={name}
      options={studentOptions}
      disabled={disabled}
      noRuby
    />
  );
};
