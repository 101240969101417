import { Stack, TextField, ThemeProvider, createTheme } from '@mui/material';
import { FieldValues, useController } from 'react-hook-form';
import { RHFTextFieldProps } from '../../types/RHFTextFieldProps';

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          position: 'absolute',
          minWidth: '911px',
          top: '49px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '14px',
        },
        input: {
          boxShadow: '0px 2px 6px 0px #00000070 inset',
          borderRadius: '18px',
          border: '5px solid #D9D9D9',
          borderWidth: '5px 5px 7px',
          padding: '14px',
          fontSize: '22px',
          fontFamily: 'Shin Maru Go DemiBold',
        },
        notchedOutline: {
          border: 'none',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          position: 'absolute',
          top: '6px',
          left: '304px',
          fontFamily: 'Shin Maru Go DemiBold, Helvetica',
          fontSize: '24px',
        },
      },
    },
  },
});

export const RHFPasswordField = <T extends FieldValues>({
  name,
  control,
  type,
}: RHFTextFieldProps<T>) => {
  // 入力フォームを共通化したいので、RHFのControllerの代わりにuseControllerを使用しています
  // errorsはルビ付きのためscreen側で処理するのでコメントアウトしています
  const {
    field,
    //formState: { errors },
  } = useController({ name, control });

  //const errorMessage = errors?.[name]?.message as string

  return (
    <ThemeProvider theme={theme}>
      <Stack direction="row" alignItems="center" m={0}>
        <TextField
          // 値がundefinedの場合は空文字に変換する
          value={field.value ?? ''}
          inputRef={field.ref}
          name={field.name}
          onChange={field.onChange}
          onBlur={field.onBlur}
          type={type}
        />
        {/* {errorMessage && (
          <Typography variant="body1" ml={3} color="red">
            {errorMessage}
          </Typography>
        )} */}
      </Stack>
    </ThemeProvider>
  );
};
