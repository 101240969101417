import {
  CBTCategoryDynamoEntity,
  cBTCbtCategoriesGet,
  eXCELCBTCbtLearningHistoriesExcelGet,
} from '@/src/__generated__';
import { withSaveFile } from '@/src/helpers/file';
import { useTeacherContext } from '@/src/hooks/useTeacherContext';
import {
  LearningHistoryCbtQueryFormSchema,
  learningHistoryCbtQueryFormSchema,
} from '@/src/schema/learning-history-cbt-query-form-schema';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

export const useLearningHistoryCbt = () => {
  const {
    hasLoaded,
    childrenList,
    schoolClassList,
    historyQueryConditions,
    updateHistoryQueryConditions,
    classIndexToClass,
  } = useTeacherContext();

  const [categories, setCategories] = useState<CBTCategoryDynamoEntity[]>([]);

  const download = ({
    class_index,
    ...params
  }: LearningHistoryCbtQueryFormSchema) => {
    return withSaveFile(
      eXCELCBTCbtLearningHistoriesExcelGet({
        ...params,
        ...classIndexToClass(class_index),
      }).catch((error) => {
        if (error.response.status == 404) {
          methods.setError('root', { type: 'custom', message: 'データがありません' });
        }
        return error.response;
      }),
    );
  };

  const methods = useForm<LearningHistoryCbtQueryFormSchema>({
    defaultValues: { class_index: historyQueryConditions.class_index },
    resolver: zodResolver(learningHistoryCbtQueryFormSchema),
  });

  const [class_index] = methods.watch(['class_index']);
  useEffect(() => {
    const schoolClass = classIndexToClass(class_index);
    if (schoolClass?.grade && schoolClass.class_name) {
      updateHistoryQueryConditions({ class_index });
      return;
    }
    if (class_index == null || class_index == undefined || class_index == "") {
      return;
    }
    const currentIndex = class_index;
    const availableSchoolClass = schoolClassList
      .slice(currentIndex)
      .findIndex(({ grade, class_name }) => grade && class_name);
    methods.setValue('class_index', currentIndex + availableSchoolClass);
  }, [class_index]);

  useEffect(() => {
    cBTCbtCategoriesGet().then((res) => {
      setCategories(res.data.cbt_categories || []);
      methods.setValue(
        'cbt_category_id',
        res.data.cbt_categories?.[0]?.id,
      );
    });
  }, []);

  return {
    hasLoaded,
    schoolClassList,
    childrenList,
    categories,
    methods,
    onSubmit: methods.handleSubmit(download),
  };
};
