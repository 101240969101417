import clsx from 'clsx';
import { ReactNode } from 'react';
import styles from '../styles.module.css';
import { relative } from 'path';

export type LabelProps = {
  label: ReactNode;
  children: ReactNode;
  labelWidth?: number;
  className?: string;
};

export const Label = ({
  label,
  labelWidth,
  children,
  className,
}: LabelProps): JSX.Element => {
  return (
    <div className={clsx(className, styles['label-container'])}>
      <div className={styles.label} style={{ width: labelWidth }}>
        {label}
      </div>
      {children}
    </div>
  );
};
