import { useAuthContext } from '@/src/middleware/auth/AuthContext';
import React from 'react';
import { TeacherSideMenu } from '../TeacherSideMenu';
import { TeacherTopbar } from '../TeacherTopbar';
import './style.css';
import styled, { DefaultTheme } from 'styled-components';
import { useRecoilState } from 'recoil';
import { profileAtom } from '../../ModalContents/modalAtom';

interface Props {
  className?: string;
  children: React.ReactNode;
  title: string;
  theme?: DefaultTheme;
}

const TeacherFrameWrap = styled('div')(
  ({ theme }) => `
    background-color: var(${theme.backgroud})!important;
    .topbar-title{
      color: var(${theme.titlecolor})!important;
    }
    .user-name{
      color: var(${theme.textcolor})!important;
    }
    .TableWrap{
      position:relative;
      .totText{
        position:absolute;
        bottom:17px;
        left:22px;
        font-size:1.125rem;
        font-family: var(--main-font-family-db);
        color: var(${theme.textcolor})
      }
    }

  `
)

export const TeacherFrame: React.FC<Props> = ({
  children,
  className = '',
  title
}) => {
  const { getCurrentUser } = useAuthContext();

  const [profiles, setProfiles] = useRecoilState(profileAtom)
  const topBar = {
    className: '',
    name: '',
    avator: ''
  }
  if (getCurrentUser) {
    const user = getCurrentUser();
    if (user) {
      topBar.name = profiles.nick_name;
      topBar.avator = '/img/avatar-' + (profiles.profile_icon + 1) + '.png';
    }
  }

  return (
    <TeacherFrameWrap className={`teacher-frame ${className}`}>
      <TeacherTopbar title={title} name={topBar.name} avator={topBar.avator} />
      <TeacherSideMenu />
      {children}
    </TeacherFrameWrap>
  );
};
