import { useState } from 'react';

export const useTheme = () => {
  const [theme, setTheme] = useState(initTheme);
  const handleThemeChange = (status: ThemeStatus) => {
    switch (status) {
      case ThemeStatus.Default:
        setTheme(defaultTheme);
        break;
      case ThemeStatus.Khaki:
        setTheme(khakiTheme);
        break;
      case ThemeStatus.Dark:
        setTheme(darkTheme);
        break;
      case ThemeStatus.Blue:
        setTheme(blueTheme);
        break;
      case ThemeStatus.Green:
        setTheme(greenTheme);
        break;
      case ThemeStatus.Pink:
        setTheme(pinkTheme);
        break;
      default:
        console.error('Invalid status value:', status);
        setTheme(defaultTheme);
        break;
    }
  };
  return { theme, handleThemeChange };
};

// 列挙型の定義
enum ThemeStatus {
  Default,
  Khaki,
  Dark,
  Blue,
  Green,
  Pink
}

const initTheme = {
  mode: '',
  backgroud: '',
  maincolor: '',
  graycolor: '',
  lightcolor: '',
  edgecolor: '',
  border: '',
  overlap: '',
  label0: '',
  label1: '',
  label2: '',
  chart0: '',
  chart1: '',
  chart2: '',
  chart3: '',
  record0: '',
  record1: '',
  arrowicon: '',
  menubg: '',
  textcolor: '',
  textcolreverse: '',
  textlabel: '',
  arrow: '',
  arrowup: '',
  titlecolor: '',
};

const defaultTheme = {
  mode: 'default',
  backgroud: '--white',
  maincolor: '--tomopla-color',
  graycolor: '--tomopla-color-gray',
  lightcolor: '--tomopla-color-light',
  edgecolor: '--top-record-edge',
  border: '--tomopla-color',
  overlap: 'overlap-default.svg',
  label0: 'rectangle-default-0.svg',
  label1: 'rectangle-default-1.svg',
  label2: 'rectangle-default-2.svg',
  chart0: '#F9D1C7',
  chart1: '#F97253',
  chart2: '#FAC2B6',
  chart3: '#FA876D',
  record0: 'rectangle-default-3.svg',
  record1: 'rectangle-default-4.svg',
  arrowicon: 'arrow-default.svg',
  menubg: 'menubg-default.png',
  textcolor: '--letter-black',
  textcolreverse: '--white',
  textlabel: '--letter-black',
  arrow: 'arrow_light.svg',
  arrowup: 'arrow_light_up.svg',
  titlecolor: '--tomopla-color'
};

const khakiTheme = {
  mode: 'khaki',
  backgroud: '--white',
  maincolor: '--khaki',
  graycolor: '--khaki-gray',
  lightcolor: '--khaki-light',
  edgecolor: '--khaki-edge',
  border: '--khaki',
  overlap: 'overlap-khaki.svg',
  label0: 'rectangle-khaki-0.svg',
  label1: 'rectangle-khaki-1.svg',
  label2: 'rectangle-khaki-2.svg',
  chart0: '#DBD5C4',
  chart1: '#A6976C',
  chart2: '#DBD5C4',
  chart3: '#A6976C',
  record0: 'rectangle-khaki-3.svg',
  record1: 'rectangle-khaki-4.svg',
  arrowicon: 'arrow-khaki.svg',
  menubg: 'menubg-khaki.png',
  textcolor: '--letter-black',
  textcolreverse: '--white',
  textlabel: '--letter-black',
  arrow: 'arrow_light.svg',
  arrowup: 'arrow_light_up.svg',
  titlecolor: '--khaki'
};

const darkTheme = {
  mode: 'dark',
  backgroud: '--dark',
  maincolor: '--tomopla-color',
  graycolor: '--dark-gray',
  lightcolor: '--dark-light',
  edgecolor: '--top-record-edge',
  border: '--tomopla-color',
  overlap: 'overlap-dark.svg',
  label0: 'rectangle-default-0.svg',
  label1: 'rectangle-default-1.svg',
  label2: 'rectangle-default-2.svg',
  chart0: '#F9D1C7',
  chart1: '#F97253',
  chart2: '#89736E',
  chart3: '#86584D',
  record0: 'rectangle-dark-3.svg',
  record1: 'rectangle-dark-4.svg',
  arrowicon: 'arrow-default.svg',
  menubg: 'menubg-dark.png',
  textcolor: '--letter-white',
  textcolreverse: '--letter-white',
  textlabel: '--letter-black',
  arrow: 'arrow_dark.svg',
  arrowup: 'arrow_dark_up.svg',
  titlecolor: '--letter-white'
};

const blueTheme = {
  mode: 'blue',
  backgroud: '--white',
  maincolor: '--blue',
  graycolor: '--blue-gray',
  lightcolor: '--blue-light',
  edgecolor: '--blue-edge',
  border: '--blue',
  overlap: 'overlap-blue.svg',
  label0: 'rectangle-blue-0.svg',
  label1: 'rectangle-blue-1.svg',
  label2: 'rectangle-blue-2.svg',
  chart0: '#9DD4FB',
  chart1: '#0A93F5',
  chart2: '#9DD4FB',
  chart3: '#0A93F5',
  record0: 'rectangle-blue-3.svg',
  record1: 'rectangle-blue-4.svg',
  arrowicon: 'arrow-blue.svg',
  menubg: 'menubg-blue.png',
  textcolor: '--letter-black',
  textcolreverse: '--white',
  textlabel: '--letter-black',
  arrow: 'arrow_light.svg',
  arrowup: 'arrow_light_up.svg',
  titlecolor: '--blue'
};

const greenTheme = {
  mode: 'green',
  backgroud: '--white',
  maincolor: '--green',
  graycolor: '--green-gray',
  lightcolor: '--green-light',
  edgecolor: '--green-edge',
  border: '--green',
  overlap: 'overlap-green.svg',
  label0: 'rectangle-green-0.svg',
  label1: 'rectangle-green-1.svg',
  label2: 'rectangle-green-2.svg',
  chart0: '#99E0BD',
  chart1: '#00B259',
  chart2: '#99E0BD',
  chart3: '#00B259',
  record0: 'rectangle-green-3.svg',
  record1: 'rectangle-green-4.svg',
  arrowicon: 'arrow-green.svg',
  menubg: 'menubg-green.png',
  textcolor: '--letter-black',
  textcolreverse: '--white',
  textlabel: '--letter-black',
  arrow: 'arrow_light.svg',
  arrowup: 'arrow_light_up.svg',
  titlecolor: '--green'
};

const pinkTheme = {
  mode: 'pink',
  backgroud: '--white',
  maincolor: '--pink',
  graycolor: '--pink-gray',
  lightcolor: '--pink-light',
  edgecolor: '--pink-edge',
  border: '--pink',
  overlap: 'overlap-pink.svg',
  label0: 'rectangle-pink-0.svg',
  label1: 'rectangle-pink-1.svg',
  label2: 'rectangle-pink-2.svg',
  chart0: '#FFCDD5',
  chart1: '#FF8196',
  chart2: '#FFCDD5',
  chart3: '#FF8196',
  record0: 'rectangle-pink-3.svg',
  record1: 'rectangle-pink-4.svg',
  arrowicon: 'arrow-pink.svg',
  menubg: 'menubg-pink.png',
  textcolor: '--letter-black',
  textcolreverse: '--white',
  textlabel: '--letter-black',
  arrow: 'arrow_light.svg',
  arrowup: 'arrow_light_up.svg',
  titlecolor: '--pink'
};
