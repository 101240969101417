import { formatDuration } from '@/src/helpers/date';
import { Link } from 'react-router-dom';
import './StudentTopRecords.css';
import styled, { DefaultTheme } from 'styled-components'
import { useEffect, useState } from 'react';
import Kanji from '../../auth/Kanji';
import { LicenseEntity } from '@/src/__generated__';

type Props = {
  total_number_logins: number;
  total_study_time: number;
  number_kanji_skill: number;
  number_Japanese_dictionary: number;
  number_kanji_dictionary: number;
  license: LicenseEntity[];
  theme: DefaultTheme;
};

const Label = styled('div')(
  ({ theme }) => `
    background-image: url(/img/${theme.label1});
    background-size: 100% 100%;
    height: 32px;
    position: relative;
  `
)

const RecodeWrapLogin = styled('div')(
  ({ theme }) => `
    height: 88px;
    position: relative;
    .group-67 {
      height: 88px;
      left: 0;
      position: absolute;
      top: 0;
      width: 408px;
      .overlap-group-13 {
        background-image: url(/img/${theme.record0});
        background-size: 100% 100%;
        height: 94px;
        position: relative;
        top: -1px;
        width: 414px;
        .rectangle-11 {
          background-color: var(${theme.maincolor});
          border-radius: 0px 4px 4px 0px;
          height: 88px;
          left: 393px;
          position: relative;
          top: 1px;
          width: 15px;
        }
      }
    }
    .group-80 {
      height: 37px;
      left: 61px;
      position: absolute;
      top: 26px;
      width: 322px;
      .text-wrapper-43 {
        color: var(${theme.textcolor});
        font-family: var(--main-font-family-db);
        font-size: 16px;
        @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1rem;};

        left: 186px;
        letter-spacing: 0.8px;
        line-height: 19px;
        position: absolute;
        text-align: right;
        top: 21px;
        white-space: nowrap;
      }
      .group-81 {
        height: 37px;
        left: 0;
        position: absolute;
        top: 0;
        width: 125px;
        .group-82 {
          height: 37px;
          .group-83 {
            height: 37px;
            position: relative;
            width: 129px;
            .text-wrapper-38 {
              color: var(${theme.textcolor});
              font-family: var(--main-font-family-db);
              font-size: 26px;
              @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.625rem;};

              left: 99px;
              letter-spacing: 1.3px;
              line-height: 19px;
              position: absolute;
              text-align: right;
              top: 0px;
              white-space: nowrap;
            }
            .text-wrapper-39 {
              color: var(${theme.maincolor});
              font-family: var(--main-font-family-b);
              font-size: 46px;
              @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 2.875rem;};

              right: 34px;
              letter-spacing: 1.38px;
              line-height: 19px;
              position: absolute;
              text-align: right;
              top: 11px;
            }
          }
        }
      }
    }
  `
)

const RecodeWrapTime = styled('div')(
  ({ theme }) => `
    height: 88px;
    position: relative;
    position: absolute;
    top: 103px;
    width: 408px;
    .overlap-7 {
      height: 88px;
      position: relative;
      .group-67 {
        height: 88px;
        left: 0;
        position: absolute;
        top: 0;
        width: 408px;
        .overlap-group-11 {
          background-image: url(/img/${theme.record0});
          background-size: 100% 100%;
          height: 94px;
          position: relative;
          top: -1px;
          width: 414px;
          .rectangle-11 {
            background-color: var(${theme.maincolor});
            border-radius: 0px 4px 4px 0px;
            height: 88px;
            left: 393px;
            position: relative;
            top: 1px;
            width: 15px;
          }
        }
      }
      .group-68 {
        height: 37px;
        left: 11px;
        position: absolute;
        top: 29px;
        width: 322px;
        .group-69 {
          height: 37px;
          position: relative;
          .group-70 {
            height: 37px;
            left: 0;
            position: absolute;
            top: 0;
            width: 258px;
            .group-71 {
              height: 37px;
              position: relative;
              width: 266px;
              .text-wrapper-36 {
                color: var(${theme.maincolor});
                font-family: var(--main-font-family-b);
                font-size: 46px;
                @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 2.875rem;};

                left: 164px;
                letter-spacing: 1.38px;
                line-height: 19px;
                position: absolute;
                text-align: right;
                top: 10px;
              }
              .text-wrapper-37 {
                color: var(${theme.textcolor});
                font-family: var(--main-font-family-db);
                font-size: 26px;
                @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.625rem;};

                left: 232px;
                letter-spacing: 1.3px;
                line-height: 19px;
                position: absolute;
                text-align: right;
                top: 0px;
                white-space: nowrap;
              }
              .text-wrapper-38 {
                color: var(${theme.textcolor});
                font-family: var(--main-font-family-db);
                font-size: 26px;
                @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.625rem;};

                left: 99px;
                letter-spacing: 1.3px;
                line-height: 19px;
                position: absolute;
                text-align: right;
                top: 0px;
                white-space: nowrap;
              }
              .text-wrapper-39 {
                color: var(${theme.maincolor});
                font-family: var(--main-font-family-b);
                font-size: 46px;
                @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 2.875rem;};

                right: 170px;
                letter-spacing: 1.38px;
                line-height: 19px;
                position: absolute;
                text-align: right;
                top: 11px;
              }
            }
          }
          .group-72 {
            height: 13px;
            left: 271px;
            position: absolute;
            top: 23px;
            width: 102px;
            .text-wrapper-40 {
              color: var(${theme.textcolor});
              font-family: var(--main-font-family-db);
              font-size: 16px;
              @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1rem;};

              left: 0;
              letter-spacing: 0.8px;
              line-height: 19px;
              position: absolute;
              text-align: right;
              top: -9px;
              white-space: nowrap;
            }
          }
        }
      }
    }
  `
)

const RecordWrapKjs = styled('div')(
  ({ theme }) => `
    height: 88px;
    left: 0;
    width: 408px;
    margin-top:15px;
    .overlap-7 {
      height: 88px;
      position: relative;
      .group-67 {
        height: 88px;
        left: 0;
        position: absolute;
        top: 0;
        width: 408px;
        .overlap-group-12 {
          background-image: url(/img/${theme.record1});
          background-size: 100% 100%;
          width: 414px;
          height: 94px;
          position: relative;
          top: -1px;
          .rectangle-12 {
            background-color: var(${theme.maincolor});
            border-radius: 0 4px 4px 0;
            width: 15px;
            height: 88px;
            position: absolute;
            top: 1px;
            left: 393px;
            .group-74 {
              background-color: var(--white);
              border-radius: 9px;
              width: 18px;
              height: 18px;
              position: absolute;
              top: 13px;
              left: 363px;
              .group-75 {
                width: 11px;
                height: 8px;
                position: absolute;
                top: 5px;
                left: 3px;
              }
            }
          }
        }
      }
      .group-76 {
        width: 84px;
        height: 13px;
        position: absolute;
        top: 52px;
        left: 297px;
        .group-48 {
          height: 13px;
          .group-77 {
            width: 86px;
            height: 13px;
            position: relative;
            .text-wrapper-40 {
              color: var(${theme.textcolor});
              font-family: var(--main-font-family-db);
              font-size: 16px;
              @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1rem;};

              left: 0;
              letter-spacing: 0.8px;
              line-height: 19px;
              position: absolute;
              text-align: right;
              top: -4px;
              white-space: nowrap;
            }
          }
        }
      }
      .group-78 {
        height: 63px;
        left: 54px;
        position: absolute;
        top: 12px;
        width: 191px;
        .text-wrapper-40 {
          color: var(${theme.textcolor});
          font-family: var(--main-font-family-db);
          font-size: 16px;
          @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1rem;};

          left: 0;
          letter-spacing: 0.8px;
          line-height: 19px;
          position: absolute;
          text-align: right;
          top: -4px;
          white-space: nowrap;
        }
        .group-79 {
          height: 37px;
          left: 32px;
          position: absolute;
          top: 26px;
          width: 161px;
          .text-wrapper-41 {
            color: var(${theme.textcolor});
            font-family: var(--main-font-family-db);
            font-size: 26px;
            @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.625rem;};

            left: 131px;
            letter-spacing: 1.3px;
            line-height: 19px;
            position: absolute;
            text-align: right;
            top: -3px;
            white-space: nowrap;
          }
          .text-wrapper-42 {
            color: var(${theme.textcolreverse});
            font-family: var(--main-font-family-b);
            font-size: 46px;
            @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 2.875rem;};

            letter-spacing: 1.38px;
            line-height: 19px;
            position: absolute;
            text-align: right;
            top: 10px;
            right: 34px;
          }
        }
      }
      .cover{
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        cursor:pointer;
      }
    }
  `
)

const RecordWrapKgd = styled('div')(
  ({ theme }) => `
    height: 88px;
    left: 0;
    width: 408px;
    margin-top:15px;
    .overlap-7 {
      height: 88px;
      position: relative;
      .group-67 {
        height: 88px;
        left: 0;
        position: absolute;
        top: 0;
        width: 408px;
        .overlap-7 {
          height: 88px;
          position: relative;
          .group-67 {
            height: 88px;
            left: 0;
            position: absolute;
            top: 0;
            width: 408px;
            .overlap-group-14 {
              background-image: url(/img/${theme.record1});
              background-size: 100% 100%;
              width: 414px;
              height: 94px;
              position: relative;
              top: -1px;
              .rectangle-12 {
                background-color: var(${theme.maincolor});
                border-radius: 0 4px 4px 0;
                width: 15px;
                height: 88px;
                position: absolute;
                top: 1px;
                left: 393px;
                .group-74 {
                  background-color: var(--white);
                  border-radius: 9px;
                  width: 18px;
                  height: 18px;
                  position: absolute;
                  top: 13px;
                  left: 363px;
                  .group-75 {
                    width: 11px;
                    height: 8px;
                    position: absolute;
                    top: 5px;
                    left: 3px;
                  }
                }
              }
            }
          }
        }
      }
      .group-76 {
        width: 84px;
        height: 13px;
        position: absolute;
        top: 52px;
        left: 297px;
        .group-48 {
          height: 13px;
          .group-77 {
            width: 86px;
            height: 13px;
            position: relative;
            .text-wrapper-40 {
              color: var(${theme.textcolor});
              font-family: var(--main-font-family-db);
              font-size: 16px;
              @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1rem;};

              left: 0;
              letter-spacing: 0.8px;
              line-height: 19px;
              position: absolute;
              text-align: right;
              top: -4px;
              white-space: nowrap;
            }
          }
        }
      }
      .group-85 {
        height: 13px;
        left: 54px;
        position: absolute;
        top: 12px;
        width: 86px;
        .text-wrapper-44 {
          color: var(${theme.textcolor});
          font-family: var(--main-font-family-db);
          font-size: 16px;
          @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1rem;};

          left: 0;
          letter-spacing: 0.8px;
          line-height: 19px;
          position: absolute;
          top: -4px;
          white-space: nowrap;
        }
      }
      .group-86 {
        height: 37px;
        left: 54px;
        position: absolute;
        top: 38px;
        width: 189px;
        .group-87 {
          height: 37px;
          position: relative;
          width: 193px;
          .text-wrapper-45 {
            color: var(${theme.textcolor});
            font-family: var(--main-font-family-db);
            font-size: 26px;
            @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.625rem;};

            left: 163px;
            letter-spacing: 1.3px;
            line-height: 19px;
            position: absolute;
            text-align: right;
            top: -3px;
            white-space: nowrap;
          }
          .text-wrapper-46 {
            color: var(${theme.textcolreverse});
            font-family: var(--main-font-family-b);
            font-size: 46px;
            @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 2.875rem;};

            letter-spacing: 1.38px;
            line-height: 19px;
            position: absolute;
            text-align: right;
            top: 10px;
            right: 34px;
          }
        }
      }
      .cover{
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        cursor:pointer;
      }
    }
  `
)

const RecordWrapKjd = styled('div')(
  ({ theme }) => `
    height: 88px;
    left: 0;
    width: 408px;
    margin-top:15px;
    .overlap-7 {
      height: 88px;
      position: relative;
      .group-67 {
        height: 88px;
        left: 0;
        position: absolute;
        top: 0;
        width: 408px;
        .overlap-7 {
          height: 88px;
          position: relative;
          .group-67 {
            height: 88px;
            left: 0;
            position: absolute;
            top: 0;
            width: 408px;
            .overlap-group-15 {
              background-image: url(/img/${theme.record1});
              background-size: 100% 100%;
              width: 414px;
              height: 94px;
              position: relative;
              top: -1px;
              .rectangle-12 {
                background-color: var(${theme.maincolor});
                border-radius: 0 4px 4px 0;
                width: 15px;
                height: 88px;
                position: absolute;
                top: 1px;
                left: 393px;
                .group-74 {
                  background-color: var(--white);
                  border-radius: 9px;
                  width: 18px;
                  height: 18px;
                  position: absolute;
                  top: 13px;
                  left: 363px;
                  .group-75 {
                    width: 11px;
                    height: 8px;
                    position: absolute;
                    top: 5px;
                    left: 3px;
                  }
                }
              }
            }
          }
          .group-76 {
            width: 84px;
            height: 13px;
            position: absolute;
            top: 52px;
            left: 297px;
            .group-48 {
              height: 13px;
              .group-77 {
                width: 86px;
                height: 13px;
                position: relative;
                .text-wrapper-40 {
                  color: var(${theme.textcolor});
                  font-family: var(--main-font-family-db);
                  font-size: 16px;
                  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1rem;};

                  left: 0;
                  letter-spacing: 0.8px;
                  line-height: 19px;
                  position: absolute;
                  text-align: right;
                  top: -4px;
                  white-space: nowrap;
                }
              }
            }
          }
          .group-85 {
            height: 13px;
            left: 54px;
            position: absolute;
            top: 12px;
            width: 86px;
            .text-wrapper-44 {
              color: var(${theme.textcolor});
              font-family: var(--main-font-family-db);
              font-size: 16px;
              @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1rem;};

              left: 0;
              letter-spacing: 0.8px;
              line-height: 19px;
              position: absolute;
              top: -4px;
              white-space: nowrap;
            }
          }
        }
      }
      .group-89 {
        height: 37px;
        left: 86px;
        position: absolute;
        top: 38px;
        width: 175px;
        .group-90 {
          height: 37px;
          position: relative;
          width: 161px;
        }
        .text-wrapper-47 {
          color: var(${theme.textcolor});
          font-family: var(--main-font-family-db);
          font-size: 26px;
          @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.625rem;};

          left: 131px;
          letter-spacing: 1.3px;
          line-height: 19px;
          position: absolute;
          text-align: right;
          top: -3px;
          white-space: nowrap;
        }
        .text-wrapper-42 {
          color: var(${theme.textcolreverse});
          font-family: var(--main-font-family-b);
          font-size: 46px;
          @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 2.875rem;};

          letter-spacing: 1.38px;
          line-height: 19px;
          position: absolute;
          text-align: right;
          top: 10px;
          right: 34px;
        }
      }
      .cover{
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        cursor:pointer;
      }
    }
  `
)

const StudentTopRecords = ({
  total_number_logins,
  total_study_time,
  number_kanji_skill,
  number_Japanese_dictionary,
  number_kanji_dictionary,
  license,
  theme
}: Props) => {

  const [hour, min] = formatDuration(total_study_time).split('：');

  const [pac, isPac] = useState(false);
  const recCheck = (arr: LicenseEntity[]) => {
    for (let i = 0; i < arr.length; i++) {
      if ((arr[i]!.button_name! ? arr[i]!.button_name! : 'XXXXX').charAt(0) === 'D') {
        isPac(true)
        return;
      }
    }
  }
  useEffect(() => {
    recCheck(license);
  }, [license])

  return (
    <>
      {/* ラベル */}
      <div className="group-62">
        <div className="group-63">
          <div className="group-64">
            <Label>
              <div className="text-wrapper-3">きのうまで</div>
            </Label>
          </div>
        </div>
      </div>
      <div className="group-65">
        {/* ログイン回数 */}
        <RecodeWrapLogin>
          <div className="overlap-7">
            <div className="group-67">
              <div className="overlap-group-13">
                <div className="rectangle-11" />
              </div>
            </div>
            <div className="group-80">
              <div className="text-wrapper-43">ログインしました</div>
              <div className="group-81">
                <div className="group-82">
                  <div className="group-83">
                    <div className="text-wrapper-38"><Kanji kana="かい">回</Kanji></div>
                    <div className="text-wrapper-39">
                      {total_number_logins}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </RecodeWrapLogin>
        {/* 学習時間 */}
        <RecodeWrapTime>
          <div className="overlap-7">
            <div className="group-67">
              <div className="overlap-group-11">
                <div className="rectangle-11" />
              </div>
            </div>
            <div className="group-68">
              <div className="group-69">
                <div className="group-70">
                  <div className="group-71">
                    <div className="text-wrapper-36">{min}</div>
                    <div className="text-wrapper-37"><Kanji kana="ふん">分</Kanji></div>
                    <div className="text-wrapper-38"><Kanji kana="じかん">時間</Kanji></div>
                    <div className="text-wrapper-39">{hour}</div>
                  </div>
                </div>
                <div className="group-72">
                  <div className="text-wrapper-40"><Kanji ls="-1" kana="がくしゅう">学習</Kanji>しました</div>
                </div>
              </div>
            </div>
          </div>
        </RecodeWrapTime>
        <div className="recwrap">
          {/* 漢字スキル */}
          {license.find((el) => el.button_name && el.button_name.includes('KJS')) ? (
            <RecordWrapKjs>
              <div className="overlap-7">
                <div className="group-67">
                  <div className="overlap-group-12">
                    <div className="rectangle-12" />
                    <Link className="group-74" to="/studenthistorykanji">
                      <img
                        className="group-75"
                        alt="Group"
                        src={`/img/${theme.arrowicon}`}
                      />
                    </Link>
                  </div>
                </div>
                <div className="group-76">
                  <div className="group-48">
                    <div className="group-77">
                      <div className="text-wrapper-40"><Kanji kana="か">書</Kanji>きました</div>
                    </div>
                  </div>
                </div>
                <div className="group-78">
                  <div className="text-wrapper-40"><Kanji kana="かんじ">漢字</Kanji>スキルで</div>
                  <div className="group-79">
                    <div className="text-wrapper-41"><Kanji kana="じ">字</Kanji></div>
                    <div className="text-wrapper-42">{number_kanji_skill}</div>
                  </div>
                </div>
                <Link className="cover" to="/studenthistorykanji"/>
              </div>
            </RecordWrapKjs>
          ) : <></>}
          {/* 国語辞典 */}
          {license.find((el) => el.button_name! === 'DKOKDC') ? (
            <RecordWrapKgd>
              <div className="overlap-7">
                <div className="group-67">
                  <div className="overlap-7">
                    <div className="group-67">
                      <div className="overlap-group-14">
                        <div className="rectangle-12" />
                        <Link className="group-74" to="/studenthistorydictionary">
                          <img
                            className="group-75"
                            alt="Group"
                            src={`/img/${theme.arrowicon}`}
                          />
                        </Link>
                      </div>
                    </div>
                    <div className="group-76">
                      <div className="group-48">
                        <div className="group-77">
                          <div className="text-wrapper-40"><Kanji kana="しら">調</Kanji>べました</div>
                        </div>
                      </div>
                    </div>
                    <div className="group-85">
                      <div className="text-wrapper-44"><Kanji kana="こくごじてん">国語辞典</Kanji>で</div>
                    </div>
                  </div>
                </div>
                <div className="group-86">
                  <div className="group-87">
                    <div className="text-wrapper-45"><Kanji kana="ご">語</Kanji></div>
                    <div className="text-wrapper-46">
                      {number_Japanese_dictionary}
                    </div>
                  </div>
                </div>
                <Link className="cover" to="/studenthistorydictionary"/>
              </div>
            </RecordWrapKgd>
          ) : <></>}
          {/* 漢字辞典 */}
          {/* {number_kanji_dictionary && number_kanji_dictionary > 0 ? ( */}
          {license.find((el) => el.button_name! === 'DKANDC') ? (
            <RecordWrapKjd>
              <div className="overlap-7">
                <div className="group-67">
                  <div className="overlap-7">
                    <div className="group-67">
                      <div className="overlap-group-15">
                        <div className="rectangle-12" />
                        <Link
                          className="group-74"
                          to="/studenthistorydictionarykanji"
                        >
                          <img
                            className="group-75"
                            alt="Group"
                            src={`/img/${theme.arrowicon}`}
                          />
                        </Link>
                      </div>
                    </div>
                    <div className="group-76">
                      <div className="group-48">
                        <div className="group-77">
                          <div className="text-wrapper-40"><Kanji kana="しら">調</Kanji>べました</div>
                        </div>
                      </div>
                    </div>
                    <div className="group-85">
                      <div className="text-wrapper-44"><Kanji kana="かんじじてん">漢字辞典</Kanji>で</div>
                    </div>
                  </div>
                </div>
                <div className="group-89">
                  <div className="group-90">
                    <div className="text-wrapper-47"><Kanji kana="じ">字</Kanji></div>
                    <div className="text-wrapper-42">
                      {number_kanji_dictionary}
                    </div>
                  </div>
                </div>
                <Link className="cover" to="/studenthistorydictionarykanji"/>
              </div>
            </RecordWrapKjd>
          ) : <></>}
        </div>
        {!pac && (
          <div className="group-10755">
            <img src="/img/record_blank.png" alt="" />
          </div>
        )}
      </div>
    </>
  );
};

export default StudentTopRecords;
