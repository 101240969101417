import { ButtonHTMLAttributes } from "react";
import styled from "styled-components";
import { DefaultTheme } from "styled-components/dist/types";

type Props = Partial<{
  type: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  disabled: ButtonHTMLAttributes<HTMLButtonElement>['disabled'];
  tabIndex: ButtonHTMLAttributes<HTMLButtonElement>['tabIndex'];
  className: any;
  onClick?: () => void;
  theme?: DefaultTheme;
}>

const Overlap = styled('button')(
  ({ theme }) => `
    border-radius: 50%;
    border: none;
    background-color: var(${theme.maincolor});
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    .group{
      margin-top:3px;
    }
  `
)

export const PencilButton = ({ type, disabled, tabIndex, className, onClick }: Props) => {
  return (
    <Overlap
      type={type}
      disabled={disabled}
      tabIndex={tabIndex}
      onClick={onClick}
      className={`${className}`}>      
        <div className="group">
          <img className="img" alt="pen" src="/img/pencil-icon.svg" />
        </div>      
    </Overlap>
  );
};