import { usersMePasswordPost } from "@/src/__generated__";
import { getAutoGeneratePassword } from '@/src/hooks/useGetAutoGeneratePassword';
import { FullPwdFormSchema,fullPwdFormSchema } from "@/src/schema/pwd-form-schema";
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm,useWatch } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
import { useRecoilState } from 'recoil';
import { modalAtom } from '../modalAtom';

export const usePasswordReset = () => {
  const {
    control, handleSubmit, setValue, setError, formState: { errors }
  } = useForm<FullPwdFormSchema>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: undefined,
    resolver: zodResolver(fullPwdFormSchema)
  })

  const [, setOpenModalKey] = useRecoilState<any>(modalAtom)

  const watchedInput = useWatch({ control })

  console.log('errors', errors)

  console.log('watchedInput', watchedInput)

  // const navigate = useNavigate();

  const onSubmit = async (data: FullPwdFormSchema) => {
    let do_not_close_modal = false;
    try {
      const res = await usersMePasswordPost({
        password: data.currentPassword,
        password_new: data.newPassword
      })
      if (res.status === 200) {
        alert('パスワードが変更されました。')
        setOpenModalKey(null);
      }
    } catch (error:any) {
      if (error.response.status === 400) {
        //setError('currentPassword', { type: 'custom', message: '「onetime code」の有効期限が切れています。' });
        alert('あたらしいパスワードが現在のパスワードと同一です。');
      }
      if (error.response.status === 401) {
        setError('currentPassword', { type: 'custom', message: '現在のパスワードが正しくありません。' });
        do_not_close_modal = true;
      }
      if (error.response.status === 404) {
        setError('currentPassword', { type: 'custom', message: '「onetime code」が存在しません。' });
        do_not_close_modal = true;
      }
      if (error.response.status === 500) {
        setError('currentPassword', { type: 'custom', message: 'システムエラーが発生しました。' });
        do_not_close_modal = true;
      }
    } finally {
      if (!do_not_close_modal) {
        setOpenModalKey(null);
      }
    }
  }

  const autoGenerate = () => {
    setValue('newPassword', getAutoGeneratePassword());
  };

  return {
    form: {
      control,
      handleSubmit,
      onSubmit,
      autoGenerate,
      errors
    }
  }
}