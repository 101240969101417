import './style.css';

export const TeacherHelp = (): JSX.Element => {
  return (
    <div className="teacher-help">
      <div className="div-22">
        <div className="overlap-73">
          <div className="main-button-contact">
            <div className="text-wrapper-318">お問い合わせ</div>
          </div>
          <div className="help-contents">
            <div className="scroll-bar-6">
              <div className="line-wrapper">
                <img className="line-11" alt="Line" src="/img/line-11-3.svg" />
              </div>
            </div>
            <div className="block-QA">
              <div className="row-QA">
                <div className="question">
                  <div className="group-277">
                    <div className="group-278">
                      <div className="text-wrapper-319">Q.</div>
                      <div className="text-wrapper-320">
                        どうすれば使えますか？
                      </div>
                    </div>
                  </div>
                  <img
                    className="frame-25"
                    alt="Frame"
                    src="/img/frame-5631.svg"
                  />
                </div>
              </div>
              <div className="row-QA-2">
                <div className="answer">
                  <div className="group-279">
                    <div className="group-280">
                      <div className="text-wrapper-319">A.</div>
                      <div className="text-wrapper-321">
                        あああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああ
                      </div>
                    </div>
                  </div>
                </div>
                <div className="question-2">
                  <div className="group-281">
                    <div className="group-282">
                      <div className="text-wrapper-319">Q.</div>
                      <div className="text-wrapper-320">
                        対応している機器を教えてください
                      </div>
                    </div>
                  </div>
                  <img
                    className="frame-25"
                    alt="Frame"
                    src="/img/frame-5631-1.svg"
                  />
                </div>
              </div>
              <div className="question-wrapper">
                <div className="question-3">
                  <div className="group-283">
                    <div className="group-284">
                      <div className="text-wrapper-319">Q.</div>
                      <div className="text-wrapper-320">
                        アプリをダウンロードする必要はありますか。
                      </div>
                    </div>
                  </div>
                  <img
                    className="frame-25"
                    alt="Frame"
                    src="/img/frame-5631.svg"
                  />
                </div>
              </div>
            </div>
            <div className="genre-title">
              <div className="text-wrapper-322">トモプラについて</div>
            </div>
            <div className="section-title" />
          </div>
          <div className="block-buttons-5">
            <div className="link-teachers-manual">
              <div className="group-285">
                <img
                  className="group-286"
                  alt="Group"
                  src="/img/group-5697.png"
                />
                <div className="text-wrapper-323">
                  教師用・管理者用マニュアル
                </div>
              </div>
            </div>
            <div className="link-students-manual">
              <div className="group-287">
                <img
                  className="group-286"
                  alt="Group"
                  src="/img/group-5697.png"
                />
                <div className="text-wrapper-323">児童用マニュアル</div>
              </div>
            </div>
          </div>
          <div className="main-message">
            <div className="text-wrapper-324">操作マニュアルはこちら</div>
          </div>
          <div className="title-9">
            <div className="text-wrapper-325">マニュアル</div>
            <img
              className="group-288"
              alt="Group"
              src="/img/group-5697-2.png"
            />
          </div>
        </div>
        <div className="overlap-74">
          <div className="input-search-8">
            <div className="overlap-75">
              <div className="input-search-keyword">
                <div className="overlap-group-84">
                  <div className="rectangle-56" />
                  <div className="text-wrapper-326">ヘルプを検索</div>
                </div>
              </div>
              <div className="main-button-search">
                <img
                  className="vector-58"
                  alt="Vector"
                  src="/img/vector-47.svg"
                />
              </div>
            </div>
          </div>
          <div className="teacher-top-bar-9">
            <div className="text-wrapper-327">トモプラ ヘルプページ</div>
            <div className="group-289">
              <div className="group-290" />
              <div className="frame-26">
                <div className="text-wrapper-328">山田 一郎</div>
                <img
                  className="vector-59"
                  alt="Vector"
                  src="/img/vector-48.svg"
                />
              </div>
            </div>
            <img className="line-12" alt="Line" src="/img/line-15.svg" />
          </div>
        </div>
        <div className="teacher-side-bar">
          <div className="overlap-76">
            <div className="text-wrapper-329">トモプラロゴ</div>
            <div className="group-291">
              <div className="group-292">
                <div className="text-wrapper-330">トモプラホーム</div>
                <div className="group-293" />
              </div>
              <div className="group-294">
                <div className="frame-27">
                  <div className="rectangle-57" />
                  <div className="text-wrapper-331">児童情報一覧</div>
                </div>
              </div>
              <div className="group-295">
                <div className="text-wrapper-330">クラス行動履歴</div>
                <div className="rectangle-58" />
              </div>
            </div>
            <div className="group-296">
              <div className="text-wrapper-332">
                ヘルプ・
                <br />
                問い合わせ
              </div>
              <div className="rectangle-59" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
