import { styles as formStyles } from '@/src/components/HistoryQueryForm';
import MainButton, { MainButtonProps } from '@/src/components/parts/MainButton';
import clsx from 'clsx';
import { ReactNode, useEffect, useState } from 'react';
import styles from './styles.module.css';
import styled, { DefaultTheme } from 'styled-components';

export const WordListRanking = ({
  items,
  theme
}: {
  items: { rank: number; word: ReactNode }[];
  theme?: DefaultTheme
}) => (
  <WordList
    label={RANKING_LABEL}
    items={items.map(toRankingItem)}
    captionClassName='caption-ranking'
  />
);

export const WordListRandom = ({
  items,
  reload,
}: Pick<WordListProps, 'items'> & { reload: VoidFunction }) => (
  <WordList
    label={RANDOM_LABEL}
    items={items}
    control={
      <ReloadButton
        type="button"
        className={formStyles.submit}
        onClick={reload}
      />
    }
    captionClassName='caption-random'
  />
);

type WordListProps = {
  label: ReactNode;
  control?: ReactNode;
  items: {
    caption: ReactNode;
    word: ReactNode;
  }[];
  className?: string;
  captionClassName?: string;
  theme?:DefaultTheme;
};

const Wrap = styled('div')(
  ({theme})=>`
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    .labelsm {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;    
      font-size: 20px;
      @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none)  {font-size: 1.25rem;}
      font-family: var(--main-font-family-db);
      letter-spacing: 0.05em;
      line-height: 25px;
    }
    ol {
      border-radius: 4px;
      border: 1px solid var(${theme.textcolor});
      overflow: hidden;
      li {
        height: 65px;
        display: flex;
        &:not(:last-child) {
          border-bottom: 1px solid var(${theme.textcolor});
        }
        > * {
          display: flex;
          align-items: flex-end;
          padding-bottom: 22px;
        }
        .caption {
          justify-content: center;
          height: 100%;
          border-right: 1px solid var(${theme.textcolor});
          background-color: var(${theme.maincolor});
          color: var(--white);
        
          font-size: 20px;
          @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none)  {font-size: 1.25rem;}
          font-family: var(--main-font-family-db);
          letter-spacing: 0.05em;
          line-height: 1;
        }
        .caption-ranking {
          width: 79px;
        }
        .caption-random {
          width: 170px;
          column-gap: 0.75em;
        }
        .contentls {
          width: 585px;
          height: 100%;
          padding-left: 20px;
          background-color: var(${theme.lightcolor});
        
          font-size: 25px;
          @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none)  {font-size: 1.5625rem;}
          font-family: var(--main-font-family-db);
          letter-spacing: 0.1em;
          line-height: 1;
        }
      }
    }
  `
)

interface Item {
  caption: ReactNode;
  word: ReactNode;
}

const WordList = ({
  label,
  control,
  items,
  className,
  captionClassName,
  theme
}: WordListProps) => {
  const [displayItems, setDisplayItems] = useState <Item[]>([]);

  useEffect(() => {
    setDisplayItems(items.slice(0, 6));
  }, [items]);

  return (
    <Wrap>
      <div className='labelsm'>
        <div>{label}</div>
        {control && <div> {control}</div>}
      </div>
      {items.length > 0 && (
        <ol>
          {displayItems.map(({ caption, word },index) => (
            <li key={index}>
              <div className={`${clsx(captionClassName)} caption`}>
                {caption}
              </div>
              <div className='contentls'>{word}</div>
            </li>
          ))}
        </ol>
      )}
    </Wrap>
  );
};

export const LABEL = (
  <>
    みんなの
    <ruby>
      調<rt>しら</rt>
    </ruby>
    べた
    <ruby>
      言<rt>こと</rt>
    </ruby>
    <ruby>
      葉<rt>ば</rt>
    </ruby>
  </>
);

const ReloadButton = (props: Omit<MainButtonProps, 'children'>) => (
  <MainButton {...props}>
    <ruby>
      再<rt>さい</rt>
    </ruby>
    <ruby>
      表<rt>ひょう</rt>
    </ruby>
    <ruby>
      示<rt>じ</rt>
    </ruby>
  </MainButton>
);

const RANKING_LABEL = (
  <div>
    {LABEL}
    （ランキング）
  </div>
);

const toRankingItem = ({ rank, word }: { rank: number; word: ReactNode }) => ({
  caption: (
    <>
      {rank}
      <ruby>
        位<rt>い</rt>
      </ruby>
    </>
  ),
  word: <>{word}</>,
});

const RANDOM_LABEL = (
  <div>
    {LABEL}
    （ランダム）
  </div>
);
