import {
  DesktopDatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import clsx from 'clsx';
import ja from 'date-fns/locale/ja';
import { useState } from 'react';
import { CustomActionBar } from './CustomActionBar';
import { CustomHeader } from './CustomHeader';
import styles from './styles.module.css';
ja.options = {
  ...ja.options,
  weekStartsOn: 1,
};

type DatePickerProps = MuiDatePickerProps<Date>;

export const DatePicker = ({
  className,
  defaultValue = new Date(),
  ...props
}: DatePickerProps) => {
  const [currentMonth, setCurrentMonth] = useState<Date | null | undefined>(
    props.value || new Date(),
  );

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={ja}
      dateFormats={{ monthAndYear: 'M月', monthShort: 'MM月' }}
    >
      <MuiDatePicker
        {...props}
        closeOnSelect
        showDaysOutsideCurrentMonth
        format="yyyy/MM/dd"
        defaultValue={defaultValue}
        disableHighlightToday
        fixedWeekNumber={6}
        views={['month', 'day']}
        onOpen={() => setCurrentMonth(props.value)}
        onClose={() => setCurrentMonth(props.value)}
        viewRenderers={{
          day: (props) => (
            <DateCalendar
              {...props}
              value={currentMonth}
              onChange={(date) => {
                setCurrentMonth(date);

                props.onChange(date, 'finish');
              }}
            />
          ),
        }}
        slotProps={{
          textField: {
            className: clsx(
              className,
              styles['text-field'],
              props.disabled && styles['disabled'],
            ),
          },
          popper: { className: styles['popper'] },
          desktopPaper: { className: styles['desktop-paper'] },
          calendarHeader: {
            classes: {
              root: styles['calendar-header'],
              labelContainer: styles['label-container'],
              switchViewButton: styles['switch-view-button'],
            },
          },
          day: {
            classes: {
              dayWithMargin: styles['day-with-margin'],
              dayOutsideMonth: styles['day-outside-month'],
            },
          },
          previousIconButton: { className: styles['icon-button'] },
          nextIconButton: { className: styles['icon-button'] },
        }}
        slots={{
          actionBar: () => (
            <CustomActionBar
              currentMonth={currentMonth ?? new Date()}
              setCurrentMonth={setCurrentMonth}
            />
          ),
          calendarHeader: () => (
            <CustomHeader
              currentMonth={currentMonth ?? new Date()}
              setCurrentMonth={setCurrentMonth}
            />
          ),
          switchViewButton: () => '▼',
          openPickerButton: ({ children: _, ...props }) => (
            <button
              {...props}
              type="button"
              style={{
                position: 'absolute',
                inset: 0,
                border: 'none',
                background: 'transparent',
                cursor: props.disabled ? 'not-allowed' : 'pointer',
              }}
            />
          ),
        }}
      />
    </LocalizationProvider>
  );
};
