import { IconButton, Typography } from '@mui/material';
import './style.css';
import { useEffect, useState } from 'react';
import { useAuthContext } from '@/src/middleware/auth/AuthContext';
import { CurrentUser } from '@/src/middleware/auth/AuthContext';
import { modalAtom, themeAtom } from '../modalAtom';
import { useRecoilState } from 'recoil';
import { usersMeThemeColorPut } from '@/src/__generated__';
import { ModalCloseButton } from '../../parts/ModalCloseButton';
import { ModalDecisionButton } from '../../parts/ModalDecisionButton';
import styled from 'styled-components';
import Kanji from '../../auth/Kanji';

const VisualWrap = styled('div')(
  ({ theme }) => `
    background-color: var(${theme.backgroud})!important;    
    .modal-title-bar{
      background-color: var(${theme.maincolor});
    }
    .text-wrapper-792,.text-wrapper-793{
      color: var(${theme.textcolor})!important;
    }
  `
)

export const VisualSettings = () => {
  //教師用
  const srcs1 = ['themelight.svg', 'themebeige.svg', 'themedark.svg', 'themeblue.svg', 'themegreen.svg', 'themepink.svg']
  //児童用
  const srcs2 = ['selectcolorlight.svg', 'selectcolorbeige.svg', 'selectcolordark.svg', 'selectcolorblue.svg', 'selectcolorgreen.svg', 'selectcolorpink.svg']
  //テーマ名
  const themeName = ['ライト', 'ベージュ', 'ダーク', 'ブルー', 'グリーン', 'ピンク']

  const [_openModalKey, setOpenModalKey] = useRecoilState<any>(modalAtom)

  const [themenum, setThemenum] = useRecoilState<number | null>(themeAtom);
  const [selected, setSelected] = useState<number | null>(themenum);

  const { getCurrentUser, isTeacher } = useAuthContext();
  const currentUser: CurrentUser | null =
    (getCurrentUser ? getCurrentUser() : null) || null;

  useEffect(() => {
    if (currentUser) {
      setSelected(themenum)
    }
  }, [])

  const handleSelect = (n: number, event: MouseEvent) => {
    event.stopPropagation();
    setSelected(n)
  }

  const handleDecide = async () => {
    try {
      if (selected === null) return
      const res = await usersMeThemeColorPut({
        theme_color: selected
      })
      if (res.status === 200) {
        // setThemenum(res.data.theme_color);
        setThemenum(selected);
        setOpenModalKey(null);
      }
    } catch (error: any) {
      //TODO:エラー画面なし：画面セット後に再設定のこと
      if (error.response.status === 400) {
        alert('「theme_color」が未入力です。')
      }
      if (error.response.status === 401) {
        alert('「theme_color」の値の形式が正しくありません。')
      }
      if (error.response.status === 404) {
        alert('「user_uuid」が存在しません。')
      }
      if (error.response.status === 500) {
        alert('システムエラーが発生しました。')
      }
    }
  }

  const ThemeGrid = () => {
    return (
      Array(6).fill({}).map((d, i) => {
        return (
          <div key={i} className='wrap'>
            <div className={selected === i ? 'row selected' : 'row'}>
              <IconButton onClick={(e: any) => handleSelect(i, e)} disableRipple={true} className='iconbutton'>
                <img
                  src={
                    isTeacher?.()
                      ? `img/${srcs1[i]}`
                      : `img/${srcs2[i]}`
                  }
                />
              </IconButton>
            </div>
            <Typography className='name'>{themeName[i]}</Typography>
          </div>
        )
      })
    )
  }

  return (
    <VisualWrap className='student-visual'>
      <ModalDecisionButton className='main-button-18' type='button' onClick={handleDecide} />
      <div className="select-color">
        <ThemeGrid />
      </div>

      <div className="subtitle">
        <div className="text-wrapper-792">テーマカラーを<Kanji kana="か">変</Kanji>える</div>
      </div>
      <div className="modal-title-bar">
        <div className="sub-button-close-3">
          <ModalCloseButton className='overlap-147' onClick={() => { setOpenModalKey(null) }} />
        </div>
        <div className="message">
          <div className="text-wrapper-795"><Kanji kana="ひょうじせってい">表示設定</Kanji></div>
        </div>
      </div>
    </VisualWrap>
  );
};
