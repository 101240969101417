import {
  WordsLookedUpRankingGetParams,
  csvDictionariesJapaneseLearningHistoriesCsvGet,
  dictionariesJapaneseLearningHistoriesGet,
  wordsLookedUpRankingGet,
} from '@/src/__generated__';
import { withSaveFile } from '@/src/helpers/file';
import { useSyncHistoryQueryConditions } from '@/src/hooks/useHistoryQueryConditions';
import { useInfiniteScrollTable } from '@/src/hooks/useInifniteScrollTable';
import { useTeacherContext } from '@/src/hooks/useTeacherContext';
import {
  LearningHistoryJapaneseDictionaryQueryFormSchema,
  learningHistoryJapaneseDictionaryQueryFormSchema,
} from '@/src/schema/learning-history-japanese-dictionary-query-form-schema';
import { zodResolver } from '@hookform/resolvers/zod';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { ReactNode, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { COLUMNS } from './columns';

export const useLearningHistoryJapaneseDictionary = () => {
  const {
    hasLoaded,
    childrenList,
    schoolClassList,
    historyQueryConditions,
    classIndexToClass,
  } = useTeacherContext();

  const [rankingItems, setRankingItems] = useState<
    { rank: number; word: ReactNode }[]
  >([]);

  const query = async ({
    class_index,
    page = 1,
  }: LearningHistoryJapaneseDictionaryQueryFormSchema) => {
    const res = await dictionariesJapaneseLearningHistoriesGet({
      ...classIndexToClass(class_index),
      page,
    });
    if (res.status !== 200) {
      // TODO handle error
      return [];
    }
    return res.data.learning_histories;
  };

  const queryRanking = async (params: WordsLookedUpRankingGetParams) => {
    const res = await wordsLookedUpRankingGet(params);
    if (res.status !== 200) {
      // TODO handle error
      return;
    }
    setRankingItems([
      { rank: 1, word: res.data.first },
      { rank: 2, word: res.data.second },
      { rank: 3, word: res.data.third },
    ]);
  };

  const methods = useForm<LearningHistoryJapaneseDictionaryQueryFormSchema>({
    defaultValues: { class_index: historyQueryConditions.class_index },
    resolver: zodResolver(learningHistoryJapaneseDictionaryQueryFormSchema),
  });

  const download = () => {
    const { class_index } = methods.getValues();
    return withSaveFile(
      csvDictionariesJapaneseLearningHistoriesCsvGet({
        ...classIndexToClass(class_index),
      }),
    );
  };

  const rankingQueryFormMethods = useForm<WordsLookedUpRankingGetParams>({
    defaultValues: { target_name: 3, term_name: 2 },
  });

  useSyncHistoryQueryConditions(['class_index'], methods.watch);
  useEffect(() => {
    queryRanking(rankingQueryFormMethods.getValues());
  }, []);

  const class_index = methods.watch('class_index');

  useEffect(()=>{
    refetch();
  },[class_index])

  const { refs, flatData, refetch } = useInfiniteScrollTable(
    [class_index],
    (page) => query({ class_index, page }),
  );
  const table = useReactTable({
    columns: COLUMNS,
    data: flatData,
    getCoreRowModel: getCoreRowModel(),
  });

  return {
    refs,
    hasLoaded,
    table,
    schoolClassList,
    childrenList,
    rankingItems,
    methods,
    onSubmit: methods.handleSubmit(() => refetch()),
    rankingQueryForm: {
      methods: rankingQueryFormMethods,
      onSubmit: rankingQueryFormMethods.handleSubmit(queryRanking),
    },
    download,
  };
};
