import { TopBarModalMenu } from '@/src/components/parts/TopBarModalMenu';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Fifteen } from '../../../icons/Fifteen';
import './style.css';
// types
import { Menu } from '@/src/types/TopBarModalMenu';
import { Link } from 'react-router-dom';
import { menuAtom, modalAtom } from '../../ModalContents/modalAtom';
import { useAuthContext } from '@/src/middleware/auth/AuthContext';
import styled, { useTheme, DefaultTheme } from 'styled-components';

interface Props {
  title: string;
  name: string;
  avator: string;
  theme?: DefaultTheme
}

// メニューの項目を定義
const menuItems: Menu[] = [
  {
    text: 'プロフィール設定',
    type: 'modal',
    modalContent: <div>プロフィール設定</div>,
    icon: '/img/icon-profile.svg',
    className: 'ProfileSettings',
  },
  {
    text: '表示設定',
    type: 'modal',
    modalContent: <div>表示設定</div>,
    icon: '/img/icon-theme.svg',
    className: 'VisualSettings',
  },
  {
    text: 'セキュリティ',
    type: 'modal',
    modalContent: <div>セキュリティ</div>,
    icon: '/img/icon-secure.svg',
    className: 'PasswordReset',
  },
  {
    text: 'ログアウト',
    type: 'modal',
    modalContent: <div>ログアウト</div>,
    icon: '/img/icon-logout.svg',
    className: 'Logout',
  },
];

const AvatarWrap = styled('div')(
  ({ theme }) => `
  height: 70px;
  width: 70px;
  background: linear-gradient(180deg, #F9D1C7 0%, #F97253 100%) border-box border-box;
  border-color: transparent;
  border-radius: 6px;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);

  `
)

export const TeacherTopbar: React.FC<Props> = ({
  title,
  name,
  avator,
}) => {
  //const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useRecoilState(menuAtom);
  const openModalKey = useRecoilValue(modalAtom);
  const { isLoginByPassword, isLoginByCode } = useAuthContext();
  const theme = useTheme();

  // Modalの状態をトグルする関数
  const toggleModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  // メニューModalを閉じる
  useEffect(() => {
    if (openModalKey === null) {
      setIsModalOpen(false);
    }
  }, [openModalKey]);

  const menuItemsFiltered = () => {
    if ((isLoginByPassword && isLoginByPassword()) || (isLoginByCode && isLoginByCode())) {
      return menuItems;
    }
    return menuItems.filter((i) => (i.className !== 'PasswordReset'));
  };

  return (
    <div className="teacher-top-bar">
      {isModalOpen && (
        <TopBarModalMenu
          menuItems={menuItemsFiltered()}
          theme={theme}
          type="teacher"
          close={() => setIsModalOpen(false)}
        />
      )}
      <div className="topbar-title">{title}</div>
      <div className="wrapper">
        <AvatarWrap className="avatar-wrapper">
          <div className="avatar-bg-wrapper">
            <div className="avatar-bg">
              <div className="avatar-border" />
            </div>
            <img className="avatar-img" alt="Group" src={avator} />
          </div>
        </AvatarWrap>
        <div className="name-wrapper" onClick={toggleModal}>
          <div className="user-name">{name}</div>
          <img className="arrow-icon" alt="Vector" src={!isModalOpen ? `/img/${theme?.arrow}` : `/img/${theme?.arrowup}`} />
        </div>
      </div>
      <Fifteen className="custom-instance" />
      <Link to="/teachertop" className="tomopla-logo-link"></Link>
    </div>
  );
};
