import { onetimeCodeOnetimeCodeGet } from '@/src/__generated__';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useOneTimeCodeCheck = () => {
  const [isValid, setIsValid] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const code = query.get('onetime_code');

  useEffect(() => {
    const checkCode = async () => {
      if (!code) {
        setIsValid(false);
        setIsLoading(false);
        return;
      }

      try {
        await onetimeCodeOnetimeCodeGet(code);
        // API呼び出しが成功した場合
        setIsValid(true);
        setIsLoading(false);
        console.log('isValid', isValid);
      } catch (error) {
        // API呼び出しが失敗した場合
        setIsValid(false);
        setIsLoading(false);
        console.log('error', error);
      }
    };
    checkCode();
  }, [code]);

  return { isValid, isLoading };
};
