import { getAutoGeneratePassword } from '@/src/hooks/useGetAutoGeneratePassword';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, useWatch } from 'react-hook-form';
import { usersMePasswordResetPost } from '../__generated__';
import { PwdFormSchema, pwdFormSchema } from '../schema/pwd-form-schema';
import { useLocation, useNavigate } from 'react-router-dom'
import { useState } from 'react';

const usePwdForm = () => {
  const {
    control,
    handleSubmit,
    setValue,
    setError,
    formState: { errors },
    // useFormのジェネリクスにはdefaultValuesの型を渡す
  } = useForm<PwdFormSchema>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: undefined,
    resolver: zodResolver(pwdFormSchema),
  });

  const watchedInput = useWatch({ control });
  // フォームのエラー状況
  console.log('errors', errors);
  // フォームの入力値
  console.log('watchedInput', watchedInput);

  const navigate = useNavigate();

  // URLからのonetime_code取得
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const code = query.get('onetime_code'); //TODO:query name確認のこと

  const [isWait, setIsWait] = useState(true);

  // zodの値変換+型チェックを通過した場合のみonSubmitが呼ばれる
  const onSubmit = async (data: PwdFormSchema) => {
    // zodの値変換+型チェックを通過した値
    console.log('data', data, 'code', code);
    setIsWait(false);

    try {
      const res = await usersMePasswordResetPost({
        password_new: data.newPassword,
        onetime_code: code!    
      })
      if(res.status === 200){
        //alert('パスワードが変更されました。')
        setIsWait(false)
        navigate('/passwordresetdone')
      }
    } catch (error:any) {
      console.log(error);
      if(error.response.status != 200){
        setIsWait(true);
      }
      if (error.response.status === 400) {
        navigate('/passwordresetdone')
      }
      if (error.response.status === 401) {
        setError('newPassword', { type: 'custom', message: 'パスワードの形式が違います。' });        
      }
      if(error.response.status === 403){ //ワンタイムコード有効期限切れ
        navigate('/passwordresettimeout')
      }
      if (error.response.status === 404) {
        setError('newPassword', { type: 'custom', message: 'UUIDが存在しません。' });
      }
    }
  };

  const autoGenerate = () => {
    setValue('newPassword', getAutoGeneratePassword());
  };

  return {
    form: {
      control,
      handleSubmit,
      onSubmit,
      autoGenerate,
      errors,
      isWait,
    },
    options: {
      //
    },
  };
};

export default usePwdForm;
