import {
  LibsDomainModelInterfaceMailSendMailRequest,
  mailMailSendCodePost,
  mailSendPost,
  municipalitiesSchoolsGet,
} from '@/src/__generated__';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { z } from 'zod';

// Parental Agreement Form Schema using zod
const parentalAgreementFormSchema = z
  .object({
    child_name: z
      .string()
      .max(60, '「児童の方のお名前」の形式が正しくありません。')
      .min(1, '「児童の方のお名前」が未入力です。'),
    guardian_name: z
      .string()
      .max(60, '「保護者の方のお名前」の形式が正しくありません。')
      .min(1, '「保護者の方のお名前」が未入力です。'),
    mail_address: z
      .string()
      .min(1, '「保護者の方のメールアドレス」が未入力です。')
      .max(
        254,
        '「保護者の方のメールアドレス」は254文字以下である必要があります。',
      )
      .refine((val) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(val), {
        message: '有効なメールアドレスを入力してください',
      }),
    school_name: z.string().optional(),
    schoolAdditionalInfo: z
      .string()
      .max(60, '「学校名」の形式が正しくありません。')
      .optional(),
    termsAgreed: z
      .boolean()
      .refine(
        (val) => val,
        '「個人情報の取り扱いについて」の同意されていません。',
      ),
  })
  .superRefine(({ school_name, schoolAdditionalInfo }, ctx) => {
    if (!school_name && !schoolAdditionalInfo) {
      ctx.addIssue({
        code: 'custom',
        message: '「学校名」が未入力です。',
        path: ['schoolName'],
      });
    }
  });

// TypeScript interface for form data
interface ParentalAgreementFormSchema {
  child_name: string;
  guardian_name: string;
  mail_address: string;
  school_name: string;
  schoolAdditionalInfo?: string;
  termsAgreed: boolean;
}

const useParentalAgreementForm = () => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm<ParentalAgreementFormSchema>({
    resolver: zodResolver(parentalAgreementFormSchema),
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: {
      child_name: '',
      guardian_name: '',
      mail_address: '',
      school_name: '',
      schoolAdditionalInfo: '',
      termsAgreed: false,
    },
  });
  const [formData, setFormData] = useState<ParentalAgreementFormSchema>();
  //確認画面切り替え用ステータス
  const [isConfirm, setIsConfirm] = useState(false);
  const watchedInput = useWatch({ control });
  // 送信成功状態を追跡する
  const [isSubmittedSuccessfully, setIsSubmittedSuccessfully] = useState(false);
  const [isSubmissionComplete, setIsSubmissionComplete] = useState(false);

  console.log('watchedInput', watchedInput);

  // フォーム送信時に確認画面に切り替える
  const onSubmit = (data: ParentalAgreementFormSchema) => {
    console.log('Form Data:', data);

    // もしschoolAdditionalInfoに値が入っていたら、school_nameに追加する
    if (data.schoolAdditionalInfo) {
      data.school_name = data.schoolAdditionalInfo;
    }

    setIsConfirm(true);

    // 送信データを作成
    const submitData = {
      child_name: data.child_name,
      guardian_name: data.guardian_name,
      mail_address: data.mail_address,
      school_name: data.school_name,
    };
    setFormData(submitData);
  };

  const handleEdit = () => {
    setIsConfirm(false);
  };

  // メール送信
  const handleMailSendPost = () => {
    // メール送信コードを取得
    mailMailSendCodePost()
      .then((res) => {
        return res.data.mail_send_code;
      })
      .then((mailSendCode) => {
        if (!formData) {
          throw new Error('フォームデータが存在しません。');
        }
        const param: LibsDomainModelInterfaceMailSendMailRequest = {
          mail_contents: formData,
          mail_send_code: mailSendCode,
          type: 2, // 保護者同意の場合
        };
        // メール送信
        return mailSendPost(param);
      })
      .then((res) => {
        console.log('Mail Send Result:', res);
        setIsSubmittedSuccessfully(true);
        setIsSubmissionComplete(true);
        reset();
      })
      .catch((error) => {
        // エラーハンドリング
        console.error('Error sending mail', error);
      });
  };

  /**
   *
   * @summary 学校名をURLパラメータから取得する
   */
  // URLからクエリパラメータを取得する関数
  const getQueryParam = (param: string) => {
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams.get(param);
  };
  // 学校名を取得するためのAPIを呼び出す関数
  useEffect(() => {
    const schoolCode = getQueryParam('school_code');
    if (schoolCode) {
      municipalitiesSchoolsGet({ school_code: schoolCode })
        .then((response) => {
          if (
            response.data &&
            response.data.school_name &&
            response.data.school_name.length > 0
          ) {
            setValue('school_name', response.data.school_name[0]);
          }
        })
        .catch((error) => {
          console.error('Error:', error.response ? error.response.data : error);
        });
    }
  }, [setValue]);

  return {
    form: {
      control,
      handleSubmit,
      onSubmit,
      errors,
      handleMailSendPost,
      isSubmissionComplete,
      handleEdit,
      isConfirm,
      formData,
      isSubmittedSuccessfully,
    },
  };
};

export default useParentalAgreementForm;
