import { z } from 'zod';
import { dateRangeRefinement } from './refinements/date-range';

export const learningHistoryKanjiSkillQueryFormSchema = z
  .object({
    class_index: z.number().nullish().or(z.enum([""])),
    user_uuid: z.string().nullish(),
    time_slot: z.number().nullish(),
    category_index: z.number(),
    from_date: z.date(),
    to_date: z.date(),
  })
  .superRefine(dateRangeRefinement);

export type LearningHistoryKanjiSkillQueryFormSchema = z.infer<
  typeof learningHistoryKanjiSkillQueryFormSchema
>;
