import React, { useEffect, useState } from 'react';
import { Iconedit } from '../../../icons/Iconedit';
import './style.css';
import { RHFTextFieldDB } from '../../RHFTextFieldDB/RHFTextFieldDB';
import { useProfileSettings } from './hooks'
import MainButton from '../../parts/MainButton';
import { TeacherAvatar } from '../TeacherAvatar';
import { Button, IconButton } from '@mui/material';
import { modalAtom, profileAtom } from '../modalAtom';
import { useRecoilState } from 'recoil';
import styled, { DefaultTheme } from 'styled-components';
import { ModalCloseButton } from '../../parts/ModalCloseButton';
import { ModalDecisionButton } from '../../parts/ModalDecisionButton';
import { PencilButton } from '../../parts/PencilButton';
import { useAuthContext } from '@/src/middleware/auth/AuthContext';
import Kanji from '../../auth/Kanji';

interface Props {
  theme?: DefaultTheme
}

const ChangeProfile = styled('div')(
  ({ theme }) => `
    background: var(${theme.backgroud});
    background-size: 100% 100%;
    height: 980px;
    left: 0;
    right: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 1633px;
    border-radius: 20px;
    .modal-title-bar-4 {
      background-color: var(${theme.maincolor});
      height: 96px;
      left: 0;
      position: absolute;
      top: 0;
      width: 1633px;
      border-radius: 20px 20px 0 0;
      z-index: 10;
    }      
    .area-avatar {
      width: 360px;
      height: 423px;
      position: absolute;
      top: 269px;
      left: 272px;
      .label-avatar {
        height: 38px;
        left: 0;
        position: absolute;
        top: 0;
        width: 217px;
        div{
          color: var(${theme.textcolor});
          font-family: var(--main-font-family-m);
          
          white-space: nowrap;
          line-height: normal;
          &.text-wrapper-294 {
            font-size: 25px;
            @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.5625rem;};
            left: 0;
            letter-spacing: 1.25px;
            position: absolute;
            top: 9px;
          }
          &.text-wrapper-295 {
            font-size: 14px;
            @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.875rem;};
            left: 164px;
            letter-spacing: 0.7px;
            position: absolute;
            top: -5px;               
          }
          &.text-wrapper-296 {
            font-size: 14px;
            @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.875rem;};
            left: 183px;
            letter-spacing: -0.7px;
            position: absolute;
            top: -5px;
          }
        }            
      }
      .avatar {
        border: 8px solid transparent;
        background: linear-gradient(180deg, ${theme.chart0} 0%, ${theme.chart1} 100%) border-box border-box;
        mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0) border-box;
        mask-composite: exclude;
        -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0) border-box;
        -webkit-mask-composite: copy;
        border-radius: 16px;
        height: 360px;
        left: 0;
        position: absolute;
        top: 63px;
        width: 360px;
        .overlap-group-82 {
          height: 344px;
          position: relative;
          width: 344px;
          background-color: white;
          border-radius: 11px;
          .group-276 {
            height: 343px;
            left: 0px;
            position: absolute;
            top: 6px;
            width: 343px;
          }
        }
      }
      .avatar-boxshadow {
        width: 360px;
        height: 360px;
        position: absolute;
        top: 63px;
        left: 0;
        border-radius: 16px;
        box-shadow: 0px 2px 6px #000000b2;
      }
    }
    .area-input-profile {
      height: 298px;
      left: 757px;
      position: absolute;
      top: 394px;
      width: 605px;
      .text-wrapper-308,.text-wrapper-309,.text-wrapper-310,.text-wrapper-311,.text-wrapper-312,.text-wrapper-313,.text-wrapper-314{
        color:var(${theme.textcolor})
      }
    }    
  `
)

export const ProfileSettings = ({ theme }: Props) => {
  const {
    form: { control, handleSubmit, onSubmit, errors, setIconval, watchedInput }
  } = useProfileSettings();
  const { getCurrentUser } = useAuthContext();
  const currentUser = getCurrentUser ? getCurrentUser() : null;

  const [isAvatar, setIsAvatar] = useState(false);

  const [openModalKey, setOpenModalKey] = useRecoilState<any>(modalAtom)

  const [profiles, setProfiles] = useRecoilState(profileAtom)

  const handleIsAvatar = () => {
    setIsAvatar(!isAvatar)
    const _profiles = {
      nick_name: currentUser?.nick_name!,
      profile_icon: currentUser?.preference.profile_icon!
    }
    setProfiles(_profiles)
  }

  const handleIconSelect = (n: any) => {
    setIsAvatar(!isAvatar)
    setIconval(n)
  }

  const handleClose = () => {
    setOpenModalKey(null)
    const _profiles = {
      nick_name: profiles.nick_name ? profiles.nick_name : currentUser?.nick_name,
      profile_icon: profiles.profile_icon && profiles.profile_icon !== currentUser?.preference.profile_icon ? profiles.profile_icon : currentUser?.preference.profile_icon
    }
    setProfiles(_profiles)
  }

  return (
    <ChangeProfile>
      <div className="modal-title-bar-4">
        <div className="view-274">
          <div className="group-273">
            <div className="text-wrapper-290">プロフィール<Kanji kana="せってい">設定</Kanji></div>
          </div>
        </div>
        <div className="view-275">
          <ModalCloseButton onClick={handleClose} />
        </div>
      </div>
      {!isAvatar ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="area-avatar">
            <div className="label-avatar">
              <div className="text-wrapper-294">プロフィール<Kanji kana="がぞう">画像</Kanji></div>
            </div>
            <PencilButton className='icon-edit' theme={theme} onClick={() => {
              setIsAvatar(true)
            }} />
            <div className="avatar">
              <div className="overlap-group-82">
                <img className="group-276" alt="Group" src={`/img/avatar-${watchedInput.profile_icon! + 1}.png`} />
              </div>
            </div>
            <div className="avatar-boxshadow">&nbsp;</div>
          </div>

          <div className="area-input-profile">
            <div className="input-profile-name">
              <RHFTextFieldDB name={'nick_name'} label='' control={control} type='text' theme={theme} />
              {watchedInput.nick_name?.length === 0 && (
                <PencilButton className='iconedit-instance' theme={theme} type='button' />
              )}

            </div>
            <div className="warning-message-7">
              {errors.nick_name?.message === '表示する名前が未入力です。' && (
                <>
                  <div className="warning-message-8"><Kanji kana="ひょうじ">表示</Kanji>する<Kanji kana="なまえ">名前</Kanji>が<Kanji kana="みにゅうりょく">未入力</Kanji>です。</div>
                </>
              )}
              {errors.nick_name?.message?.includes('文字制限') && (
                <>
                  <div className="warning-message-8"><Kanji kana="ひょうじ">表示</Kanji>する<Kanji kana="なまえ">名前</Kanji>は8<Kanji kana="もじ">文字</Kanji><Kanji kana="いない">以内</Kanji>です。</div>
                </>
              )}
              {errors.nick_name?.message === '「nickname」が未入力です。' && (
                <>
                  <div className="warning-message-8"><Kanji kana="ひょうじ">表示</Kanji>する<Kanji kana="なまえ">名前</Kanji>が<Kanji kana="みにゅうりょく">未入力</Kanji>です。</div>
                </>
              )}
              {errors.nick_name?.message === '「nickname」の値の形式が正しくありません。' && (
                <>
                  <div className="warning-message-8"><Kanji kana="ひょうじ">表示</Kanji>する<Kanji kana="なまえ">名前</Kanji>の<Kanji kana="けいしき">形式</Kanji>が<Kanji kana="ただ">正</Kanji>しくありません。</div>
                </>
              )}
              {errors.nick_name?.message === '「user_uuid」が存在しません。' && (
                <>
                  <div className="warning-message-8">「user_uuid」が<Kanji kana="そんざい">存在</Kanji>しません。</div>
                </>
              )}

            </div>
            <div className="navbar-6">
              <div className="text-wrapper-308"><Kanji kana="ひょうじ">表示</Kanji>する<Kanji kana="なまえ">名前</Kanji></div>
            </div>
            <ModalDecisionButton className='main-button-11' type='submit' />
          </div>
        </form>
      ) : (
        <TeacherAvatar handleChange={handleIsAvatar} handleSelected={handleIconSelect} avator={watchedInput.profile_icon} />
      )}
    </ChangeProfile>
  );
};
