import { authConfig, useAuthContext } from '@/src/middleware/auth/AuthContext';
import { PostLoginHook } from '@/src/middleware/auth/AuthContext/AuthTypes';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './style.css';

export const Entry = (): JSX.Element => {
  const { CodeLoginSubmit, processLogout } = useAuthContext();
  const location = useLocation();
  const navigate = useNavigate();

  const LICENSE_STATUS_UNAVAILABLE = 0;
  const LICENSE_STATUS_READ_ONLY = 1;
  const LICENSE_STATUS_AVAILABLE = 2;

  const ERROR_MESSAGE_LOGIN_ONLY = "ライセンスの<ruby>有<rt>ゆう</rt></ruby><ruby>効<rt>こう</rt></ruby><ruby>期<rt>き</rt></ruby><ruby>限<rt>げん</rt></ruby>が<ruby>過<rt>す</rt></ruby>ぎてます。"
  const ERROR_MESSAGE_UNAVAILABLE = "<ruby>有<rt>ゆう</rt></ruby><ruby>効<rt>こう</rt></ruby>な<ruby>学<rt>がく</rt></ruby><ruby>習<rt>しゅう</rt></ruby>アプリのライセンスがありません。"
  const ERROR_MESSAGE_EXPIRED = "ログイン<ruby>可<rt>か</rt></ruby><ruby>能<rt>のう</rt></ruby><ruby>期<rt>き</rt></ruby><ruby>間<rt>かん</rt></ruby>を<ruby>過<rt>す</rt></ruby>ぎているため、ログインできません。"

  const checkLicense: PostLoginHook = (loginUser, postLoginRedirect) => {
    const licenses = loginUser.license;
    if (licenses.length == 0) {
      // ライセンスなし
      processLogout!().then(() => {
        console.log("logout processed");
      });
      navigate('/error', {state: {errorMessage: ERROR_MESSAGE_UNAVAILABLE}});
      return false;
    }
    const active = licenses.filter((l) => l.status == LICENSE_STATUS_AVAILABLE);
    const readonly = licenses.filter((l) => l.status == LICENSE_STATUS_READ_ONLY);
    const expired = licenses.filter((l) => l.status == LICENSE_STATUS_UNAVAILABLE);
    if (active.length > 0) {
      // ライセンス有効
      postLoginRedirect(loginUser);
      return true;
    }
    if (readonly.length > 0) {
      // アプリの有効期限切れ ログインは可能
      navigate('/error', {
        state: { errorMessage: ERROR_MESSAGE_LOGIN_ONLY },
      });
      return true;
    }
    processLogout!().then(() => {
      console.log("logout processed");
    });
    if (expired.length > 0) {
      // ログイン可能時間切れ
      navigate('/error', {
        state: { errorMessage: ERROR_MESSAGE_EXPIRED },
      });
      return false;
    }
    // 有効なライセンスなし（本来到達しない）
    navigate('/error', {
      state: { errorMessage: ERROR_MESSAGE_UNAVAILABLE },
    });
    return false;
  };
  authConfig.postLoginHook = checkLicense;

  const LoginCall = (code: string) => {
    // ログイン処理中の表示
    if (CodeLoginSubmit) {
      CodeLoginSubmit(code)
        .then((status: number | undefined) => {
          console.log('submit result');
          console.log(status);
          if (status != 200) {
            navigate('/error', {
              state: { errorMessage: 'ログインできませんでした' },
            });
          }
        })
        .catch((e) => {
          navigate('/error', {
            state: {
              errorMessage:
                'システムエラーによりログインできませんでした。しばらくお待ちいただきまたログインし直してください。',
            },
          });
        });
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    console.log(params);
    if (params && params.get('cd')) {
      const code = params.get('cd');
      console.log(code);
      LoginCall(code!);
    } else {
      console.log('error');
      navigate('/error', {
        state: {
          errorMessage:
            '許可されてないアクセスです',
        },
      });
}
  }, []);

  return (
    <>
      <div className="page-login">
        <img src="/img/type_d.gif" alt="読み込み中" className="loading" />
      </div>
    </>
  );
};
