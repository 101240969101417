.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 0 27px;
  border-radius: 20px 20px 0 0;
  z-index: 1;

  color: var(--white);
  background: var(--tomopla-color);
}

.calendar-header button {
  background: none;
  border: none;
  cursor: pointer;
}
.calendar-header > .current {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  width: 106px;
}
.calendar-header > .current > button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  z-index: 2;
  font-size: 22px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none)  {font-size: 1.375rem;}
  color: var(--white) !important;
}
.calendar-header > .current > button:hover,
.calendar-header > .current > button.open {
  background-color: var(--tomopla-color-mid);
}
.calendar-header .current span {
  font-size: 11px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none)  {font-size: 0.6875rem;}
}
.calendar-header .month-select {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 6px 0 13px;
  background: var(--white);
  line-height: 36.5px;
  letter-spacing: 0.05em;
  filter: drop-shadow(
    0px 2.6000001430511475px 3.800000190734863px rgba(0, 0, 0, 0.7)
  );
  clip-path: inset(0 0 100%);
  opacity: 0.5;
  transition: all 0.3s ease-in-out;
}
.calendar-header .month-select.open {
  opacity: 1;
  clip-path: inset(-100%);
}

.calendar-header .month-select button {
  color: var(--letter-black);
  font-size: 18px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none)  {font-size: 1.125rem;}
  border-radius: 5px;
  width: 65px;
  text-align: center;
}

.calendar-header .month-select button.current {
  border: 2px solid var(--beta-blue);
  background-color: var(--light-blue-2);
}

.calendar-header .month-select button:hover {
  background-color: var(--light-blue-2);
}

.calendar-header > button:first-child {
  left: 10px;
  transform: rotate(90deg);
}
.calendar-header > button:last-child {
  right: 10px;
  transform: rotate(-90deg);
}
.calendar-header > button:disabled {
  visibility: hidden;
}
