import {
  DownloadButton,
  FormItemCategorySelect,
  FormItemSelect,
  TeacherHistorySelect,
  styles as formStyles,
} from '@/src/components/HistoryQueryForm';
import { HistoryQueryFormTeacher } from '@/src/components/HistoryQueryForm/HistoryQueryFormTeacher';
import { KanjiToggleTeacher } from '@/src/components/HistoryQueryForm/items/KanjiToggle';
import { TableWithScroll } from '@/src/components/Table';
import { TeacherFrame } from '@/src/components/layouts/TeacherFrame';
import clsx from 'clsx';
import { FormProvider } from 'react-hook-form';
import { useLearningHistoryKanjiSkillTest } from './hooks';
import styles from './styles.module.css';
import styled, { useTheme } from 'styled-components';
import { useEffect } from 'react';
import { useToppageTeachers } from '../TeacherTop/hooks';
import { useRecoilState } from 'recoil';
import { themeAtom, profileAtom } from '@/src/components/ModalContents/modalAtom';
import { TeachingUnitEntity } from '@/src/__generated__';

type Props = {
  onThemeChange: (status: number) => void;
};

export const WrapDiv = styled('div')(
  ({ theme }) => `
  table{
    tbody{
      tr{
        &:nth-child(8n + 5) td,
        &:nth-child(8n + 6) td,
        &:nth-child(8n + 7) td,
        &:nth-child(8n) td {
          background: var(${theme.lightcolor});

        td{
          &:last-child {
            color: var(${theme.textlabel})
          }
        }
      }      
    }
  }  
  `
)

export const TeacherHistoryKanjiTest = (props: Props) => {
  const {
    hasLoaded,
    table,
    categories,
    teachingUnits,
    methods,
    onSubmit,
    download,
    type,
  } = useLearningHistoryKanjiSkillTest();

  const { currentUser } = useToppageTeachers();
  const [themenum, setThemenum] = useRecoilState(themeAtom);
  const [profiles, setProfiles] = useRecoilState(profileAtom);

  useEffect(() => {
    if (currentUser) {
      if (!themenum) {
        setThemenum(currentUser?.preference.theme_color);
      }
      const _profiles = {
        nick_name: profiles.nick_name
          ? profiles.nick_name
          : currentUser?.nick_name,
        profile_icon:
          profiles.profile_icon &&
            profiles.profile_icon !== currentUser?.preference.profile_icon
            ? profiles.profile_icon
            : currentUser?.preference.profile_icon,
      };
      if (
        _profiles.nick_name !== profiles.nick_name ||
        _profiles.profile_icon !== profiles.profile_icon
      ) {
        setProfiles(_profiles);
      }
      methods.reset();
    }
  }, [currentUser, profiles, themenum]);

  useEffect(() => {
    if (currentUser && typeof themenum === 'number') {
      props.onThemeChange(themenum);
    }
  }, [themenum, currentUser]);

  const theme = useTheme();

  const extract_teaching_units = (teachingUnits: TeachingUnitEntity[]) => {
    const ret = teachingUnits.map((item) => ({
      label: item.teaching_unit_name!,
      value: item.teaching_unit_id!,
    }));
    ret.unshift({label: "", value: 0});
    return ret;
  }

  const current_teaching_unit_id = methods?.getValues("teaching_unit_id");

  return (
    <TeacherFrame title="学習履歴 教師用">
      <WrapDiv className={styles.body}>
        <FormProvider {...methods}>
          <HistoryQueryFormTeacher
            onSubmit={onSubmit}
            states={{
              studentSelectDisabled: true,
              dateRangeDisabled: true,
              timeSlotDisabled: true,
            }}
          >
            <div className={clsx(formStyles.row, styles['selects-row'])}>
              <div className={styles.selects}>
                <TeacherHistorySelect />
                {hasLoaded && (
                  <>
                    <FormItemCategorySelect
                      className={clsx(
                        styles['category-select'],
                        styles['small-label'],
                        'label'
                      )}
                      label="学年・学期／上下をえらぶ"
                      categories={categories}
                      noRuby
                    />
                    <FormItemSelect<number>
                      className={clsx(
                        styles['teaching-unit-select'],
                        styles['small-label'],
                        'label'
                      )}
                      name="teaching_unit_id"
                      label="単元をえらぶ"
                      options={extract_teaching_units(teachingUnits)}
                      noRuby
                    />
                  </>
                )}
              </div>
              <div className={styles.buttons}>
                {/* <SubmitButton noRuby /> */}
                <DownloadButton onClick={download} />
              </div>
            </div>
            <div className={clsx(formStyles.row, styles['toggle-row'])}>
              {type && <KanjiToggleTeacher type={type} />}
            </div>
          </HistoryQueryFormTeacher>
        </FormProvider>
        {hasLoaded && (current_teaching_unit_id > 0) && (
          <TableWithScroll
            className={clsx('kanjitest', styles.table)}
            table={table}
            maxWidth={1440}
            maxHeight={630}
          />
        )}
      </WrapDiv>
    </TeacherFrame>
  );
};
