import { Select, Props as SelectProps } from '@/src/components/Select/Select';
import { ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Label } from './Label';

export type FormItemSelectProps<T extends {}> = SelectProps<T> & {
  label?: ReactNode;
  labelWidth?: number;
  name: string;
};

export const FormItemSelect = <T extends {}>({
  className = '',
  name,
  label,
  labelWidth,
  ...props
}: FormItemSelectProps<T>): JSX.Element => {
  const { control } = useFormContext();
  return label ? (
    <div style={{ display:'flex',gap:'1em',alignItems:'center',position:'relative' }}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => <Select {...field} {...props} />}
      />
      <div className={className} style={{fontSize:'1rem',position:'absolute',top:'-25px',margin:'0'}}>{label}</div>
    </div>
  ) : (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Select className={className} {...field} {...props} />
      )}
    />
  );
};
