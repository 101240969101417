import { isBefore, addDays } from 'date-fns';
import { RefinementCtx } from 'zod';

export const DATE_RANGE_ERROR = '終了日が開始日より前の日付になっています。';
type DateRange = {
  from_date: Date;
  to_date: Date;
};
export const dateRangeRefinement = (arg: DateRange, ctx: RefinementCtx) => {
  if (isBefore(arg.to_date, arg.from_date))
    ctx.addIssue({
      code: 'custom',
      path: ['to_date'],
      message: DATE_RANGE_ERROR,
    });
};
