/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

interface Props {
  className: any;
}

export const TwentyTwo = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={`twenty-two ${className}`}
      fill="none"
      height="157"
      viewBox="0 0 493 157"
      width="493"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_9_7270)">
        <path
          className="path-2"
          d="M30.1503 48.1174H4.31026C2.45293 48.1174 0.947266 49.6231 0.947266 51.4804V153.32C0.947266 155.178 2.45293 156.683 4.31026 156.683H30.1503C32.0076 156.683 33.5133 155.178 33.5133 153.32V51.4804C33.5133 49.6231 32.0076 48.1174 30.1503 48.1174Z"
          fill="#FA7355"
        />
        <path
          className="path-2"
          d="M65.291 116.527C75.2545 116.527 83.3315 108.45 83.3315 98.4865C83.3315 88.523 75.2545 80.446 65.291 80.446C55.3275 80.446 47.2505 88.523 47.2505 98.4865C47.2505 108.45 55.3275 116.527 65.291 116.527Z"
          fill="#FA7355"
        />
        <path
          className="path-2"
          d="M216.493 69.0079V51.4804C216.493 49.6184 214.982 48.1174 213.13 48.1174H122.158C120.296 48.1174 118.795 49.6279 118.795 51.4804V69.0079C118.795 70.8699 120.305 72.3709 122.158 72.3709H138.346C139.685 72.3709 140.778 73.4634 140.778 74.8029V83.6759C140.778 85.0154 139.685 86.1079 138.346 86.1079H122.158C120.296 86.1079 118.795 87.6184 118.795 89.4709V106.998C118.795 108.86 120.305 110.361 122.158 110.361H138.346C139.685 110.361 140.778 111.454 140.778 112.793V122.996C140.778 141.588 155.854 156.664 174.446 156.664H213.13C214.992 156.664 216.493 155.154 216.493 153.301V135.774C216.493 133.912 214.982 132.411 213.13 132.411H182.967C177.657 132.411 173.344 128.107 173.344 122.787V112.784C173.344 111.444 174.436 110.352 175.776 110.352H213.13C214.992 110.352 216.493 108.841 216.493 106.989V89.4614C216.493 87.5994 214.982 86.0984 213.13 86.0984H175.776C174.436 86.0984 173.344 85.0059 173.344 83.6664V74.7934C173.344 73.4539 174.436 72.3614 175.776 72.3614H213.13C214.992 72.3614 216.493 70.8509 216.493 68.9984V69.0079Z"
          fill="#FA7355"
        />
        <path
          className="path-2"
          d="M489.589 48.1174H398.607C396.75 48.1174 395.244 49.6231 395.244 51.4804V69.0079C395.244 70.8653 396.75 72.3709 398.607 72.3709H489.589C491.446 72.3709 492.952 70.8653 492.952 69.0079V51.4804C492.952 49.6231 491.446 48.1174 489.589 48.1174Z"
          fill="#FA7355"
        />
        <path
          className="path-2"
          d="M398.617 86.1174C396.755 86.1174 395.254 87.6279 395.254 89.4804V107.008C395.254 108.87 396.764 110.371 398.617 110.371H457.954C459.293 110.371 460.386 111.463 460.386 112.803V122.806C460.386 128.117 456.082 132.43 450.762 132.43H436.807C434.945 132.43 433.444 133.94 433.444 135.793V153.32C433.444 155.182 434.954 156.683 436.807 156.683H459.284C477.875 156.683 492.952 141.607 492.952 123.015V89.4899C492.952 87.6279 491.441 86.1269 489.589 86.1269H398.617V86.1174Z"
          fill="#FA7355"
        />
        <path
          className="path-2"
          d="M252.431 48.1174C250.569 48.1174 249.068 49.6279 249.068 51.4804V69.0079C249.068 70.8699 250.578 72.3709 252.431 72.3709H311.768C313.107 72.3709 314.2 73.4634 314.2 74.8029V122.797C314.2 128.107 309.896 132.42 304.576 132.42H290.697C288.835 132.42 287.334 133.931 287.334 135.783V153.311C287.334 155.173 288.844 156.674 290.697 156.674H313.098C331.689 156.674 346.766 141.597 346.766 123.006V51.4804C346.766 49.6184 345.255 48.1174 343.403 48.1174H252.431Z"
          fill="#FA7355"
        />
        <path
          className="path-2"
          d="M373.499 30.533C373.499 30.533 373.499 30.5045 373.499 30.4855L373.518 30.4095C373.575 30.077 373.613 29.735 373.613 29.393C373.613 26.334 371.134 23.8545 368.084 23.8545C367.41 23.8545 366.773 23.978 366.184 24.1965L366.431 24.111C366.042 24.187 365.652 24.225 365.244 24.225C361.719 24.225 358.86 21.3655 358.86 17.841C358.86 16.3875 359.344 15.048 360.161 13.9745C360.389 13.6515 360.598 13.3095 360.779 12.958L360.798 12.9295C361.434 11.704 361.795 10.3265 361.795 8.854C361.795 3.9615 357.834 0 352.941 0C350.49 0 348.277 0.9975 346.671 2.603L322.361 27.9775C322.028 28.3005 321.715 28.6425 321.401 28.9845L321.002 29.4025H321.031C316.784 34.276 314.21 40.6315 314.21 47.595C314.21 62.8805 326.598 75.2685 341.883 75.2685C355.136 75.2685 366.203 65.949 368.911 53.5135L368.949 53.3425C368.977 53.2095 369.006 53.0765 369.025 52.9435L373.509 30.5425L373.499 30.533Z"
          fill="#FA7355"
        />
        <path
          className="path-2"
          d="M350.965 41.3915H349.987C348.932 41.3915 348.077 40.5365 348.077 39.482V38.5035C348.077 35.15 345.569 32.1385 342.216 31.9675C338.644 31.7775 335.689 34.618 335.689 38.152V39.482C335.689 40.5365 334.834 41.3915 333.78 41.3915H332.801C329.448 41.3915 326.436 43.8995 326.265 47.253C326.075 50.825 328.916 53.7795 332.45 53.7795H333.78C334.834 53.7795 335.689 54.6345 335.689 55.689V56.6675C335.689 60.021 338.197 63.0325 341.551 63.2035C345.123 63.3935 348.077 60.553 348.077 57.019V55.689C348.077 54.6345 348.932 53.7795 349.987 53.7795H351.317C354.851 53.7795 357.691 50.825 357.501 47.253C357.33 43.8995 354.319 41.3915 350.965 41.3915Z"
          fill="#FAFFBE"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_9_7270">
          <rect
            className="rect"
            fill="white"
            height="156.683"
            transform="translate(0.947266)"
            width="492.005"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
