import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { z } from 'zod';

import {
  LibsDomainModelInterfaceMailSendMailRequest,
  mailMailSendCodePost,
  mailSendPost,
} from '@/src/__generated__';

import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';

const useContactForm = () => {

  const siteNames: any = {
    kjm: 'あかねこ漢字スキル 光村図書版',
    kjt: 'あかねこ漢字スキル 東京書籍版',
    kjk: 'あかねこ漢字スキル 教育出版版',
    ket: 'あかねこ計算スキル 東京書籍版',
    kek: 'あかねこ計算スキル 啓林館版',
    keh: 'あかねこ計算スキル 標準版',
    hsm: 'あかねこひらがな・すうじスキル  光村図書版',
    hst: 'あかねこひらがな・すうじスキル 東京書籍版',
    hsk: 'あかねこひらがな・すうじスキル 教育出版版',
    kth: 'あかねこカタカナスキル',
    dks: 'あかねこ よくわかる読解スキル',
    kan: '漢字の学習 漢字かんぺきくん',
    kan_hkd: '北海道限定 漢字の学習 漢字かんぺきくん',
    utm: '書く力トレーニング うつしまる',
    natsu: 'あかねこ夏スキル',
    fuyu: 'あかねこ冬スキル',
    siage: 'あかねこしあげスキル',
  }

  const baseschema = z.object({
    site_name: z.string().min(1, { message: '選択してください。' }),
    type: z.string().optional(),
    name: z.string().optional(),
    age: z.string().optional(),
    business: z.string().optional(),
    prefecture_name: z.string().optional(),
    mail_address: z.string().optional(),
    tel: z.string().optional(),
    message: z.string().min(1, { message: "メッセージを入力してください。" }),
  })
  const conditionalScheme = z.discriminatedUnion("type", [
    z.object({
      type: z.literal('お問い合わせ'),
      mail_address: z.string().min(1, "メールアドレスを入力してください。").email()
    }),
    z.object({
      type: z.literal('ご意見'),
      mail_address: z.string().optional()
    })
  ])
  const schema = baseschema.and(conditionalScheme);
  type InputState = z.infer<typeof schema>

  //メール必須確認用
  const [isEmail, setIsEmail] = useState(false);

  const [formData, setFormData] = useState<any>();

  const [searchParams, setSearchParams] = useSearchParams();

  const [pageState, setPageState] = useState(0);
  //0:top 1:confirm 2:thnaks 3:rule 4:policy

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset
  } = useForm<InputState>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: zodResolver(schema),
    defaultValues: {
      site_name: searchParams && siteNames[searchParams.get('id')!] !== undefined ? siteNames[searchParams.get('id')!] : '未指定',
    }
  });

  const watchedType = useWatch({
    control,
    name: 'type'
  })

  useEffect(() => {
    watchedType && watchedType === "お問い合わせ" ? setIsEmail(true) : setIsEmail(false)
  }, [watchedType])

  const navigate = useNavigate();

  const onSubmit = (data: any) => {
    const obj = Object.fromEntries(
      Object.entries(data).map(([k, v]) => [k, v === "" ? "<未入力>" : v]));
    console.log(obj);
    setFormData(obj);
    setPageState(1);
  }

  const onEdit = () => {
    setPageState(0);    
  }

  const onTop = () => {    
    reset();
    setPageState(0); 
  }
  
  const onThanks = () => {
    setPageState(2);
  }

  const onShowRule = () => {
    setPageState(3)
  }

  const onShowPolicy = () => {
    setPageState(4)
  }

  const onSendMail = () => {
    mailMailSendCodePost()
      .then((res) => {
        if (!res.data) {
          throw new Error('メール送信コードの取得に失敗しました。')
        }
        return res.data.mail_send_code
      })
      .then((code) => {
        if (!formData) {
          throw new Error('フォームデータが存在しません。')
        }

        const param: LibsDomainModelInterfaceMailSendMailRequest = {
          mail_contents: formData,
          mail_send_code: code,
          type: 3
        };
        mailSendPost(param)
          .then((res) => {
            if (!res.data) {
              throw new Error('メール送信応答データが存在しません。');
            }
            console.log('handleMailSendPost data', res.data);
          })
          .catch((e) => {
            console.error('handleMailSendPost catch', e);
          });
      })
      .finally(() => {
        setPageState(2);
      })
      .catch((e) => {
        console.error('handleMailSendPost catch', e);
      })

  }

  return {
    form: {
      control,
      watch,
      register,
      handleSubmit,
      onSubmit,
      errors,
      isEmail,
      formData,
      onEdit,
      onSendMail,
      onShowRule,
      onShowPolicy,
      onTop,
      pageState
    },
    options: {
      //
    },
  }
}

export default useContactForm;
