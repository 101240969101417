import { LICENSES, ROUTES } from './constants';

export const defaultHistoryPage = (licenses: number[] | null | undefined) => {
  if (!licenses) return;
  if (LICENSES.learning_contents.kanji.some((item) => licenses.includes(item)))
    return ROUTES.teacher.kanji;
  if (
    LICENSES.learning_contents.hiragana_suuji.some((item) =>
      licenses.includes(item),
    )
  )
    return ROUTES.teacher.kanji;
  if (
    LICENSES.learning_contents.katakana.some((item) => licenses.includes(item))
  )
    return ROUTES.teacher.kanji;
  if (LICENSES.learning_contents.romaji.some((item) => licenses.includes(item)))
    return ROUTES.teacher.romaji;
  if (LICENSES.learning_contents.keisan.some((item) => licenses.includes(item)))
    return ROUTES.teacher.keisan;
};
