import { Modal } from '@mui/material';
import React from 'react';
import './style.css';

import { modalAtom } from '../../ModalContents/modalAtom';

// types
import { useRecoilState } from 'recoil';
import { Menus } from '../../../types/TopBarModalMenu';
import { Logout } from '../../ModalContents/Logout/Logout';
import { PasswordReset } from '../../ModalContents/PasswordReset';
import { ProfileSettings } from '../../ModalContents/ProfileSettings';
import { VisualSettings } from '../../ModalContents/VisualSettings';
import Kanji from '../../auth/Kanji';
import { useAuthContext } from '@/src/middleware/auth/AuthContext';

export const TopBarModalMenu: React.FC<Menus> = ({
  menuItems,
  theme,
  type,
  close,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [openModalKey, setOpenModalKey] = useRecoilState<any>(modalAtom)
  const { redirect } = useAuthContext();

  const handleOpenModal = (key: string) => {
    console.log(key)
    setOpenModalKey(key);
  };

  const handleLink = (path: string | null | undefined) => {
    if (path && redirect) {
      redirect(path);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const switchModal = (key: any) => {
    switch (key) {
      // プロフィール設定ProfileSettings
      case 'ProfileSettings':
        return (
          <ProfileSettings theme={theme} />
        )
        break;
      //表示設定 VisualSettings
      case 'VisualSettings':
        return <VisualSettings />;
      //セキュリティ PasswordReset
      case 'PasswordReset':
        return <PasswordReset />;
      //ログアウト Logout
      case 'Logout':
        return <Logout />;
      default:
        break;
    }
  };

  return (
    <>
      <div
        className="menu-modal-bg"
        onClick={(e) => {
          e.stopPropagation();
          close();
        }}
      />
      <div className={'menu-modal menu-item-count-' + menuItems.length}>
        {menuItems.map((item, index) =>
          item.type === 'modal' ? (
            <div
              key={item.text}
              onClick={() => handleOpenModal(item.text)}
              className={`modal-menu-item-${index + 1}`}
            >
              {item.text.includes("プロフィール") && type === 'student' && (
                <div className={`menu-item-text ${type}`}>プロフィール<Kanji kana="せってい">設定</Kanji></div>
              )}
              {item.text.includes("プロフィール") && type === 'teacher' && (
                <div className={`menu-item-text ${type}`}>{item.text}</div>
              )}
              {item.text.includes("表示") && type === 'student' && (
                <div className={`menu-item-text ${type}`}><Kanji kana="ひょうじ">表示</Kanji><Kanji kana="せってい">設定</Kanji></div>
              )}
              {item.text.includes("表示") && type === 'teacher' && (
                <div className={`menu-item-text ${type}`}>{item.text}</div>
              )}
              {item.text.includes("セキュリティ") && (
                <div className={`menu-item-text ${type}`}>{item.text}</div>
              )}
              {item.text.includes("ログアウト") && (
                <div className={`menu-item-text ${type}`}>{item.text}</div>
              )}
              <div className="menu-item-circle">
                <img src={item.icon} alt="" />
              </div>
            </div>
          ) : (
            <div
              key={item.text}
              onClick={() => handleLink(item.href)}
              className={`modal-menu-item-${index + 1}`}
            >
              <div className="menu-item-text"><Kanji kana="がくしゅう" ls="-2">学習</Kanji>りれき</div>
              <div className="menu-item-circle">
                <img src={item.icon} alt="" />
              </div>
            </div>
          ),
        )}
      </div>
      {menuItems
        .filter((item) => item.type === 'modal')
        .map((item) => (
          <Modal
            key={item.text}
            open={openModalKey === item.text}
            onClose={() => {
              setOpenModalKey(null);
            }}
            className={item.className}
          >
            <div>{switchModal(item.className)}</div>
          </Modal>
        ))}
    </>
  );
};
