import './style.css';

export const StudentHelp = (): JSX.Element => {
  return (
    <div className="student-help">
      <div className="div-12">
        <div className="overlap-38">
          <div className="main-container">
            <div className="help-contents">
              <div className="block-QA">
                <div className="row-QA">
                  <div className="question">
                    <div className="group-96">
                      <div className="group-97">
                        <div className="text-wrapper-157">Q.</div>
                        <div className="text-wrapper-158">
                          ログインの方法をおしえてください。
                        </div>
                      </div>
                    </div>
                    <img
                      className="frame-10"
                      alt="Frame"
                      src="/img/frame-5631.svg"
                    />
                  </div>
                </div>
                <div className="row-QA-2">
                  <div className="answer">
                    <div className="group-98">
                      <div className="group-99">
                        <div className="text-wrapper-157">A.</div>
                        <div className="text-wrapper-159">
                          あああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああ
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="question-2">
                    <div className="group-100">
                      <div className="group-101">
                        <div className="text-wrapper-157">Q.</div>
                        <div className="text-wrapper-158">
                          ログインカードのつかいかたをおしえてください。
                        </div>
                      </div>
                    </div>
                    <img
                      className="frame-10"
                      alt="Frame"
                      src="/img/frame-5631-1.svg"
                    />
                  </div>
                </div>
                <div className="question-wrapper">
                  <div className="question-3">
                    <div className="group-102">
                      <div className="group-103">
                        <div className="text-wrapper-157">Q.</div>
                        <div className="text-wrapper-158">
                          自分の学習りれきはどこから見られますか。
                        </div>
                      </div>
                    </div>
                    <img
                      className="frame-10"
                      alt="Frame"
                      src="/img/frame-5631.svg"
                    />
                  </div>
                </div>
              </div>
              <div className="genre-title">
                <div className="text-wrapper-160">トモプラについて</div>
              </div>
              <div className="section-title">
                <div className="group-104">
                  <div className="group-105">
                    <div className="text-wrapper-161">よくある質問</div>
                  </div>
                </div>
                <img
                  className="group-106"
                  alt="Group"
                  src="/img/group-5699.png"
                />
              </div>
            </div>
            <div className="link-button">
              <div className="group-107">
                <div className="group-108">
                  <img className="group-109" alt="Group" />
                  <div className="text-wrapper-162">児童用マニュアル</div>
                </div>
              </div>
            </div>
            <div className="main-message">
              <div className="text-wrapper-163">操作マニュアルはこちら</div>
            </div>
            <div className="main-header">
              <div className="text-wrapper-164">マニュアル</div>
              <img
                className="group-110"
                alt="Group"
                src="/img/group-5697-1.png"
              />
            </div>
          </div>
          <div className="input-search-2">
            <div className="overlap-39">
              <div className="input-help-keyword">
                <div className="overlap-group-32">
                  <div className="rectangle-20" />
                  <div className="text-wrapper-165">つかいかたをけんさく</div>
                </div>
              </div>
              <div className="main-button-search">
                <img
                  className="vector-28"
                  alt="Vector"
                  src="/img/vector-10.svg"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="student-top-bar">
          <img className="line-5" alt="Line" src="/img/line-12-3.svg" />
          <div className="group-111">
            <div className="text-wrapper-166">トモプラの つかいかた</div>
            <div className="group-112">
              <div className="group-113" />
              <div className="frame-11">
                <div className="text-wrapper-167">山田 一郎</div>
                <img
                  className="vector-29"
                  alt="Vector"
                  src="/img/vector-11.svg"
                />
              </div>
            </div>
            <div className="frame-12">
              <div className="text-wrapper-168">
                コーポレート
                <br />
                ロゴ
              </div>
            </div>
          </div>
          <div className="group-114">
            <div className="text-wrapper-169">
              トモプラ
              <br />
              ホーム
            </div>
            <div className="rectangle-21" />
          </div>
        </div>
        <div className="scroll-bar" />
      </div>
    </div>
  );
};
