import OutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput';
import './InputBox.css';
import { useController } from 'react-hook-form';

export type InputBoxProps = OutlinedInputProps & {control: any, name: string};

const InputBox = (props: InputBoxProps) => {
  const { className, name, control } = props;
  const {
    field,
  } = useController({ name, control });
  const value= field.value ?? '';
  const inputRef= field.ref;
  const onChange = field.onChange;
  const onBlur= field.onBlur;
  const fieldProps = {...props, value, inputRef, onChange, onBlur};
  return (
    <div className={`inputbox ${className}`}>
      <OutlinedInput {...fieldProps} />
    </div>
  );
};

export default InputBox;
