import { LicenseEntity } from '@/src/__generated__';
import {
  HistoryQueryFormStudent,
  StudentHistorySelect,
  styles as formStyles,
} from '@/src/components/HistoryQueryForm';
import { KEISAN_LICENSES } from '@/src/components/HistoryQueryForm/constants';
import { Label } from '@/src/components/HistoryQueryForm/items/Label';
import {
  profileAtom,
  themeAtom,
} from '@/src/components/ModalContents/modalAtom';
import { Select } from '@/src/components/Select';
import { StudentFrame } from '@/src/components/layouts/StudentFrame';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useRecoilState } from 'recoil';
import styled, { useTheme } from 'styled-components';
import { useLearningHistoryKanjiSkill } from '../StudentHistoryKanji/hooks';
import { useToppageStudent } from '../StudentTop/hook';
import styles from './styles.module.css';

type Props = {
  onThemeChange: (status: number) => void;
};

export const StudentHistoryKeisan = (props: Props): JSX.Element => {
  const { currentUser } = useToppageStudent();
  const [themenum, setThemenum] = useRecoilState(themeAtom);
  const [profiles, setProfiles] = useRecoilState(profileAtom);
  const {
    table,
    conditions: { categories },
    type,
    methods,
    onSubmit,
    errorMessage,
    hasLoaded,
  } = useLearningHistoryKanjiSkill();

  useEffect(() => {
    if (currentUser) {
      if (!themenum) {
        setThemenum(currentUser?.preference.theme_color);
      }
      const _profiles = {
        nick_name: profiles.nick_name
          ? profiles.nick_name
          : currentUser?.nick_name,
        profile_icon:
          profiles.profile_icon &&
            profiles.profile_icon !== currentUser?.preference.profile_icon
            ? profiles.profile_icon
            : currentUser?.preference.profile_icon,
      };
      if (
        _profiles.nick_name !== profiles.nick_name ||
        _profiles.profile_icon !== profiles.profile_icon
      ) {
        setProfiles(_profiles);
      }
    }
  }, [currentUser, profiles, themenum]);

  useEffect(() => {
    if (currentUser && typeof themenum === 'number') {
      props.onThemeChange(themenum);
    }
  }, [themenum, currentUser]);

  const theme = useTheme();

  const [once,] = useState(true);

  const CoverDiv = styled('div')(
    ({ theme }) => `
    width:499px;
    height:39px;
    background-color: var(${theme.backgroud});
    position:absolute;
    z-index:99;
    top:50px;
    `
  )

  const P = styled('p')(
    ({ theme }) => `
      color:var(${theme.textcolor})!important
    `,
  );

  return (
    <StudentFrame title="学習りれき">
      <div className={styles.body}>
        <FormProvider {...methods}>
          <HistoryQueryFormStudent
            onSubmit={onSubmit}
            errorMessage={errorMessage}
          >
            <div className={formStyles['row']}>
              <StudentHistorySelect labelWidth={180} />
              {/* {categories.length > 0 && (
                <FormItemCategorySelect categories={categories} />
              )} */}
              <KeisanSkillSelect
                license={
                  currentUser?.license.filter(({ status }) => status === 2) ||
                  []
                }
              />
              {once && <CoverDiv />}
            </div>
          </HistoryQueryFormStudent>
        </FormProvider>
        <P className={styles.description}>
          「
          <ruby>
            学<rt>がく</rt>
          </ruby>
          <ruby>
            年<rt>ねん</rt>
          </ruby>
          をえらぶ」で
          <ruby>
            教<rt>きょう</rt>
          </ruby>
          <ruby>
            材<rt>ざい</rt>
          </ruby>
          の
          <ruby>
            学<rt>がく</rt>
          </ruby>
          <ruby>
            年<rt>ねん</rt>
          </ruby>
          をえらぶと、
          <ruby>
            計<rt>けい</rt>
          </ruby>
          <ruby>
            算<rt>さん</rt>
          </ruby>
          スキルコネクトへ
          <ruby>
            移<rt>い</rt>
          </ruby>
          <ruby>
            動<rt>どう</rt>
          </ruby>
          します。
          <br />
          <ruby>
            目<rt>もく</rt>
          </ruby>
          <ruby>
            次<rt>じ</rt>
          </ruby>
          <ruby>
            画<rt>が</rt>
          </ruby>
          <ruby>
            面<rt>めん</rt>
          </ruby>
          で「りれき」ボタンをおしてください。
        </P>
      </div>
    </StudentFrame>
  );
};

export const KeisanSkillSelect = ({
  license,
}: {
  license: LicenseEntity[];
}) => {
  const options = useMemo(() => {
    const standard = license.filter((l) =>
      KEISAN_LICENSES.standard.includes(l.learning_contents),
    );
    const tokyoshoseki = license.filter((l) =>
      KEISAN_LICENSES.tokyoshoseki.includes(l.learning_contents),
    );
    const keirinkan = license.filter((l) =>
      KEISAN_LICENSES.keirinkan.includes(l.learning_contents),
    );

    return [
      { label: '　', value: '', id: -1 },
      ...[...tokyoshoseki, ...keirinkan, ...standard].map((l) => ({
        label: formatLabel(l.learning_contents)!,
        value: l.path!,
        id: l.learning_contents,
      })),
    ];
  }, [license]);

  return (
    <Label className="label" label="学年をえらぶ">
      <Select<string>
        defaultValue=""
        options={options}
        onChange={(value) => {
          if (!value) return;
          window.location.href = value;
        }}
        noRuby
      />
    </Label>
  );
};

const formatLabel = (license: number) => {
  let idx = KEISAN_LICENSES.standard.indexOf(license);
  if (idx >= 0) return `計算スキル${idx + 1}年`;
  idx = KEISAN_LICENSES.tokyoshoseki.indexOf(license);
  if (idx >= 0) return `計算スキル${idx + 1}年 東京書籍版`;
  idx = KEISAN_LICENSES.keirinkan.indexOf(license);
  if (idx >= 0) return `計算スキル${idx + 1}年 啓林館版`;
};
