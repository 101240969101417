.table-container {
  position: relative;
  width: fit-content;
  border-radius: 4px;
  /* border: 1px solid var(--line-color); */
  overflow: hidden;
  z-index: 0;
}

.table-container ::-webkit-scrollbar {
  background-color: var(--gray);
  width: 10px;
}
.table-container ::-webkit-scrollbar-thumb {
  background-color: #828282;
  border-radius: 5px;
  min-height: 60px;
}

.table-scroll-container {
  width: 100%;
  display: flex;
  overflow: auto;
  border: transparent;
}

.table-infinite-scroll-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  border: transparent;
}
.table-infinite-scroll-container .wrapper {
  position: relative;
}
.table-infinite-scroll-container .marker {
  position: absolute;
  top: calc(100% - 10000px);
  bottom: 0;
  z-index: -1;
}

.table {
  border-collapse: separate;
  table-layout: fixed;
  font-family: var(--main-font-family-db);
}



/* .table thead {
  position: sticky;
  top: 0;
  z-index: 2;
} */

/* .table thead tr:first-child th:first-child {
  position: sticky;
  top: 0;
  left: 0;
} */

/* .table thead th {
  position: relative;
  height: 45px;

  background-color: var(--tomopla-color);
  color: var(--white);
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.1em;
  vertical-align: middle;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
} */

/* .table td {
  height: 46px;
  padding: 15px;

  background-color: var(--white);
  color: var(--letter-black);
  font-size: 18px;
  letter-spacing: 0.1em;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
td .no-padding {
  margin: -15px;
} */

/* .table th,
.table td {
  border-right: 1px solid var(--line-color);
  border-bottom: 1px solid var(--line-color);
} */

/* .table th:last-child,
.table td:last-child {
  border-right: none;
}
.table tr:last-child td {
  border-bottom: none;
} */

.th-button {
  position: absolute;
  top: 11px;
  right: 13px;

  padding: 0;
  background: none;
  border: none;

  cursor: pointer;
}

.clickable {
  cursor: pointer;
}

.sort-icon-container {
  margin-left: 12px;
}
.sort-icon {
  opacity: 0.5;
}
.sort-icon.asc {
  transform: rotate(180deg);
  opacity: 1;
  margin-bottom: 2px;
}
.sort-icon.desc {
  opacity: 1;
}
