import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import './App.css';
import apiClient from './helpers/apiClient';
import { authConfig, useAuthContext } from './middleware/auth/AuthContext';
import { Error } from './screens/Error';
import { LicenseApplyForm } from './screens/LicenseApplyForm';
import { Logout } from './screens/Logout';
import { Maintenance } from './screens/Maintenance';
import { ParentalAgreementForm } from './screens/ParentalAgreementForm';
import { PasswordReset } from './screens/PasswordReset';
import { PasswordResetDone } from './screens/PasswordResetDone';
import { PasswordResetInput } from './screens/PasswordResetInput';
import { PasswordResetUrl } from './screens/PasswordResetUrl';
import { PasswordResetUrlSend } from './screens/PasswordResetUrlSend';
import { PreLogin } from './screens/PreLogin';
import { StudentHelp } from './screens/StudentHelp';
import { StudentHistoryDictionary } from './screens/StudentHistoryDictionary';
import { StudentHistoryDictionaryKanji } from './screens/StudentHistoryDictionaryKanji';
import { StudentHistoryKanji } from './screens/StudentHistoryKanji';
import { StudentHistoryKanjiTest } from './screens/StudentHistoryKanjiTest';
import { StudentHistoryKeisan } from './screens/StudentHistoryKeisan';
import { StudentHistoryRomaji } from './screens/StudentHistoryRomaji';
import { StudentTop } from './screens/StudentTop';
import { TeacherHelp } from './screens/TeacherHelp';
import { TeacherHistoryCbt } from './screens/TeacherHistoryCbt';
import { TeacherHistoryDictionary } from './screens/TeacherHistoryDictionary';
import { TeacherHistoryDictionaryKanji } from './screens/TeacherHistoryDictionaryKanji';
import { TeacherHistoryKanji } from './screens/TeacherHistoryKanji';
import { TeacherHistoryKanjiTest } from './screens/TeacherHistoryKanjiTest';
import { TeacherHistoryKeisan } from './screens/TeacherHistoryKeisan';
import { TeacherHistoryRomaji } from './screens/TeacherHistoryRomaji';
import { TeacherHistoryVideo } from './screens/TeacherHistoryVideo';
import { TeacherStudentList } from './screens/TeacherStudentList';
import { TeacherTop } from './screens/TeacherTop';
import { Entry } from './screens/auth/Entry';
import { Login } from './screens/auth/Login';
import { QrEntry } from './screens/auth/QrEntry';
import { ContactForm } from './screens/Contact';
import { useEffect } from 'react';

type RouterProps = {
  handleThemeChange: (status: number) => void;
};

declare global {
  interface Window {
    // eslint-disable-next-line
    dataLayer: any[];
  }
}

export const DashboardRouter = ({ handleThemeChange }: RouterProps) => {
  const { isStudent, isTeacher } = useAuthContext();
  const navigate = useNavigate();
  const location = useLocation();
  apiClient.setErrorHandlingMethod(() => {
    navigate('/error');
  });
  apiClient.setNoAuthHandlingMethod(() => {
    // path check
    if (authConfig.noAuthPageUrl.includes(location.pathname)) {
      return;
    }
    navigate('/login'); // 必要か？
  });

  useEffect(() => {
    const pageviewEvent = new CustomEvent('pageview', {
      detail: {
        url: window.location.href,
      },
    });

    window.dispatchEvent(pageviewEvent);

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'pageview',
      url: window.location.href,
    });
  }, [location]);


  return (
    <Routes>
      <Route path="/" element={<Top handleThemeChange={handleThemeChange} />} />
      <Route path="/login" element={<Login />} />
      <Route path="/entry" element={<Entry />} />
      <Route path="/qrentry" element={<QrEntry />} />
      <Route path="/error" element={<Error />} />
      <Route path="/maintenance" element={<Maintenance />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/licenseapplyform" element={<LicenseApplyForm />} />
      <Route path="/prelogin" element={<PreLogin />} />
      <Route
        path="/parentalagreementform"
        element={<ParentalAgreementForm />}
      />
      <Route path="/passwordreseturlsend" element={<PasswordResetUrlSend />} />
      <Route path="/passwordreseturlsenddone" element={<PasswordResetUrl />} />
      <Route path="/passwordresettimeout" element={<PasswordReset />} />
      <Route path="/passwordresetinput" element={<PasswordResetInput />} />
      <Route path="/passwordresetdone" element={<PasswordResetDone />} />
      <Route path="/studenthelp" element={<StudentHelp />} />
      <Route path="/teacherhelp" element={<TeacherHelp />} />
      {isStudent?.() && StudentRoutes(handleThemeChange)}
      {isTeacher?.() && TeacherRoutes(handleThemeChange)}
      <Route path="/contents/contact" element={<ContactForm />} />
      <Route path="*" element={<Navigate to='/' replace={true} />} />
      <Route path="*" element={<Navigate to="/" replace={true} />} />
    </Routes>
  );
};

const Top = ({ handleThemeChange }: RouterProps) => {
  const { isTeacher } = useAuthContext();
  if (!isTeacher) return <Login />;
  if (isTeacher()) return <TeacherTop onThemeChange={handleThemeChange} />;
  return <StudentTop onThemeChange={handleThemeChange} />;
};

const StudentRoutes = (handleThemeChange: RouterProps['handleThemeChange']) => {
  return (
    <>
      <Route
        path="/studenttop"
        element={<StudentTop onThemeChange={handleThemeChange} />}
      />
      <Route
        path="/studenthistorydictionarykanji"
        element={
          <StudentHistoryDictionaryKanji onThemeChange={handleThemeChange} />
        }
      />
      <Route
        path="/studenthistorydictionary"
        element={<StudentHistoryDictionary onThemeChange={handleThemeChange} />}
      />
      <Route
        path="/studenthistorykanjitest"
        element={<StudentHistoryKanjiTest onThemeChange={handleThemeChange} />}
      />
      <Route
        path="/studenthistorykanji"
        element={<StudentHistoryKanji onThemeChange={handleThemeChange} />}
      />
      <Route
        path="/studenthistoryromaji"
        element={<StudentHistoryRomaji onThemeChange={handleThemeChange} />}
      />
      <Route
        path="/studenthistorykeisan"
        element={<StudentHistoryKeisan onThemeChange={handleThemeChange} />}
      />
    </>
  );
};

const TeacherRoutes = (handleThemeChange: RouterProps['handleThemeChange']) => {
  return (
    <>
      <Route
        path="/teachertop"
        element={<TeacherTop onThemeChange={handleThemeChange} />}
      />
      <Route
        path="/teacherstudentlist"
        element={<TeacherStudentList onThemeChange={handleThemeChange} />}
      />
      <Route
        path="/teacherhistorydictionarykanji"
        element={
          <TeacherHistoryDictionaryKanji onThemeChange={handleThemeChange} />
        }
      />
      <Route
        path="/teacherhistorydictionary"
        element={<TeacherHistoryDictionary onThemeChange={handleThemeChange} />}
      />
      <Route
        path="/teacherhistorycbt"
        element={<TeacherHistoryCbt onThemeChange={handleThemeChange} />}
      />
      <Route
        path="/teacherhistorykanjitest"
        element={<TeacherHistoryKanjiTest onThemeChange={handleThemeChange} />}
      />
      <Route
        path="/teacherhistorykanji"
        element={<TeacherHistoryKanji onThemeChange={handleThemeChange} />}
      />
      <Route
        path="/teacherhistoryvideo"
        element={<TeacherHistoryVideo onThemeChange={handleThemeChange} />}
      />
      <Route
        path="/teacherhistoryromaji"
        element={<TeacherHistoryRomaji onThemeChange={handleThemeChange} />}
      />
      <Route
        path="/teacherhistorykeisan"
        element={<TeacherHistoryKeisan onThemeChange={handleThemeChange} />}
      />
    </>
  );
};
