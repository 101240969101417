import AccentButton from '../../parts/AccentButton';
import NeutralButton from '../../parts/NeutralButton';
import './style.css';
import { modalAtom } from '../modalAtom';
import { useRecoilState } from 'recoil';
import { useAuthContext } from '@/src/middleware/auth/AuthContext';
import { ModalCloseButton } from '../../parts/ModalCloseButton';
import styled from 'styled-components';
import { LogoutResponse } from '@/src/middleware/auth/AuthContext/AuthTypes';

const LogoutModal = styled('div')(
  ({theme})=>`
  .overlap-54 {
    background: linear-gradient(180deg, #FFFFFF 0%, var(${theme.lightcolor}) 60%)!important;
  }
  .overlap-56 {
    background-color: var(${theme.maincolor})
  }
  .rectangle-26 {
    background-color: var(${theme.backgroud})!important;
    border-color: var(${theme.backgroud})!important;
  }
  .text,.text-2{
    color:var(${theme.textcolor})
  }
  `
)

// TODO: モーダルフレームはマージ後に使用する
export const Logout = () => {
  const [_openModalKey, setOpenModalKey] = useRecoilState<any>(modalAtom)
  const { processLogout } = useAuthContext();

  const handleLogout = async() =>{
    if (processLogout) {
      return await processLogout().then((res:LogoutResponse)=>{
        //TBD:APIコールバックを受けて分岐
        console.log(res)
        if(res.status===200 || res.status===401){
          setOpenModalKey(null)
          //ログアウト後は明示的にページ遷移する
          window.location.href = '/logout';
        } else {
          console.log('ログアウトに失敗しました')
          window.location.href = '/logout';
        }
      })
    }
    else {
      console.log('ログアウトに失敗しました')
      window.location.href = '/logout';
    };
  }

  return (
    <LogoutModal className="logout-modal">
      <div className="overlap-54">
        <div className="modal-background">
          <div className="rectangle-26" />
        </div>
        <div className="main-button-cancel">
          <NeutralButton onClick={()=>{setOpenModalKey(null)}}>いいえ</NeutralButton>
        </div>
        <div className="accent-button-OK">
          <AccentButton onClick={handleLogout}>は い</AccentButton>
        </div>
        <div className="message">
          <div className="text">よろしいですか？</div>
          <div className="text-2">ログアウトします。</div>
        </div>
        <div className="modal-title-bar-2">
          <div className="overlap-56">
            <div className="sub-button-close-2">
              <ModalCloseButton className='overlap-57' onClick={() => { setOpenModalKey(null) }}/>
            </div>
            <div className="modal-title-2">ログアウト</div>
          </div>
        </div>
      </div>
    </LogoutModal>
  );
};
