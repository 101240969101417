import { useHistoryLocation } from '@/src/hooks/useHistoryNavigation';
import { useSyncHistoryQueryConditions } from '@/src/hooks/useHistoryQueryConditions';
import { useTeacherContext } from '@/src/hooks/useTeacherContext';
import {
  LearningHistoryKeisanSkillQueryFormSchema,
  learningHistoryKeisanSkillQueryFormSchema,
} from '@/src/schema/learning-history-keisan-skill-query-form-schema';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';

export const useLearningHistoryKeisan = () => {
  const {
    hasLoaded,
    schoolClassList,
    historyQueryConditions,
  } = useTeacherContext();
  // 児童詳細からの遷移
  const { state: locationState } = useHistoryLocation();
  const methods = useForm<LearningHistoryKeisanSkillQueryFormSchema>({
    defaultValues: locationState
      ? {
        ...historyQueryConditions,
        class_index: locationState.class_name
          ? schoolClassList.findIndex(
            (e) =>
              e.grade === locationState.grade &&
              e.class_name === locationState.class_name,
          )
          : undefined,
        user_uuid: locationState.user_uuid,
      }
      : historyQueryConditions,
    resolver: zodResolver(learningHistoryKeisanSkillQueryFormSchema),
  });

  useSyncHistoryQueryConditions(
    ['class_index'],
    methods.watch,
  );
  return {
    hasLoaded,
    methods,
  };
};
