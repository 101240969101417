import { SchoolClass, UserCore } from '@/src/__generated__';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { CurrentUser, useAuthContext } from '../middleware/auth/AuthContext';
import { useHistoryLocation } from './useHistoryNavigation';
import {
  HistoryQueryConditions,
  useHistoryQueryConditions,
} from './useHistoryQueryConditions';

/**
 * @property schoolClassList - 学年・組の一覧。学校全体(grade=undefined), 学年全体(class_name=undefined) を含む
 */
type TeacherContextValues = {
  childrenList: UserCore[];
  schoolClassList: Partial<SchoolClass>[];
  hasLoaded: boolean;
  historyQueryConditions: HistoryQueryConditions;
  updateHistoryQueryConditions: (
    values: Partial<HistoryQueryConditions>,
  ) => void;
  classIndexToClass: (
    index: number | null | undefined | "",
  ) => Partial<{ grade: number; class_name: string }> | undefined;
};

const TeacherContext = createContext<TeacherContextValues>({
  childrenList: [],
  schoolClassList: [],
  hasLoaded: false,
  historyQueryConditions: {
    from_date: new Date(),
    to_date: new Date(),
  },
  updateHistoryQueryConditions: () => {},
  classIndexToClass: () => undefined,
});

const shouldWaitConditionLoaded = () =>
  window.location.pathname.startsWith('/teacherhistory') ||
  window.location.pathname === '/teacherstudentlist';

export const TeacherContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const {
    uuid,
    isTeacher,
    isStudent,
    hasLoaded,
    getCurrentUser,
    getSchoolClassList,
    getChildrens,
  } = useAuthContext();
  const currentUser: CurrentUser | null =
    (getCurrentUser ? getCurrentUser() : null) || null;
  const _childrenList = (getChildrens ? getChildrens() : []) || [];
  const _schoolClassList =
    (getSchoolClassList ? getSchoolClassList() : []) || [];
  const { state } = useHistoryLocation();

  const [conditionLoaded, setConditionLoaded] = useState(false);
  const [childrenList, setChildrenList] = useState<UserCore[]>([]);
  const [schoolClassList, setSchoolClassList] = useState<
    Partial<SchoolClass>[]
  >([]);

  const {
    historyQueryConditions: conditions,
    updateHistoryQueryConditions: updateConditions,
  } = useHistoryQueryConditions();

  const filteredChildrenList = useMemo(() => {
    if (conditions.class_index === null) return childrenList;
    if (conditions.class_index === undefined) return childrenList;
    if (conditions.class_index === "") return childrenList;
    const schoolClass = schoolClassList[conditions.class_index];
    if (!schoolClass) return childrenList;
    return childrenList.filter(
      (user) =>
        user &&
        (schoolClass.grade == null || schoolClass.grade === user.grade) &&
        (!schoolClass.class_name || schoolClass.class_name === user.class_name),
    );
  }, [uuid, conditions]);

  const classIndexToClass = useCallback(
    (index: number | null | undefined | "") => {
      if (index === null) return undefined;
      if (index === undefined) return undefined;
      if (index === "") return undefined;
      const schoolClass = schoolClassList[index];
      return {
        grade: schoolClass?.grade ?? undefined,
        class_name: schoolClass?.class_name ?? undefined,
      };
    },
    [schoolClassList],
  );

  // 初期ロード
  useEffect(() => {
    if (!isTeacher?.()) return;
    if (_schoolClassList) {
      setSchoolClassList(withGradeAll(_schoolClassList));
    }
    if (_childrenList) {
      setChildrenList(_childrenList);
    }
  }, [uuid, currentUser, _schoolClassList, _childrenList]);

  // 担当クラスを初期選択
  useEffect(() => {
    if (conditionLoaded || !currentUser) return;
    if (isStudent?.()) {
      return;
    }
    if (schoolClassList.length === 0) return;

    if ((conditions.class_index != null, state?.user_uuid, state?.class_name))
      return;
    const index = schoolClassList.findIndex(
      ({ grade, class_name }) =>
        grade === currentUser.grade && class_name === currentUser.class_name,
    );
    updateConditions({ class_index: index < 0 ? undefined : index });
    setConditionLoaded(true);
  }, [currentUser, schoolClassList]);

  return (
    <TeacherContext.Provider
      value={{
        hasLoaded: !!(hasLoaded?.() && conditionLoaded),
        childrenList: filteredChildrenList,
        schoolClassList,
        historyQueryConditions: conditions,
        updateHistoryQueryConditions: updateConditions,
        classIndexToClass,
      }}
    >
      {(!shouldWaitConditionLoaded() || conditionLoaded) && children}
    </TeacherContext.Provider>
  );
};

export const useTeacherContext = () => useContext(TeacherContext);

type GradesAndClasses = {
  grades: number[];
  classesByGrade: Record<number, string[]>;
};
const withGradeAll = (schoolClassList: SchoolClass[]) => {
  if (!schoolClassList) {
    // console.log(schoolClassList);
    // schoolClassList = [];
  }
  const gradesAndClasses = schoolClassList.reduce<GradesAndClasses>(
    (acc, school_class) => {
      if (school_class.grade == null || !school_class.class_name) return acc;
      if (!acc.grades.includes(school_class.grade)) {
        acc.grades.push(school_class.grade);
        acc.classesByGrade[school_class.grade] = [];
      }
      acc.classesByGrade[school_class.grade].push(school_class.class_name);
      return acc;
    },
    { grades: [], classesByGrade: {} },
  );

  return [
    ...gradesAndClasses.grades.flatMap((grade) => [
      { grade, class_name: null },
      ...gradesAndClasses.classesByGrade[grade].map((class_name) => ({
        grade,
        class_name,
      })),
    ]),
  ];
};
