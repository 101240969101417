import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const QrEntry = (): JSX.Element => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        console.log(params);
        if (params && params.get('cd')) {
            const code = params.get('cd');
            console.log(code);
            navigate('/login', {state: {email: code}});
        }
        else {
            console.log('error');
            // TODO error redirect
        }    
    }, [])
    return <></>;
    
};
