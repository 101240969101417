import { StudyTime } from '@/src/__generated__';
import { BarChart } from '@/src/components/BarChart';
import {
  formatToDateDisplay,
  formatToDateDisplayShort,
} from '@/src/helpers/date';
import { Label } from './Label';
import styles from './styles.module.css';
import styled from 'styled-components';

type ChartProps = {
  scope: string;
  data: StudyTime[];
  theme?: any;
};

const ChartWrap = styled('div')(
  ({ theme }) => `
    position: relative;
    flex-shrink: 0;
    width: 640px;
    height: 519px;
    padding: 57px 40px 20px 43px;
    border: 1px solid var(${theme.maincolor});
    border-radius: 16px;
    background: var(${theme.lightcolor});
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.4);
    .label {
      left: 22px;
    }
    .caption {
      position: absolute;
      top: 23px;
      left: 22px;
    
      display: flex;
      align-items: center;
      column-gap: 10px;
    
      font-family: var(--main-font-family-m);
      font-size: 23px;
      @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.4375rem;};
      
      letter-spacing: 2.2px;

      color:var(${theme.textcolor})
    }
    .period {
      position: absolute;
      top: 26px;
      right: 40px;
    
      font-family: var(--main-font-family-m);
      font-size: 16px;
      @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1rem;};
      
      letter-spacing: 0.05em;
      color:var(${theme.textcolor});
      > span {
        margin: 0 4.5px;
      }
    }
    .scaling {
      position: absolute;
      top: 11px;
      right: 13px;
    
      background: none;
      border: none;
      cursor: pointer;
    }
    .main {
      width: 100%;
      height: 100%;

      .recharts-legend-wrapper{
        color:var(${theme.textcolor});
      }

      .recharts-cartesian-axis-ticks{
        font-size:1.2rem;
      }
    }
  `
)


export const Chart = ({ scope, data, theme }: ChartProps) => {
  const dates = data.map((d) => d.date).sort();
  const keys = dates.map((d) => formatToDateDisplayShort(d));
  const chartData = data.map((d) => ({
    key: formatToDateDisplayShort(d.date),
    value: d.study_time,
  }));
  const range =
    dates.length > 0
      ? {
        from: formatToDateDisplay(dates[0]),
        to: formatToDateDisplay(dates[dates.length - 1]),
      }
      : undefined;

  return (
    <>
      <ChartWrap className='chart'>
        <Label theme={theme}>{scope} 学習した時間</Label>
        <div className='caption'>
          <img src="/img/teacher-top-chart-icon.png" height={25} />
          学習時間平均（分）
        </div>
        <div className='period'>
          {range && (
            <>
              {range.from}
              <span>〜</span>
              {range.to}
            </>
          )}
        </div>
        <div className='main'>
          <BarChart
            width={560}
            height={443}
            legendDisabled
            theme={theme}
            dataSeries={{
              keys,
              series: [
                {
                  key: 'study-time',
                  gradient: {
                    id: 'single-gradient',
                    colors: [
                      { offset: '0%', stopColor: `${theme.chart0}` },
                      { offset: '100%', stopColor: `${theme.chart1}` },
                    ],
                  },
                  data: chartData,
                },
              ],
            }}
          />
        </div>
      </ChartWrap>
    </>
  );
};
