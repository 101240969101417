import {
  FormItemSelect,
  SubmitButton,
} from '@/src/components/HistoryQueryForm';
import { FormEventHandler, ReactNode } from 'react';
import styles from './styles.module.css';
import styled from 'styled-components';
import MainButton from '@/src/components/parts/MainButton';


const Div = styled('div')(
  ({ theme }) => `
    border: 5px solid var(${theme.maincolor})!important;
    background-color: var(${theme.lightcolor})!important;
    .title{
      background-color: var(${theme.maincolor})!important;
      color: var(--white);
    }
    .caption{
      background-color: var(${theme.maincolor})!important;
    }
    .select{
      color:var(${theme.textcolor})!important;
      div{
        color:var(${theme.textcolor})!important;
      }
    }
  `
)

type RankingQueryFormProps = {
  items: { rank: number; word: ReactNode }[];
  onSubmit: FormEventHandler<HTMLFormElement>;
  noClass?: boolean;
};
export const RankingQueryForm = ({
  items,
  onSubmit,
  noClass,
}: RankingQueryFormProps) => {

  return (
    <Div className={styles.ranking}>
      <div className={`${styles.title} title`}>{'みんなの調べた言葉\nランキング'}</div>
      <form className={styles.form} onSubmit={onSubmit}>
        <FormItemSelect
          className={`${styles.select} select`}
          name="target_name"
          label="対象指定"
          options={TARGET_OPTIONS.filter((o) => !noClass || o.value !== 1)}
          noRuby
          popperPlacement="top-start"
        />
        <FormItemSelect
          className={`${styles.select} select`}
          name="term_name"
          label="月指定"
          options={MONTH_OPTIONS}
          noRuby
          popperPlacement="top-start"
        />
        <SubmitButton className={styles.submit} noRuby alt />
      </form>
      <ol className={styles['word-list']}>
        {items.slice(0, 3).map(({ rank, word }, i) => (
          <li key={`${rank?.toString()}_${word?.toString()}`}>
            <div className={`${styles['caption']} caption`}>{rank}位</div>
            <div className={styles['content']}>{word}</div>
          </li>
        ))}
      </ol>
    </Div>
  );
};

const TARGET_OPTIONS = [
  { label: 'クラス', value: 1 },
  { label: '学校', value: 2 },
  { label: '全国', value: 3 },
];

const MONTH_OPTIONS = [
  { label: '今月', value: 1 },
  { label: '前月', value: 2 },
  ...Array.from({ length: 11 }, (_, i) => ({
    label: `${i + 2}か月前`,
    value: i + 3,
  })),
];
