.body {
  margin-top: 20px;
  margin-left: 40px;
  width: 1440px;
}
.body .toggle-row {
  margin-top: 21px;
}

.table {
  margin-top: 21px;
  z-index: 0;
}

/* .table tr:nth-child(8n + 1) td:nth-child(n + 3),
.table tr:nth-child(8n + 2) td,
.table tr:nth-child(8n + 3) td,
.table tr:nth-child(8n + 4) td {
  background: var(--bg-table-pretest-row);
}
.table tr:nth-child(8n + 5) td,
.table tr:nth-child(8n + 6) td,
.table tr:nth-child(8n + 7) td,
.table tr:nth-child(8n) td {
  background: var(--bg-table-test-row);
} */

.table td {
  height: 35px;
  padding: 0;
}

.table td .total {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  padding-right: 11px;
  /* background: var(--bg-table-summary-col) !important; */
}

.table td .type {
  white-space: pre-wrap;
  line-height: 28px;
}
.table td .date-column {
  display: flex;
}
.table td .frequncy-type,
.table td .date {
  font-size: 13px;
  text-align: center;
}
.table td .frequncy-type {
  width: 75px;
}

.table td .date {
  flex: 1;
}

.buttons {
  margin-left: 15px;
  display: flex;
  column-gap: 15px;
}

.selects-row {
  width: 100%;
  justify-content: space-between;
  margin-top: 2em;
}

.selects {
  display: flex;
  align-items: center;
  gap: 25px;
}
.selects .category-select {
  margin-left: 36px;
}
.selects .category-select > *:first-child {
  white-space: wrap;
}

.selects .teaching-unit-select {
  margin-left: 30px;
}

.small-label {
  column-gap: 15px;
}
.small-label > *:first-child {
  font-size: 17px !important;
  line-height: 22px;
  letter-spacing: 0.01em;
  width: 111px;@media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none)  {
    font-size: 1.1em!important;
    white-space: initial;
  }
}
.small-label > *:not(:first-child) {
  flex: 1;
}
.category-select {
  width: 316px;
}
.teaching-unit-select {
  width: 380px;
}

.buttons {
  margin-left: 15px;
  display: flex;
  column-gap: 15px;
}

.toggle-row {
  width: 100%;
  justify-content: flex-end;
}
