import { useInView } from 'react-intersection-observer'

type views = {
  url?: string;
  title: string;
}

export const useElementVisibility = (props: views) => {
  return useInView({
    threshold: 0.5,
    triggerOnce: true, // GTMの、要素の表示トリガーと同様にページごとに1回発火させたい
    onChange(inView) {
      if (inView) {
        (window as any).dataLayer = (window as any).dataLayer || [];
        (window as any).dataLayer.push({
          event: 'page_view',
          page: {
            url: props.url,
            title: props.title
          }
        })
      }
    },
  })
}
