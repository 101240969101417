import clsx from 'clsx';
import AccentButton, { AccentButtonProps } from '../../parts/AccentButton';
import styles from '../styles.module.css';

export const DownloadButton = ({
  className,
  type = 'button',
  ...props
}: AccentButtonProps) => {
  return (
    <AccentButton
      {...props}
      type={type}
      className={clsx(className, styles.download)}
      style={{minHeight:'52px'}}
    >
      ダウンロード
    </AccentButton>
  );
};
