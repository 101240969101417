import { SchoolClassSelect } from '@/src/components/HistoryQueryForm';
import { InputSearch } from '@/src/components/InputSearch';
import { TableWithScroll } from '@/src/components/Table';
import { TeacherFrame } from '@/src/components/layouts/TeacherFrame';
import { ChildDetailModal } from './ChildDetailModal';
import { useStudentList } from './hooks';
import styles from './styles.module.css';
import clsx from 'clsx';
import { themeAtom, profileAtom } from '@/src/components/ModalContents/modalAtom';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';
import { useToppageTeachers } from '../TeacherTop/hooks';


type Props = {
  onThemeChange: (status: number) => void
}

const StdTable = styled('div')(
  ({theme}) => `
    .stdlist{
      table{
        tbody{
          tr:last-child {
            td{
              background-color: var(${theme.backgroud});
              color: var(${theme.textcolor});
            }
          }
          td:last-child {
            color: var(${theme.textcolor});
          }
        }
      }
    }
  `
)

export const TeacherStudentList = (props: Props) => {
  const {
    hasLoaded,
    table,
    schoolClassList,
    historyQueryConditions: { class_index, studentName },
    updateHistoryQueryConditions,
    selectedChild,
    setSelectedChild,
  } = useStudentList();

  const { currentUser } = useToppageTeachers();
  const [themenum, setThemenum] = useRecoilState(themeAtom)
  const [profiles, setProfiles] = useRecoilState(profileAtom)

  useEffect(() => {
    if (currentUser) {
      if (!themenum) {
        setThemenum(currentUser?.preference.theme_color);
      }
      const _profiles = {
        nick_name: profiles.nick_name ? profiles.nick_name : currentUser?.nick_name,
        profile_icon: profiles.profile_icon && profiles.profile_icon !== currentUser?.preference.profile_icon ? profiles.profile_icon : currentUser?.preference.profile_icon
      }
      if (
        _profiles.nick_name !== profiles.nick_name ||
        _profiles.profile_icon !== profiles.profile_icon
      ) {
        setProfiles(_profiles);
      }
    }
    
    return () => {
      updateHistoryQueryConditions({ studentName: null });
    };
  }, [currentUser, profiles, themenum]);

  useEffect(() => {
    if (currentUser && typeof themenum === 'number') {
      props.onThemeChange(themenum);
    }
  }, [themenum, currentUser]);

  return (
    <TeacherFrame title="児童情報一覧 教師用">
      {hasLoaded && (
        <StdTable className={styles.body}>
          <div className={styles.conditions}>
            <SchoolClassSelect
              schoolClassList={schoolClassList}
              value={class_index}
              onChange={(value) =>
                updateHistoryQueryConditions({
                  class_index: value,
                })
              }
            />
            <InputSearch
              placeholder="児童名検索"
              value={studentName || undefined}
              onChange={({ target: { value } }) =>
                updateHistoryQueryConditions({ studentName: value })
              }
            />
          </div>
          <TableWithScroll
            className={clsx('stdlist', styles.table)}
            table={table}
            maxWidth={1480}
            maxHeight={864}
          />
        </StdTable>
      )}
      <ChildDetailModal
        child={selectedChild}
        open={!!selectedChild}
        close={() => setSelectedChild(undefined)}
      />
    </TeacherFrame>
  );
};