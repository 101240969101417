import { z } from 'zod';

export const learningHistoryJapaneseDictionaryQueryFormSchema = z.object({
  class_index: z.number().nullish().or(z.enum([""])),
  page: z.number().optional(),
});

export type LearningHistoryJapaneseDictionaryQueryFormSchema = z.infer<
  typeof learningHistoryJapaneseDictionaryQueryFormSchema
>;
