import { FC, ReactNode, createContext, useContext, useReducer } from 'react';

type TableContextValues = {
  columnExpanded?: boolean;
  toggleColumnExpanded?: () => void;
};

const TableContext = createContext<TableContextValues>({});

export const TableContextProvider = ({ children }: { children: ReactNode }) => {
  const [columnExpanded, toggleColumnExpanded] = useReducer(
    (prev) => !prev,
    false,
  );
  return (
    <TableContext.Provider
      value={{
        columnExpanded,
        toggleColumnExpanded,
      }}
    >
      {children}
    </TableContext.Provider>
  );
};

export const withTableContext =
  <P extends {}>(Component: FC<P>) =>
  (props: P) => (
    <TableContextProvider>
      <Component {...props} />
    </TableContextProvider>
  );

export const useTableContext = () => useContext(TableContext);
