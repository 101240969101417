.body {
  margin-top: 33px;
  margin-left: 35px;
  width: 1580px;
}

.tables {
  margin-top: 100px;
  max-width: 1580px;
}

.tables table {
  flex-shrink: 0;
}

.tables table:not(:last-child) {
  border-right: 1px solid var(--line-color);
}

.tables table tr:nth-child(1) th {
  height: 79px;
}
.tables table tr:nth-child(2) th {
  height: 48px;
}
.tables table td {
  height: 67px;
  padding-right: 9px;

  font-family: var(--main-font-family-m);
  
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.1em;
}

.caption-table {
  position: sticky;
  left: 0;
  z-index: 3;
}
.caption-table thead {
  height: 127px;
}
/* .tables table:not(.caption-table) td:last-child {
  background: var(--tomopla-color-light);
} */

.test-type {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;

  font-family: var(--main-font-family-db);
  font-size: 30px;
  
  letter-spacing: 0.1em;
  line-height: 28px;
}
