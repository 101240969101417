.student-history-kanji.body {
  margin-top: 33px;
  margin-left: 35px;
  width: 1580px;
}

.student-history-kanji .table {
  margin-top: 62px;
  z-index: 0;
}

.student-history-kanji .teaching-unit {
  padding: 10px;
}
.student-history-kanji .teaching-unit-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
