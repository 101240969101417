import { z } from 'zod';

export const learningHistoryCbtQueryFormSchema = z.object({
  class_index: z.number().nullish().or(z.enum([""])),
  cbt_category_id: z.string().nullish(),
});

export type LearningHistoryCbtQueryFormSchema = z.infer<
  typeof learningHistoryCbtQueryFormSchema
>;
