import {
  kanjiSkillTestLearningHistoriesGet,
  LibsDomainModelInterfaceKanjiGetKanjiSkillTestLearningHistoriesResponse,
} from '@/src/__generated__';
import { get_category_index_from_type_id, get_category_selection, get_current_type_category_id, save_category_selection } from '@/src/helpers/kanji-skill/pulldown';
import { useAuthContext } from '@/src/middleware/auth/AuthContext';
import { LearningHistoryKanjiSkillTestQueryFormSchema } from '@/src/schema/learning-history-kanji-skill-test-query-form-schema';
import { KanjiApplicationType } from '@/src/types/KanjiApplicationType';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

export const useLearningHistoryKanjiSkillTest = () => {
  const { uuid, getCurrentUser } = useAuthContext();
  const [categories, setCategories] = useState<
    LibsDomainModelInterfaceKanjiGetKanjiSkillTestLearningHistoriesResponse['categories']
  >([]);
  const [data, setData] =
    useState<LibsDomainModelInterfaceKanjiGetKanjiSkillTestLearningHistoriesResponse>();
  const [type, setType] = useState<KanjiApplicationType>();
  const [loaded, setLoaded] = useState<boolean>(false);

  const methods = useForm<LearningHistoryKanjiSkillTestQueryFormSchema>({
    defaultValues: { category_index: 0 },
  });

  const query = async (
    params: LearningHistoryKanjiSkillTestQueryFormSchema,
  ) => {
    const user = getCurrentUser?.();
    if (!user) return;
    const category_index = params.category_index || 0;
    const [type, category_id] = get_current_type_category_id(user, category_index, categories);

    const res = await kanjiSkillTestLearningHistoriesGet({
      ...params,
      type,
      category_id,
      grade: user?.grade!,
      class_name: user?.class_name!,
      page: 1,
    });
    if (res.status !== 200) {
      // TODO handle error
      return;
    }
    if (categories.length < 1) {
      setCategories(res.data.categories ? res.data.categories : []);
      let category_index = params.category_index;
      if (res.data.category && res.data.category.category_id !== null) {
        category_index = get_category_index_from_type_id(res.data.category.type, res.data.category.category_id, res.data.categories);
      }
      else {
        category_index = get_category_index_from_type_id(type, category_id, res.data.categories);
      }
      methods.setValue("category_index", category_index);
    }
    if (res.data.category) {
      save_category_selection(category_index, res.data.category.type, res.data.category.category_id);
      setType(res.data.category.type);
    }
    else {
      save_category_selection(category_index, type, category_id);
      setType(type);
    }
    setData(res.data);
  };

  const [category_id] = methods.watch(['category_index']);
  useEffect(() => {
    if (loaded) {
      query(methods.getValues());
    }
  }, [category_id, loaded]);

  useEffect(() => {
    if (!uuid || !getCurrentUser) return;
    const user = getCurrentUser();
    if (!user) return;
    // セッションストレージから category_index を取得（存在しない場合はデフォルト値 '0' を使用）
    const storedCategorySelection = get_category_selection();
    const category_index = parseInt(storedCategorySelection.category_index || 0);
    methods.setValue('category_index', category_index);
    setLoaded(true);
  }, [uuid]);

  return {
    data,
    hasLoaded: !!data,
    methods,
    onSubmit: methods.handleSubmit(query),
    conditions: {
      categories,
    },
    type,
  };
};
