import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { SelectOptions } from '../components/RHFSelect/RHFSelect';
import {
  LicenseApplyFormSchema,
  licenseApplyFormSchema,
} from '../schema/licenseapply-form-schema';

import {
  LibsDomainModelInterfaceMailSendMailRequest,
  MunicipalitiesEntity,
  contentsPaperGet,
  mailMailSendCodePost,
  mailSendPost,
  municipalitiesGet,
  municipalitiesSchoolsGet,
  prefecturesGet,
} from '@/src/__generated__';

const useLicenseApplyForm = () => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<LicenseApplyFormSchema>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    //undefined,
    defaultValues:
      /**以下、テスト用モックデータ
       * API取得時は 以下をコメントアウトして上記の undefined をコメントイン**/
      {
        person_name: '',
        prefecture_name: 0,
        //schoolMunicipalities: 1,
        //schoolName: 1,
        distributor_name: '',
        tel: '',
        mail_address: '',
        emailConfirm: '',
        textbooks1: '0',
        textbookQty1: '',
        textbooks2: '0',
        textbookQty2: '',
        textbooks3: '0',
        textbookQty3: '',
        textbooks4: '0',
        textbookQty4: '',
        textbooks5: '0',
        textbookQty5: '',
        textbooks6: '0',
        textbookQty6: '',
        memo: '',
      },
    resolver: zodResolver(licenseApplyFormSchema),
  });

  // エラーメッセージを表示する順序
  const errorFieldOrder = [
    'prefecture_name',
    'municipalities_name',
    'school_name',
    'schoolAlt',
    'person_name',
    'tel',
    'mail_address',
    'emailConfirm',
    'distributor_name',
    'textbooks1',
    'textbookQty1',
    'textbooks2',
    'textbookQty2',
    'textbooks3',
    'textbookQty3',
    'textbooks4',
    'textbookQty4',
    'textbooks5',
    'textbookQty5',
    'textbooks6',
    'textbookQty6',
    'memo',
  ];

  // エラーオブジェクトからエラーメッセージを取得し、定義した順序でソート
  const sortedErrors = errorFieldOrder.reduce(
    (acc, fieldName) => {
      const error = errors[fieldName as keyof typeof errors];
      if (error) {
        acc.push({
          fieldName,
          message: error.message || 'エラーが発生しました', // FieldErrors 型の場合、message プロパティを直接使用
        });
      }
      return acc;
    },
    [] as { fieldName: string; message: string }[],
  );
  console.log('sortedErrors', sortedErrors);

  //確認画面切り替え用ステータス
  const [isConfirm, setIsConfirm] = useState(false);

  const [formData, setFormData] = useState<LicenseApplyFormSchema>();

  const [optionWithMunicipalities, setOptionWithMunicipalities] = useState<
    MunicipalitiesEntity[]
  >([]);

  const [optionWithSchoolNames, setOptionWithSchoolNames] = useState<string[]>(
    [],
  );
  const [options, setOptions] = useState<SelectOptions>([]);

  const watchedInput = useWatch({ control });
  const [isSubmissionComplete, setIsSubmissionComplete] = useState(false);
  // school_alt フィールドの disabled 状態を管理するための状態
  const [isSchoolAltDisabled, setIsSchoolAltDisabled] = useState(false);
  const [optionsWithPrefectures, setOptionsWithPrefectures] =
    useState<SelectOptions>([]);

  //
  // school_name フィールドの値を監視
  const watchedSchoolName = useWatch({
    control,
    name: 'school_name', // 監視するフィールド名
  });

  useEffect(() => {
    // school_name が空文字以外で選択された場合、school_alt フォームの入力を無効にする
    if (watchedSchoolName && watchedSchoolName.trim() !== '0') {
      setValue('school_alt', '', { shouldValidate: false });
      setIsSchoolAltDisabled(true);
    } else {
      setIsSchoolAltDisabled(false);
    }
  }, [watchedSchoolName]);

  console.log('errors', errors);

  console.log('watchedInput', watchedInput);

  // zodの値変換+型チェックを通過した場合のみonSubmitが呼ばれる
  const onSubmit = (data: LicenseApplyFormSchema) => {
    const prefectureLabel =
      optionsWithPrefectures.find(
        (option) => option.value === data.prefecture_name,
      )?.label || '未選択';

    const municipalityLabel =
      optionWithMunicipalities.find(
        (option) => option.municipality_id === data.municipalities_name,
      )?.municipality_name || '未選択';

    // zodの値変換+型チェックを通過した値
    console.log('data', data);
    // 確認画面表示(disabled表示)
    setIsConfirm(true);

    // teaching_materials 配列を作成
    const teachingMaterials = [];

    // 各教材と数量のペアについてループ処理
    for (let i = 1; i <= 6; i++) {
      // 型アサーションを使用してエラーを回避
      const textbookNameKey = `textbooks${i}` as keyof LicenseApplyFormSchema;
      const textbookQtyKey = `textbookQty${i}` as keyof LicenseApplyFormSchema;

      const textbookName = data[textbookNameKey] as string;
      const textbookQty = data[textbookQtyKey];

      // 教材名が空でない場合のみ配列に追加
      if (textbookName && textbookQty) {
        // textbookQty を文字列に変換してから parseInt を使用
        const quantity = parseInt(String(textbookQty)) || 0;
        teachingMaterials.push({
          teaching_materials_name: textbookName,
          purchases_number: quantity,
        });
      }
    }

    const submitData = {
      prefecture_name: prefectureLabel, // "東京都" などの文字列
      municipalities_name: municipalityLabel, // "目黒区" などの文字列
      school_name:
        data.school_name !== '0' ? data.school_name : data.school_alt,
      person_name: data.person_name,
      tel: data.tel,
      mail_address: data.mail_address,
      distributor_name: data.distributor_name,
      teaching_materials: teachingMaterials,
      note: data.memo,
      textbooks1: '',
      emailConfirm: '',
    };

    console.log('submitData', submitData);

    // formデータを保持
    // 不足分のデータは本来必要なし
    setFormData(submitData);
  };

  const handleEdit = () => {
    setIsConfirm(false);
  };

  const handleMailSendPost = () => {
    //メール送信コードを取得
    mailMailSendCodePost()
      .then((res) => {
        if (!res.data) {
          throw new Error('メール送信コードの取得に失敗しました。');
        }
        console.log('handleMailSendPost', res.data.mail_send_code);
        return res.data.mail_send_code;
      })
      .then((v) => {
        if (!formData) {
          throw new Error('フォームデータが存在しません。');
        }

        console.log('handleMailSendPost', formData);
        console.log('handleMailSendPost', v);

        const param: LibsDomainModelInterfaceMailSendMailRequest = {
          mail_contents: formData,
          mail_send_code: v,
          type: 1,
        };
        mailSendPost(param)
          .then((res) => {
            setIsSubmissionComplete(true);
            if (!res.data) {
              throw new Error('メール送信応答データが存在しません。');
            }
            console.log('handleMailSendPost data', res.data);
          })
          .catch((e) => {
            console.error('handleMailSendPost catch', e);
          });
      })
      .catch((e) => {
        console.error('handleMailSendPost catch', e);
      });
    //メール送信

    //送信完了メッセージ（トースト）
  };

  /**
   * 教材選択セレクトボックスの変更イベント
   */
  useEffect(() => {
    contentsPaperGet().then((response) => {
      // 取得したデータを SelectOptions の形式に変換
      const newOptions = response.data.contents.map((item) => ({
        value: item.content_name,
        label: item.content_name,
      }));
      setOptions(newOptions);
    });
  }, []);

  const selectChange = (e: any) => {
    if (e.target.name === 'prefecture_name') {
      setValue('municipalities_name', 0);
      setValue('school_name', '0');
    }
    if (e.target.name === 'municipalities_name') {
      setValue('school_name', '0');
    }
    setValue(e.target.name, e.target.value);
  };

  // 都道府県一覧を取得
  useEffect(() => {
    prefecturesGet().then((res: any) => {
      const prefectures = res.data.prefectures.map((prefecture: any) => ({
        value: prefecture.prefecture_id,
        label: prefecture.prefecture_name,
      }));
      setOptionsWithPrefectures([
        ...prefectures,
        { value: 0, label: '未選択' },
      ]);
    });
  }, []);

  const prefectureSelectChange = (e: any) => {
    const prefectureId = e.target.value;

    // 都道府県が未選択または変更された場合、市区町村と学校の状態をリセット
    if (prefectureId === '0' || prefectureId !== watchedInput.prefecture_name) {
      setOptionWithMunicipalities([]);
      setOptionWithSchoolNames([]);
      setValue('municipalities_name', '0');
      setValue('school_name', '0');
    }

    municipalitiesGet({ prefecture_id: e.target.value }).then((res) =>
      setOptionWithMunicipalities(res.data.municipalities),
    );
    setValue('prefecture_name', e.target.value);
  };

  const manicipalitySelectChange = (e: any) => {
    municipalitiesSchoolsGet({ municipalities_id: e.target.value }).then(
      (res) => {
        setOptionWithSchoolNames(res.data.school_name);
      },
    );
    setValue('municipalities_name', e.target.value);
  };

  return {
    form: {
      control,
      handleSubmit,
      setValue,
      onSubmit,
      errors,
      sortedErrors,
      isConfirm,
      isSchoolAltDisabled,
      handleEdit,
      handleMailSendPost,
      selectChange,
      prefectureSelectChange,
      manicipalitySelectChange,
      isSubmissionComplete,
    },
    options: {
      options,
      optionsWithPrefectures,
      optionWithMunicipalities,
      optionWithSchoolNames,
    },
  };
};

export default useLicenseApplyForm;
