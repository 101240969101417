import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, useWatch} from 'react-hook-form';
import { z } from 'zod';
import { usersMeProfilePut } from "@/src/__generated__";
import { useMemo } from 'react';
import { modalAtom, profileAtom } from '../modalAtom';
import { useRecoilState } from 'recoil';

const profileFormSchema = z.object({
  nick_name: z.string({ required_error: '表示する名前が未入力です。' }).min(1, '表示する名前が未入力です。').max(8, '文字制限'),
  profile_icon: z.number({ required_error: 'アイコンを選んでください' })
})
type ProfileFormSchema = z.infer<typeof profileFormSchema>

export const useProfileSettings = () => {
  const [profiles, setProfiles] = useRecoilState(profileAtom)

  const defaultValues = useMemo(() => {
    return {
      nick_name: profiles.nick_name,
      //profile_icon: currentUser && currentUser.preference ? currentUser.preference.profile_icon : 0,
      profile_icon:profiles.profile_icon
    }
  }, [])

  const {
    control, handleSubmit, setValue, setError, formState: { errors }
  } = useForm<ProfileFormSchema>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: defaultValues,
    resolver: zodResolver(profileFormSchema)
  })

  const [_openModalKey, setOpenModalKey] = useRecoilState<any>(modalAtom)

  const watchedInput = useWatch({ control })

  const setIconval = (val: number) => {
    setValue('profile_icon', val)
  }

  const onSubmit = async (data: ProfileFormSchema) => {
    try {
      const res = await usersMeProfilePut({
        nick_name: data.nick_name,
        profile_icon: data.profile_icon
      })
      if (res.status === 200) {
        setValue('nick_name', res.data.nick_name)
        setValue('profile_icon', res.data.profile_icon)
        const _profiles = {
          nick_name: res.data.nick_name,
          profile_icon: res.data.profile_icon
        }
        setOpenModalKey(null)
        setProfiles(_profiles)
        
      }
    } catch (error: any) {
      if (error.response.status === 400) {
        setError('nick_name', { type: 'custom', message: '「nickname」が未入力です。' });
      }
      if (error.response.status === 401) {
        setError('nick_name', { type: 'custom', message: '「nickname」の値の形式が正しくありません。' });
      }
      if (error.response.status === 404) {
        setError('nick_name', { type: 'custom', message: '「user_uuid」が存在しません。' });
      }
      if (error.response.status === 500) {
        setError('nick_name', { type: 'custom', message: 'システムエラーが発生しました。' });
      }
    }
  }

  return {
    form: {
      control,
      handleSubmit,
      onSubmit,
      errors,
      setIconval,
      watchedInput
    }
  }
}
