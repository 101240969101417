import { useFormContext } from 'react-hook-form';
import { FormItemDateRangePicker, FormItemTimeSlotSelect } from '../items';
import styles from '../styles.module.css';

export type DateTimeConditionsGroupProps = {
  states?: {
    dateRangeDisabled?: boolean;
    timeSlotDisabled?: boolean;
  };
};
export const DateTimeConditionsGroup = ({
  states = {},
}: DateTimeConditionsGroupProps) => {
  const {
    formState: { errors },
  } = useFormContext();
  return (
    <div className={`${styles.row} row`}>
      <FormItemDateRangePicker
        label="期間選択"
        noRuby
        disabled={states.dateRangeDisabled}
        className='label'
      />
      <FormItemTimeSlotSelect disabled={states.timeSlotDisabled} />
      {errors.to_date?.message && (
        <div className={styles['date-range-error-message']}>
          {errors.to_date.message as string}
        </div>
      )}
    </div>
  );
};
