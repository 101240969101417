import { Controller, useFormContext } from 'react-hook-form';
import { SchoolClassSelect, SchoolClassSelectProps } from './SchoolClassSelect';

export type FormItemSchoolClassSelectProps = SchoolClassSelectProps & {
  name: string;
};

export const FormItemSchoolClassSelect = ({
  name,
  ...props
}: FormItemSchoolClassSelectProps): JSX.Element => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => <SchoolClassSelect {...field} {...props} />}
    />
  );
};
