.body {
  margin-top: 20px;
  margin-left: 40px;
  width: 1440px;
}
form{
  position: relative;
}
.body .toggle-row {
  margin-top: 21px;
}
.body .table {
  margin-top: 17px;
  z-index: 0;
}

.table tr:nth-last-child(2) td {
  border-bottom: none;
}
.table tr:last-child td {
  border-top: 1px solid var(--line-color);
  /* background-color: var(--bg-table-summary-row); */
}
.table tbody tr:last-child {
  position: sticky;
  bottom: -1px;
  z-index: 1;
}

.table tbody tr:last-child td{
  border-bottom: 2px solid var(--line-color);
}


.table td:nth-child(n + 6) {
  padding: 16px 4px;
}

.selects-row {
  width: 100%;
  justify-content: space-between;
  margin-top: 2em;
}

.selects {
  display: flex;
  align-items: center;
  column-gap: 36px;
}

.buttons {
  margin-left: 15px;
  display: flex;
  column-gap: 15px;
}

.toggle-row {
  width: 100%;
  justify-content: flex-end;
}

