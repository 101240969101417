// useController: https://react-hook-form.com/docs/usecontroller
import { Stack, TextField, ThemeProvider, Typography, createTheme } from '@mui/material';
import { FieldValues, useController } from 'react-hook-form'
import { RHFTextFieldProps } from '../../types/RHFTextFieldProps';

export const RHFTextFieldDB = <T extends FieldValues>({
  name,
  control,
  type,
  placeholder,
  multiline,
  rows,
  tabIndex,
  disabled,
  theme
}: RHFTextFieldProps<T>) => {
  // 入力フォームを共通化したいので、RHFのControllerの代わりにuseControllerを使用しています
  const {
    field,
    formState: { errors },
  } = useController({ name, control })

  const errorMessage = errors?.[name]?.message as string

  const muiTheme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: '22px',
            //backgroundColor: 'white',
            border: '5px solid transparent',
            fontFamily: ['"A-OTF UD Shin Maru Go Pr6N-DB"', '"Shin Maru Go DemiBold"', 'Helvetica'].join(',') + ' !important',
            background: `linear-gradient(180deg, ${theme!.chart0} 0%, ${theme!.chart1} 100%) border-box border-box`,
            WebkitMask:'linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0) border-box',
            // WebkitMaskComposite:'destination-out',
            mask:'linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0) border-box',
            // maskComposite:'exclude',
          },
          input: {
            boxShadow: '0px 1px 5px 0px #00000070 inset',
            borderRadius: '17px',
            backgroundColor:'white',
            padding: '6px 19px',
            fontSize: '38px',
            fontFamily: ['"A-OTF UD Shin Maru Go Pr6N-DB"', '"Shin Maru Go DemiBold"', 'Helvetica'].join(',') + ' !important',
            width: '100vw',
            "&::placeholder": {
              fontFamily: ['"A-OTF UD Shin Maru Go Pr6N-DB"', '"Shin Maru Go DemiBold"', 'Helvetica'].join(',') + ' !important',
            },
            "&[name='memo']": {
              padding: '23px 25px',
              lineHeight: '1.3',
              fontFamily: ['"A-OTF UD Shin Maru Go Pr6N-DB"', '"Shin Maru Go DemiBold"', 'Helvetica'].join(',') + ' !important',
            },
            "&[name='memo'].Mui-disabled": {
              boxShadow: 'none'
            },
            //ブラウザの補完機能で背景色を変えない
            "&:-webkit-autofill": {
              transition: 'background-color 5000s ease-in-out 0s !important'
            },
            "&.Mui-disabled": {
              boxShadow: 'none',
              WebkitTextFillColor: 'rgba(0,0,0,1)'
            },
            "&.Mui-disabled::placeholder": {
              WebkitTextFillColor: 'rgba(0,0,0,0)'
            }
          },
          notchedOutline: {
            border: 'none',
          },
        }
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            position: 'absolute',
            top: '110px',
            left: '-4px',
            fontFamily: ['"A-OTF UD Shin Maru Go Pr6N-DB"', '"Shin Maru Go DemiBold"', 'Helvetica'].join(',') + ' !important',
            fontSize: '24px',
            letterSpacing:'-1px'
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={muiTheme}>
      <Stack direction="row" alignItems="center" m={2}>
        {multiline ?
          <TextField
            value={field.value ?? ''}
            inputRef={field.ref}
            name={field.name}
            onChange={field.onChange}
            onBlur={field.onBlur}
            type={type}
            placeholder={placeholder}
            multiline
            rows={rows}
            tabIndex={tabIndex}
            disabled={disabled}
          /> :
          <TextField
            value={field.value ?? ''}
            inputRef={field.ref}
            name={field.name}
            onChange={field.onChange}
            onBlur={field.onBlur}
            type={type}
            placeholder={placeholder}
            tabIndex={tabIndex}
            disabled={disabled}
          />
        }
        {/* {errorMessage && (
          <Typography variant="body1" ml={3} color="red">
            {errorMessage}
          </Typography>
        )} */}
      </Stack>
    </ThemeProvider>

  )
}

