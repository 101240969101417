import Button, { ButtonProps } from '@mui/material/Button';
import './SubButton.css';

export type SubButtonProps = ButtonProps & {};

const SubButton = (props: SubButtonProps) => {
  const { children } = props;

  return (
    <Button className="sub-button" {...props}>
      {children}
    </Button>
  );
};

export default SubButton;
