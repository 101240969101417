import { UserCore, usersUserUuidLastLoginDateGet } from '@/src/__generated__';
import { defaultHistoryPage } from '@/src/components/HistoryQueryForm/utils';
import { Modal } from '@/src/components/Modal';
import MainButton from '@/src/components/parts/MainButton';
import { formatToDateTimeDisplay } from '@/src/helpers/date';
import { useHistoryNavigate } from '@/src/hooks/useHistoryNavigation';
import { useAuthContext } from '@/src/middleware/auth/AuthContext';
import { useEffect, useState } from 'react';
import styles from './styles.module.css';

type ChildDetailModalProps = {
  child: UserCore | undefined;
  open: boolean;
  close: VoidFunction;
};
export const ChildDetailModal = ({
  child,
  open,
  close,
}: ChildDetailModalProps) => {
  const navigate = useHistoryNavigate();

  const { getCurrentUser } = useAuthContext();
  const historyPath = defaultHistoryPage(
    getCurrentUser?.()
      ?.license.filter(({ status }) => status !== 0)
      .map((license) => license.learning_contents),
  );

  const [lastLoginDate, setLastLoginDate] = useState<Date | null>();
  useEffect(() => {
    if (!child) {
      setLastLoginDate(null);
      return;
    }
    usersUserUuidLastLoginDateGet(child.user_uuid).then((res) => {
      setLastLoginDate(res.data.last_login_date);
    });
  }, [child]);
  return (
    <Modal
      open={open}
      onClose={close}
      headerClassName={styles['modal-header']}
      titleClassName={styles['modal-title']}
      title={child?.user_name || ''}
    >
      <ul className={styles['modal-content']}>
        <li>
          <div>ID</div>
          <div>{child?.account_id || ''}</div>
        </li>
        <li>
          <div>名前</div>
          <div>{child?.user_name || ''}</div>
        </li>
        <li>
          <div>表示名</div>
          <div>{child?.nick_name || ''}</div>
        </li>
        <li>
          <div>年</div>
          <div>{child?.grade != null ? `${child.grade}年` : ''}</div>
        </li>
        <li>
          <div>組</div>
          <div>{child?.class_name ? `${child.class_name}組` : ''}</div>
        </li>
        <li>
          <div>出席番号</div>
          <div>{child?.attendance_no ?? ''}</div>
        </li>
        <li>
          <div>最終ログイン</div>
          <div>
            {lastLoginDate ? formatToDateTimeDisplay(lastLoginDate) : ''}
          </div>
        </li>
        {historyPath && (
          <li>
            <div>行動履歴</div>
            <div>
              <MainButton
                type="button"
                onClick={() =>
                  navigate(historyPath, {
                    state: {
                      user_uuid: child?.user_uuid,
                      grade: child?.grade,
                      class_name: child?.class_name,
                    },
                  })
                }
              >
                詳細を見る
              </MainButton>
            </div>
          </li>
        )}
      </ul>
    </Modal>
  );
};
