import { sSOMicrosoftLoginSsoMicrosoftPost } from '../../../../__generated__';
import { SSOClickAction, SSOClickResponse } from '../AuthTypes';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const msal: any;

type MicrosoftLoginPrompt = 'login' | 'select_account' | 'consent' | 'none';

export const SSOLoginMicrosoftOnClick: SSOClickAction = () => {
  const promise = new Promise<SSOClickResponse>((resolve, reject) => {
    const clientId = process.env.SSO_MICROSOFT_CLIENT_ID || "";
    const redirectUri = process.env.SSO_MICROSOFT_REDIRECT_URI || "";
    const graphScopes = ['user.read'];
    const prompt: MicrosoftLoginPrompt = 'select_account';
    const msAuth = document.createElement("script");
    // <script src="https://alcdn.msauth.net/browser/2.13.1/js/msal-browser.js" integrity="sha384-7hwr87O1w6buPsX92CwuRaz/wQzachgOEq+iLHv0ESavynv6rbYwKImSl7wUW3wV" crossorigin="anonymous"></script>

    msAuth.src =  "https://alcdn.msauth.net/browser/2.13.1/js/msal-browser.js";
    msAuth.type = "text/javascript";
    msAuth.onload = () => {
      // PublicClientApplication 
      const publicClientApplication = new msal.PublicClientApplication({
        auth: {
          clientId: clientId,
          redirectUri: redirectUri,
          validateAuthority: false,
        },
        cache: {
          cacheLocation: 'sessionStorage',
          storeAuthStateInCookie: true,
        },
      });

      console.log("call popupLogin");
      try {
        console.log("call popupLogin try");
        const params = {
          scopes: graphScopes,
          prompt: prompt,
          loginHint: 'login@tomosplus.jp', // TODO
          responseType: 'code',
        };

        console.log('call popup');
        console.log(params);
        publicClientApplication.acquireTokenPopup(params).then((ret: any) => {
          console.log('call popup done');
          console.log(ret);
          const accessToken = ret.accessToken;
          sSOMicrosoftLoginSsoMicrosoftPost({code: accessToken}).then((response) => {
            if (response && response.data && response.data.user_uuid) {
              resolve(response);
              return;
            }
            reject(response);
            return;
          }).catch ((err) => {
            console.log(err);
            reject(err.response);
          });
          return null;
        }).catch((err: any) => {
          console.log(err);
          // ユーザキャンセル
          if (err.errorCode == "user_cancelled") {
            const res = {
              response: {
                status: 499
              }
            }
            reject(res);
          }
          reject(err);  
        });
      } catch (err) {
        console.log(err);
        reject(err);
      }
    };
    document.head.append(msAuth);
  });
  return promise;
};
