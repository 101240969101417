export const getAutoGeneratePassword = () => {
  // パスワードの文字数を決定
  const maxLength = 10;
  const minLength = 10;

  // パスワードに使用可能な文字を決定（今回はアルファベットの大文字と小文字 ＋ 数字 ＋ symbolsの記号）
  const lowerCase = 'abdefghjmnqru';
  const upperCase = 'ABCDEFGHJKLMNPQRSTUVWXY';
  const numbers = '23456789';
  const symbols = '!#$%&()*+-<=>?@[]_{}'
  const chars = lowerCase + upperCase + numbers + symbols;

  // パスワードが持つ文字列条件を正規表現で追加（今回はアルファベット小文字1つ以上かつ数字1つ以上）
  const lowerCaseRegex = new RegExp('[a-z]');
  const numberRegex = new RegExp('[0-9]');
  const symbolsRegex = new RegExp(/[!"#$%&'()\*\+\-\.,\/:;<=>?@\[\\\]^_`{|}~]/g);

  // autoGeneratedPasswordが以上の条件を満たせばvalidがtrueになる
  let valid = false;
  let autoGeneratedPassword: string = '';

  while (!valid) {
    autoGeneratedPassword = '';
    // パスワードの文字数を上で決めた範囲内（今回は10文字以上10文字以下）でランダムに決定
    const randomLength =
      Math.floor(Math.random() * (maxLength - minLength)) + minLength;
    while (autoGeneratedPassword.length < randomLength) {
      // 上で決定したcharsの中からランダムに1文字ずつ追加
      const index = Math.floor(Math.random() * chars.length);
      autoGeneratedPassword += chars[index];
    }

    // パスワードが上で決定した条件をすべて満たしているかチェック
    // 条件を満たすまでループ
    valid =
      minLength <= autoGeneratedPassword.length &&
      autoGeneratedPassword.length <= maxLength &&
      lowerCaseRegex.test(autoGeneratedPassword) &&
      numberRegex.test(autoGeneratedPassword) &&
      symbolsRegex.test(autoGeneratedPassword);
  }
  return autoGeneratedPassword;
};
