/* .form {
  position: relative;
  color: var(--letter-black);
} */

.form.student {
  height: 124px;
}

.form.student .row {
  column-gap: 36px;
}

.form .items {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.form>.submit {
  position: absolute;
  top: 68px;
  left: 512px;
}

.form .time-slot {
  width: 115px;
  height: 28px;
  padding: 4px 9px;

  font-size: 18px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {
    font-size: 1rem;
  }

  ;
  font-family: var(--main-font-family-m);

  letter-spacing: 0.02em;
}

.form .time-slot>img {
  width: 15px;
  right: 10px;
}

.conditions {
  display: flex;
  flex-direction: column;
}

.conditions>*:nth-child(2) {
  margin-top: 22px;
  margin-bottom: 11px;
}

.row {
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 20px;
  /* margin-bottom: 1.3em;
  margin-top:1em; */
}

.row .school-class-select {
  width: 220px;
}

.row .student-select {
  margin-left: 15px;
  width: 340px;
}

.row .student-search {
  margin-left: 45px;
  width: 340px;
  background-color: white;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {
    width:378px;
  }
}

.row .time-slot {
  margin-left: 28px;
}

.submit {
  width: fit-content !important;
  height: 45px !important;
  font-size: 22px !important;
  line-height: 1 !important;
  padding: 6px 9px 9px !important;
  border-radius: 8px !important;
  box-shadow: 0px 1.0533334016799927px 3.1600003242492676px 0px #000000b2 !important;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {
    font-size: 1.375rem !important;
  }
  white-space: nowrap;


}

.submit.ruby {
  height: 47px !important;
  position: absolute;
  left: 505px;
  top: 70px;
}

.submit:disabled,
.download:disabled {
  cursor: not-allowed !important;
  pointer-events: unset !important;
  box-shadow: 0 0 0 !important;
}

.download {
  width: 175px !important;
  height: 45px !important;
  border-radius: 8px !important;

  font-size: 22px !important;
  line-height: 1 !important;
  letter-spacing: 0.05em !important;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {
    font-size: 1.375rem !important;
  }

  ;
  white-space: nowrap;
}

.toggle-container {
  display: flex;
  align-items: center;
  column-gap: 5px;
  padding: 3px;
  border-radius: 40px;
  background: var(--gray);
}

.toggle-container.student {
  position: absolute;
  /* bottom: -16px; */
  top:68px;
  right: 0;
}

.toggle-container.student>button {
  align-items: flex-end;
  padding-bottom: 12px;
}

/* .toggle-container > button {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 196px;
  height: 50px;
  border-radius: 4px;
  border: 2px solid var(--beta-blue);
  background: linear-gradient(180deg, #ecf6fe 10%, #9fd4fa 47.4%),
    linear-gradient(0deg, #0a93f5, #0a93f5);
  box-shadow: 0px 2px 6px 0px #000000b2;

  color: var(--letter-blue);
  font-size: 18px;
  font-family: var(--main-font-family-m);
  
  line-height: 1;

  cursor: pointer;
}
.toggle-container > button:first-child {
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
}
.toggle-container > button:last-child {
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
}
.toggle-container > button.active {
  color: var(--white);
  background: linear-gradient(180deg, #004d80 32.81%, #008bc4 90.62%);
  box-shadow: none;
  border: none;
  pointer-events: none;
} */

.label-container {
  display: flex;
  align-items: center;
  column-gap: 20px;
  
}

.label-container .label {
  font-size: 24px;
  font-family: var(--main-font-family-m);

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {
    font-size: 1.375rem
  }

  ;

  line-height: 28.5px;
  letter-spacing: 0.1em;
  white-space: nowrap;
}

.date-range-picker {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.label-container.ruby .date-range-picker {
  margin-top: 8px;
}

.date-range-picker>span {
  font-family: var(--main-font-family-db);
  font-size: 18px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {
    font-size: 1.125rem;
  }

  ;

  line-height: 25.65px;
  letter-spacing: 0.002em;
}

.date-range-error-message {
  position: absolute;
  top: calc(100% + 0px);
  color: var(--gold-red);
  font-size: 18px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {
    font-size: 1.125rem;
  }

  ;
  font-family: var(--main-font-family-m);

}

.student .date-range-error-message {
  top: calc(100% - 20px);
}

.date-picker {
  width: 132px;

  font-family: var(--main-font-family-db);
  font-size: 16px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {
    font-size: 1rem;
  }

  ;

  line-height: 20px;
  letter-spacing: 0.005em;
  
}

.date-picker>div {
  height: 28px;
}

.date-picker input {
  /* width: 108px; */

  font-family: var(--main-font-family-db);
  font-size: 16px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {
    font-size: 1rem;
  }

  ;

  line-height: 20px;
  letter-spacing: 0.005em;
}

.student-select {
  width: 340px;
}