.text-field > div {
  padding-right: 0;
  padding-left: 10px;
  color: var(--letter-black);
}

.text-field input {
  padding: 0;
}

.text-field > button {
  padding: 0;
}

.text-field fieldset {
  border-radius: 4px;
  border: 1px solid var(--line-color) !important;
}

.disabled {
  opacity: 0.5;
  background: var(--gray);
}
.disabled button {
  color: var(--letter-black) !important;
}

.icon-button:hover {
  background-color: transparent !important;
}
.icon-button > svg {
  width: 28px;
  height: 28px;
  margin: 0 16px;
}

.popper *,
.popper :global(.MuiDayCalendar-root) {
  font-family: var(--main-font-family-db) !important;
  letter-spacing: 0.05em;
}

.popper :global(.MuiPaper-root) {
  margin-top: 5px;
  background: transparent;
  overflow: visible;
  box-shadow: none;
  filter: drop-shadow(
    0 2.8600001335144043px 3.579999923706055px rgba(0, 0, 0, 0.7)
  );
}
.popper :global(.MuiPickersLayout-root) {
  display: block;
  background: transparent;
}
.popper :global(.MuiDateCalendar-root) {
  height: fit-content;
  width: 386px;
  max-height: unset;
  overflow: unset;
}

.popper :global(.MuiDayCalendar-root) {
  background: var(--white);
  border-left: 2px solid var(--tomopla-color);
  border-right: 2px solid var(--tomopla-color);
}

.popper :global(.MuiDayCalendar-root) * {
  font-size: 20px !important;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none)  {font-size: 1.25rem!important;}
  color: var(--letter-black);
}
.popper :global(.MuiDayCalendar-header) > * {
  width: 44px;
  height: 44px;
}
.popper :global(.MuiDayCalendar-header) > :nth-child(6) {
  color: var(--beta-blue);
}
.popper :global(.MuiDayCalendar-header) > :nth-child(7) {
  color: var(--gold-red);
}
.popper :global(.MuiPickersSlideTransition-root) {
  min-height: 286px;
}
.popper :global(button.MuiPickersDay-root) {
  width: 44px;
  height: 44px;
}
.popper :global(.MuiPickersDay-root.Mui-selected) {
  border: 2px solid var(--beta-blue);
  background-color: var(--light-blue-2) !important;
  color: var(--letter-black);
}
.popper :global(.MuiPickersDay-root):hover {
  background-color: var(--light-blue-2) !important;
}

.day-outside-month {
  color: var(--gray) !important;
}
