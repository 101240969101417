import { atom } from 'recoil';
// import { recoilPersist } from 'recoil-persist'

// const { persistAtom } = recoilPersist({
//     key: "theme-persist",
//     storage: typeof window === "undefined" ? undefined : sessionStorage
// })
type Profiles = {
    nick_name: any;
    profile_icon: any;
}

export const menuAtom = atom({
    key: "menustate",
    default: false,
})

export const modalAtom = atom({
    key: "modalstate",
    default: null,
})

export const themeAtom = atom<number | null>({
    key: "themenum",
    default: null
})

export const profileAtom = atom<Profiles>({
    key: "profiles",
    default: {
        nick_name: undefined,
        profile_icon: undefined
    }
})

export const errorMessageAtom = atom<any>({
    key: "errorMessage",
    default: undefined
})