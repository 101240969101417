import { useAuthContext } from '@/src/middleware/auth/AuthContext';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './styles.module.css';
import styled from 'styled-components';
import { defaultHistoryPage } from '../../HistoryQueryForm/utils';

const Wrap = styled('div')(
  ({theme})=>`
    background-color: var(${theme.graycolor});
  `
)

export const TeacherSideMenu: React.FC = () => {
  const { pathname } = useLocation();
  const { getCurrentUser } = useAuthContext();
  const historyPath = defaultHistoryPage(
    getCurrentUser?.()
      ?.license.filter(({ status }) => status !== 0)
      .map((l) => l.learning_contents),
  );
  return (
    <Wrap className={styles.container}>
      <div className={styles.items}>
        <Link to="/teacherstudentlist" className={styles.item}>
          <img src="/img/teacher-menu-student-list.svg" />
          <div>児童情報一覧</div>
        </Link>
        {historyPath && (
          <Link
            to={pathname.startsWith('/teacherhistory') ? '' : historyPath}
            className={styles.item}
          >
            <img src="/img/teacher-menu-action-history.svg" />
            <div>学習履歴</div>
          </Link>
        )}
      </div>
      <Link to="https://help.tomosplus.jp/" target='_blank' className={styles.item}>
        <img src="/img/teacher-menu-help.svg" />
        <div>
          ヘルプ・
          <br />
          問い合わせ
        </div>
      </Link>
    </Wrap>
  );
};
