import React from 'react'

export const Thanks = () => {
  return (
    <>
      
        <h2 className="pagettl">送信完了</h2>

        <p className="message">メッセージを受け付けました。<br />
          ありがとうございました。</p>

        {/* <div className="btn_wrap">
          <p className="btn_return"><a href="index.html">← トップへもどる</a></p>
        </div> */}
      
    </>
  )
}
