import { ButtonHTMLAttributes } from 'react';
import './StudentHistoryTestButton.scss';

type Props = Partial<{
  type: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  disabled: ButtonHTMLAttributes<HTMLButtonElement>['disabled'];
  tabIndex: ButtonHTMLAttributes<HTMLButtonElement>['tabIndex'];
  className: any;
  onClick?: () => void;
  children: React.ReactNode;
}>;

export const StudentHistoryTestButton = ({
  type,
  disabled,
  tabIndex,
  className,
  onClick,
  children,
}: Props) => {
  return (
    <button
      className={`shtb-element-default-wrapper ${className}`}
      type={type}
      disabled={disabled}
      tabIndex={tabIndex}
      onClick={onClick}
    >
      <div className="overlap-group">
        <div className="rectangle" />
        <div className="text-wrapper">{children}</div>
      </div>
    </button>
  );
};
