import { SchoolClass } from '@/src/__generated__';
import { Select } from '@/src/components/Select';

export type SchoolClassSelectProps = {
  schoolClassList: Partial<SchoolClass>[];
  onChange?: (index: number | "" | null | undefined) => void;
  value?: number | "" | null;
  className?: string;
  disabled?: boolean;
  allDisabled?: boolean;
};
export const SchoolClassSelect = ({
  schoolClassList,
  allDisabled,
  ...props
}: SchoolClassSelectProps) => (
  <Select<number|"">
    {...props}
    options={[
      { label: '全体', value: "", disabled: allDisabled },
      ...schoolClassList.map((schoolClass, index) => ({
        value: index,
        label:
          schoolClass.class_name == null
            ? `${schoolClass.grade}年全体`
            : `${schoolClass.grade}年${schoolClass.class_name}組`,
        disabled:
          allDisabled &&
          (schoolClass.grade == null || schoolClass.class_name == null),
      })),
    ]}
    noRuby
  />
);
