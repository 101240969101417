import Kanji from '@/src/components/auth/Kanji';
import { Link } from 'react-router-dom';
import './Link.css';

interface Props {
  className: any;
  href: string;
}

const RouteLink = (props: Props) => {
  const { className, href } = props;

  return (
    <Link to={href} {...props} className={`tps-link ${className}`}>
      パスワードを<Kanji kana="わす">忘</Kanji>れたら？
    </Link>
  );
};

export default RouteLink;
