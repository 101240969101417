import { LibsDomainModelInterfaceKanjiGetKanjiSkillLearningHistoriesResponse } from '@/src/__generated__';
import { formatToDateDisplay } from '@/src/helpers/date';
import { useAuthContext } from '@/src/middleware/auth/AuthContext';
import { CellContext,ColumnDef } from '@tanstack/react-table';

export type RowData = {
  user_uuid?: LibsDomainModelInterfaceKanjiGetKanjiSkillLearningHistoriesResponse['user_uuid'];
  school_class?: LibsDomainModelInterfaceKanjiGetKanjiSkillLearningHistoriesResponse['school_class'];
  daily_datum: Partial<
    LibsDomainModelInterfaceKanjiGetKanjiSkillLearningHistoriesResponse['daily_data'][0]
  >;
  isTotal?: boolean;
};

export const COLUMN_DEFS: Record<string, ColumnDef<RowData>> = {
  grade: {
    id: 'grade',
    accessorKey: 'school_class.grade',
    size: 73,
    meta: { stickyLeft: 0 },
    header: '年',
    cell: (cell) => {
      const row = cell.row.original;
      if (row.isTotal) return '';
      const value = cell ? cell.getValue() as number : null;
      return value ? `${value}年` : '';
    },
  },
  class: {
    id: 'class',
    accessorKey: 'school_class.class_name',
    size: 73,
    meta: { stickyLeft: 73 },
    header: '組',
    cell:(cell)=>{
      const row = cell.row.original;
      if (row.isTotal) return '';
      const value = cell.getValue() as any;
      return value ? `${value}組` : '';
    }
  },
  name: {
    id: 'name',
    accessorKey: 'user_uuid',
    size: 236,
    meta: { stickyLeft: 146, align: 'left' },
    header: '氏名',
    cell: (cell) => {
      const { getUserFromUuid } = useAuthContext();
      const uuid = cell.getValue() as string | undefined;
      if (uuid) {
        const user = uuid && getUserFromUuid?.(uuid);
        if (!user) return '';
        return `${user.user_name} さん`;
      }
      const row = cell.row.original;
      if (row.isTotal) return '';
      if (!row.school_class) return '全体';
      if (!row.school_class.grade) return '全体';
      if (!row.school_class.class_name)
        return `${row.school_class.grade}年全体`;
      return `${row.school_class.grade}年${row.school_class.class_name}組`;
    },
  },
  date: {
    id: 'date',
    accessorKey: 'daily_datum.date',
    size: 169,
    meta: { stickyLeft: 382 },
    header: '日付',
    cell:({cell}:CellContext<any, any>) => {
      const row = cell.row.original;
      if (row.isTotal) return '';
      const value = cell.getValue();
      if(!value) return;
      return formatToDateDisplay(value as any);
    }
  },
  total_number: {
    id: 'total_number',
    accessorKey: 'daily_datum.total_number',
    size: 86,
    meta: { stickyLeft: 551 },
    header: 'アクセス\n総数',
  },
};

export const acces_data_columns = (data: RowData) => ({
  id: 'acces_data',
  header: 'アクセス数（単元）',
  columns: data.daily_datum.access_data?.map((e, i) => ({
    id: `access_data_${i}`,
    accessorKey: `daily_datum.access_data.${i}.number_studies`,
    size: 39,
    header: e.teaching_unit_type === 2 ? `(${e.teaching_unit_number})` : e.teaching_unit_number,
  })),
});

export const generateColumns = (data: RowData) => [
  COLUMN_DEFS.grade,
  COLUMN_DEFS.class,
  COLUMN_DEFS.name,
  COLUMN_DEFS.date,
  COLUMN_DEFS.total_number,
  acces_data_columns(data),
];
