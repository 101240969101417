import { z } from 'zod';

// フォームのsubmit時に走るschema
export const pwdFormSchema = z
  .object({
    newPassword: z
      .string({ required_error: '新しいパスワードが入力されていません。' })
      .min(1, '新しいパスワードが入力されていません。')
      // .min(10, '新しいパスワードの形式が正しくありません。')
      // .max(64, '新しいパスワードの形式が正しくありません。')
      .regex(
        /^(?=.*?[a-z])(?=.*?\d)(?=.*?[!-\/:-@[-`{-~])(?!.*(,|'|"|\||\\|\?|\/))[!-~]{10,100}$/i,
        '正規表現エラー',
      ),
    newPasswordConfirm: z
      .string({ required_error: '新しいパスワード（確認）が入力されていません。' })
      .min(1, '新しいパスワード（確認）が入力されていません。'),
  })
  .superRefine(({ newPassword, newPasswordConfirm }, ctx) => {
    if( newPassword.length <= 0){
      ctx.addIssue({
        path: ['newPassword'],
        code: 'custom',
        message: '新しいパスワードが入力されていません。',
      });
    }
    if (newPassword !== newPasswordConfirm) {
      ctx.addIssue({
        path: ['newPasswordConfirm'],
        code: 'custom',
        message: '新しいパスワードと新しいパスワード（確認）が一致していません。',
      });
    }
  });

export type PwdFormSchema = z.infer<typeof pwdFormSchema>;

// モーダルのsubmit時に走るschema拡張
export const fullPwdFormSchema = z
  .object({
    currentPassword: z
      .string({ required_error: '現在のパスワードが入力されていません。' })
      .min(1, '現在のパスワードが入力されていません。'),
    newPassword: z
      .string({ required_error: '新しいパスワードが入力されていません。' })
      // .min(10, '新しいパスワードの形式が正しくありません。')
      // .max(64, '新しいパスワードの形式が正しくありません。')
      .min(1, '新しいパスワードが入力されていません。')
      .regex(
        /^(?=.*?[a-z])(?=.*?\d)(?=.*?[!-\/:-@[-`{-~])(?!.*(,|'|"|\||\\|\?|\/))[!-~]{10,100}$/i,
        '正規表現エラー',
      ),
    newPasswordConfirm: z
      .string({ required_error: '新しいパスワード（確認）が入力されていません。' })
      .min(1, '新しいパスワード（確認）が入力されていません。'),
  })
  .superRefine(({ currentPassword, newPassword, newPasswordConfirm }, ctx) => {
    if (currentPassword === newPassword) {
      ctx.addIssue({
        path: ['newPassword'],
        code: 'custom',
        message: '現在のパスワードと新しいパスワードが同じです。',
      });
    }
    if (newPassword !== newPasswordConfirm) {
      ctx.addIssue({
        path: ['newPasswordConfirm'],
        code: 'custom',
        message: '新しいパスワードと確認用のパスワードがちがいます。',
      });
    }
  });

export type FullPwdFormSchema = z.infer<typeof fullPwdFormSchema>;
