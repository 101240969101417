import { z } from 'zod';

export const learningHistoryKanjiSkillTestQueryFormSchema = z.object({
  class_index: z.number().or(z.enum([""])),
  category_index: z.number(),
  teaching_unit_id: z.number(),
});

export type LearningHistoryKanjiSkillTestQueryFormSchema = z.infer<
  typeof learningHistoryKanjiSkillTestQueryFormSchema
>;
