import {
  Location,
  NavigateOptions,
  To,
  useLocation,
  useNavigate,
} from 'react-router-dom';

type NavigateWithState<S = any> = (
  to: To,
  options?: Omit<NavigateOptions, 'state'> & { state?: S },
) => void;

type HistoryState = Partial<{
  class_name: string | null;
  grade: number | null;
  user_uuid: string;
}>;
export const useHistoryNavigate = () =>
  useNavigate() as NavigateWithState<HistoryState>;

export const useHistoryLocation = () =>
  useLocation() as Location<HistoryState | undefined>;
