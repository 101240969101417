import { ReactNode, ButtonHTMLAttributes } from "react";
import "./style.scss";
import Kanji from "../../auth/Kanji";

type Props = Partial<{
  type: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  disabled: ButtonHTMLAttributes<HTMLButtonElement>['disabled'];
  tabIndex: ButtonHTMLAttributes<HTMLButtonElement>['tabIndex'];
  className: any;
  onClick?: () => void;
}>;

export const ModalDecisionButton: React.FC<Props> = ({ type, disabled, tabIndex, className, onClick }) => {
  return (
    <button
      type={type}
      disabled={disabled}
      tabIndex={tabIndex}
      onClick={onClick}
      className={`main-button ${className}`}>
      <div className="text-wrapper"><Kanji kana="けっ">決</Kanji><Kanji kana="てい">定</Kanji></div>
    </button>
  );
};