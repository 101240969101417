import FormSendComplete from '@/src/components/FormSendComplete/FormSendComplete';
import { RHFTextField } from '@/src/components/RHFTextField';
import MainButton from '@/src/components/parts/MainButton';
import SubButton from '@/src/components/parts/SubButton';
import useParentalAgreementForm from '@/src/hooks/useParentalAgreementForm';
import { Controller } from 'react-hook-form';
import './style.css';

import { useEffect } from 'react';

export const ParentalAgreementForm = (): JSX.Element => {
  const {
    form: {
      control,
      handleSubmit,
      onSubmit,
      errors,
      isConfirm,
      handleEdit,
      handleMailSendPost,
      isSubmittedSuccessfully,
      isSubmissionComplete,
    },
  } = useParentalAgreementForm();

  console.log('isSubmittedSuccessfully', isSubmittedSuccessfully);

  if (isSubmissionComplete) {
    return <FormSendComplete />;
  }

  return (
    <div className="parental-agreement-form">
      <div className="overlap-2">
        <div className="no-BG-2">
          <div className="group-7">
            <div className="frame-3">
              <div className="frame-4">
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
              </div>
              <div className="frame-4">
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
              </div>
              <div className="frame-4">
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
              </div>
              <div className="frame-4">
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
              </div>
              <div className="frame-4">
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
              </div>
              <div className="frame-4">
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
              </div>
              <div className="frame-4">
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
              </div>
              <div className="frame-4">
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
              </div>
            </div>
          </div>
          <div className="group-8">
            <div className="frame-3">
              <div className="frame-4">
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
              </div>
              <div className="frame-4">
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
              </div>
              <div className="frame-4">
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
              </div>
              <div className="frame-4">
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
              </div>
              <div className="frame-4">
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
              </div>
              <div className="frame-4">
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
              </div>
              <div className="frame-4">
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
              </div>
              <div className="frame-4">
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
              </div>
            </div>
          </div>
          <div className="group-9">
            <div className="frame-3">
              <div className="frame-4">
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
              </div>
              <div className="frame-4">
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
              </div>
              <div className="frame-4">
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
              </div>
              <div className="frame-4">
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
              </div>
              <div className="frame-4">
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
              </div>
              <div className="frame-4">
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
              </div>
              <div className="frame-4">
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
              </div>
              <div className="frame-4">
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
              </div>
            </div>
          </div>
          <div className="group-10">
            <div className="frame-3">
              <div className="frame-4">
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
              </div>
              <div className="frame-4">
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
              </div>
              <div className="frame-4">
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
              </div>
              <div className="frame-4">
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
              </div>
              <div className="frame-4">
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
              </div>
              <div className="frame-4">
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
              </div>
              <div className="frame-4">
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
              </div>
              <div className="frame-4">
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
              </div>
            </div>
          </div>
          <div className="group-11">
            <div className="frame-3">
              <div className="frame-4">
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
              </div>
              <div className="frame-4">
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
              </div>
              <div className="frame-4">
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
              </div>
              <div className="frame-4">
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
              </div>
              <div className="frame-4">
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
              </div>
              <div className="frame-4">
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
              </div>
              <div className="frame-4">
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
              </div>
              <div className="frame-4">
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
                <div className="ellipse-2" />
              </div>
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="message-warning">
            {Object.entries(errors).map(([fieldName, error]) => (
              <div key={fieldName} className="error-message">
                {error.message}
              </div>
            ))}
          </div>
          <div className="main-form-2">
            <div className="block-person-2">
              <div className="group-wrapper-2">
                <RHFTextField
                  name="guardian_name"
                  control={control}
                  placeholder=""
                  disabled={isConfirm}
                  showError={false}
                />
              </div>
              <div className="label-person-2">
                <div className="text-wrapper-12">児童の方のお名前</div>
              </div>
            </div>
            <div className="block-tel-2">
              <div className="group-wrapper-2">
                <RHFTextField
                  name="child_name"
                  control={control}
                  placeholder=""
                  disabled={isConfirm}
                  showError={false}
                />
              </div>
              <div className="label-tel-2">保護者の方のお名前</div>
            </div>
            <div className="block-email-2">
              <div className="group-wrapper-2">
                <RHFTextField
                  name="mail_address"
                  control={control}
                  placeholder=""
                  disabled={isConfirm}
                  showError={false}
                />
              </div>
              <div className="label-email-2">
                <div className="text-wrapper-11">
                  保護者の方の
                  <br />
                  メールアドレス
                </div>
              </div>
              <div className="text-wrapper-15">
                ※メール受信設定をされている場合は、ドメイン「@tomosplus.jp」からのメール受信を許可してください。
              </div>
            </div>
            <div className="block-email-confirm-2">
              <div className="group-wrapper-2">
                <div className="div-wrapper-2">
                  <RHFTextField
                    name="school_name"
                    control={control}
                    placeholder="〇〇〇〇小学校"
                    disabled={true}
                    showError={false}
                  />
                </div>
              </div>
              <div className="label-email-confirm-2">
                <div className="text-wrapper-10">学校名</div>
              </div>
            </div>
            <div className="block-store">
              <div className="div-wrapper-2">
                <div className="group-14">
                  <RHFTextField
                    name="schoolAdditionalInfo"
                    control={control}
                    placeholder="表示されている学校名と異なる場合は学校名を入力してください。"
                    disabled={isConfirm}
                    showError={false}
                  />
                </div>
              </div>
            </div>
          </div>

          {!isConfirm && (
            <>
              <div className="group-12">
                <div className="group-13">
                  <Controller
                    name="termsAgreed"
                    control={control}
                    render={({ field }) => (
                      <label className="overlap-group-4">
                        <input
                          type="checkbox"
                          onChange={(e) => field.onChange(e.target.checked)}
                          checked={field.value}
                          style={{ display: 'none' }}
                        />
                        <div className="message-description-2">
                          上記「個人情報の取り扱いについて」を確認し、記載内容に同意します。
                        </div>
                        <svg
                          className="vector"
                          width="50"
                          height="50"
                          viewBox="0 0 50 50"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="2.5"
                            y="2.5"
                            width="45"
                            height="45"
                            rx="6.5"
                            fill={field.value ? '#000' : '#D9D9D9'}
                            stroke={field.value ? '#000' : '#D9D9D9'}
                            strokeWidth="5"
                          />
                          <rect
                            x="5"
                            y="5"
                            width="40"
                            height="40"
                            rx="4"
                            fill="white"
                          />
                          <path
                            d="M11.5 24L17.6652 34.2753C18.3399 35.3998 19.896 35.5816 20.8117 34.643L39 16"
                            stroke={field.value ? '#000' : '#D9D9D9'}
                            strokeWidth="5"
                            strokeLinecap="round"
                          />
                        </svg>
                      </label>
                    )}
                  />
                </div>
              </div>
              <div className="view-3">
                <div className="overlap-4">
                  <div className="text-wrapper-14">
                    個人情報の取り扱いについて
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="view-wrapper">
            <div className="view-2">
              <div className="group-15">
                <div className="text-wrapper-13">
                  児童の個人情報提供に伴う
                  <br />
                  保護者同意送信フォーム
                </div>
              </div>
            </div>
          </div>
          <div className="message-description-3">
            本学習アプリを児童の方が使用するためには、保護者の方の同意が必要となります。
            <br />
            下記フォームに入力の上、送信ください。
          </div>
          {!isSubmittedSuccessfully && (
            <>
              {!isConfirm ? (
                <div className="overlap-wrapper">
                  <MainButton className="overlap-3" type="submit">
                    <div className="text-wrapper-7">確認画面へ</div>
                  </MainButton>
                </div>
              ) : (
                <div className="block-buttons-wrapper">
                  <div className="block-buttons">
                    <SubButton
                      className="sub-button"
                      type="button"
                      onClick={handleEdit}
                    >
                      <div className="text-wrapper">修正する</div>
                    </SubButton>
                    <MainButton
                      className="main-button"
                      type="button"
                      onClick={handleMailSendPost}
                    >
                      <div className="text-wrapper">送信する</div>
                    </MainButton>
                  </div>
                </div>
              )}
            </>
          )}
          {isSubmittedSuccessfully && (
            <div className="mailpost-complete">ありがとうございました。</div>
          )}
        </form>
      </div>
    </div>
  );
};
