.container {
  display: flex;
  justify-content: center;
  padding: 60px 60px 40px;
}

.main {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1450px;
  max-height: 980px;
  border-radius: 20px;
  overflow: hidden;
  background: var(--white);
  color: var(--letter-black);
}
.main:focus {
  outline: none;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 15px 15px 15px 50px;
  column-gap: 16px;

  background: var(--tomopla-color);
  color: var(--white);
  font-size: 40px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 2.5rem;};
  font-family: var(--main-font-family-db);
  
  letter-spacing: 0.05em;
  min-height: 96px;
}
.title {
  flex: 1;
}

.close-button {
  display: flex;
  align-items: center;
  column-gap: 5px;
  position: absolute;
  right: 10px;

  /* width: 140px;
  height: 66px;
  padding: 8px 9px;
  border: 1px solid var(--white);
  border-radius: 50px;
  background: linear-gradient(180deg, #b5b5c1 10.42%, #5a5a63 91.67%);
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.7);
  color: var(--white);
  font-family: var(--main-font-family-m);
  font-size: 20px;
   */
  cursor: pointer;
}

/* .close-button > img {
  display: gird;
  place-content: center;
  width: 50px;
  height: 50px;
  padding: 12px 11px 11px 12px;
  border-radius: 50%;
  background: var(--white);
} */

.body {
  flex: 1;
  overflow-y: auto;
}
