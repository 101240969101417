export const Rule = () => {
  return (
    <>
      <h2 className="pagettl">利用規約</h2>

      <div className="Txt_Box">
        <p>光村教育図書株式会社（以下、当社）は、当社が運営する当社のウェブサイト（以下、当サイトといいます）の利用について、以下のとおり本規約を定めます。</p>

        <h3 className="bold">第1条（定義）</h3>
        <p>本規約においては、次の各号記載の用語はそれぞれ次の意味で使用します。「当サイト」とは、｢https://tomosplus.jp/contents/contact｣、および、それ以下のURLのサイトをいいます。「利用者」とは、当サイトにアクセスする者をいいます。</p>

        <h3 className="bold">第2条（規程範囲と変更）</h3>
        <ol>
          <li>本規約は、当サイトの利用に関し、当社および利用者に適用するものとし、利用者は本規約を誠実に遵守するものとします。</li>
          <li>当社が別途当サイト上における掲示またはその他の方法により規定する個別規定および
            当社が随時利用者に対し通知する追加規定は、本規約の一部を構成します。</li>
          <li>当サイトには当社が運営する他のウェブサイトもリンクされております。それらのサイトを利用される際にはその中に掲載されているご利用規約にもご同意いただく必要があります。</li>
          <li>当社は利用者の承諾なく、当社の独自の判断により、本規約を変更する事があります。この場合、当サイトの利用条件は変更後の利用規約に基づくものとします。当該変更は、当サイトに表示した時点より効力を発するものとします。</li>
          <li>規約の変更に伴い、利用者に不利益、損害が発生した場合、当社はその責任を一切負わないものとします。</li>
        </ol>

        <h3 className="bold">第3条（利用者の地位および制限事項）</h3>
        <ol>
          <li>利用者は、当サイトを利用することをもって、本規約に合意したものとみなされ、同時に当サイトにおける利用者としての地位を得るものとします。</li>
          <li>利用者は、以下に掲げる行為は行ってはならないものとします。
            <ol>
              <li><span className="inner">(1)</span>第三者もしくは当社の財産もしくはプライバシー等を侵害する行為、または侵害する恐れのある行為</li>
              <li><span className="inner">(2)</span>第三者もしくは当社に、不利益もしくは損害を与える行為、またはその恐れのある行為</li>
              <li><span className="inner">(3)</span>当サイトの運営を妨害する行為</li>
              <li><span className="inner">(4)</span>公序良俗に反する行為</li>
              <li><span className="inner">(5)</span>犯罪行為もしくは犯罪行為に結びつく行為、またはその恐れのある行為</li>
              <li><span className="inner">(6)</span>虚偽の申告、届出を行なう行為</li>
              <li><span className="inner">(7)</span>当サイトを利用した営業活動もしくは営利を目的とする行為、またはその準備を目的とする行為。ただし、当社が別途承認した場合には、この限りではありません</li>
              <li><span className="inner">(8)</span>第三者もしくは当社の名誉もしくは信用を毀損する行為</li>
              <li><span className="inner">(9)</span>コンピュータウイルス等有害なプログラムを使用もしくは提供する行為、またはその恐れのある行為</li>
              <li><span className="inner">(10)</span>その他、国内外の法律、法令に違反する行為、またはその恐れのある行為</li>
              <li><span className="inner">(11)</span>その他、当社が不適切と判断する行為</li>
            </ol>
          </li>
        </ol>

        <h3 className="bold">第4条（掲載情報）</h3>
        <p>当社は、当サイトに掲載する情報の正確性、最新性、有用性等その他一切の事項について、いかなる保証もするものではありません。また、当社は、利用者に事前に通知することなく、当社の判断によって当サイトで公開されている情報の追加、変更、修正、削除を行う場合があります。当社は、いかなる場合においても、当サイトの情報の追加、変更、修正、削除等により利用者に生じたいかなる損害についても一切の責任を負いません。</p>

        <h3 className="bold">第5条（公開中断・停止）</h3>
        <ol>
          <li>当社は以下の何れかの事由に該当する場合、当社の独自の判断により、利用者に事前に通知することなく当サイトの一部もしくは全部の公開を一時中断、または停止することがあります。
            <ol>
              <li><span className="inner">(1)</span>当サイト公開のための装置、システムの保守点検、更新を定期的にまたは緊急に行う場合</li>
              <li><span className="inner">(2)</span>火災、停電、天災などの不可抗力により、当サイトの公開が困難な場合</li>
              <li><span className="inner">(3)</span>第一種電気通信事業者の任務が提供されない場合</li>
              <li><span className="inner">(4)</span>その他、運用上あるいは技術上当社が当サイトの公開一時中断、もしくは停止が必要であるか、または不測の事態により、当社が当サイトの公開が困難と判断した場合</li>
            </ol>
          </li>
          <li>当社は、当サイトの公開一時中断、停止等の発生により、利用者または第三者が被ったいかなる不利益、損害について、理由を問わず一切の責任を負わないものとします。</li>
        </ol>

        <h3 className="bold">第6条（リンク取扱い）</h3>
        <p>当サイトから他のウェブサイトへリンクしている、第三者が当サイトへのリンクを提供している場合、当社は当サイト外のサイトについては、何ら責任は負いません。この場合、当該サイトに包括され、また当該サイト上で利用が可能となっているコンテンツ、広告、商品、サービスなどについても同様に一切責任を負いません。当社は、それらのコンテンツ、広告、商品、サービスなどに起因または関連して生じた一切の損害についても賠償する責任は負いません。なお、個別のページへのリンクは、サイト構造やサーバ構成の変更にともなって予告無くリンク先が無くなる可能性があります。</p>

        <h3 className="bold">第7条（著作権）</h3>
        <ol>
          <li>利用者は、権利者の承諾を得ないで、いかなる方法においても当サイトを通じて提供されるいかなる情報も、著作権法で定める利用者個人の私的使用の範囲を超える複製、販売、出版、その他の用途に使用することはできないものとします。ただし、当サイト内においてダウンロード用として弊社が指定した各種ファイルについては、教育目的での利用など非営利目的で使用する場合に限り、弊社の著作権表示、またはそれに準ずる表示が付されることを条件に使用・複製されることを許可します。</li>
          <li>利用者は、権利者の承諾を得ないで、いかなる方法においても、第三者をして、当サイトを通じて提供されるいかなる情報も使用させたり、公開させたりすることはできないものとします。</li>
          <li>本条の規約に違反して問題が発生した場合、利用者は、自己の責任と費用において係る問題を解決するとともに、当社に何らの迷惑または損害を与えないものとします。</li>
        </ol>

        <h3 className="bold">第8条（メッセージ送信フォームの利用）</h3>
        <ol>
          <li>当サイトの「メッセージ送信フォーム機能」（以下、「本機能」といいます。）は、当サイトへのご質問や掲載記事へのご意見・ご感想を送信できる機能です。本機能の利用について、以下のように定めます。</li>
          <li>本機能を通じて寄せられたご質問へのご回答は、原則として、本フォームに入力されたメールアドレス宛に送信いたします。当社が不適切と判断したご質問については、回答を拒否できるものとし、当社は、その理由の開示等の義務を負いません。メールアドレスが不明なご質問については破棄いたしますので、送信前にご確認ください。</li>
          <li>送信いただきました内容は、当社管理者による確認後に当社コーポレートサイト（https://www.mitsumura-kyouiku.co.jp/）、または次のドメインによる当社教材サイト「tomosplus.jp」ドメインによるサイト）にご意見・ご感想として掲載する場合があります。掲載する際には、任意で入力された年齢、お住まいの都道府県のみの掲載とし、個人情報保護法およびプライバシー保護の観点から、メールアドレス、氏名などの個人情報を本人の許可無く公開することはありません。 詳しくは当社の個人情報保護方針をご確認ください。</li>
          <li>送信された内容は、いただきました内容の著作権、その他知的財産権および出願権はすべて当社に帰属するものとします。当社帰属の印刷物、またはPCサイトへの転載については、利用者の了承を得ることなくこれを転載できるもの（一部抜粋や必要最低限の修正等を施す場合があります）としますので、ご了承ください。</li>
          <li>本機能の利用によって何らかの損害が発生した場合でも、当社は一切の責任を負わないものとします。本機能の利用にあたっては、利用者の責任において行ってください。</li>
        </ol>

        <h3 className="bold">第9条（商標）</h3>
        <p>当サイトに掲載されている商標、当社開発商品の名称ならびに商品ロゴ等は、当社または関連会社の登録商標です。その他の商品および会社等の名称は、一般に各社の商号、登録商標です。当社の登録商標のご使用に際しては、当社の書面による事前許諾が必要となります。</p>

        <h3 className="bold">第10条（賠償責任）</h3>
        <ol>
          <li>当サイトの変更、中断、中止、停止、もしくは廃止、またはその他当サイトに関連して発生した利用者または第三者の損害について、当社は一切の責任を負わないものとします。</li>
          <li>利用者が当サイトの利用によって第三者に対して損害を与えた場合、利用者は自己の責任と費用をもって解決し、当社に損害を与えることのないものとします。 利用者が本規約に反した行為、または不正もしくは違法な行為によって当社に損害を与えた場合、当社は当該利用者に対して相応の損害賠償の請求ができるものとします。</li>
        </ol>

        <h3 className="bold">第11条（個人情報保護方針）</h3>
        <p>利用者による本サイトの利用に関連して当社が知り得る利用者の情報の管理および取扱いについては、当社が別途定める個人情報保護方針によるものとします。</p>

        <h3 className="bold">第12条（準拠法、裁判管轄）</h3>
        <p>本利用規約の成立、効力発生、解釈にあたっては日本法を準拠法とします。また、当社とお客様との間で生じた紛争については東京地方裁判所を第一審の専属的合意管轄裁判所とします。</p>

        <p className="right">制定日：令和6年4月1日<br />
          光村教育図書株式会社</p>
      </div>
    </>
  )
}
