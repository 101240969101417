import clsx from 'clsx';
import { ButtonHTMLAttributes, InputHTMLAttributes, useState } from 'react';
import styles from './styles.module.css';

type InputProps = InputHTMLAttributes<HTMLInputElement>;
type InputSearchProps = InputProps & {
  containerClassName?: string;
  buttonProps?: ButtonHTMLAttributes<HTMLButtonElement>;
};
export const InputSearch = ({
  containerClassName,
  buttonProps: { type = 'button', ...buttonProps } = {},
  ...props
}: InputSearchProps) => {
  return (
    <div className={clsx(containerClassName, styles.container)}>
      <input {...props} />
      <button
        {...buttonProps}
        type={type}
        disabled={props.value == null || props.value === '' || props.disabled}
      >
        <img src="/img/vector-search.svg" alt="search" />
      </button>
    </div>
  );
};

export const InputSearchForm = ({
  search,
  ...props
}: Omit<InputSearchProps, 'value' | 'onChange'> & {
  search?: (value: string) => void;
}) => {
  const [value, setValue] = useState('');
  return (
    <div>
      <InputSearch
        {...props}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={(e) => {
          if (e.nativeEvent.isComposing) return;
          if (e.code !== 'Enter') return;
          e.preventDefault();
          value && search?.(value);
        }}
        buttonProps={{
          onClick: () => value && search?.(value),
        }}
      />
    </div>
  );
};
