import {
  DictionariesKanjiLearningHistoriesGetParams,
  dictionariesKanjiLearningHistoriesGet,
} from '@/src/__generated__';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { COLUMN_DEFS, RowData } from './columns';

export const useLearningHistoryKanjiDictionary = () => {
  const [tableData, setTableData] = useState<RowData[]>([]);

  const table = useReactTable({
    columns: [
      COLUMN_DEFS.last_action_date,
      COLUMN_DEFS.active_time,
      COLUMN_DEFS.total_number_word,
      COLUMN_DEFS.total_number_labels,
      COLUMN_DEFS.total_number_word_quiz,
      COLUMN_DEFS.total_number_correct,
      COLUMN_DEFS.total_number_medals,
    ],
    data: tableData,
    getCoreRowModel: getCoreRowModel(),
  });

  const query = async (params: DictionariesKanjiLearningHistoriesGetParams) => {
    const res = await dictionariesKanjiLearningHistoriesGet(params);
    if (res.status !== 200) {
      // TODO handle error
      return;
    }
    setTableData(res.data.learning_histories.slice(0, 1));
  };

  useEffect(() => {
    query({ page: 1 });
  }, []);

  return {
    table,
  };
};
