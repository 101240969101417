import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { LoginFormSchema, loginFormSchema } from '../schema/login-form-schema';

const useLoginForm = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    // useFormのジェネリクスにはdefaultValuesの型を渡す
  } = useForm<LoginFormSchema>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: undefined,
    resolver: zodResolver(loginFormSchema),
  });

  // const watchedInput = useWatch({ control });
  return {
    form: {
      control,
      handleSubmit,
      setValue,
      errors,
    },
    options: {
      //
    },
  };
};

export default useLoginForm;
