import Button, { ButtonProps } from '@mui/material/Button';
import './ButtonSSOLogin.css';

export type ButtonSSOLoginProps = ButtonProps & {
  sso_icon: string;
};

const ButtonSSOLogin = (props: ButtonSSOLoginProps) => {
  const { className, sso_icon, children } = props;

  return (
    <Button
      {...props}
      className={className + ' button-sso-login'}
      startIcon={
        <img
          className="button-sso-login-icon"
          src={sso_icon}
          alt="button-sso-login"
        />
      }
    >
      {children}
    </Button>
  );
};

export default ButtonSSOLogin;
