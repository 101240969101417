import { utilityHolidaysGet } from '@/src/__generated__';
import type { HolidayEntity } from '@/src/__generated__';
import { EventInput, createPlugin } from '@fullcalendar/core/';
import type { EventSourceDef } from '@fullcalendar/core/internal';

type MtHolidayMeta = {}
type ConvertEvents = (items: HolidayEntity[]) => EventInput[];
type ConvertEvent = (item: HolidayEntity) => EventInput;

const eventSourceDef: EventSourceDef<MtHolidayMeta> = {
    parseMeta(_refined) {
        return {
        };
    },
    fetch({range}, successCallback, errorCallback) {
        const start = range.start; // format(range.start, 'yyyy-MM-dd');
        const end = range.end; // format(range.end, 'yyyy-MM-dd');
        
        return utilityHolidaysGet({start, end}).then((response) => {
            const body = response.data;
            console.log(body);
            successCallback({
                rawEvents: convertEvents(body.holidays),
                response,
            });
        }).catch(errorCallback);
    },
};


const convertEvents: ConvertEvents = (items) => {
    return items.map((item) => convertEvent(item));
};

const convertEvent: ConvertEvent = (item) => {
    return {
        id: item.holiday_date.toString(),
        title: item.holiday_name,
        start: item.holiday_date,
        end: item.holiday_date,
    };
}

const mkFullcalendarHolidayPlugin = createPlugin({
    name: 'mk-fullcalendar-holidays',
    eventSourceDefs: [eventSourceDef],
});

export { mkFullcalendarHolidayPlugin as default };