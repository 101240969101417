import { startOfYesterday } from 'date-fns';
import { useEffect } from 'react';
import { atom, useRecoilState } from 'recoil';
import { recentPastOf } from '../helpers/date';

export type HistoryQueryConditions = {
  class_index?: number | "" | null | undefined;
  user_uuid?: string | null;
  studentName?: string | null;
  from_date: Date;
  to_date: Date;
  time_slot?: number | null;
};

const historyQueryConditionAtom = atom<HistoryQueryConditions>({
  key: 'historyQueryConditionAtom',
  default: {
    from_date: recentPastOf(4, 1),
    to_date: startOfYesterday(),
  },
});

export const useHistoryQueryConditions = () => {
  const [historyQueryConditions, setHistoryQueryConditions] = useRecoilState(
    historyQueryConditionAtom,
  );

  const updateHistoryQueryConditions = (
    value: Partial<HistoryQueryConditions>,
  ) => {
    setHistoryQueryConditions((prev) => ({ ...prev, ...value }));
  };

  return {
    historyQueryConditions,
    updateHistoryQueryConditions,
  };
};

export const useSyncHistoryQueryConditions = <
  K extends keyof HistoryQueryConditions,
>(
  keys: K[],
  watcher: (keys: K[]) => HistoryQueryConditions[K][],
) => {
  const { updateHistoryQueryConditions } = useHistoryQueryConditions();
  const [...values] = watcher(keys);
  
  useEffect(() => {
    updateHistoryQueryConditions(
      keys.reduce(
        (acc, key, i) => ({ ...acc, [key]: values[i] }),
        {} as Partial<HistoryQueryConditions>,
      ),
    );
  }, [...values]);
};
