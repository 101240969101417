// useController: https://react-hook-form.com/docs/usecontroller
import {
  Stack,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
} from '@mui/material';
import { FieldValues, useController } from 'react-hook-form';
import { RHFTextFieldProps } from '../../types/RHFTextFieldProps';

export const RHFTextField = <T extends FieldValues>({
  name,
  control,
  type,
  placeholder,
  multiline,
  rows,
  tabIndex,
  disabled,
  showError = true,
}: RHFTextFieldProps<T>) => {
  // 入力フォームを共通化したいので、RHFのControllerの代わりにuseControllerを使用しています
  const {
    field,
    formState: { errors },
  } = useController({ name, control });

  const errorMessage = errors?.[name]?.message as string;
  console.log(showError);

  // テキストフィールドの inputProps を設定するための変数
  let inputProps = {};

  // type が 'number' の場合にマイナス値を入力できないように設定
  if (type === 'number') {
    inputProps = {
      min: 0, // 最小値を0に設定
      inputMode: 'numeric', // 数字のみの入力モードに設定
    };
  }

  return (
    <ThemeProvider theme={theme}>
      <Stack direction="row" alignItems="center" m={2}>
        {multiline ? (
          <TextField
            value={field.value ?? ''}
            inputRef={field.ref}
            name={field.name}
            onChange={field.onChange}
            onBlur={field.onBlur}
            type={type}
            placeholder={placeholder}
            multiline
            rows={rows}
            tabIndex={tabIndex}
            disabled={disabled}
          />
        ) : (
          <TextField
            value={field.value ?? ''}
            inputRef={field.ref}
            name={field.name}
            onChange={field.onChange}
            onBlur={field.onBlur}
            type={type}
            placeholder={placeholder}
            tabIndex={tabIndex}
            disabled={disabled}
            inputProps={inputProps}
          />
        )}
        {showError && errorMessage && (
          <Typography variant="body1" ml={3} color="red">
            {errorMessage}
          </Typography>
        )}
      </Stack>
    </ThemeProvider>
  );
};

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '14px',
          backgroundColor: 'white',
          fontFamily: 'var(--main-font-family-db)',
        },
        input: {
          boxShadow: '0px 1px 5px 0px #00000070 inset',
          fontWeight: 'bold',
          borderRadius: '18px',
          border: '5px solid #D9D9D9',
          borderWidth: '5px 5px 5px',
          padding: '11px 14px',
          fontSize: '20px',
          '@media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none)':
            {
              fontSize: '1.25rem',
            },
          fontFamily: 'var(--main-font-family-db)',
          width: '100vw',
          '&::placeholder': {
            fontFamily: 'var(--main-font-family-db)',
          },
          "&[name='memo']": {
            padding: '23px 25px',
            lineHeight: '1.3',
            fontFamily: 'var(--main-font-family-db)',
          },
          "&[name='memo'].Mui-disabled": {
            boxShadow: 'none',
          },
          //ブラウザの補完機能で背景色を変えない
          '&:-webkit-autofill': {
            transition: 'background-color 5000s ease-in-out 0s !important',
          },
          '&.Mui-disabled': {
            boxShadow: 'none',
            WebkitTextFillColor: 'rgba(0,0,0,1)',
          },
          '&.Mui-disabled::placeholder': {
            WebkitTextFillColor: 'rgba(0,0,0,0)',
          },
        },
        notchedOutline: {
          border: 'none',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          position: 'absolute',
          top: '6px',
          left: '304px',
          fontFamily: 'var(--main-font-family-db)',
          fontSize: '24px',
          '@media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none)':
            {
              fontSize: '1.5rem',
            },
        },
      },
    },
  },
});
