import Kanji from '@/src/components/auth/Kanji';
import MainButton from '@/src/components/parts/MainButton';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './style.css';

export const PasswordReset = (): JSX.Element => {
  const navigate = useNavigate();
  return (
    <div className="password-reset">
      <div className="overlap-wrapper-8">
        <div className="overlap-44">
          <div className="div-16">
            <div className="group-129">
              <div className="frame-17">
                <div className="frame-18">
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                </div>
                <div className="frame-18">
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                </div>
                <div className="frame-18">
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                </div>
                <div className="frame-18">
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                </div>
                <div className="frame-18">
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                </div>
                <div className="frame-18">
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                </div>
                <div className="frame-18">
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                </div>
                <div className="frame-18">
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                </div>
              </div>
            </div>
            <div className="group-130">
              <div className="frame-17">
                <div className="frame-18">
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                </div>
                <div className="frame-18">
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                </div>
                <div className="frame-18">
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                </div>
                <div className="frame-18">
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                </div>
                <div className="frame-18">
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                </div>
                <div className="frame-18">
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                </div>
                <div className="frame-18">
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                </div>
                <div className="frame-18">
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                </div>
              </div>
            </div>
            <div className="group-131">
              <div className="frame-17">
                <div className="frame-18">
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                </div>
                <div className="frame-18">
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                </div>
                <div className="frame-18">
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                </div>
                <div className="frame-18">
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                </div>
                <div className="frame-18">
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                </div>
                <div className="frame-18">
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                </div>
                <div className="frame-18">
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                </div>
                <div className="frame-18">
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                </div>
              </div>
            </div>
            <div className="group-132">
              <div className="frame-17">
                <div className="frame-18">
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                </div>
                <div className="frame-18">
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                </div>
                <div className="frame-18">
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                </div>
                <div className="frame-18">
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                </div>
                <div className="frame-18">
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                </div>
                <div className="frame-18">
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                </div>
                <div className="frame-18">
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                </div>
                <div className="frame-18">
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                  <div className="ellipse-17" />
                </div>
              </div>
            </div>
          </div>
          <MainButton
            className="main-button-7"
            onClick={() => {
              navigate('/passwordreseturlsend');
            }}
          >
            <div className="text-wrapper-250">
              パスワード<Kanji kana="さいせっていよう">再設定用</Kanji>のURL
              <Kanji kana="そうしん">送信</Kanji>へもどる
            </div>
          </MainButton>
          <div className="main-message-3">
            <div className="navbar-4">
              <div className="URL-URL">
                <Kanji kana="ほん">本</Kanji>URLの
                <Kanji kana="ゆうこうきげん">有効期限</Kanji>が
                <Kanji kana="き">切</Kanji>れております
                <br />
                <Kanji kana="さいど">再度</Kanji>、パスワード
                <Kanji kana="さいせっていよう">再設定用</Kanji>のURLを
                <Kanji kana="そうしん">送信</Kanji>してください。
              </div>
            </div>
          </div>
          <div className="main-header-6">
            <div className="main-header-7">
              パスワードを<Kanji kana="さいせってい">再設定</Kanji>
            </div>
          </div>
          <div className="title-5" />
        </div>
      </div>
    </div>
  );
};
