import React, { ButtonHTMLAttributes } from "react";
import "./style.scss";
import Kanji from "../../auth/Kanji";

type Props = Partial<{
  type: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  disabled: ButtonHTMLAttributes<HTMLButtonElement>['disabled'];
  tabIndex: ButtonHTMLAttributes<HTMLButtonElement>['tabIndex'];
  className?: any;
  onClick?: () => void;
}>;

export const ModalSubButton: React.FC<Props> = ({ type, disabled, tabIndex, className, onClick }) => {
  return (
    <button 
      type={type}
      disabled={disabled}
      tabIndex={tabIndex}
      onClick={onClick}
      className={`modal-sub-button ${className}`}>
      <div className="text-wrapper"><Kanji kana="もど">戻</Kanji>る</div>
    </button>
  );
};