import styled from "styled-components";
import { ButtonHTMLAttributes, ReactNode } from 'react';
import { DefaultTheme } from 'styled-components/dist/types';

type Props = Partial<{
  type: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  disabled: ButtonHTMLAttributes<HTMLButtonElement>['disabled'];
  tabIndex: ButtonHTMLAttributes<HTMLButtonElement>['tabIndex'];
  className?: string;
  onClick?: () => void;
  theme?: DefaultTheme;
  children: ReactNode;
}>

const AvaterButton = styled('button')(
  ({ theme }) => `
    background-color: var(--white);
    border: 7px solid;
    border-color: var(--gray);
    border-radius: 16px;
    width: 80px;
    height: 80px;
    position: relative;
    &.select{
      border-color:transparent;
      background: linear-gradient(${theme.chart0} 0%, ${theme.chart1} 100%) border-box;
      -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
      mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    }
    .mask{
      background-color: var(--white);
      border-radius: 11px;
      position: absolute;
      width:66px;
      height:66px;
      top:0;
      left:0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
)

export const AvatarIconButton = ({ type, disabled, children, tabIndex, className, onClick }: Props) => {
  return (
    <AvaterButton
      type={type}
      disabled={disabled}
      tabIndex={tabIndex}
      onClick={onClick}
      className={className}>
      <div className="mask">
        {children}
      </div>
    </AvaterButton>
  );
};