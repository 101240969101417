import Button, { ButtonProps } from '@mui/material/Button';
import './SecondaryButton.css';

export type SecondaryButtonProps = ButtonProps & {};

const SecondaryButton = (props: SecondaryButtonProps) => {
  const { children } = props;

  return (
    <Button className="secondary-button" {...props}>
      {children}
    </Button>
  );
};

export default SecondaryButton;
