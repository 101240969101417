.body {
  margin-top: 20px;
  margin-left: 40px;
}
.body .table {
  margin-top: 22px;
  z-index: 0;
}

.table th {
  height: 89px !important;
  font-size: 20px;
}
.table td {
  font-size: 18px;
}
.table td:nth-child(1),
.table td:nth-child(2),
.table td:nth-child(4) {
  padding: 18px 0px;
}

.buttons {
  margin-left: 15px;
  display: flex;
  column-gap: 15px;
}
