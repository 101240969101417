.action-bar {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 13px 10px 18px;
  background: var(--tomopla-color);
}
.action-bar > * {
  flex: 1;
  line-height: 1;
  font-size: 22px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none)  {font-size: 1.375rem;}
  color: var(--white);
}

.action-bar .current {
  text-align: center;
}
.action-bar button {
  display: flex;
  align-items: center;
  column-gap: 8px;
  padding: 0;
  background: transparent;
  border: none;
  cursor: pointer;
}
.action-bar .current {
  position: relative;
}
.action-bar .current::after {
  position: absolute;
  content: '';
  left: 50%;
  bottom: -8px;
  transform: translateX(-50%);
  width: 66px;
  height: 2px;
  border-radius: 2px;
  background: var(--white);
}
.action-bar button:first-child {
  text-align: left;
}
.action-bar button:first-child img {
  transform: rotate(90deg);
  height: 9px;
}

.action-bar button:last-child {
  justify-content: flex-end;
}
.action-bar button:last-child img {
  transform: rotate(-90deg);
  height: 9px;
}
.action-bar button:disabled {
  pointer-events: none;
}
