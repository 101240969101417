import Button, { ButtonProps } from '@mui/material/Button';
import './NeutralButton.scss';

export type NeutralButtonProps = ButtonProps & {};

const NeutralButton = (props: NeutralButtonProps) => {
  const { children } = props;
  return (
    <Button className="neutral-button" {...props}>
      {children}
    </Button>
  );
};

export default NeutralButton;
