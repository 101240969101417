import { LicenseEntity } from '@/src/__generated__';
import {
  TeacherHistorySelect,
  styles as formStyles,
} from '@/src/components/HistoryQueryForm';
import { HistoryQueryFormTeacher } from '@/src/components/HistoryQueryForm/HistoryQueryFormTeacher';
import { KEISAN_LICENSES } from '@/src/components/HistoryQueryForm/constants';
import { Label } from '@/src/components/HistoryQueryForm/items/Label';
import {
  profileAtom,
  themeAtom,
} from '@/src/components/ModalContents/modalAtom';
import { Select } from '@/src/components/Select';
import { TeacherFrame } from '@/src/components/layouts/TeacherFrame';
import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useRecoilState } from 'recoil';
import styled, { useTheme } from 'styled-components';
import { useToppageTeachers } from '../TeacherTop/hooks';
import { useLearningHistoryKeisan } from './hooks';
import styles from './styles.module.css';

type Props = {
  onThemeChange: (status: number) => void;
};

export const TeacherHistoryKeisan = (props: Props): JSX.Element => {
  const { hasLoaded, methods } = useLearningHistoryKeisan();

  const { currentUser } = useToppageTeachers();
  const [themenum, setThemenum] = useRecoilState(themeAtom);
  const [profiles, setProfiles] = useRecoilState(profileAtom);

  useEffect(() => {
    if (currentUser) {
      if (!themenum) {
        setThemenum(currentUser?.preference.theme_color);
      }
      const _profiles = {
        nick_name: profiles.nick_name
          ? profiles.nick_name
          : currentUser?.nick_name,
        profile_icon:
          profiles.profile_icon &&
          profiles.profile_icon !== currentUser?.preference.profile_icon
            ? profiles.profile_icon
            : currentUser?.preference.profile_icon,
      };
      if (
        _profiles.nick_name !== profiles.nick_name ||
        _profiles.profile_icon !== profiles.profile_icon
      ) {
        setProfiles(_profiles);
      }
    }
  }, [currentUser, profiles, themenum]);

  useEffect(() => {
    if (currentUser && typeof themenum === 'number') {
      props.onThemeChange(themenum);
    }
  }, [themenum, currentUser]);

  const theme = useTheme();

  const P = styled('p')(
    ({ theme }) => `
      color:var(${theme.textcolor})!important
    `,
  );

  return (
    <TeacherFrame title="学習履歴 教師用">
      {hasLoaded && (
        <div className={styles.body}>
          <FormProvider {...methods}>
            <HistoryQueryFormTeacher
              onSubmit={() => {}}
              states={{
                studentSelectDisabled: true,
                schoolClassSelectDisabled: true,
                dateRangeDisabled: true,
                timeSlotDisabled: true,
              }}
            >
              <div className={formStyles.row}>
                <TeacherHistorySelect />
                <KeisanSkillSelect
                  license={
                    currentUser?.license.filter(({ status }) => status === 2) ||
                    []
                  }
                />
              </div>
            </HistoryQueryFormTeacher>
          </FormProvider>
          <P className={styles.description}>
            「学年をえらぶ」で教材の学年をえらぶと、計算スキルコネクトへ移動します。
            <br />
            目次画面で「りれき」ボタンをおしてください。
          </P>
        </div>
      )}
    </TeacherFrame>
  );
};

export const KeisanSkillSelect = ({
  license,
}: {
  license: LicenseEntity[];
}) => {
  const options = useMemo(() => {
    const standard = license.filter((l) =>
      KEISAN_LICENSES.standard.includes(l.learning_contents),
    );
    const tokyoshoseki = license.filter((l) =>
      KEISAN_LICENSES.tokyoshoseki.includes(l.learning_contents),
    );
    const keirinkan = license.filter((l) =>
      KEISAN_LICENSES.keirinkan.includes(l.learning_contents),
    );
    return [
      { label: '　', value: '', id: -1 },
      ...[...tokyoshoseki, ...keirinkan, ...standard].map((l) => ({
        label: formatLabel(l.learning_contents)!,
        value: l.path!,
        id: l.learning_contents,
      })),
    ];
  }, [license]);
  return (
    <Label className="label" label="学年をえらぶ">
      <Select<string>
        defaultValue=""
        options={options}
        onChange={(value) => {
          if (!value) return;
          window.location.href = value;
        }}
        noRuby
      />
    </Label>
  );
};

const formatLabel = (license: number) => {
  let idx = KEISAN_LICENSES.standard.indexOf(license);
  if (idx >= 0) return `計算スキル${idx + 1}年`;
  idx = KEISAN_LICENSES.tokyoshoseki.indexOf(license);
  if (idx >= 0) return `計算スキル${idx + 1}年 東京書籍版`;
  idx = KEISAN_LICENSES.keirinkan.indexOf(license);
  if (idx >= 0) return `計算スキル${idx + 1}年 啓林館版`;
};
