.container {
  display: flex;
  align-items: center;
  row-gap: 8px;

  position: relative;
  width: fit-content;
  min-height: 45px;
  padding: 0 10px 0 16px;

  border-radius: 4px;
  border: 1px solid var(--line-color);

  background-color: white;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {
    min-height: 54px;
  }
}

.container input {
  font-size: 24px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {
    all:unset;
    font-family: var(--main-font-family-m);
    font-size: 1.375rem;
  };
  font-family: var(--main-font-family-db);
  
  line-height: 1;
  letter-spacing: 0.1em;
  overflow: hidden;
  text-overflow: ellipsis;

  border: none;
}

.container input:focus {
  outline: none;
}

.container input::placeholder {
  color: #666;
}

.container button {
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  position: absolute;
  right: 13px;
}
.container button:disabled {
  cursor: not-allowed;
  filter: grayscale(1);
}
.container input[value=''] + button {
  filter: grayscale(1);
}
