import {
  Category,
  csvKanjiSkillLearningHistoriesCsvGet,
  kanjiSkillLearningHistoriesGet,
} from '@/src/__generated__';
import { withSaveFile } from '@/src/helpers/file';
import { useHistoryLocation } from '@/src/hooks/useHistoryNavigation';
import { useSyncHistoryQueryConditions } from '@/src/hooks/useHistoryQueryConditions';
import { useTeacherContext } from '@/src/hooks/useTeacherContext';
import { useAuthContext } from '@/src/middleware/auth/AuthContext';
import {
  LearningHistoryKanjiSkillQueryFormSchema,
  learningHistoryKanjiSkillQueryFormSchema,
} from '@/src/schema/learning-history-kanji-skill-query-form-schema';
import { KanjiApplicationType } from '@/src/types/KanjiApplicationType';
import { zodResolver } from '@hookform/resolvers/zod';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { RowData, generateColumns } from './columns';
import { useRecoilState } from 'recoil';
import { errorMessageAtom } from '@/src/components/ModalContents/modalAtom';
import { get_category_index_from_type_id, get_category_selection, get_current_type_category_id, save_category_selection } from '@/src/helpers/kanji-skill/pulldown';

export const useLearningHistoryKanjiSkill = () => {
  const { uuid, getCurrentUser } = useAuthContext();
  const {
    hasLoaded,
    childrenList,
    schoolClassList,
    historyQueryConditions,
    classIndexToClass,
  } = useTeacherContext();

  const [data, setData] = useState<RowData[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);

  const columns = useMemo(
    () => (data.length ? generateColumns(data[0]) : []),
    [data],
  );

  const [type, setType] = useState<KanjiApplicationType>();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [prevRequest, setPrevRequest] = useState<{[key: string]: any}>({})
  const [errorMessage, setErrorMessage] = useRecoilState(errorMessageAtom);

  // 児童詳細からの遷移
  const { state: locationState } = useHistoryLocation();
  const methods = useForm<LearningHistoryKanjiSkillQueryFormSchema>({
    defaultValues: locationState
      ? {
        ...historyQueryConditions,
        class_index: locationState.class_name
          ? schoolClassList.findIndex(
            (e) =>
              e.grade === locationState.grade &&
              e.class_name === locationState.class_name,
          )
          : undefined,
        user_uuid: locationState.user_uuid,
      }
      : historyQueryConditions,
    resolver: zodResolver(learningHistoryKanjiSkillQueryFormSchema),
  });

  const is_same_request = (request: any) => {
    console.log(request);
    console.log(prevRequest);
    if (request.grade !== prevRequest.grade) return false;
    if (request.class_name !== prevRequest.class_name) return false;
    if (request.user_uuid !== prevRequest.user_uuid) return false;
    if (request.type !== prevRequest.type) return false;
    if (request.category_id !== prevRequest.category_id) return false;
    if (request.time_slot !== prevRequest.time_slot) return false;
    if (request.from_date !== prevRequest.from_date) return false;
    if (request.to_date !== prevRequest.to_date) return false;
    return true;
  }
  const query = async ({
    class_index,
    category_index,
    ...params
  }: LearningHistoryKanjiSkillQueryFormSchema) => {
    const schoolClass = classIndexToClass(class_index) as {
      grade: number;
      class_name: string | undefined;
    };
    // ユーザ指定時にクラス指定とのズレチェック
    if (params.user_uuid && schoolClass) {
      const [selected_user] = childrenList.filter((child) => child.user_uuid == params.user_uuid);
      if (!selected_user) return;
      if (schoolClass.grade && schoolClass.grade !== selected_user.grade) return;
      if (schoolClass.class_name && schoolClass.class_name !== selected_user.class_name) return;
    }

    // ユーザー情報を取得
    const user = getCurrentUser?.();
    if (!user) return;
    const [type, category_id] = get_current_type_category_id(user, category_index, categories);

    // 初期値を設定する
    // ユーザーのライセンスを取得し、漢字スキルがなければひらがな2かカタカナ3に変更する
    // type = category?.type;
    const request = {
      ...params,
      type,
      category_id,
      time_slot: Number.isInteger(params.time_slot)
        ? params.time_slot
        : undefined,
      user_uuid: params.user_uuid || undefined,
      ...schoolClass,
    };
    if (is_same_request(request)) {
      return;
    }
    setPrevRequest(Object.assign({}, request));
    await kanjiSkillLearningHistoriesGet(request)
      .then((res) => {
        if (res.status === 200) {
          setErrorMessage('');
          methods.clearErrors();
          if (categories.length < 1) {
            setCategories(res.data.categories ? res.data.categories : []);
            const prev_category_index = category_index;
            if (res.data.category && res.data.category.category_id !== null) {
              category_index = get_category_index_from_type_id(res.data.category.type, res.data.category.category_id, res.data.categories);
            }
            else {
              category_index = get_category_index_from_type_id(type, category_id, res.data.categories);
            }
            if (category_index !== prev_category_index) {
              methods.setValue("category_index", category_index);
            }
          }
          if (res.data.category) {
            save_category_selection(category_index, res.data.category.type, res.data.category.category_id);
            setType(res.data.category.type);
          }
          else {
            save_category_selection(category_index, type, category_id);
            setType(type);
          }
          if (res.data.daily_data)
            setData(
              appendTotal(
                res.data.daily_data.map((d) => ({
                  user_uuid: res.data.user_uuid,
                  school_class: res.data.school_class,
                  daily_datum: d,
                })),
              ),
            );
        }
      })
      .catch((error) => {
        setErrorMessage('error');
        if (error.response.data.message.includes('日付')) {
          methods.setError('to_date', {
            type: 'custom',
            message: '終了日が開始日より前の日付になっています。',
          });
        }
        if (error.response.data.message.includes('grade')) {
          methods.setError('to_date', {
            type: 'custom',
            message: 'エラーが発生しました。',
          });
        }
        return;
      });
  };

  const download = () => {
    const { class_index, category_index, ...params } = methods.getValues();
    const user = getCurrentUser?.();
    if (!user) return;
    const [type, category_id] = get_current_type_category_id(user, category_index, categories);
    const schoolClass = classIndexToClass(class_index) as {
      grade: number;
      class_name: string | undefined;
    };
    const time_slot =
      Number.isInteger(params.time_slot) && params.time_slot !== null
        ? params.time_slot
        : undefined;
    return withSaveFile(
      csvKanjiSkillLearningHistoriesCsvGet({
        ...params,
        type,
        category_id,
        time_slot: time_slot,
        user_uuid: params.user_uuid || undefined,
        ...schoolClass,
      }),
    );
  };

  useSyncHistoryQueryConditions(
    ['class_index', 'from_date', 'to_date', 'time_slot', 'user_uuid'],
    methods.watch,
  );

  const [
    class_index,
    from_date,
    to_date,
    time_slot,
    category_select_index,
    user_uuid,
  ] = methods.watch([
    'class_index',
    'from_date',
    'to_date',
    'time_slot',
    'category_index',
    'user_uuid',
  ]);
  useEffect(() => {
    if (loaded) {
      methods.clearErrors();
      query(methods.getValues());
    }
  }, [class_index, from_date, to_date, time_slot, category_select_index, user_uuid, loaded]);

  useEffect(() => {
    if (!uuid || !getCurrentUser) return;
    const user = getCurrentUser();
    if (!user) return;
    user.first_use_date_kanji_skill &&
      methods.setValue('from_date', new Date(user.first_use_date_kanji_skill));
    // セッションストレージから category_index を取得（存在しない場合はデフォルト値 '0' を使用）
    const storedCategorySelection = get_category_selection();
    const category_index = parseInt(storedCategorySelection.category_index || -1);
    methods.setValue('category_index', category_index);
    const storedTimeSlot = sessionStorage.getItem('time_slot');
    const time_slot =
      storedTimeSlot === null
        ? null
        : isNaN(parseInt(storedTimeSlot))
        ? null
        : parseInt(storedTimeSlot);
    methods.setValue('time_slot', time_slot);
    setLoaded(true);
  }, [uuid]);

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
  });

  return {
    hasLoaded: hasLoaded && columns.length > 0,
    table,
    schoolClassList,
    childrenList,
    categories,
    errorMessage,
    type,
    methods,
    onSubmit: methods.handleSubmit(query),
    download,
  };
};

const appendTotal = (data: RowData[] = []) => {
  const total = data.reduce<RowData>(
    (acc, d) => {
      acc.daily_datum!.total_number! += d.daily_datum?.total_number || 0;
      d.daily_datum?.access_data?.forEach((a, i) => {
        if (!acc.daily_datum!.access_data![i]) {
          acc.daily_datum!.access_data![i] = {
            teaching_unit_number: a.teaching_unit_number,
            teaching_unit_name: a.teaching_unit_name,
            teaching_unit_code: a.teaching_unit_code,
            teaching_unit_type: a.teaching_unit_type,
            number_studies: 0,
          };
        }
        acc.daily_datum!.access_data![i].number_studies! += a.number_studies;
      });
      return acc;
    },
    {
      daily_datum: { total_number: 0, access_data: [], date: undefined },
      isTotal: true,
    },
  );
  return [...data, total];
};
