.chart {
  font-size: 18px;
  /*  */
  font-family: var(--main-font-family-m);
}

.chart svg {
  overflow: visible;
}

.legend {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 20px;
  font-size: 12px;
  /*  */
  font-family: var(--main-font-family-db);
}
.legend .item {
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.legend > li .icon {
  width: 8px;
  height: 8px;
}
