import './StudentTopGreeting.css';
import styled from 'styled-components'
import { useState } from 'react';
import Kanji from '../../auth/Kanji';
import { getLocalDateTime } from '@/src/helpers/date';

type Props = {
  name: string;
  last_login_date: any;
  theme?: any;
  time_difference_from_utc?: number;
};

const GreetingWrap = styled('div')(
  ({ theme }) => `
    background-image: url(/img/${theme.overlap});
    background-size: 100% 100%;
    height: 89px;
    position: relative;
    .text-wrapper-4 {
      color: var(${theme.textcolor});
      font-family: var(--main-font-family-db);
      font-size: 22px;
      @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.375rem;};
      
      left: 0;
      right: 0;
      letter-spacing: 1.1px;
      line-height: 30px;
      position: absolute;
      text-align: center;
      top: 15px;
      width: 338px;

      display: flex;
      flex-direction: column;
      align-items: center;
      .line{
        display:flex;
        .txt-limit{
          max-width:161px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display:block;
          height:auto;
        }
      }      
    }
  `
)

const Element = styled('div')(
  ({ theme }) => `
    color: var(${theme.textcolor});
    font-family: var(--main-font-family-m);
    font-size: 19px;
    @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.0625rem;};
    
    left: 111px;
    line-height: 20px;
    position: absolute;
    text-align: right;
    top: 98px;
    white-space: nowrap;
    .ruby{
      font-size:12px;   
      @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.75rem;};   
      .rt000{
        position:absolute;
        top:-16px;
        left:-3px;
      }
      .rt001{
        position:absolute;
        top:-16px;
        left:186px;
      }
      .rt002{
        position:absolute;
        top:-16px;
        left:233px;
      }
      .rt003{
        position:absolute;
        top:-16px;
        left:280px;
      }
    }
  `
)

const formatrubyDate = (dt_utc: any) => {
  if (dt_utc !== undefined) {
    const dt = getLocalDateTime(dt_utc);
    const Y = dt.getFullYear();
    const M = (dt.getMonth() + 1).toString().padStart(2, '0');
    const D = dt.getDate().toString().padStart(2, '0');
    const h = dt.getHours().toString().padStart(2, '0');
    const m = dt.getMinutes().toString().padStart(2, '0');
    const s = dt.getSeconds().toString().padStart(2, '0');

    return (
      dt ? <>
        {Y}<Kanji kana='ねん'>年</Kanji>{M}<Kanji kana='がつ'>月</Kanji>{D}<Kanji kana='にち'>日</Kanji> {h}:{m}:{s}
      </> : <>-</>
    )
  } else {
    return (
      <>---</>
    )
  }
}

const StudentTopGreeting = ({ name, last_login_date, theme, time_difference_from_utc }: Props) => {
  const dif = time_difference_from_utc! / 60;
  const utcHour = new Date().getUTCHours();
  const localHour = (utcHour + dif + 24) % 24; // 24時間制に収める

  let readText = '';
  if (localHour >= 4 && localHour < 11) {
    readText = 'おはよう!';
  } else if (localHour >= 11 && localHour < 17) {
    readText = 'こんにちは!';
  } else {
    readText = 'こんばんは!';
  }

  const [num] = useState(Math.floor(Math.random() * 9));



  return (
    <>
      <img className="group-6" alt="Group" src={`/img/character${num + 1}.png`} />
      <div className="group-7">
        <GreetingWrap>
          <div className="text-wrapper-4">
            {readText}
            <div className="line"><span className='txt-limit'>{name}</span>さん</div>
          </div>
        </GreetingWrap>
      </div>
      <Element>
        <span className="span">
          <Kanji kana="ぜんかい">前回</Kanji>ログイン：{formatrubyDate(last_login_date)}
        </span>
      </Element>
    </>
  );
};

export default StudentTopGreeting;
