import { ButtonHTMLAttributes } from 'react';

export const ExpandToggle = ({
  expanded,
  ...props
}: ButtonHTMLAttributes<HTMLButtonElement> & { expanded?: boolean }) => (
  <button {...props}>
    <img
      {...(expanded
        ? { src: '/img/vector-collapse.svg', alt: 'とじる' }
        : { src: '/img/vector-expand.svg', alt: 'ひらく' })}
    />
  </button>
);
