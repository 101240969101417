import { Category } from '@/src/__generated__';
import { FormItemSelect } from './FormItemSelect';

type FormItemCategorySelectProps = {
  categories: Category[];
  className?: string;
  name?: string;
  label?: React.ReactNode;
  noRuby?: boolean;
};
export const FormItemCategorySelect = ({
  categories,
  className,
  name = 'category_index',
  label = LABEL,
  noRuby,
}: FormItemCategorySelectProps) => {
  return (
    <FormItemSelect<number>
      className={className}
      name={name}
      label={label}
      options={[
        ...categories.map(({ category_grade, category_name }, index) => ({
          label: category_grade
            ? `${category_grade}年${category_name}`
            : category_name,
          value: index,
        })),
      ]}
      noRuby={noRuby}
    />
  );
};
const LABEL = (
  <div>
    <ruby>
      学<rt>がく</rt>
    </ruby>
    <ruby>
      年<rt>ねん</rt>
    </ruby>
    ・
    <ruby>
      学<rt>がっ</rt>
    </ruby>
    <ruby>
      期<rt>き</rt>
    </ruby>
    ／
    <ruby>
      上<rt>じょう</rt>
    </ruby>
    <ruby>
      下<rt>げ</rt>
    </ruby>
    をえらぶ
  </div>
);
