import { useInfiniteQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { TableInfiniteScrollCotainerRefs } from '../components/Table';

export const useInfiniteScrollTable = <T>(
  queryKey: any[],
  fetchData: (page: number) => Promise<T[]>,
) => {
  const [currentQueryKey, setCurrentQueryKey] = useState(queryKey);
  const refs = useRef<TableInfiniteScrollCotainerRefs>(null);
  const { ref: markerRef, inView } = useInView();
  const { data, fetchNextPage, isFetching, isLoading, refetch } =
    useInfiniteQuery({
      queryKey: currentQueryKey,
      queryFn: async ({ pageParam = 1 }) => {
        return fetchData(pageParam);
      },
      initialPageParam: 1,
      getNextPageParam: (_lastGroup, groups) => groups.length + 1,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      enabled: false,
    });

  const flatData = useMemo(
    () => data?.pages?.flatMap((page) => page) ?? [],
    [data],
  );

  const totalFetched = flatData.length;
  const fetchMoreOnBottomReached = useCallback(async () => {
    if (
      isLoading ||
      isFetching ||
      (data && data.pages[data.pages.length - 1].length === 0)
    )
      return;
    return fetchNextPage();
  }, [fetchNextPage, isFetching, totalFetched]);

  useEffect(() => {
    if (!refs.current) return;
    markerRef(refs.current.marker);
  }, [markerRef, refs.current]);

  useEffect(() => {
    if (!data || !inView) return;
    fetchMoreOnBottomReached();
  }, [fetchMoreOnBottomReached, inView]);

  useEffect(() => {
    refetch();
  }, [currentQueryKey]);

  return {
    refs,
    refetch: () => {
      setCurrentQueryKey(queryKey);
    },
    flatData,
  };
};
