import { ReactNode } from 'react';
import styles from './styles.module.css';
import { styled } from 'styled-components';

const Wrap = styled('div')(
  ({ theme }) => `
    > div {
      background-color: var(${theme.maincolor})!important;
      &::before {
        background-image: url(/img/${theme.label2})!important;
      }
    }
  `
)


export const Label = ({ children, theme }: { children: ReactNode, theme?: any }) => (
  <Wrap className={styles.label}>
    <div>
      <span>{children}</span>
    </div>
  </Wrap>
);
