import React, { useEffect } from "react";
import "./style.css";
import Kanji from "@/src/components/auth/Kanji";
import SubButton from "@/src/components/parts/SubButton";
import { useNavigate } from "react-router-dom";


export const Maintenance = (): JSX.Element => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return (
    <div className="maintenance">
      <div className="overlap">
        <div className="div">
          <img className="dots" alt="Dots" src="/img/dots-4.png" />
          <img className="img" alt="Dots" src="/img/dots-4.png" />
          <img className="dots-2" alt="Dots" src="/img/dots-4.png" />
          <img className="dots-3" alt="Dots" src="/img/dots-1.png" />
        </div>
        <SubButton className="sub-button" onClick={goBack}>
          <div className="text-wrapper"><Kanji kana="まえ">前</Kanji>の<Kanji kana="が">画</Kanji><Kanji kana="めん">面</Kanji>にもどる</div>
        </SubButton>
        <div className="group">
          <div className="main-header">メンテナンス<Kanji kana="ちゅう">中</Kanji></div>
        </div>
        <img className="img-2" alt="Img" src="/img/image.svg" />
        <div className="group-2">
          <div className="text-wrapper-6">しばらくお<Kanji kana="ま">待</Kanji>ちください</div>
          <div className="text-wrapper-8">2024<Kanji kana="ねん">年</Kanji>12<Kanji kana="がつ">月</Kanji>8<Kanji kana="にち">日</Kanji> 0：00〜12<Kanji kana="がつ">月</Kanji>8<Kanji kana="にち">日</Kanji> 5：00</div>
        </div>
      </div>
    </div>
  );
};
