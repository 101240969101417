import { z } from 'zod';

const isValidStr = (s: string | undefined | null) => {
  if ((s === undefined) || (s === null) || (s === "")) {
    return false;
  }
  return true;
}

const requiredSchema = z.object({
  email: z.optional(z.string({ required_error: '「メールアドレス」が入力されていません。' })),
  password: z.optional(z.string()),
}).refine((data) => (isValidStr(data.email) || isValidStr(data.password)), {
  path: ['email'],
  message: "「メールアドレス」と「パスワード」が入力されていません。",
});

const loginFormItemsSchema = z.object({
  email: z
    .string({ required_error: '「メールアドレス」が入力されていません。' })
    .min(1,'「メールアドレス」が入力されていません。')
    .max(255,'「メールアドレス」の形式が正しくありません。')
    .email({ message: '「メールアドレス」の形式が正しくありません。' }),

  password: z
    .string({ required_error: '「パスワード」が入力されていません。' })
    .min(1, '「パスワード」が入力されていません。')
});

export const loginFormSchema = requiredSchema.pipe(loginFormItemsSchema);

export type LoginFormSchema = z.infer<typeof loginFormSchema>;
