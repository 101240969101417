import {
  Option as MuiOption,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  SelectRootSlotProps,
} from '@mui/base';
import clsx from 'clsx';
import { ReactNode, forwardRef } from 'react';
import styles from './styles.module.css';

export type Option<T> = { value: T; label: ReactNode; disabled?: boolean };
export type Props<T extends {}> = Omit<MuiSelectProps<T, false>, 'onChange'> & {
  options: Option<T | null | undefined>[];
  onChange?: (value: T | null | undefined) => void;
  noRuby?: boolean;
  popperPlacement?: 'top-start' | 'bottom-start';
  name?: string;
};

export const Select = <T extends {}>({
  className = '',
  options,
  onChange,
  noRuby,
  popperPlacement,
  name,
  ...props
}: Props<T>): JSX.Element => {
  const handleChange = (value: T | null | undefined) => {
    if (onChange) {
      onChange(value);
    }
    if (value !== null && value !== undefined) {
      if (name !== null && name !== undefined) {
        sessionStorage.setItem(name, JSON.stringify(value));
      }
    }
  };
  return (
    <MuiSelect
      className={clsx(
        className,
        styles.select,
        props.disabled && styles.disabled,
        noRuby && styles['no-ruby'],
      )}
      onChange={onChange && ((e, value) => handleChange(value))}
      renderValue={(current) => {
        const option =
          (current &&
            options.find((option) => option.value === current.value)) ??
          options.find((option) => !option.disabled);
        return option?.label;
      }}
      slotProps={{
        popup: {
          className: styles.popper,
          ...(popperPlacement && { placement: popperPlacement }),
        },
        listbox: { className: styles.listbox },
      }}
      slots={{ root: Button }}
      {...props}
    >
      {options.map(({ value, label, disabled }, index) => (
        <MuiOption
          className={clsx(
            value == null && props.value == null && 'Mui-selected',
          )}
          key={index}
          value={value == null ? '' : value}
          disabled={disabled}
        >
          {label}
        </MuiOption>
      ))}
    </MuiSelect>
  );
};

const Button = forwardRef(function Button<
  TValue extends {},
  Multiple extends boolean,
>(
  props: SelectRootSlotProps<TValue, Multiple>,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  const { ownerState, ...other } = props;
  return (
    <button type="button" {...other} ref={ref}>
      {other.children}
      <img
        className={`${styles.toggle} ${ownerState.open ? styles.open : ''}`}
        src="/img/vector-12-select.svg"
      />
    </button>
  );
});
