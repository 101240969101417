import {
  FormControl,
  MenuItem,
  Select,
  Stack,
  ThemeProvider,
  Typography,
  createTheme,
} from '@mui/material';
import { FieldValues, useController } from 'react-hook-form';
import { RHFTextFieldProps } from '../../types/RHFTextFieldProps';

export type SelectOptions = Readonly<{ value: any; label: string }[]>;

export const RHFSelect = <T extends FieldValues>({
  name,
  control,
  label,
  options,
  tabIndex,
  disabled,
  setValue,
  onChange,
  showError = true,
  menuProps,
}: RHFTextFieldProps<T> & {
  options: SelectOptions;
  showError?: boolean;
}) => {
  const {
    field: { value, ref, ...rest },
    formState: { errors },
  } = useController({ name, control });

  const errorMessage = errors?.[name]?.message as string;

  const handleChange = (e: any) => {
    const newValue = e.target.value;
    if (setValue) {
      setValue(name, newValue);
    }
    if (onChange) {
      onChange(e); // 追加のカスタム動作
    }
  };

  const displayLabel =
    value === '0'
      ? label
      : options.find((opt) => opt.value === value)?.label || label;

  return (
    <ThemeProvider theme={theme}>
      <Stack direction="row" alignItems="center" m={2}>
        <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
          <Select
            disableUnderline
            displayEmpty
            // 値がundefinedの場合は空文字に変換する
            value={value}
            inputRef={ref}
            {...rest}
            tabIndex={tabIndex}
            disabled={disabled}
            onChange={handleChange}
            MenuProps={menuProps}
          >
            <MenuItem value="0">
              <em style={{ color: '#d2d2d2' }}>{displayLabel}</em>
            </MenuItem>
            {options.map((option: any, i: any) => (
              <MenuItem key={`${option.value}-${i}`} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {showError && errorMessage && (
          <Typography variant="body1" ml={3} color="red">
            {errorMessage}
          </Typography>
        )}
      </Stack>
    </ThemeProvider>
  );
};

const theme = createTheme({
  components: {
    MuiStack: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          position: 'absolute',
          zIndex: '1',
          left: '43px',
          fontSize: '20px',
          fontFamily: 'var(--main-font-family-db)',
          fontWeight: 'bold',
          color: '#D9D9D9',
          '@media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none)':{
            fontSize:'1.25rem'
          }
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          margin: '0!important',
          width: '100vw',
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
        },
        input: {
          paddingTop: '12px',
          paddingBottom: '12px',
          '&:focus': {
            borderRadius: '13px',
            boxShadow: '0px 1px 5px 0px #00000070 inset',
          },
          '&.Mui-disabled': {
            WebkitTextFillColor: 'rgba(0,0,0,1)',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        ...{
          root: {
            boxShadow: '0px 1px 5px 0px #00000070 inset',
            borderRadius: '18px',
            border: '4px solid #D9D9D9',
            borderWidth: '5px 5px 5px',
            fontSize: '20px',
            '@media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none)':{
              fontSize:'1.25rem'
            },
            fontFamily: 'var(--main-font-family-db)',
            fontWeight: 'bold',
            notchedOutline: {
              border: 'none',
            },
            '&.Mui-disabled': {
              boxShadow: 'none',
              backgroundColor: 'white',
              WebkitTextFillColor: 'rgba(0,0,0,1)',
            },
            '&.Mui-disabled em': {
              display: 'none',
            },
          },
        },
        select: {
          borderRadius: '13px',
        },
      },
    },
  },
});
