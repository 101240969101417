import {
  DownloadButton,
  FormItemCategorySelect,
  TeacherHistorySelect,
  styles as formStyles,
} from '@/src/components/HistoryQueryForm';
import { HistoryQueryFormTeacher } from '@/src/components/HistoryQueryForm/HistoryQueryFormTeacher';
import { KanjiToggleTeacher } from '@/src/components/HistoryQueryForm/items/KanjiToggle';
import {
  profileAtom,
  themeAtom,
} from '@/src/components/ModalContents/modalAtom';
import { TableWithScroll } from '@/src/components/Table';
import { TeacherFrame } from '@/src/components/layouts/TeacherFrame';
import { useTheme } from '@emotion/react';
import clsx from 'clsx';
import { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useRecoilState } from 'recoil';
import { useToppageTeachers } from '../TeacherTop/hooks';
import { useLearningHistoryKanjiSkill } from './hooks';
import styles from './styles.module.css';
import styled from 'styled-components';

type Props = {
  onThemeChange: (status: number) => void;
};

const ErrorCoverDiv = styled('div')(
  ({ theme }) => `
    width:411px;
    height:197px;
    background-color: var(${theme.backgroud});
    position:absolute;
    right:0;
    bottom:0;
    z-index:99;
    opacity:40%;
  `
)
const ErrorCoverListDiv = styled('div')(
  ({ theme }) => `
    width:109px;
    height:97px;
    background-color: var(${theme.graycolor});
    position:absolute;
    left:-165px;
    top:-96px;
    z-index:98;
    opacity:40%;
  `
)

const ErrorSearchDiv = styled('div')(
  ({ theme }) => `
    width:1003px;
    height:77px;
    background-color: var(${theme.backgroud});
    position:absolute;
    left:0;
    top:-15px;
    z-index:98;
    opacity:40%;
  `
)

export const TeacherHistoryKanji = (props: Props) => {
  const { hasLoaded, table, categories, methods, onSubmit, download, type, errorMessage } =
    useLearningHistoryKanjiSkill();

  const { currentUser } = useToppageTeachers();
  const [themenum, setThemenum] = useRecoilState(themeAtom);
  const [profiles, setProfiles] = useRecoilState(profileAtom);

  useEffect(() => {
    if (currentUser) {
      if (!themenum) {
        setThemenum(currentUser?.preference.theme_color);
      }
      const _profiles = {
        nick_name: profiles.nick_name
          ? profiles.nick_name
          : currentUser?.nick_name,
        profile_icon:
          profiles.profile_icon &&
            profiles.profile_icon !== currentUser?.preference.profile_icon
            ? profiles.profile_icon
            : currentUser?.preference.profile_icon,
      };
      if (
        _profiles.nick_name !== profiles.nick_name ||
        _profiles.profile_icon !== profiles.profile_icon
      ) {
        setProfiles(_profiles);
      }
      methods.reset();
    }
  }, []);

  useEffect(() => {
    if (currentUser && typeof themenum === 'number') {
      props.onThemeChange(themenum);
    }
  }, [themenum, currentUser]);

  const theme = useTheme();

  return (
    <TeacherFrame title="学習履歴 教師用">
      <div className={styles.body}>
        <FormProvider {...methods}>
          <HistoryQueryFormTeacher onSubmit={onSubmit}>
            <div className={clsx(formStyles.row, styles['selects-row'])}>
              <div className={styles.selects}>
                <TeacherHistorySelect />
                {hasLoaded && (
                  <FormItemCategorySelect
                    className='label'
                    label="学年・学期／上下をえらぶ"
                    categories={categories}
                    noRuby
                  />
                )}
              </div>
              <div className={styles.buttons}>
                {/* <SubmitButton noRuby /> */}
                <DownloadButton onClick={download} />
              </div>
            </div>
            <div className={clsx(formStyles.row, styles['toggle-row'])}>
              {type && <KanjiToggleTeacher type={type} />}
            </div>

            {errorMessage && < ErrorCoverDiv />}
            {errorMessage && < ErrorCoverListDiv />}
            {errorMessage && < ErrorSearchDiv />}

          </HistoryQueryFormTeacher>
        </FormProvider>
        {hasLoaded && (
          <div className='TableWrap' style={{ position: 'relative' }}>
            <TableWithScroll
              className={clsx('kanji', styles.table)}
              table={table}
              maxWidth={1440}
              maxHeight={635}
            />
            <div className='totText'>合計</div>
          </div>
        )}
      </div>
    </TeacherFrame>
  );
};
