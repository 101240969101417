import { KEISAN_LICENSES } from '@/src/components/HistoryQueryForm/constants';
import { Option, Select } from '@/src/components/Select/Select';
import { useAuthContext } from '@/src/middleware/auth/AuthContext';
import { useToppageStudent } from '@/src/screens/StudentTop/hook';
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LICENSES, ROUTES } from '../constants';
import { Label } from './Label';

type HistorySelectProps = {
  labelWidth?: number;
};

export const TeacherHistorySelect = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const availableOptions = useAvailableOptionsTeacher(TEACHER_OPTIONS);
  return (
    <Select<string>
      defaultValue={
        location.pathname === ROUTES.teacher.kanjitest
          ? ROUTES.teacher.kanji
          : location.pathname
      }
      options={availableOptions}
      onChange={(value) => value && navigate(value)}
      noRuby
    />
  );
};

export const StudentHistorySelect = ({ labelWidth }: HistorySelectProps) => {
  const location = useLocation();
  const { currentUser } = useToppageStudent();
  const { redirect } = useAuthContext();
  const license =
    currentUser?.license.filter(({ status }) => status === 2) || [];
  const availableOptions = useAvailableOptionsStudent(STUDENT_OPTIONS);

  const _options = useMemo(() => {
    const standard = license.filter((l) =>
      KEISAN_LICENSES.standard.includes(l.learning_contents),
    );
    const tokyoshoseki = license.filter((l) =>
      KEISAN_LICENSES.tokyoshoseki.includes(l.learning_contents),
    );
    const keirinkan = license.filter((l) =>
      KEISAN_LICENSES.keirinkan.includes(l.learning_contents),
    );
    return [
      ...[...tokyoshoseki, ...keirinkan, ...standard].map((l) => ({
        value: l.path!,
        id: l.learning_contents,
      })),
    ];
  }, [license]);
  console.log("HistorySelect");
  const redirectEx = (path: string | null) => {
    if (redirect && path) {
      redirect(path);
    }
  }

  return (
    <Label label={STUDENT_LABEL} labelWidth={labelWidth}>
      <Select<string>
        defaultValue={
          location.pathname === ROUTES.student.kanjitest
            ? ROUTES.student.kanji
            : location.pathname
        }
        options={availableOptions}
        onChange={(value) => value && redirectEx(value)}
        // onChange={(value) => {
        //   // 計算スキルのライセンスに変更した場合リダイレクトする
        //   if (value === '/studenthistorykeisan') {
        //     // options に含まれる value にリダイレクトする
        //     window.location.href = options[0].value;
        //   } else {
        //     console.log('ELSE', location.pathname);
        //     navigate(value);
        //   }
        // }}
      />
    </Label>
  );
};

const useAvailableOptionsStudent = <
  O extends Option<any> & { licenseIds: number[] },
>(
  options: O[],
): O[] => {
  const { getCurrentUser } = useAuthContext();
  const availableOptions = useMemo(() => {
    const user = getCurrentUser?.();
    if (!user) return [];
    return options.filter((option) =>
      user.license.some(
        (license) =>
          license.status !== 0 &&
          option.licenseIds.includes(license.learning_contents),
      ),
    );
  }, [getCurrentUser]);

  return availableOptions;
};
const useAvailableOptionsTeacher = <
  O extends Option<any> & { licenseIds: number[] },
>(
  options: O[],
): O[] => {
  const { getCurrentUser } = useAuthContext();
  const availableOptions = useMemo(() => {
    const user = getCurrentUser?.();
    if (!user) return [];
    const licenses = user.license
      .filter(({ status }) => status !== 0)
      .map((license) => license.learning_contents);
    return options.filter((option) =>
      option.licenseIds.some((item) => licenses.includes(item)),
    );
  }, [getCurrentUser]);

  return availableOptions;
};

const TEACHER_OPTIONS: (Option<string> & {
  licenseIds: number[];
})[] = [
  {
    value: ROUTES.teacher.romaji,
    label: 'ローマ字スキル',
    licenseIds: LICENSES.learning_contents.romaji,
  },
  {
    value: ROUTES.teacher.video,
    label: '光村の国語指導資料映像',
    licenseIds: LICENSES.learning_contents.video,
  },
  {
    value: ROUTES.teacher.kanji,
    label: (
      <div>
        漢字スキル&thinsp;
        <span style={{ fontSize: 'x-small', letterSpacing: '0px' }}>
          (ひらがな・すうじ／カタカナ)
        </span>
      </div>
    ),
    licenseIds: [
      ...LICENSES.learning_contents.kanji,
      ...LICENSES.learning_contents.hiragana_suuji,
      ...LICENSES.learning_contents.katakana,
    ],
  },
  {
    value: ROUTES.teacher.cbt,
    label: 'きそのたしかめCBT 国語',
    licenseIds: LICENSES.learning_contents.cbt,
  },
  {
    value: ROUTES.teacher.dictionary,
    label: 'WEB版デジタル国語辞典',
    licenseIds: LICENSES.learning_contents.dictionary,
  },
  {
    value: ROUTES.teacher.dictionarykanji,
    label: 'WEB版デジタル漢字辞典',
    licenseIds: LICENSES.learning_contents.dictionarykanji,
  },
  {
    value: ROUTES.teacher.keisan,
    label: '計算スキル',
    licenseIds: LICENSES.learning_contents.keisan,
  },
];

export const STUDENT_LABEL = (
  <div>
    <ruby>
      教<rt>きょう</rt>
    </ruby>
    <ruby>
      材<rt>ざい</rt>
    </ruby>
    をえらぶ
  </div>
);

export const STUDENT_OPTIONS: (Option<string> & { licenseIds: number[] })[] = [
  {
    value: ROUTES.student.romaji,
    label: (
      <div>
        ローマ
        <ruby>
          字<rt>じ</rt>
        </ruby>
        スキル
      </div>
    ),
    licenseIds: LICENSES.learning_contents.romaji,
  },
  {
    value: ROUTES.student.kanji,
    label: (
      <div>
        <ruby>
          漢<rt>かん</rt>
        </ruby>
        <ruby>
          字<rt>じ</rt>
        </ruby>
        スキル<span style={{fontSize:'11px',letterSpacing:'-0.5px'}}>（ひらがな・すうじ／カタカナ）</span>
      </div>
    ),
    licenseIds: [
      ...LICENSES.learning_contents.kanji,
      ...LICENSES.learning_contents.hiragana_suuji,
      ...LICENSES.learning_contents.katakana,
    ],
  },
  {
    value: ROUTES.student.dictionary,
    label: (
      <div>
        <ruby>
          国<rt>こく</rt>
        </ruby>
        <ruby>
          語<rt>ご</rt>
        </ruby>
        <ruby>
          辞<rt>じ</rt>
        </ruby>
        <ruby>
          典<rt>てん</rt>
        </ruby>
      </div>
    ),
    licenseIds: LICENSES.learning_contents.dictionary,
  },
  {
    value: ROUTES.student.dictionarykanji,
    label: (
      <div>
        <ruby>
          漢<rt>かん</rt>
        </ruby>
        <ruby>
          字<rt>じ</rt>
        </ruby>
        <ruby>
          辞<rt>じ</rt>
        </ruby>
        <ruby>
          典<rt>てん</rt>
        </ruby>
      </div>
    ),
    licenseIds: LICENSES.learning_contents.dictionarykanji,
  },
  {
    value: ROUTES.student.keisan,
    label: (
      <div>
        <ruby>
          計<rt>けい</rt>
        </ruby>
        <ruby>
          算<rt>さん</rt>
        </ruby>
        スキル
      </div>
    ),
    licenseIds: LICENSES.learning_contents.keisan,
  },
];
