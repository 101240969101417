import { RHFEmailField } from '@/src/components/RHFEmailField';
import MainButton from '@/src/components/parts/MainButton';
import SubButton from '@/src/components/parts/SubButton';
import useEmailForm from '@/src/hooks/useEmailForm';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TwentyTwo } from '../../icons/TwentyTwo';
import './style.css';
import Kanji from '@/src/components/auth/Kanji';


export const PasswordResetUrlSend = (): JSX.Element => {
  const {
    form: { control, handleSubmit, onSubmit, errors },
    options: { },
  } = useEmailForm();

  const navigate = useNavigate();

  return (
    <div className="password-reset-url-send">
      <div className="password-reset-url-2">
        <div className="overlap-46">
          <div className="div-18">
            <div className="group-137">
              <div className="frame-21">
                <div className="frame-22">
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                </div>
                <div className="frame-22">
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                </div>
                <div className="frame-22">
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                </div>
                <div className="frame-22">
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                </div>
                <div className="frame-22">
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                </div>
                <div className="frame-22">
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                </div>
                <div className="frame-22">
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                </div>
                <div className="frame-22">
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                </div>
              </div>
            </div>
            <div className="group-138">
              <div className="frame-21">
                <div className="frame-22">
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                </div>
                <div className="frame-22">
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                </div>
                <div className="frame-22">
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                </div>
                <div className="frame-22">
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                </div>
                <div className="frame-22">
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                </div>
                <div className="frame-22">
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                </div>
                <div className="frame-22">
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                </div>
                <div className="frame-22">
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                </div>
              </div>
            </div>
            <div className="group-139">
              <div className="frame-21">
                <div className="frame-22">
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                </div>
                <div className="frame-22">
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                </div>
                <div className="frame-22">
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                </div>
                <div className="frame-22">
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                </div>
                <div className="frame-22">
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                </div>
                <div className="frame-22">
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                </div>
                <div className="frame-22">
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                </div>
                <div className="frame-22">
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                </div>
              </div>
            </div>
            <div className="group-140">
              <div className="frame-21">
                <div className="frame-22">
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                </div>
                <div className="frame-22">
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                </div>
                <div className="frame-22">
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                </div>
                <div className="frame-22">
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                </div>
                <div className="frame-22">
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                </div>
                <div className="frame-22">
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                </div>
                <div className="frame-22">
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                </div>
                <div className="frame-22">
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                  <div className="ellipse-19" />
                </div>
              </div>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="button-area">
              <SubButton
                className="sub-button-back-2"
                onClick={() => {
                  navigate('/login');
                }}
              >
                <div className="text-wrapper-286">ログインページへもどる</div>
              </SubButton>
              <MainButton className="main-button-send" type="submit">
                <div className="text-wrapper-287"><Kanji kana="そうしん">送信</Kanji></div>
              </MainButton>
            </div>
            <RHFEmailField
              name="mail_address"
              label=""
              control={control}
              type="email"
            />
            <div className="message-warning-5">
              {errors.mail_address?.message === '「メールアドレス」が未入力です。' && (
                <>
                  <div className="message-warning-6">「メールアドレス」が<Kanji kana="みにゅうりょく">未入力</Kanji>です。</div>
                </>
              )}
              {errors.mail_address?.message === '「メールアドレス」の形式が正しくありません。' && (
                <>
                  <div className="message-warning-61">「メールアドレス」の<Kanji kana="けいしき">形式</Kanji>が<Kanji kana="ただ">正</Kanji>しくありません。</div>
                </>
              )}
              {errors.mail_address?.message === 'メールアドレスが登録されていません。' && (
                <>
                  <div className="message-warning-61">メールアドレスが<Kanji kana="とうろく">登録</Kanji>されていません。</div>
                </>
              )}
            </div>
            <div className="navbar-6">
              <div className="navbar-7">
                <div className="URL-2">
                  パスワード<Kanji kana="さいせっていよう">再設定用</Kanji>のURLをお<Kanji kana="おく">送</Kanji>りします
                  <br />
                  <Kanji kana="とうろく">登録</Kanji>したメールアドレスを<Kanji kana="にゅうりょく">入力</Kanji>し、「<Kanji kana="そうしん">送信</Kanji>」を<Kanji kana="お">押</Kanji>してください
                </div>
              </div>
            </div>
            <div className="main-title">
              <div className="main-header-9">
                <span>パスワードを</span>
                <Kanji kana="さい">再</Kanji>
                <Kanji kana="せっ">設</Kanji>
                <Kanji kana="てい">定</Kanji>
              </div>
            </div>
            <TwentyTwo className="instance-21" />
          </form>
        </div>
      </div>
    </div>
  );
};
