import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, useWatch } from 'react-hook-form';
import { uRLMailResetPasswordUrlSendPost } from '../__generated__';
import { EmailFormSchema, emailFormSchema } from '../schema/email-form-schema';
import { useNavigate } from 'react-router-dom';

const useEmailForm = () => {
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
    // useFormのジェネリクスにはdefaultValuesの型を渡す
  } = useForm<EmailFormSchema>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: undefined,
    resolver: zodResolver(emailFormSchema),
  });
  const navigate = useNavigate();

  const watchedInput = useWatch({ control });
  // フォームのエラー状況
  console.log('errors', errors);
  // フォームの入力値
  console.log('watchedInput', watchedInput);

  // zodの値変換+型チェックを通過した場合のみonSubmitが呼ばれる
  const onSubmit = async (data: EmailFormSchema) => {
    try {
      const res = await uRLMailResetPasswordUrlSendPost({
        mail_address: data.mail_address
      })
      if (res.status === 200) {
        navigate('/passwordreseturlsenddone')
      }
    } catch (error: any) {
      if (error.response.status === 401 || error.response.status === 404) {
          //alert('メールアドレスが登録されていません。')
        setError('mail_address', { type: 'custom', message: 'メールアドレスが登録されていません。' })
      }
    }
  };

  return {
    form: {
      control,
      handleSubmit,
      onSubmit,
      errors,
    },
    options: {
      //
    },
  };
};

export default useEmailForm;
