import { getAutoGeneratePassword } from '@/src/hooks/useGetAutoGeneratePassword';
import { Button, IconButton, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { usePasswordReset } from './hooks';
import MainButton from '../../parts/MainButton';
import SubButton from '../../parts/SubButton';
import './style.css';
import { RHFTextFieldPW } from '../../RHFTextFieldPW';
import { modalAtom } from '../modalAtom';
import { useRecoilState } from 'recoil';
import { ModalCloseButton } from '../../parts/ModalCloseButton';
import { ModalDecisionButton } from '../../parts/ModalDecisionButton';
import { ModalSubButtonAuto } from '../../parts/ModalSubButtonAuto';
import styled, { DefaultTheme } from 'styled-components';
import Kanji from '../../auth/Kanji';

interface Props {
  theme?: DefaultTheme
}

const ChangePassword = styled('div')(
  ({ theme }) => `
    background: var(${theme.backgroud})!important;
    .modal-title-bar{
      background-color: var(${theme.maincolor})!important;
    }
    ,text-wrapper-pr0,.label-9,.text-wrapper-pr1,.label-9,.text-wrapper-pr2,.text-wrapper-pr3,.text-wrapper-pr4,.message-note-3,.text-wrapper-pr5,.text-wrapper-pr6,.label-8,.text-wrapper-pr7,.text-wrapper-263{
      color:var(${theme.textcolor})!important;
    }
  `
)

export const PasswordReset = () => {
  const {
    form: { control, handleSubmit, onSubmit, autoGenerate, errors }
  } = usePasswordReset();

  const [isRevealPassword, setIsRevealPassword] = useState(false);

  const [openModalKey, setOpenModalKey] = useRecoilState<any>(modalAtom)

  const togglePassword = () => {
    setIsRevealPassword((prevState: boolean) => !prevState);
  };

  const [isRevealPassword1, setIsRevealPassword1] = useState(false);

  const togglePassword1 = () => {
    setIsRevealPassword1((prevState: boolean) => !prevState);
  };

  const [isRevealPassword2, setIsRevealPassword2] = useState(false);

  const togglePassword2 = () => {
    setIsRevealPassword2((prevState: boolean) => !prevState);
  };

  return (
    <ChangePassword className="teacher-password">
      <div className="overlap-61">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="input-current">
            <div className="label-9"><Kanji kana="げんざい">現在</Kanji>のパスワード</div>
            <div className="input-area-8">
              <RHFTextFieldPW name='currentPassword' control={control} type={isRevealPassword1 ? 'text' : 'password'} />
              <IconButton className="icon-eye" onClick={togglePassword1}>
                <img alt="Eye icon" src={isRevealPassword1 ? "/img/eyeicon_close.png" : "/img/eyeicon.png"} />
              </IconButton>
            </div>
            {errors.currentPassword && (
              <div className="warning-message-6">
                {errors.currentPassword.message === '現在のパスワードが入力されていません。' && (
                  <>
                    <div className="warning-message"><Kanji ls="-1" kana="げんざい">現在</Kanji>のパスワードが<Kanji kana="にゅうりょく" ls="-1.5">入力</Kanji>されていません。</div>
                  </>
                )}
                {errors.currentPassword.message === '現在のパスワードが正しくありません。' && (
                  <>
                    <div className="warning-message"><Kanji kana="げんざい">現在</Kanji>のパスワードが<Kanji kana="ただ">正</Kanji>しくありません。</div>
                  </>
                )}
              </div>
            )}
          </div>
          <ModalSubButtonAuto type='button' className='sub-button' onClick={autoGenerate} />
          <div className="input-password-2">
            <div className="label-9"><Kanji kana="あたら">新</Kanji>しいパスワード</div>
            <div className="input-area-8">
              <RHFTextFieldPW name='newPassword' control={control} type={isRevealPassword ? 'text' : 'password'} />
              <IconButton className="icon-eye" onClick={togglePassword}>
                <img alt="Eye icon" src={isRevealPassword ? "/img/eyeicon_close.png" : "/img/eyeicon.png"} />
              </IconButton>
            </div>
            {errors.newPassword && (
              <div className="warning-message-6">
                {errors.newPassword.message === '新しいパスワードが入力されていません。' && (
                  <>
                    <div className="warning-message"><Kanji kana="あたら">新</Kanji>しいパスワードが<Kanji kana="にゅうりょく" ls="-1.5">入力</Kanji>されていません。</div>
                  </>
                )}
                {errors.newPassword.message === '新しいパスワードの形式が正しくありません。' && (
                  <>
                    <div className="warning-message"><Kanji kana="あたら">新</Kanji>しいパスワードの<Kanji kana="けいしき">形式</Kanji>が<Kanji kana="ただ">正</Kanji>しくありません。</div>
                  </>
                )}
                {errors.newPassword.message === '正規表現エラー' && (
                  <>
                    <div className="warning-message">パスワードは<Kanji kana="えいじ">英字</Kanji>・<Kanji kana="すうじ">数字</Kanji>・<Kanji kana="きごう">記号</Kanji>をすべて<Kanji kana="しよう">使用</Kanji>して、10<Kanji kana="もじいじょう">文字以上</Kanji>で<Kanji kana="にゅうりょく" ls="-1.5">入力</Kanji>してください。
                      <br />「,」「?」「/」「\」「'」「|」「¥」「"」は<Kanji kana="しよう">使用</Kanji>できません。</div>
                  </>
                )}
                {errors.newPassword.message === '現在のパスワードと新しいパスワードが同じです。' && (
                  <>
                    <div className="warning-message"><Kanji kana="げんざい" ls="-1">現在</Kanji>のパスワードと<Kanji kana="あたら">新</Kanji>しいパスワードが<Kanji kana="おな">同</Kanji>じです。</div>
                  </>
                )}
              </div>
            )}
          </div>
          <div className="message-note-3">
            ※パスワードを<Kanji kana="へんこう">変更</Kanji>すると、<Kanji kana="げんざい">現在</Kanji>のユーザー<Kanji kana="じょうほう">情報</Kanji>カードではログインできなくなります。
          </div>
          <div className="input-password">
            <div className="label-8">
              <Kanji kana="あたら">新</Kanji>しいパスワード
              <br />
              （<Kanji kana="かくにん">確認</Kanji>）
            </div>
            <div className="input-area-8">
              <RHFTextFieldPW name='newPasswordConfirm' control={control} type={isRevealPassword2 ? 'text' : 'password'} />
              <IconButton className="icon-eye" onClick={togglePassword2}>
                <img alt="Eye icon" src={isRevealPassword2 ? "/img/eyeicon_close.png" : "/img/eyeicon.png"} />
              </IconButton>
            </div>
            {errors.newPasswordConfirm && (
              <div className="warning-message-6">
                {errors.newPasswordConfirm.message === '新しいパスワード（確認）が入力されていません。' && (
                  <>
                    <div className="warning-message"><Kanji kana="あたら">新</Kanji>しいパスワード（<Kanji ls="-1" kana="かくにん">確認</Kanji>）が<Kanji kana="にゅうりょく" ls="-1.5">入力</Kanji>されていません。</div>
                  </>
                )}
                {errors.newPasswordConfirm.message === '新しいパスワードと確認用のパスワードがちがいます。' && (
                  <>
                    <div className="warning-message"><Kanji ls="-1" kana="あたら">新</Kanji>しいパスワードと<Kanji ls="-1" kana="かくにんよう">確認用</Kanji>のパスワードがちがいます。
                    </div>
                  </>
                )}
              </div>
            )}
          </div>

          <div className="title-8">
            <div className="text-wrapper-263">パスワードを<Kanji kana="へんこう">変更</Kanji>する</div>
          </div>
          <div className="main-button-8">
            <ModalDecisionButton className='overlap-group-74' type='submit' />
          </div>
        </form>
        <div className="modal-title-bar">
          <div className="sub-button-close">
            <ModalCloseButton className='overlap-63' onClick={() => { setOpenModalKey(null) }} />
          </div>
          <div className="modal-title">
            <div className="text-wrapper-265">セキュリティ</div>
          </div>
        </div>
      </div>
    </ChangePassword>
  );
};
