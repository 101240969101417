import ReactDOMClient from 'react-dom/client';
import { App } from './App';

const prepare = () => {
  if (process.env.MSW === 'true') {
    return import('../tests/__mocks__/browser').then(({ worker }) =>
      worker.start(),
    );
  }
  return Promise.resolve();
};

prepare().then(() => {
  const app = document.getElementById('app');
  const root = ReactDOMClient.createRoot(app as HTMLElement);
  root.render(<App />);
});
