import './style.css';

const FormSendComplete = () => {
  return (
    <div className="logout">
      <div className="overlap-wrapper-11">
        <div className="overlap-58">
          <div className="message-2">
            <div className="overlap-60">
              <div className="text-wrapper-327">送信が完了しました。</div>
                  <li>【*tomosplus.jp】の受信を許可する</li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormSendComplete;
