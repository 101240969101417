import {
  Bar,
  CartesianGrid,
  Legend,
  BarChart as RechartsBarChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip
} from 'recharts';
import { DefaultLegendContentProps } from 'recharts/types';
import styles from './styles.module.css';
import { DefaultTheme } from 'styled-components';
import { display } from '@mui/system';

export type DataSeries = {
  series: {
    key: string;
    label?: string;
    color?: string;
    stackId?: string;
    data: { key: string; value: number }[];
    gradient?: GradientProps;
  }[];
  keys: string[];
};

export type BarChartProps = {
  width: number | string;
  height: number | string;
  dataSeries: DataSeries;
  legendDisabled?: boolean;
  theme: any;
};
export const BarChart = ({
  width,
  height,
  dataSeries,
  legendDisabled,
  theme
}: BarChartProps) => {

  const tooltipStyle = {
    background: 'transparent',
    border: 'none',
    fontWeight: 'bold'
  }

  interface Props {
    active?: any;
    payload?: any;
    label?: any;
  }

  const CustomTooltip = ({ active, payload, label }: Props) => {
    console.log('payload', payload); //you check payload
    if (!active || !payload?.[0]?.value) return null;
    return (
      <div style={{ padding: '5px 10px', background: 'none' }}>
        <p style={{ color: '#000' }}>{payload[0].value}</p>
      </div>
    );
  };

  return (
    <ResponsiveContainer className={styles.chart} width="100%" height={height}>
      <RechartsBarChart
        margin={{ top: 12, left: -24 }}
        data={seriesToData(dataSeries)}
      >
        <defs>
          {dataSeries.series.map(
            ({ gradient }) =>
              gradient && <Gradient key={gradient.id} {...gradient} />,
          )}
        </defs>
        <CartesianGrid stroke="#d9d9d9" vertical={false} />
        {dataSeries.series.map(
          ({ key, color, gradient, stackId = 'stack' }, i, arr) => (
            <Bar
              key={key}
              dataKey={key}
              stackId={stackId}
              fill={gradient ? `url(#${gradient.id})` : color}
              barSize={36}
              stroke={arr.length > 1 ? '#fff' : undefined}
              radius={arr.length - 1 === i ? [4, 4, 0, 0] : undefined}
            />
          ),
        )}
        <XAxis dataKey="key" stroke={`var(${theme.textcolor})`} strokeWidth={2} tickLine={false} interval={0} />
        <YAxis ticks={ticks(dataSeries)} tick={{fill:`var(${theme.textcolor})`}} axisLine={false} tickLine={false} />
        {!legendDisabled && (
          <Legend
            iconSize={8}
            iconType="square"
            wrapperStyle={{ bottom: -15, paddingLeft: 24 }}
            content={LegendContent}
            formatter={(value) =>
              dataSeries.series.find((s) => s.key === value)?.label || value
            }
          />
        )}
        {/* <Tooltip contentStyle={tooltipStyle} cursor={{ fill: 'transparent' }} content={<CustomTooltip />} /> */}
      </RechartsBarChart>
    </ResponsiveContainer>
  );
};

type GradientProps = {
  id: string;
  colors: {
    stopColor: string;
    offset: string;
  }[];
};
const Gradient = ({ colors, id }: GradientProps) => (
  <linearGradient id={id} x1={0} y1={1} x2={0} y2={0}>
    {colors.map(({ offset, stopColor }) => (
      <stop key={offset} offset={offset} stopColor={stopColor} />
    ))}
  </linearGradient>
);

const LegendContent = ({ payload, formatter }: DefaultLegendContentProps) => {
  return (
    <ul className={styles.legend}>
      {payload?.map((entry, index) => (
        <li className={styles.item} key={`item-${index}`}>
          <span className={styles.icon} style={{ background: entry.color }} />
          <span>
            {formatter ? formatter(entry.value, entry, index) : entry.value}
          </span>
        </li>
      ))}
    </ul>
  );
};

const seriesToData = ({ series, keys }: DataSeries) =>
  keys.map((key) => ({
    key,
    ...series.reduce(
      (acc, s) => ({
        ...acc,
        [s.key]: s.data.find((d) => d.key === key)?.value,
      }),
      {},
    ),
  }));

const ticks = ({ series, keys }: DataSeries) => {
  const max = Math.max(
    ...keys.map((key) =>
      series.reduce(
        (acc, { data }) => (data.find((d) => d.key === key)?.value || 0) + acc,
        0,
      ),
    ),
  );
  const ticks = [0, 30, 60, 90, 120, 180, 240];
  let current = 240;
  while (max > current) ticks.push((current += 60));
  return ticks;
};
