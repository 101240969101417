import { z } from 'zod';

export const emailFormSchema = z.object({
  mail_address: z
    .string({ required_error: '「メールアドレス」が未入力です。' })
    .min(1,'「メールアドレス」が未入力です。')
    .max(255,'「メールアドレス」の形式が正しくありません。')
    .email({ message: '「メールアドレス」の形式が正しくありません。' })
})
// .superRefine(
//   ({ mail_address }, ctx) => {
//     if (mail_address !== "") {
//       ctx.addIssue({
//         code: 'custom',
//         message: '「メールアドレス」が未入力です。',
//         path: ["mail_address"],
//       })
//     }
//     if (mail_address.length > 255) {
//       ctx.addIssue({
//         code: 'custom',
//         message: '「メールアドレス」の形式が正しくありません。',
//         path: ["mail_address"],
//       })
//     }
//   }
// );

export type EmailFormSchema = z.infer<typeof emailFormSchema>;
