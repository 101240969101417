import { BarChart} from '@/src/components/BarChart';
import { formatToDateDisplay } from '@/src/helpers/date';
import styles from './styles.module.css';
import styled from 'styled-components';
import Kanji from '@/src/components/auth/Kanji';


const ChartWrap = styled('div')(
  ({ theme }) => `
    position: relative;
    flex-shrink: 0;
    width: 640px;
    height: 533px;
    padding: 65px 40px 31px 43px;
    border: 1px solid var(${theme.maincolor});
    border-radius: 16px;
    background-color: var(${theme.lightcolor});
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.4);
    color:var(${theme.textcolor});
    .caption {
      position: absolute;
      top: 36px;
      left: 22px;      
      display: flex;
      align-items: center;
      column-gap: 3.8px;      
      font-family: var(--main-font-family-m);
      font-size: 23px;
      @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.4375rem;};
      height: 26px;
      ruby:nth-of-type(2) rt {
        letter-spacing: -4px;
        white-space:nowrap;
      }
    }
    .period{
      font-family: var(--main-font-family-m);
      @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1rem;};
      position: absolute;
      top: 43px;
      right: 34px;
      > span{
        margin: 0 4.5px;  
      }
    }
    .period > span {
      margin: 0 4.5px;
    }
    .main {
      width: 100%;
      height: 100%;
    }
  `
)

type ChartProps = {
  data: { key: any; value: number }[];
  from: Date;
  to: Date;
  theme?: any;
};
export const Chart = ({ data, from, to, theme }: ChartProps) => {
  console.log('chart : ',data)
  return (
    <ChartWrap>
      <div className='caption'>
        <img src="/img/teacher-top-chart-icon.png" style={{marginTop:'7px'}} />
        <Kanji kana="がく">学</Kanji><Kanji kana="しゅう">習</Kanji><Kanji kana="じ">時</Kanji><Kanji kana="かん">間</Kanji>&nbsp;<span style={{marginTop:'11px'}}>(</span><Kanji kana="ふん">分</Kanji><span style={{marginTop:'11px'}}>)</span>
      </div>
      <div className='period'>
        {formatToDateDisplay(from)}
        <span>〜</span>
        {formatToDateDisplay(to)}
      </div>
      <div className='main'>
        <BarChart
          width={560}
          height={443}
          legendDisabled
          theme={theme}
          dataSeries={{
            series: [
              {
                key: 'study-time',
                gradient: {
                  id: 'single-gradient',
                  colors: [
                    { offset: '0%', stopColor: `${theme.chart0}` },
                    { offset: '100%', stopColor: `${theme.chart1}` },
                  ],
                },
                data,
              },
            ],
            keys: data.map((d) => d.key),
          }}
        />
      </div>
    </ChartWrap>
  );
};
