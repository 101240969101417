import MainButton from '@/src/components/parts/MainButton';
import SubButton from '@/src/components/parts/SubButton';
import { Link } from 'react-router-dom';
import { TwentyTwo } from '../../icons/TwentyTwo';
import './style.css';

import { useEffect } from 'react';

export const PreLogin = (): JSX.Element => {
  return (
    <div className="pre-login">
      <div className="overlap-wrapper-3">
        <div className="overlap-7">
          <div className="div-3">
            <div className="frame-wrapper">
              <div className="frame">
                <div className="frame-2">
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                </div>
                <div className="frame-2">
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                </div>
                <div className="frame-2">
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                </div>
                <div className="frame-2">
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                </div>
                <div className="frame-2">
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                </div>
                <div className="frame-2">
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                </div>
                <div className="frame-2">
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                </div>
                <div className="frame-2">
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                </div>
              </div>
            </div>
            <div className="group-12">
              <div className="frame">
                <div className="frame-2">
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                </div>
                <div className="frame-2">
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                </div>
                <div className="frame-2">
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                </div>
                <div className="frame-2">
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                </div>
                <div className="frame-2">
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                </div>
                <div className="frame-2">
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                </div>
                <div className="frame-2">
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                </div>
                <div className="frame-2">
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                </div>
              </div>
            </div>
            <div className="group-13">
              <div className="frame">
                <div className="frame-2">
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                </div>
                <div className="frame-2">
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                </div>
                <div className="frame-2">
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                </div>
                <div className="frame-2">
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                </div>
                <div className="frame-2">
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                </div>
                <div className="frame-2">
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                </div>
                <div className="frame-2">
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                </div>
                <div className="frame-2">
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                </div>
              </div>
            </div>
            <div className="group-14">
              <div className="frame">
                <div className="frame-2">
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                </div>
                <div className="frame-2">
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                </div>
                <div className="frame-2">
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                </div>
                <div className="frame-2">
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                </div>
                <div className="frame-2">
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                </div>
                <div className="frame-2">
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                </div>
                <div className="frame-2">
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                </div>
                <div className="frame-2">
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                  <div className="ellipse" />
                </div>
              </div>
            </div>
          </div>
          <div className="message-description">
            <div className="group-15">
              <div className="overlap-group-6">
                <p className="div-4">
                  <span className="text-wrapper-37">
                    学習りれきを保存する場合は、事前にユーザー登録が必要です。
                    <br />
                    詳しくは
                  </span>
                  <Link to="/" className="text-wrapper-38">
                    こちら
                  </Link>
                  <span className="text-wrapper-37">。</span>
                </p>
                <div className="text-wrapper-39">くわ</div>
              </div>
            </div>
          </div>
          <div className="block-buttons">
            <div className="overlap-8">
              <div className="overlap-9">
                <div className="group-16" />
                <SubButton className="accent-button" onClick={() => { }}>
                  <div className="overlap-10">
                    <div className="text-wrapper-40">
                      ログインせずこのまま使う
                    </div>
                  </div>
                </SubButton>
              </div>
              <MainButton className="main-button" onClick={() => { }}>
                <div className="group-17">
                  <div className="group-18">
                    <div className="group-19">
                      <div className="overlap-group-7">
                        <div className="text-wrapper-41">
                          登録済みの方はログイン
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </MainButton>
            </div>
          </div>
          <div className="title">
            <div className="view-2">
              <div className="group-20">
                <div className="group-21">
                  <div className="text-wrapper-42">紙付録</div>
                </div>
              </div>
            </div>
            <TwentyTwo className="instance-22" />
          </div>
        </div>
      </div>
    </div>
  );
};
