import { z } from 'zod';

export const learningHistoryKeisanSkillQueryFormSchema = z
  .object({
    class_index: z.number().nullish().or(z.enum([""])),
  })

export type LearningHistoryKeisanSkillQueryFormSchema = z.infer<
  typeof learningHistoryKeisanSkillQueryFormSchema
>;
