import styles from './CustomActionBar.module.css';
import { CustomControlProps } from './types';

export const CustomActionBar = ({
  currentMonth,
  setCurrentMonth,
}: CustomControlProps) => {
  const date = currentMonth ?? new Date();
  const year = date.getFullYear();
  return (
    <div className={styles['action-bar']}>
      <button
        type="button"
        onClick={() => {
          setCurrentMonth(new Date(year - 1, date.getMonth(), date.getDate()));
        }}
        disabled={year - 1 < 1970}
      >
        <img src="/img/vector-arrow.svg" />
        {year - 1}
      </button>
      <div className={styles.current}>{year}</div>
      <button
        type="button"
        onClick={() =>
          setCurrentMonth(new Date(year + 1, date.getMonth(), date.getDate()))
        }
        disabled={year + 1 > 9999}
      >
        {year + 1}
        <img src="/img/vector-arrow.svg" />
      </button>
    </div>
  );
};
