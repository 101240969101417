import Button, { ButtonProps } from '@mui/material/Button';
import clsx from 'clsx';
import './AccentButton.scss';

export type AccentButtonProps = ButtonProps & {};

const AccentButton = ({ className, children, ...props }: AccentButtonProps) => {
  return (
    <Button className={clsx('accent-button', className)} {...props}>
      {children}
    </Button>
  );
};

export default AccentButton;
