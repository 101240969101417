import {
  csvKanjiSkillTestLearningHistoriesCsvGet,
  KanjiLearningHistoryTestEntity,
  kanjiSkillTestLearningHistoriesGet,
  LibsDomainModelInterfaceKanjiGetKanjiSkillTestLearningHistoriesResponse,
} from '@/src/__generated__';
import { withSaveFile } from '@/src/helpers/file';
import { useSyncHistoryQueryConditions } from '@/src/hooks/useHistoryQueryConditions';
import { useTeacherContext } from '@/src/hooks/useTeacherContext';
import { useAuthContext } from '@/src/middleware/auth/AuthContext';
import {
  LearningHistoryKanjiSkillTestQueryFormSchema,
  learningHistoryKanjiSkillTestQueryFormSchema,
} from '@/src/schema/learning-history-kanji-skill-test-query-form-schema';
import { KanjiApplicationType } from '@/src/types/KanjiApplicationType';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { generateColumns, RowData, SubRowData } from './columns';
import { get_category_index_from_type_id, get_category_selection, get_current_type_category_id, save_category_selection } from '@/src/helpers/kanji-skill/pulldown';

export const useLearningHistoryKanjiSkillTest = () => {
  const { uuid, getCurrentUser, getUserFromUuid } = useAuthContext();
  const {
    hasLoaded,
    childrenList,
    schoolClassList,
    historyQueryConditions,
    classIndexToClass,
  } = useTeacherContext();

  const [data, setData] = useState<RowData[]>([]);
  const [categories, setCategories] = useState<
    NonNullable<
      LibsDomainModelInterfaceKanjiGetKanjiSkillTestLearningHistoriesResponse['categories']
    >
  >([]);
  const [teachingUnits, setTeachingUnits] = useState<
    NonNullable<
      LibsDomainModelInterfaceKanjiGetKanjiSkillTestLearningHistoriesResponse['teaching_units']
    >
  >([]);

  const [columns, setColumns] = useState<ColumnDef<any>[]>([]);

  const [type, setType] = useState<KanjiApplicationType>();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [prevRequest, setPrevRequest] = useState<{[key: string]: any}>({})

  const comp = (a: string | number, b: string | number) => {
    if (a > b) return 1
    if (a < b) return -1
    return 0
  }
  const compareFunc = (a: KanjiLearningHistoryTestEntity, b: KanjiLearningHistoryTestEntity) => {
    const user_a = getUserFromUuid!(a.user_uuid!);
    const user_b = getUserFromUuid!(b.user_uuid!);
    return (
      comp(user_a!.grade!, user_b!.grade!) ||
      comp(user_a!.class_name!, user_b!.class_name!) ||
      comp(user_a!.attendance_no!, user_b!.attendance_no!) ||
      comp(user_a!.user_name!, user_b!.user_name!)
    );
  };

  const sortByUserData = (data: KanjiLearningHistoryTestEntity[] | undefined) => {
    if (data === undefined) {
      return [];
    }
    if (data.length > 0) {
      return data.sort(compareFunc);
    }
    return [];
  }
  const is_same_request = (request: any) => {
    if (request.grade !== prevRequest.grade) return false;
    if (request.class_name !== prevRequest.class_name) return false;
    if (request.user_uuid !== prevRequest.user_uuid) return false;
    if (request.type !== prevRequest.type) return false;
    if (request.category_id !== prevRequest.category_id) return false;
    if (request.teaching_unit_id !== prevRequest.teaching_unit_id) return false;
    return true;
  }

  const query = async ({
    class_index,
    category_index,
    ...params
  }: LearningHistoryKanjiSkillTestQueryFormSchema) => {
    const schoolClass = classIndexToClass(class_index) as {
      grade: number;
      class_name: string | undefined;
    };
    // ユーザー情報を取得
    const user = getCurrentUser?.();
    if (!user) return;
    const [type, category_id, saved_teaching_unit_id] = get_current_type_category_id(user, category_index, categories);
    const teaching_unit_id = params.teaching_unit_id || saved_teaching_unit_id;
    const request = {
      ...params,
      type,
      category_id,
      teaching_unit_id,
      ...schoolClass,
      page: 1,
    };
    if (is_same_request(request)) {
      return;
    }
    const workingRequest = Object.assign({}, request);
    setPrevRequest(workingRequest);
    await kanjiSkillTestLearningHistoriesGet(request).then((res) => {
      if (res.status === 200) {
        setTeachingUnits(res.data.teaching_units || []);
        if (categories.length < 1) {
          setCategories(res.data.categories ? res.data.categories : []);
          if (res.data.category && res.data.category.category_id !== null) {
            category_index = get_category_index_from_type_id(res.data.category.type, res.data.category.category_id, res.data.categories);
          }
          else {
            category_index = get_category_index_from_type_id(type, category_id, res.data.categories);
          }
          methods.setValue("category_index", category_index);
        }
        const save_teaching_unit_id = (res.data.learning_histories?.[0]?.teaching_unit_serial_number) ?
          res.data.learning_histories?.[0]?.teaching_unit_serial_number :
          teaching_unit_id ? teaching_unit_id : res.data.teaching_units?.[0]?.teaching_unit_id;
        if (res.data.category) {
          save_category_selection(category_index, res.data.category.type, res.data.category.category_id, save_teaching_unit_id);
          setType(res.data.category.type);
        }
        else {
          save_category_selection(category_index, type, category_id, save_teaching_unit_id);
          setType(type);
        }
        const teaching_unit = res.data.teaching_units?.find(
          (u) => u.teaching_unit_id === save_teaching_unit_id,
        );
        if (!teaching_unit) return;
        // APIで児童設定された単元の値をセット
        methods.setValue('teaching_unit_id', save_teaching_unit_id);
        workingRequest.teaching_unit_id = save_teaching_unit_id;
        setData(sortByUserData(res.data.learning_histories?.[0]?.details) || []);
        setColumns(generateColumns(teaching_unit, res.data.learning_histories[0]));
      }
      return res;
    }).catch((error) => {
      console.log(error)
    });
  };

  const methods = useForm<LearningHistoryKanjiSkillTestQueryFormSchema>({
    defaultValues: {
      class_index: historyQueryConditions.class_index == undefined ? 0 : historyQueryConditions.class_index,
    },
    resolver: zodResolver(learningHistoryKanjiSkillTestQueryFormSchema),
  });

  const download = async () => {
    const { class_index, category_index, ...params } = methods.getValues();
    const category = categories[category_index || 0];
    const schoolClass = classIndexToClass(class_index) as {
      grade: number;
      class_name: string | undefined;
    };
    // ユーザー情報を取得
    const user = getCurrentUser?.();
    if (!user) return;
    const [type, category_id, saved_teaching_unit_id] = get_current_type_category_id(user, category_index, categories);
    const teaching_unit_id = params.teaching_unit_id || saved_teaching_unit_id;
    return withSaveFile(
      csvKanjiSkillTestLearningHistoriesCsvGet({
        ...params,
        type,
        category_id,
        teaching_unit_id,
        ...schoolClass,
        page: 1,
      }),
    );
  };

  useSyncHistoryQueryConditions(['class_index'], methods.watch);
  // 値の保持
  const [
    class_index,
    category_select_index,
    teaching_unit_id
  ] = methods.watch([
    'class_index', 
    'category_index', 
    'teaching_unit_id'
  ]);

  useEffect(() => {
    if (loaded) {
      query(methods.getValues());
    }
  }, [class_index, type, teaching_unit_id, loaded]);

  useEffect(() => {
    const user = getCurrentUser?.();
    if (!user) return;
    const [selected_type, _category_id, saved_teaching_unit_id] = get_current_type_category_id(user, category_select_index, categories);
    setType(selected_type);
    methods.setValue("teaching_unit_id", saved_teaching_unit_id);
  }, [category_select_index]);

  useEffect(() => {
    if (!uuid || !getCurrentUser) return;
    const user = getCurrentUser();
    if (!user) return;
    // セッションストレージから category_index を取得（存在しない場合はデフォルト値 '0' を使用）
    const storedCategorySelection = get_category_selection();
    const category_index = parseInt(storedCategorySelection.category_index || -1);
    methods.setValue('category_index', category_index);
    setLoaded(true);
  }, [uuid]);

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSubRows: (row) => getSubRows(row) as any,
  });

  return {
    hasLoaded: hasLoaded && categories.length > 0 && type !== undefined,
    table,
    schoolClassList,
    childrenList,
    categories,
    teachingUnits,
    type,
    methods,
    onSubmit: methods.handleSubmit(query),
    download,
  };
};

const SUBROW_KEYS = ['pretest', 'test', 'matome'] as const;
const SUBROW_LABELS = {
  pretest: 'プレ\nテスト',
  test: 'テスト',
  matome: 'まとめ',
} as const;
const NUM_OF_SUBROWS = 4;
const parseResults = (results: any) => {
  return results.map((r: any, _i: number) => {
    if (r['judgement'] == '×') {
      return '×';
    }
    if (r['judgement'] == '丸') {
      return '○';
    }
    if (r['judgement'] == '花丸') {
      return '◎';
    }
    return r['judgement'];
  });
};
const getSubRows = (row: RowData): SubRowData[] => {
  const common = {
    user_uuid: row.user_uuid!,
    school_class: row.school_class,
  };
  return SUBROW_KEYS.flatMap((key) => {
    const data = row[key];
    if (!data) return [];
    const type = SUBROW_LABELS[key];
    return Array.from({ length: NUM_OF_SUBROWS }).map((_, i) => ({
      ...common,
      type,
      // @ts-ignore
      frequency_type: data[`frequency_type_${i + 1}`] || '',
      // @ts-ignore
      historical_date: data[`historical_date_${i + 1}`] || '',
      // @ts-ignore
      results: parseResults(data[`result_${i + 1}`]) || [],
      // @ts-ignore
      total: data[`total_${i + 1}`] || '',
    }));
  });
};
