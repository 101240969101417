import { UserCore } from '@/src/__generated__';
import { useTeacherContext } from '@/src/hooks/useTeacherContext';
import {
  ColumnFiltersState,
  SortingState,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { COLUMN_DEFS } from './columns';

export const useStudentList = () => {
  const [selectedChild, setSelectedChild] = useState<UserCore>();

  const {
    hasLoaded,
    childrenList,
    schoolClassList,
    historyQueryConditions,
    updateHistoryQueryConditions,
  } = useTeacherContext();

  const columnFilters = useMemo<ColumnFiltersState>(() => {
    const filters: ColumnFiltersState = [];
    const { studentName } = historyQueryConditions;
    if (studentName) {
      filters.push({ id: COLUMN_DEFS.name.id!, value: studentName });
    }
    return filters;
  }, [schoolClassList, historyQueryConditions]);

  const [sorting, setSorting] = useState<SortingState>([
    { id: COLUMN_DEFS.grade.id!, desc: false },
    { id: COLUMN_DEFS.class_name.id!, desc: false },
    { id: COLUMN_DEFS.attendance_no.id!, desc: false },
  ]);

  const table = useReactTable({
    columns: [
      COLUMN_DEFS.id,
      COLUMN_DEFS.name,
      COLUMN_DEFS.grade,
      COLUMN_DEFS.class_name,
      COLUMN_DEFS.attendance_no,
    ],
    data: childrenList,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: { columnFilters, sorting },
    onSortingChange: setSorting,
    enableSorting: true,
    enableMultiSort: true,
    meta: {
      onClick: setSelectedChild,
    },
  });

  return {
    hasLoaded,
    table,
    schoolClassList,
    historyQueryConditions,
    updateHistoryQueryConditions,
    selectedChild,
    setSelectedChild,
  };
};
