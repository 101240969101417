import {
  csvDictionariesKanjiLearningHistoriesCsvGet,
  dictionariesKanjiLearningHistoriesGet,
} from '@/src/__generated__';
import { withSaveFile } from '@/src/helpers/file';
import { useSyncHistoryQueryConditions } from '@/src/hooks/useHistoryQueryConditions';
import { useInfiniteScrollTable } from '@/src/hooks/useInifniteScrollTable';
import { useTeacherContext } from '@/src/hooks/useTeacherContext';
import {
  LearningHistoryKanjiDictionaryQueryFormSchema,
  learningHistoryKanjiDictionaryQueryFormSchema,
} from '@/src/schema/learning-history-kanji-dictionary-query-form-schema';
import { zodResolver } from '@hookform/resolvers/zod';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useForm } from 'react-hook-form';
import { COLUMNS } from './columns';
import { useEffect } from 'react';

export const useLearningHistoryKanjiDictionary = () => {
  const {
    hasLoaded,
    childrenList,
    schoolClassList,
    historyQueryConditions,
    classIndexToClass,
  } = useTeacherContext();

  const query = async ({
    class_index,
    page = 1,
  }: LearningHistoryKanjiDictionaryQueryFormSchema) => {
    const res = await dictionariesKanjiLearningHistoriesGet({
      ...classIndexToClass(class_index),
      page,
    });
    if (res.status !== 200) {
      // TODO handle error
      return [];
    }
    return res.data.learning_histories;
  };

  const methods = useForm<LearningHistoryKanjiDictionaryQueryFormSchema>({
    defaultValues: {
      class_index: historyQueryConditions.class_index,
    },
    resolver: zodResolver(learningHistoryKanjiDictionaryQueryFormSchema),
  });

  const download = () => {
    const { class_index } = methods.getValues();
    return withSaveFile(
      csvDictionariesKanjiLearningHistoriesCsvGet({
        ...classIndexToClass(class_index),
      }),
    );
  };

  useSyncHistoryQueryConditions(['class_index'], methods.watch);

  const class_index = methods.watch('class_index');
  const { refs, flatData, refetch } = useInfiniteScrollTable(
    [class_index],
    (page) => query({ class_index, page }),
  );

  useEffect(() => {
    refetch();
  }, [class_index])

  const table = useReactTable({
    columns: COLUMNS,
    data: flatData,
    getCoreRowModel: getCoreRowModel(),
  });

  return {
    refs,
    hasLoaded,
    table,
    schoolClassList,
    childrenList,
    methods,
    onSubmit: methods.handleSubmit(() => refetch()),
    download,
  };
};
