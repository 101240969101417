:global(#app) {
  height: 100%;
}

.body {
  margin-top: 25px;

  display: flex;
  flex-direction: column;
  width: 100%;
}

.body .statistics {
  margin-top: 27px;
}

.content {
  flex: 1;
  padding: 0 50px 28px;
}

.nav {
  position: relative;
  /*padding: 51px 50px 30px; */
  background: var(--tomopla-yellow);
  /* width: 100%; */
  /* min-width: 1550px; */
  height: 210px;
}

.nav .menu {
  position: relative;
}

.label {
  position: absolute;
  top: -16px;
  left: 50px;
  color: var(--white);
  font-size: 20px;

  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px),
  (any-hover:none) {
    font-size: 1.25rem;
  }

  ;

  font-family: var(--main-font-family-db);
  line-height: 1;
  letter-spacing: 0.05em;
}

.label>div {
  position: relative;
  height: 32px;
  padding: 6px 0px 6px 10px;
  /* background-color: var(--tomopla-color); */
  border-radius: 4px 0 0 4px;
}

.label>div>span {
  position: relative;
}

.label>div::before {
  content: '';
  position: absolute;
  top: 0;
  right: -32px;
  width: 178px;
  bottom: 0;
  /* background-image: url(/static/img/rectangle-499.svg); */
  background-repeat: no-repeat;
}

.logo {
  position: absolute;
  top: 0;
  right: 0;
}

.statistics .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 2px solid var(--gray);


  font-family: var(--main-font-family-m);
  letter-spacing: 0.05em;
}

.statistics .header:last-child {
  font-size: 20px;
}

.statistics .header:last-child {
  font-size: 17px;
}

.statistics .body {
  margin-top: 36px;

  display: flex;
  flex-direction: row;
  column-gap: 42px;
  width: 100%;
}

.statistics .chart {
  position: relative;
  flex-shrink: 0;
  width: 640px;
  height: 519px;
  padding: 57px 40px 20px 43px;
  border: 1px solid var(--tomopla-color);
  border-radius: 16px;
  background: var(--bg-student-chart);
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.4);
}

.statistics .chart .label {
  left: 22px;
}

.statistics .chart .caption {
  position: absolute;
  top: 23px;
  left: 22px;

  display: flex;
  align-items: center;
  column-gap: 10px;

  font-family: var(--main-font-family-db);
  font-size: 13px;

}

.statistics .chart .period {
  position: absolute;
  top: 26px;
  right: 62px;

  font-family: var(--main-font-family-db);
  font-size: 16px;

  letter-spacing: 0.05em;
}

.statistics .chart .period>span {
  margin: 0 4.5px;
}

.statistics .chart .scaling {
  position: absolute;
  top: 11px;
  right: 13px;

  background: none;
  border: none;
  cursor: pointer;
}

.statistics .chart .main {
  width: 100%;
  height: 100%;
}

.statistics .records {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 14px;
  position: relative;
}

.statistics .records .blank {
  position: absolute;
  top: 345px;
  right: 0;
  /* opacity: 0;
  animation-delay: 2s;
  animation: fadeIn 2s ease-out; */
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.statistics .records .item {
  position: relative;
  display: flex;
  padding: 14px 31px 9px 30px;
  border-radius: 4px;
  background: var(--tomopla-color-light);
  box-shadow: 3px 2px 3px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.statistics .records .item.linked {
  background: linear-gradient(90deg, #f9d0c7 -23.77%, #f9866b 100%);
}

.statistics .records .item.linked .value span {
  color: var(--white);
}

.statistics .records .item::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 15px;
  background-color: var(--top-record-edge);
}

.statistics .records .item .icon {
  position: absolute;
  top: 10px;
  right: 25px;
  width: 18px;
}

.statistics .records .item .caption {
  font-family: var(--main-font-family-db);
  font-size: 26px;

  line-height: 1;
  color: var(--letter-black);
  letter-spacing: 0.05em;
}

.statistics .records .item .caption> :last-child {
  margin-top: 9px;

  font-family: var(--main-font-family-m);
  font-size: 15px;

  letter-spacing: 0.05em;
}

.statistics .records .item .value {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex: 1;
  padding: 0 38px 0 14px;
  overflow: hidden;
}

.statistics .records .item .value>span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  color: var(--tomopla-color);
  font-family: var(--main-font-family-b);
  font-size: 50px;

  letter-spacing: 0.03em;
}

.statistics .records .item .value .unit {
  position: absolute;
  right: 0;
  bottom: 5px;
  color: var(--letter-black);
  font-family: var(--main-font-family-db);
  font-size: 22px;

}

.modal {
  display: grid;
  place-content: center;
  height: 100%;
  padding: 8px 0 24px;
}

.appMenuWrapper {
  height: 140px;
  left: 30px;
  position: relative;
  top: 40px;
  width: 1630px;
  display: flex;
  justify-content: space-between;
}