import {
  csvRomajiSkillLearningHistoriesCsvGet,
  romajiSkillLearningHistoriesGet,
} from '@/src/__generated__';
import { withSaveFile } from '@/src/helpers/file';
import { useHistoryLocation } from '@/src/hooks/useHistoryNavigation';
import { useSyncHistoryQueryConditions } from '@/src/hooks/useHistoryQueryConditions';
import { useTeacherContext } from '@/src/hooks/useTeacherContext';
import {
  LearningHistoryRomajiSkillQueryFormSchema,
  learningHistoryRomajiSkillQueryFormSchema,
} from '@/src/schema/learning-history-romaji-skill-query-form-schema';
import { zodResolver } from '@hookform/resolvers/zod';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { COLUMN_DEFS, RowData } from './columns';

export const useLearningHistoryRomajiSkill = () => {
  const {
    hasLoaded,
    childrenList,
    schoolClassList,
    historyQueryConditions,
    classIndexToClass,
  } = useTeacherContext();

  const [data, setData] = useState<RowData[]>([]);

  const query = async ({
    class_index,
    ...params
  }: LearningHistoryRomajiSkillQueryFormSchema) => {
    const schoolClass = classIndexToClass(class_index) as {
      grade: number;
      class_name: string | undefined;
    };
    // ユーザ指定時にクラス指定とのズレチェック
    if (params.user_uuid && schoolClass) {
      const [selected_user] = childrenList.filter((child) => child.user_uuid == params.user_uuid);
      if (!selected_user) return;
      if (schoolClass.grade && schoolClass.grade !== selected_user.grade) return;
      if (schoolClass.class_name && schoolClass.class_name !== selected_user.class_name) return;
    }

    const res = await romajiSkillLearningHistoriesGet({
      ...params,
      time_slot: Number.isInteger(params.time_slot) ? params.time_slot : undefined,
      user_uuid: params.user_uuid || undefined,
      ...(params.user_uuid ? {} : classIndexToClass(class_index)),
    }).then((res) => {
      if (res.status === 200) {
        methods.clearErrors();
        setData(res.data.learning_histories);
        
      }
    }).catch((error) => {
      if (error.response.status === 400) {
        methods.setError('to_date', { type: 'custom', message: '終了日が開始日より前の日付になっています。' })
      }
    })
  };

  // 児童詳細からの遷移
  const { state: locationState } = useHistoryLocation();
  const methods = useForm<LearningHistoryRomajiSkillQueryFormSchema>({
    defaultValues: locationState
      ? {
        ...historyQueryConditions,
        class_index: locationState.class_name
          ? schoolClassList.findIndex(
            (e) =>
              e.grade === locationState.grade &&
              e.class_name === locationState.class_name,
          )
          : undefined,
        user_uuid: locationState.user_uuid,
      }
      : historyQueryConditions,
    resolver: zodResolver(learningHistoryRomajiSkillQueryFormSchema),
  });

  const download = () => {
    const { class_index, ...params } = methods.getValues();
    return withSaveFile(
      csvRomajiSkillLearningHistoriesCsvGet({
        ...params,
        time_slot: Number.isInteger(params.time_slot) ? params.time_slot : undefined,
        user_uuid: params.user_uuid || undefined,
        ...(params.user_uuid ? {} : classIndexToClass(class_index)),
      }),
    );
  };

  useSyncHistoryQueryConditions(
    ['class_index', 'user_uuid', 'from_date', 'to_date', 'time_slot'],
    methods.watch,
  );

  useEffect(() => {
    query(methods.getValues());
  }, []);

  const [class_index, user_uuid, from_date, to_date, time_slot] = methods.watch(['class_index', 'user_uuid', 'from_date', 'to_date', 'time_slot'])
  useEffect(() => {
    query(methods.getValues());
  }, [class_index, user_uuid, from_date, to_date, time_slot])



  const table = useReactTable({
    columns: [
      COLUMN_DEFS.name,
      COLUMN_DEFS.date,
      COLUMN_DEFS.total_number,
      COLUMN_DEFS.total_keystrokes,
      COLUMN_DEFS.average_keystroke_speed,
      COLUMN_DEFS.average_time,
    ],
    data,
    getCoreRowModel: getCoreRowModel(),
  });

  return {
    hasLoaded,
    table,
    schoolClassList,
    childrenList,
    methods,
    onSubmit: methods.handleSubmit(query),
    download,
  };
};
