.body {
  margin-top: 20px;
  margin-left: 40px;
}

.table {
  margin-top: 22px;
  width: 1480px;
  /* border: 1px solid var(--line-color); */
  border-radius: 4px;
  /* overflow: hidden; */
  z-index: 0;
}

.buttons {
  margin-left: 15px;
  display: flex;
  column-gap: 15px;
}
