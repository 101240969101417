export const ErrorInvalidDateRange = () => (
  <>
    <ruby>
      終<rt>しゅう</rt>
    </ruby>
    <ruby>
      了<rt>りょう</rt>
    </ruby>
    <ruby>
      日<rt>び</rt>
    </ruby>
    が
    <ruby>
      開<rt>かい</rt>
    </ruby>
    <ruby>
      始<rt>し</rt>
    </ruby>
    <ruby>
      日<rt>び</rt>
    </ruby>
    より
    <ruby>
      前<rt>まえ</rt>
    </ruby>
    の
    <ruby>
      日<rt>ひ</rt>
    </ruby>
    <ruby>
      付<rt>づけ</rt>
    </ruby>
    になっています。
  </>
);
