.select-label-container {
  display: flex;
  align-items: center;
  column-gap: 20px;
}
.select-label-container .label {
  font-size: 24px;
  font-family: var(--main-font-family-db);
  
  line-height: 28.5px;
  letter-spacing: 0.1em;
  color: var(--letter-black);
}

.select {
  display: block;
  position: relative;

  width: 380px;
  height: 45px;
  padding: 3px 44px 4px 15px;
  border-radius: 4px;
  border: 1px solid var(--line-color);

  background: var(--white);
  color: var(--letter-black);

  font-size: 24px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.375rem;};
  font-family: var(--main-font-family-db);
  
  line-height: 1;
  letter-spacing: 0.1em;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  z-index: 20;
  cursor: pointer;
}

.select div .sm{
  font-size: small;
}

.select .toggle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 11.5px;
}

.select .toggle.open {
  transform: translateY(-50%) rotate(180deg);
}

.select.disabled {
  opacity: 0.5;
  background: var(--gray);
  color: var(--letter-black);
  cursor: not-allowed;
}

.no-ruby {
  padding-top: 0;
  padding-bottom: 0;
  align-items: center;
  height: 52px;
}

.popper {
  padding-right: 10px;
  border-radius: 4px;
  border: 1px solid var(--line-color);
  background: var(--white);
  color: var(--letter-black);
  z-index: 15;
  overflow: hidden;

  font-size: 18px;
  font-family: var(--main-font-family-db);
  
  margin: 12px 0;

  cursor: pointer;
}
.popper[data-popper-placement^='top'] {
  bottom: 6px !important;
}
.popper[data-popper-placement^='bottom'] {
  top: 6px !important;
}
.listbox {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  max-height: 327px;
  padding: 10px 14px 10px 7px;
  overflow: auto;
  background-color: #fff;
  font-size: 1.25em;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1rem;};
  font-family: var(--main-font-family-db);
}

.listbox::-webkit-scrollbar {
  background-color: transparent;
  width: 9px;
}
.listbox::-webkit-scrollbar-track {
  background-color: transparent;
  margin: 6px 0;
}
.listbox::-webkit-scrollbar-thumb {
  background-color: var(--line-color);
  border-radius: 5px;
  max-height: 40px;
}
.listbox li:first-child {
  padding: 0 8px 6px;
}
.listbox li {
  padding: 6px 8px;
}
.listbox li:hover,
.listbox li:global(.Mui-selected) {
  border-radius: 5.2px;
  background-color: var(--light-blue-2);
}

.listbox li:global(.Mui-disabled) {
  opacity: 0.5;
  background: var(--gray);
  color: var(--letter-black);
  cursor: not-allowed;
}
