import {
  HistoryQueryFormStudent,
  StudentHistorySelect,
} from '@/src/components/HistoryQueryForm';
import { Table } from '@/src/components/Table';
import { StudentFrame } from '@/src/components/layouts/StudentFrame';
import { FormProvider } from 'react-hook-form';
import { WordLists } from './WordLists';
import { useLearningHistoryJapaneseDictionary } from './hooks';
import styles from './styles.module.css';
import { useTheme } from 'styled-components';
import styled from 'styled-components';
import { useEffect } from 'react';
import { profileAtom, themeAtom } from '@/src/components/ModalContents/modalAtom';
import { useRecoilState } from 'recoil';
import { useToppageStudent } from '../StudentTop/hook';


const Div2 = styled('div')(
  ({ theme }) => `
    background-color: var(${theme.backgroud});
    height: 1080px;
    position: relative;
  `
)

type Props = {
  onThemeChange: (status: number) => void
}

export const StudentHistoryDictionary = (props: Props): JSX.Element => {
  const { currentUser } = useToppageStudent();
  const [themenum, setThemenum] = useRecoilState(themeAtom)
  const [profiles, setProfiles] = useRecoilState(profileAtom)
  const { table, rankingItems, randomItems, methods, onSubmit, queryRandom } =
    useLearningHistoryJapaneseDictionary();

  useEffect(() => {
    if (currentUser) {
      if (!themenum) {
        setThemenum(currentUser?.preference.theme_color);
      }
      const _profiles = {
        nick_name: profiles.nick_name ? profiles.nick_name : currentUser?.nick_name,
        profile_icon: profiles.profile_icon && profiles.profile_icon !== currentUser?.preference.profile_icon ? profiles.profile_icon : currentUser?.preference.profile_icon
      }
      if (
        _profiles.nick_name !== profiles.nick_name ||
        _profiles.profile_icon !== profiles.profile_icon
      ) {
        setProfiles(_profiles);
      }
    }
    
  }, [currentUser, profiles, themenum]);

  useEffect(() => {
    if (currentUser && typeof themenum === 'number') {
      props.onThemeChange(themenum);
    }
  }, [themenum, currentUser]);

  const theme = useTheme();

  return (
    <StudentFrame title="学習りれき">
      <div className={styles.body}>
        <HistoryQueryFormStudent className={styles['history-select-container']}>
          <StudentHistorySelect />
        </HistoryQueryFormStudent>
        <Table className={styles.table} table={table} theme={theme} />
        <FormProvider {...methods}>
          <WordLists
            rankingItems={rankingItems}
            randomItems={randomItems}
            onSubmit={onSubmit}
            queryRandom={queryRandom}
          />
        </FormProvider>
      </div>
    </StudentFrame>
  );
};
