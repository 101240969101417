import { LibsDomainModelInterfaceKanjiGetKanjiSkillLearningHistoriesResponse } from '@/src/__generated__';
import { formatToDateDisplay } from '@/src/helpers/date';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import styles from './styles.module.css';

export type RowData = {
  user_uuid?: LibsDomainModelInterfaceKanjiGetKanjiSkillLearningHistoriesResponse['user_uuid'];
  school_class?: LibsDomainModelInterfaceKanjiGetKanjiSkillLearningHistoriesResponse['school_class'];
  daily_datum: Partial<
    LibsDomainModelInterfaceKanjiGetKanjiSkillLearningHistoriesResponse['daily_data'][0]
  >;
  isTotal?: boolean;
};

export const generateColumns = (
  data: RowData | undefined,
): ColumnDef<RowData>[] => [
    COLUMN_DEFS.historical_date,
    COLUMN_DEFS.total_number,
    COLUMN_DEFS.access_data(data),
  ];

const COLUMN_DEFS = {
  historical_date: {
    accessorKey: 'daily_datum.date',
    size: 180,
    meta: { stickyLeft: 0 },
    cell: ({ cell }: CellContext<any, any>) => {
      const value = cell.getValue();
      if (cell.row.original.isTotal)
        return (
          <div>
            <ruby>
              合<rt>ごう</rt>
            </ruby>
            <ruby>
              計<rt>けい</rt>
            </ruby>
          </div>
        );
      if (!value) return;
      return formatToDateDisplay(value as any);
    },
    header: () => (
      <div>
        <ruby>
          日<rt>ひ</rt>
        </ruby>
        <ruby>
          付<rt>づけ</rt>
        </ruby>
      </div>
    ),
  },
  total_number: {
    accessorKey: 'daily_datum.total_number',
    size: 189,
    header: () => (
      <div>
        <div>
          アクセス
          <ruby>
            数<rt>すう</rt>
          </ruby>
        </div>
        <div>
          （
          <ruby>
            合<rt>ごう</rt>
          </ruby>
          <ruby>
            計<rt>けい</rt>
          </ruby>
          ）
        </div>
      </div>
    ),
  },
  access_data: (data: RowData | undefined) => ({
    accessorKey: 'access_data',
    columns:
      data?.daily_datum?.access_data?.map(
        ({ teaching_unit_number, teaching_unit_name, teaching_unit_type }, i) => ({
          id: teaching_unit_name,
          accessorKey: `daily_datum.access_data.${i}.number_studies`,
          header: (
            <div className={styles['teaching-unit']}>
              <div>{teaching_unit_type == 2 ? `(${teaching_unit_number})` : `【${teaching_unit_number}】`}</div>
              <div className={styles['teaching-unit-name']}>
                {teaching_unit_name.replace(/\\n/, ' ')}
              </div>
            </div>
          ),
          meta: { align: 'right' },
        }),
      ) || [],
    header: () => (
      <div>
        <ruby>
          各<rt>かく</rt>
        </ruby>
        <ruby>
          単<rt>たん</rt>
        </ruby>
        <ruby>
          元<rt>げん</rt>
        </ruby>
        のアクセス
        <ruby>
          数<rt>すう</rt>
        </ruby>
      </div>
    ),
  }),
};
