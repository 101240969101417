import Button, { ButtonProps } from '@mui/material/Button';
import clsx from 'clsx';
import './MainButton.css';

export type MainButtonProps = ButtonProps & {};

const MainButton = ({ className, children, ...props }: MainButtonProps) => {
  return (
    <Button className={clsx(className, 'main-button')} {...props}>
      {children}
    </Button>
  );
};

export default MainButton;
