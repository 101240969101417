import {
  WordsLookedUpRankingGetParams,
  dictionariesJapaneseLearningHistoriesGet,
  wordsLookedUpRandomGet,
  wordsLookedUpRankingGet,
} from '@/src/__generated__';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { ReactNode, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { COLUMN_DEFS, RowData } from './columns';

export const useLearningHistoryJapaneseDictionary = () => {
  const [tableData, setTableData] = useState<NonNullable<RowData[]>>([]);
  const [rankingItems, setRankingItems] = useState<
    { rank: number; word: ReactNode }[]
  >([]);
  const [randomItems, setRandomItems] = useState<
    { caption: ReactNode; word: ReactNode }[]
  >([]);

  const table = useReactTable({
    columns: [
      COLUMN_DEFS.last_action_date,
      COLUMN_DEFS.active_time,
      COLUMN_DEFS.total_number_word,
      COLUMN_DEFS.total_number_labels,
      COLUMN_DEFS.total_number_word_quiz,
      COLUMN_DEFS.total_number_correct,
      COLUMN_DEFS.total_number_my_dictionary,
      COLUMN_DEFS.total_number_medals,
    ],
    data: tableData,
    getCoreRowModel: getCoreRowModel(),
  });

  const query = async () => {
    const res = await dictionariesJapaneseLearningHistoriesGet({ page: 1 });
    if (res.status !== 200) {
      // TODO handle error
      return;
    }
    setTableData(res.data.learning_histories.slice(0, 1));
  };

  const queryRanking = async (params: WordsLookedUpRankingGetParams) => {
    const res = await wordsLookedUpRankingGet(params);
    if (res.status !== 200) {
      // TODO handle error
      return;
    }
    setRankingItems([
      { rank: 1, word: res.data.first },
      { rank: 2, word: res.data.second },
      { rank: 3, word: res.data.third },
    ]);
  };

  const queryRandom = async () => {
    const res = await wordsLookedUpRandomGet();
    if (res.status !== 200) {
      // TODO handle error
      return;
    }
    setRandomItems(
      [
        { caption: res.data.words1?.researchers, word: res.data.words1?.words },
        { caption: res.data.words2?.researchers, word: res.data.words2?.words },
        { caption: res.data.words3?.researchers, word: res.data.words3?.words },
        { caption: res.data.words4?.researchers, word: res.data.words4?.words },
        { caption: res.data.words5?.researchers, word: res.data.words5?.words },
        { caption: res.data.words6?.researchers, word: res.data.words6?.words },
      ]
        .filter((item) => item.word)
        .map((item) => ({ ...item, caption: item.caption || '-' })),
    );
  };

  const methods = useForm<WordsLookedUpRankingGetParams>({
    defaultValues: { target_name: 3, term_name: 2 },
  });

  const [target_name, term_name] = methods.watch([
    'target_name',
    'term_name',
  ]);
  useEffect(() => {
    queryRanking(methods.getValues());
  }, [target_name, term_name]);

  const reload = () => {
    query();
    queryRanking(methods.getValues());
    queryRandom();
  };

  useEffect(() => {
    reload();
  }, []);

  return {
    table,
    rankingItems,
    randomItems,
    methods,
    onSubmit: methods.handleSubmit(queryRanking),
    queryRandom,
  };
};
