import { useTeacherContext } from '@/src/hooks/useTeacherContext';
import clsx from 'clsx';
import { FormEventHandler, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from './constants';
import {
  DateTimeConditionsGroup,
  DateTimeConditionsGroupProps,
  StudentConditionsGroup,
  StudentConditionsGroupProps,
} from './groups';
import styles from './styles.module.css';
import styled, { DefaultTheme } from 'styled-components';

type HistoryQueryFormTeacherProps = {
  className?: string;
  children?: ReactNode;
  onSubmit?: FormEventHandler<HTMLFormElement>;
  states?: StudentConditionsGroupProps['states'] &
  DateTimeConditionsGroupProps['states'];
  theme?: DefaultTheme;
};

const Form = styled('form')(
  ({ theme }) => `
    .label{
      color:var(${theme.textcolor})!important;
    }
    input{
      color:var(${theme.textlabel})!important;
    }
  `
)


export const HistoryQueryFormTeacher = ({
  onSubmit,
  children,
  className,
  states,
}: HistoryQueryFormTeacherProps) => {
  const {
    childrenList,
    schoolClassList,
    historyQueryConditions,
    updateHistoryQueryConditions,
  } = useTeacherContext();
  const navigate = useNavigate();
  return (
    <Form onSubmit={onSubmit} className={clsx(className, styles.form)}>
      <div className={styles.conditions}>
        <StudentConditionsGroup
          schoolClassList={schoolClassList}
          childrenList={childrenList}
          searchStudent={(value) => {
            updateHistoryQueryConditions({ studentName: value });
            navigate(ROUTES.teacher.studentlist);
          }}
          defaultValues={{
            studentName: historyQueryConditions.studentName,
          }}
          states={states}
        />
        <DateTimeConditionsGroup states={states} />
        {children}
      </div>
    </Form>
  );
};
