import {
  HistoryQueryFormStudent,
  StudentHistorySelect,
} from '@/src/components/HistoryQueryForm';
import { Table } from '@/src/components/Table';
import { StudentFrame } from '@/src/components/layouts/StudentFrame';
import { useLearningHistoryKanjiDictionary } from './hooks';
import { useTheme } from "styled-components";
import styles from './styles.module.css';
import { themeAtom, profileAtom } from '@/src/components/ModalContents/modalAtom';
import { useRecoilState } from 'recoil';
import { useToppageStudent } from '../StudentTop/hook';
import { useEffect } from 'react';


type Props = {
  onThemeChange: (status: number) => void
}

export const StudentHistoryDictionaryKanji = (props:Props): JSX.Element => {
  const { currentUser } = useToppageStudent();
  const [themenum, setThemenum] = useRecoilState(themeAtom)
  const [profiles, setProfiles] = useRecoilState(profileAtom)
  const { table } = useLearningHistoryKanjiDictionary();
  
  useEffect(() => {
    if (currentUser) {
      if (!themenum) {
        setThemenum(currentUser?.preference.theme_color);
      }
      const _profiles = {
        nick_name: profiles.nick_name ? profiles.nick_name : currentUser?.nick_name,
        profile_icon: profiles.profile_icon && profiles.profile_icon !== currentUser?.preference.profile_icon ? profiles.profile_icon : currentUser?.preference.profile_icon
      }
      if (
        _profiles.nick_name !== profiles.nick_name ||
        _profiles.profile_icon !== profiles.profile_icon
      ) {
        setProfiles(_profiles);
      }
    }
    
  }, [currentUser, profiles, themenum]);

  useEffect(() => {
    if (currentUser && typeof themenum === 'number') {
      props.onThemeChange(themenum);
    }
  }, [themenum, currentUser]);

  const theme = useTheme();
  return (
    <StudentFrame title="学習りれき">
      <div className={styles.body}>
        <HistoryQueryFormStudent className={styles['history-select-container']}>
          <StudentHistorySelect />
        </HistoryQueryFormStudent>
        <Table className={styles.table} table={table} />
      </div>
    </StudentFrame>
  );
};
