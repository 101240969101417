import clsx from 'clsx';
import { addMonths, subMonths } from 'date-fns';
import { useState } from 'react';
import styles from './CustomHeader.module.css';
import { CustomControlProps } from './types';

export const CustomHeader = ({
  currentMonth,
  setCurrentMonth,
}: CustomControlProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  return (
    <div className={styles['calendar-header']}>
      <button
        type="button"
        onClick={() => setCurrentMonth(subMonths(currentMonth, 1))}
        disabled={subMonths(currentMonth, 1).getFullYear() < 1970}
      >
        <img src="/img/vector-arrow.svg" />
      </button>
      <div className={styles.current}>
        <button
          type="button"
          onClick={() => setIsMenuOpen((prev) => !prev)}
          className={clsx(isMenuOpen && styles.open)}
        >
          {currentMonth.getMonth() + 1}月<span>▼</span>
        </button>
        <ul className={clsx(styles['month-select'], isMenuOpen && styles.open)}>
          {Array.from({ length: 12 }).map((_, i) => (
            <li key={i}>
              <button
                type="button"
                className={clsx(
                  i === currentMonth.getMonth() && styles.current,
                )}
                onClick={() =>
                  setCurrentMonth(
                    new Date(
                      currentMonth.getFullYear(),
                      i,
                      currentMonth.getDate(),
                    ),
                  )
                }
              >
                {i + 1}月
              </button>
            </li>
          ))}
        </ul>
      </div>
      <button
        type="button"
        onClick={() => setCurrentMonth(addMonths(currentMonth, 1))}
        disabled={addMonths(currentMonth, 1).getFullYear() > 9999}
      >
        <img src="/img/vector-arrow.svg" />
      </button>
    </div>
  );
};
