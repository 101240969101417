import { IconButton } from '@mui/material';
import MainButton from '../../parts/MainButton';
import SubButton from '../../parts/SubButton';
import './style.css';
import { useEffect, useState } from 'react';
import { ModalSubButton } from '../../parts/ModalSubButton';
import { ModalDecisionButton } from '../../parts/ModalDecisionButton';
import { DefaultTheme } from 'styled-components/dist/types';
import styled from 'styled-components';
import { AvatarIconButton } from '../../parts/AvatarIconButton';
import { useAuthContext } from '@/src/middleware/auth/AuthContext';
import { useRecoilState } from 'recoil';
import { profileAtom } from '../modalAtom';
import Kanji from '../../auth/Kanji';

type Props = {
  handleChange: any,
  handleSelected: any,
  avator?: any,
  theme?: DefaultTheme
}

const Wrap = styled('div')(
  ({ theme }) => `
    background-color: var(${theme.backgroud})
    background-size: 100% 100%;
    height: 980px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1633px;
    .text-wrapper-281,.text-wrapper-282,.text-wrapper-283,.text-wrapper-284,.text-wrapper-285 {
      color: var(${theme.textcolor});
    }
  `
)

const CurrentAvatar = styled('div')(
  ({ theme }) => `
    background: linear-gradient(${theme.chart0} 0%, ${theme.chart1} 100%) border-box;
    border: 8px solid #0000;
    border-radius: 16px;
    width: 360px;
    height: 360px;
    position: absolute;
    top: 333px;
    left: 1052px;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    .group-260 {
      background-color: var(--white);
      border-radius: 11px;
      height: 344px;
      position: relative;
      width: 344px;
      .group-261 {
        height: 343px;
        left: 2px;
        position: absolute;
        top: 6px;
        width: 343px;
      }
    }
  `
)

export const TeacherAvatar = ({ handleChange, handleSelected, avator }: Props) => {
  const { getCurrentUser } = useAuthContext();
  const currentUser = getCurrentUser ? getCurrentUser() : null;

  const [val, setVal] = useState<any>();

  useEffect(() => {
    setVal(avator)
  },[])

  const handleClick = (i: number) => {
    setVal(i)
  }

  const AvatarGrid = () => {
    return (
      Array(30).fill({}).map((d, i) => {
        return (
          <div key={i} className="group-263">
            <div className="frame-24">
              <AvatarIconButton className={val === i ? 'select' : ''} onClick={() => handleClick(i)}>
                <img className='thumbnail' src={`/img/avatar-${i + 1}.png`} alt={`avatar${i + 1}`} />
              </AvatarIconButton>
            </div>
          </div>
        )
      })
    )
  }

  return (
    <Wrap className="teacher-avatar">
      <ModalDecisionButton className='main-button-10' onClick={() => { handleSelected(val) }} />
      <ModalSubButton className='sub-button' onClick={() => { handleChange(true) }} />
      <CurrentAvatar>
        <div className="group-260">
          <img className="group-261" alt="Group" src={`/img/avatar-${val + 1}.png`} />
        </div>
      </CurrentAvatar>
      <div className="avatar-boxshadow">&nbsp;</div>
      <img className="line-9" alt="Line" src="/img/line-10-5.svg" />
      <div className="select-avatar">
        <AvatarGrid />
      </div>
      <img className="line-10" alt="Line" src="/img/line-11-5.svg" />
      <div className="navbar-5">
        <div className="text-wrapper-281">プロフィール<Kanji kana="がぞう">画像</Kanji>を<Kanji kana="せんたく">選択</Kanji></div>
      </div>
    </Wrap>
  );
};
