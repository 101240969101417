import { Modal as MuiModal, ModalProps as MuiModaProps } from '@mui/material';
import clsx from 'clsx';
import { ModalCloseButton } from '../parts/ModalCloseButton';
import styles from './styles.module.css';

type ModalProps = MuiModaProps & {
  title: string;
  headerClassName?: string;
  titleClassName?: string;
};
export const Modal = ({
  title,
  children,
  className,
  headerClassName,
  titleClassName,
  ...props
}: ModalProps) => (
  <MuiModal {...props} className={clsx(className, styles['container'])}>
    <div className={styles['main']}>
      <div className={clsx(styles['header'], headerClassName)}>
        <div className={clsx(styles['title'], titleClassName)}>{title}</div>
        <ModalCloseButton
          onClick={(e) => props.onClose?.(e, 'backdropClick')}
        />
      </div>
      <div className={styles.body}>{children}</div>
    </div>
  </MuiModal>
);
