/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/
import { ImgWrapper } from '@/src/components/ImgWrapper';
import { TopBarModalMenu } from '@/src/components/parts/TopBarModalMenu';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import './style.css';

// types
import { useLicenseNavigator } from '@/src/hooks/useLicenseNavigator';
import { useAuthContext } from '@/src/middleware/auth/AuthContext';
import { useToppageStudent } from '@/src/screens/StudentTop/hook';
import { TopBar } from '@/src/types/TopBarFrame';
import { Menu } from '@/src/types/TopBarModalMenu';
import { Link } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled, { useTheme } from 'styled-components';
import { menuAtom, modalAtom } from '../../ModalContents/modalAtom';
import { LICENSES } from '../../HistoryQueryForm/constants';

// メニューの項目を定義
const menuItems = (redirectPath: string | null): Menu[] => [
  {
    text: 'プロフィール設定',
    type: 'modal',
    icon: '/img/icon-profile.svg',
    modalContent: <div>プロフィール設定</div>,
    className: 'ProfileSettings',
  },
  {
    text: '学習りれき',
    type: 'link',
    href: redirectPath || '/studenthistorykanji',
    icon: '/img/icon-history.svg',
  },
  {
    text: '表示設定',
    type: 'modal',
    icon: '/img/icon-theme.svg',
    modalContent: <div>表示設定</div>,
    className: 'VisualSettings',
  },
  {
    text: 'セキュリティ',
    type: 'modal',
    icon: '/img/icon-secure.svg',
    modalContent: <div>セキュリティ</div>,
    className: 'PasswordReset',
  },
  {
    text: 'ログアウト',
    type: 'modal',
    icon: '/img/icon-logout.svg',
    modalContent: <div>ログアウト</div>,
    className: 'Logout',
  },
];

const Div = styled('div')(
  ({ theme }) => `
    .user-avatar-wrapper{
      background: linear-gradient(180deg, #F9D1C7 0%, #F97253 100%) border-box border-box!important;
    }
    .name-extension{
      .name-part-5,.name-part-6{
        color:var(${theme.titlecolor})!important;
      }
    }
  `,
);

const BarTitle = styled('div')(
  ({ theme }) => `
    color: var(${theme.titlecolor});
    font-family: var(--main-font-family-db);
    letter-spacing: 2px;
    font-size: 40px;
    @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 2.5rem;};

    line-height: 20px;
    position: absolute;
    top: 46px;
    left: 301px;
  `,
);

const UserName = styled('div')(
  ({ theme }) => `
    color: var(${theme.textcolor});
    font-family: var(--main-font-family-db);
    font-size: 27px;
    @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.6875rem;};

    letter-spacing: 1.35px;
    line-height: 27px;
    margin-top: -1.5px;
    position: relative;
    text-align: right;
    white-space: nowrap;
    width: 176px;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
);

export const StudentTopBar = ({
  className,
  title,
  name,
  avator,
  override = (
    <ImgWrapper className="tomopla-logo" src="/img/tomopla-logo-1.png" />
  ),
}: TopBar): JSX.Element => {
  //const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useRecoilState(menuAtom);
  const openModalKey = useRecoilValue(modalAtom);
  const { isLoginByPassword, isLoginByCode } = useAuthContext();
  const { currentUser } = useToppageStudent();
  const theme = useTheme();

  const [isLicenseAll, setIslicenseAll] = useState<boolean>(true);

  // ライセンスの状態を取得
  const { redirectPath, hasCalculation } = useLicenseNavigator();
  console.log('Redirect Path:', redirectPath);
  console.log('Has Calculation Skills:', hasCalculation);
  
  useEffect(() => {
    // 比較対象の配列を定義
    const array: number[] = [
      // ...LICENSES.learning_contents.video,
      // ...LICENSES.learning_contents.cbt,
      // ...LICENSES.learning_contents.pbt,
      ...LICENSES.learning_contents.dictionary,
      ...LICENSES.learning_contents.dictionarykanji,
      ...LICENSES.learning_contents.romaji,
      ...LICENSES.learning_contents.kanji,
      ...LICENSES.learning_contents.hiragana_suuji,
      ...LICENSES.learning_contents.katakana,
      ...LICENSES.learning_contents.keisan,
    ];
    // ユーザーが所持しているライセンスの learning_contents を格納する配列を初期化
    const apps: number[] = [];
    // currentUser が存在する場合、以下の処理を実行
    currentUser?.license
      // status が 0以外のライセンスのみをフィルタリング
      .filter(({ status }) => status !== 0)
      // フィルタリングされたライセンスの learning_contents を apps 配列に追加
      .map((e: any) => {
        apps.push(e.learning_contents);
      });

    console.log('currentUserライセンス', currentUser?.license);

    // array と apps の内容を比較し、isLicenseAll の状態を更新
    // array と array から apps に含まれる要素を取り除いた配列が等しくない場合、isLicenseAll を true に設定
    // つまり、array に含まれる全てのライセンスを所持していない場合、isLicenseAll が true になる
    setIslicenseAll(
      JSON.stringify(array) !==
        JSON.stringify(array.filter((i) => apps.indexOf(i) == -1)),
    );
  }, []);

  // メニューModalの状態をトグルする関数
  const toggleMenu = () => {
    setIsModalOpen((prev) => !prev);
  };

  // メニューModalを閉じる
  useEffect(() => {
    if (openModalKey === null) {
      setIsModalOpen(false);
    }
  }, [openModalKey]);

  const menuItemsFilterByLicense = (redirectPath: string | null): Menu[] => {
    if (!isLicenseAll) {
      return menuItems(redirectPath).filter((i) => i.type !== 'link');
    }
    return menuItems(redirectPath);
  };
  const menuItemsFiltered = (redirectPath: string | null): Menu[] => {
    if (
      (isLoginByPassword && isLoginByPassword()) ||
      (isLoginByCode && isLoginByCode())
    ) {
      return menuItemsFilterByLicense(redirectPath);
    }
    return menuItemsFilterByLicense(redirectPath).filter(
      (i) => i.className !== 'PasswordReset',
    );
  };

  return (
    <Div className={`student-top-bar ${className}`}>
      {isModalOpen && (
        <TopBarModalMenu
          menuItems={menuItemsFiltered(redirectPath)}
          theme={theme}
          type="student"
          close={() => setIsModalOpen(false)}
        />
      )}
      <div className="user-menu">
        <div className="user-avatar-wrapper">
          <div className="avatar-overlay">
            <div className="avatar-bg">
              <div className="avatar-border" />
            </div>
            <img className="avatar-image" alt="Group" src={avator} />
          </div>
        </div>
        <div className="user-details" onClick={toggleMenu}>
          <UserName>{name}</UserName>
          <img
            className="dropdown-icon"
            alt="Vector"
            src={
              !isModalOpen ? `/img/${theme?.arrow}` : `/img/${theme?.arrowup}`
            }
          />
        </div>
      </div>
      <BarTitle>{title}</BarTitle>
      <div className="name-container">
        <div className="navbar-name">
          {title !== 'ホーム' && (
            <div className="name-extension">
              <div className="name-part-5">がく</div>
              <div className="name-part-6">しゅう</div>
            </div>
          )}
        </div>
      </div>
      {override}
      <Link to="/studenttop" className="tomopla-logo-link"></Link>
    </Div>
  );
};

StudentTopBar.propTypes = {
  vector: PropTypes.string,
};
