import { z } from 'zod';

const teachingMaterialSchema = z.object({
  teaching_materials_name: z.string(),
  purchases_number: z.number(),
});

export const licenseApplyFormSchema = z
  .object({
    prefecture_name: z.union([z.number(), z.string()]).optional().nullable(),
    municipalities_name: z
      .union([z.number(), z.string()])
      .optional()
      .nullable(),
    school_name: z.string().optional().nullable(),
    school_alt: z
      .string()
      .optional()
      .nullable(),
    person_name: z
      .string()
      .min(1, '「担当者名」が入力されていません。')
      .max(60, '担当者名の形式が正しくありません。'),
    tel: z
      .string()
      .optional()
      .nullable()
      .refine(
        (data) => {
          // null または undefined の場合はバリデーションをスキップ
          if (!data) return true;

          // 正規表現によるチェック
          // 例: 03-1234-5678, 090-1234-5678
          return /^\d{2,3}[-\s]?\d{3,4}[-\s]?\d{4}$/.test(data);
        },
        {
          message: '「電話番号」に入力された文字列が正しくありません。',
        },
      ),
    distributor_name: z
      .string({ required_error: '販売店名が未入力です。' })
      .min(1, '「販売店名」が入力されていません。')
      .max(100, '「販売店名」に入力された文字列が正しくありません。'),
    textbooks1: z.string({ required_error: '選択必須です' }),
    textbooks2: z.string().optional().nullable(),
    textbooks3: z.string().optional().nullable(),
    textbooks4: z.string().optional().nullable(),
    textbooks5: z.string().optional().nullable(),
    textbooks6: z.string().optional().nullable(),
    textbookQty1: z
      .string()
      .optional()
      .nullable()
      .refine(
        (data) => {
          if (data == null || data == '0') return false;
          if (data == null) return true;

          // 半角数字で1以上の値であるかをチェック
          return /^\d{1,4}$/.test(data);
        },
        {
          message: '「購入した数」が正しく入力されていません。',
        },
      ),
    textbookQty2: z
      .string()
      .optional()
      .nullable()
      .refine(
        (data) => {
          if (data == null || data == '0') return false;
          if (data == null || data == '') return true;

          // 半角数字4文字以内であるかをチェック
          return /^\d{1,4}$/.test(data);
        },
        {
          message: '「購入した数」が正しく入力されていません。',
        },
      ),
    textbookQty3: z
      .string()
      .optional()
      .nullable()
      .refine(
        (data) => {
          if (data == null || data == '0') return false;
          if (data == null || data == '') return true;

          // 半角数字4文字以内であるかをチェック
          return /^\d{1,4}$/.test(data);
        },
        {
          message: '「購入した数」が正しく入力されていません。',
        },
      ),
    textbookQty4: z
      .string()
      .optional()
      .nullable()
      .refine(
        (data) => {
          if (data == null || data == '0') return false;
          if (data == null || data == '') return true;

          // 半角数字4文字以内であるかをチェック
          return /^\d{1,4}$/.test(data);
        },
        {
          message: '「購入した数」が正しく入力されていません。',
        },
      ),
    textbookQty5: z
      .string()
      .optional()
      .nullable()
      .refine(
        (data) => {
          if (data == null || data == '0') return false;
          if (data == null || data == '') return true;

          // 半角数字4文字以内であるかをチェック
          return /^\d{1,4}$/.test(data);
        },
        {
          message: '「購入した数」が正しく入力されていません。',
        },
      ),
    textbookQty6: z
      .string()
      .optional()
      .nullable()
      .refine(
        (data) => {
          if (data == null || data == '0') return false;
          if (data == null || data == '') return true;

          // 半角数字4文字以内であるかをチェック
          return /^\d{1,4}$/.test(data);
        },
        {
          message: '「購入した数」が正しく入力されていません。',
        },
      ),
    memo: z.string().optional().nullable(),
    teaching_materials: z.array(teachingMaterialSchema).optional(),
    mail_address: z
      .string({ required_error: 'メールアドレスが未入力です。' })
      .email({ message: '「メールアドレス」が入力されていません。' })
      .max(254, '「メールアドレス」に入力された文字列が正しくありません。'),
    emailConfirm: z
      .string({ required_error: '「メールアドレス（確認）」が未入力です。' })
      .min(1, '「メールアドレス（確認）」が未入力です。')
      .email({
        message: '「メールアドレス」と「確認用メールアドレス」が一致しません。',
      })
      .max(254, '「メールアドレス」に入力された文字列が正しくありません。'),
  })
  .superRefine(
    (
      {
        prefecture_name,
        municipalities_name,
        school_alt,
        school_name,
        mail_address,
        emailConfirm,
        textbooks1,
        textbookQty1,
        textbooks2,
        textbookQty2,
        textbooks3,
        textbookQty3,
        textbooks4,
        textbookQty4,
        textbooks5,
        textbookQty5,
        textbooks6,
        textbookQty6,
      },
      ctx,
    ) => {
      // 都道府県が未選択の場合にエラーを追加
      if (
        !prefecture_name ||
        prefecture_name === '' ||
        prefecture_name === '0'
      ) {
        ctx.addIssue({
          path: ['prefecture_name'], // エラーを追加するフィールドのパス
          message: '「都道府県」が選択されていません。', // 表示するエラーメッセージ
          code: 'custom', // エラーコード（カスタム）
        });
      }
      // 市区町村名が未選択の場合にエラーを追加
      if (
        !municipalities_name ||
        municipalities_name === '' ||
        municipalities_name === '0'
      ) {
        ctx.addIssue({
          path: ['municipalities_name'], // エラーを追加するフィールドのパス
          message: '「市区町村名」が選択されていません。', // 表示するエラーメッセージ
          code: 'custom', // エラーコード（カスタム）
        });
      }
      if (
        (school_name === '' ||
          school_name === undefined ||
          school_name === '0') &&
        (school_alt === '' || school_alt === undefined)
      ) {
        ctx.addIssue({
          code: 'custom',
          message: '「学校名」が入力されていません。',
          path: ['schoolAlt'],
        });
      } else if (
        school_name !== '0' &&
        school_name !== '' &&
        school_name !== undefined &&
        school_alt !== '' &&
        school_alt !== undefined
      ) {
        // 「学校名」と「自由入力」の両方が入力されている場合のエラー
        ctx.addIssue({
          code: 'custom',
          message:
            '「学校名」が選択され、さらに「自由入力」にも学校名が入力されています。',
          path: ['schoolAlt'],
        });
      }
      if (school_alt &&
        school_alt !== undefined &&
        school_alt?.length > 100
      ) {
        ctx.addIssue({
          code: 'custom',
          message:
            '「学校名」は100文字以内で入力してください。',
          path: ['schoolAlt'],
        })
      }

      if (mail_address !== emailConfirm) {
        ctx.addIssue({
          path: ['emailConfirm'],
          code: 'custom',
          message: 'メールアドレスと確認用メールアドレスが一致しません。',
        });
      }
      if (textbooks1 === '0') {
        ctx.addIssue({
          code: 'custom',
          message: '「購入教材名」が入力されていません。',
          path: ['textbooks1'],
        });
      }
      if (
        textbooks1 !== undefined &&
        textbooks1 !== '' &&
        (textbookQty1 === undefined || textbookQty1 === '')
      ) {
        ctx.addIssue({
          code: 'custom',
          message: '「購入した数」が正しく入力されていません。',
          path: ['textbookQty1'],
        });
      }

      // textbookQty1 が設定されていて textbooks1 が未設定の場合
      if (
        textbookQty1 !== undefined &&
        textbookQty1 !== '' &&
        (textbooks1 === undefined || textbooks1 === '0')
      ) {
        ctx.addIssue({
          code: 'custom',
          message: '「購入教材名」が入力されていません。',
          path: ['textbooks1'],
        });
      }
      // textbooks2 が選択されていて textbookQty2 が未入力の場合
      if (
        textbooks2 &&
        textbooks2 !== '0' &&
        (!textbookQty2 || textbookQty2 === '')
      ) {
        ctx.addIssue({
          code: 'custom',
          message: '「購入した数」が正しく入力されていません。',
          path: ['textbookQty2'],
        });
      }
      // textbookQty2 が入力されていて textbooks2 が未選択の場合
      if (textbookQty2 && (!textbooks2 || textbooks2 === '0')) {
        ctx.addIssue({
          code: 'custom',
          message: '「購入教材名」が入力されていません。',
          path: ['textbooks2'],
        });
      }
      // textbooks3 と textbookQty3 のバリデーション
      if (
        textbooks3 &&
        textbooks3 !== '0' &&
        (!textbookQty3 || textbookQty3 === '')
      ) {
        ctx.addIssue({
          code: 'custom',
          message: '「購入した数」が正しく入力されていません。',
          path: ['textbookQty3'],
        });
      }
      if (textbookQty3 && (!textbooks3 || textbooks3 === '0')) {
        ctx.addIssue({
          code: 'custom',
          message: '「購入教材名」が入力されていません。',
          path: ['textbooks3'],
        });
      }

      // textbooks4 と textbookQty4 のバリデーション
      if (
        textbooks4 &&
        textbooks4 !== '0' &&
        (!textbookQty4 || textbookQty4 === '')
      ) {
        ctx.addIssue({
          code: 'custom',
          message: '「購入した数」が正しく入力されていません。',
          path: ['textbookQty4'],
        });
      }
      if (textbookQty4 && (!textbooks4 || textbooks4 === '0')) {
        ctx.addIssue({
          code: 'custom',
          message: '「購入教材名」が入力されていません。',
          path: ['textbooks4'],
        });
      }

      // textbooks5 と textbookQty5 のバリデーション
      if (
        textbooks5 &&
        textbooks5 !== '0' &&
        (!textbookQty5 || textbookQty5 === '')
      ) {
        ctx.addIssue({
          code: 'custom',
          message: '「購入した数」が正しく入力されていません。',
          path: ['textbookQty5'],
        });
      }
      if (textbookQty5 && (!textbooks5 || textbooks5 === '0')) {
        ctx.addIssue({
          code: 'custom',
          message: '「購入教材名」が入力されていません。',
          path: ['textbooks5'],
        });
      }
      // textbooks6 と textbookQty6 のバリデーション
      if (
        textbooks6 &&
        textbooks6 !== '0' &&
        (!textbookQty6 || textbookQty6 === '')
      ) {
        ctx.addIssue({
          code: 'custom',
          message: '「購入した数」が正しく入力されていません。',
          path: ['textbookQty6'],
        });
      }
      if (textbookQty6 && (!textbooks6 || textbooks6 === '0')) {
        ctx.addIssue({
          code: 'custom',
          message: '「購入教材名」が入力されていません。',
          path: ['textbooks6'],
        });
      }
      // textbooks重複チェック
      // 空文字ではない全てのtextbooksフィールドの値を配列に格納
      const textbooks = [
        textbooks1,
        textbooks2,
        textbooks3,
        textbooks4,
        textbooks5,
        textbooks6,
      ].filter((textbook) => textbook !== '0');

      // 重複をチェックするためにSetを使用
      const uniqueTextbooks = new Set(textbooks);
      console.log('uniqueTextbooks', uniqueTextbooks);
      // 元の配列とSetのサイズが異なる場合、重複が存在する
      // 元の配列とSetのサイズが異なる場合、重複が存在する
      if (uniqueTextbooks.size !== textbooks.length) {
        // 重複がある場合はエラーメッセージを一箇所にのみ追加
        ctx.addIssue({
          code: 'custom',
          message: '選択された購入教材が重複しています。',
          path: ['textbooks1'], // 最初のtextbooksフィールドにエラーを追加
        });
      }
    },
  );

export type LicenseApplyFormSchema = z.infer<typeof licenseApplyFormSchema>;
