import { LibsDomainModelInterfaceToppageGetToppageForTeachersResponse } from '@/src/__generated__';
import { LICENSES } from '@/src/components/HistoryQueryForm/constants';
import { formatDuration } from '@/src/helpers/date';
import { Link } from 'react-router-dom';
import styles from './styles.module.css';
import styled, { DefaultTheme } from 'styled-components';

export type RecordsProps = {
  records: Partial<
    Omit<
      LibsDomainModelInterfaceToppageGetToppageForTeachersResponse,
      'notice' | 'graph_data_study_time' | 'preferences'
    >
  >;
  scope: string;
  theme: DefaultTheme;
  licenses: number[];
};

const RecordWrap = styled('div')(
  ({ theme }) => `
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    display: flex;
    .item{
      background:var(${theme.lightcolor});
      border-radius: 4px;
      padding: 14px 31px 9px 30px;
      display: flex;
      position: relative;
      overflow: hidden;
      box-shadow: 3px 2px 3px #0000001a;
      .caption{
        font-family: var(--main-font-family-db);
        color: var(${theme.textcolor});
        letter-spacing: .05em;
        font-size: 26px;
        @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.625rem;};
        
        line-height: 1;
        div:last-child {
          margin-top: 9px;        
          font-family: var(--main-font-family-db);
          font-size: 15px;
          @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 0.9375rem;};
          
          letter-spacing: 0.05em;
        }
      }
      .value{
        position: relative;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex: 1;
        padding: 0 38px 0 14px;
        overflow: hidden;
        span{
          text-overflow: ellipsis;
          white-space: nowrap;
          color: var(${theme.maincolor});
          font-family: var(--main-font-family-db);
          letter-spacing: .03em;
          font-size: 50px;
          @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 3.125rem;};
          
          overflow: hidden;
        }
        .unit{
          color: var(${theme.textcolor});
          font-family: var(--main-font-family-db);
          font-size: 22px;
          @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.375rem;};
          
          position: absolute;
          bottom: 5px;
          right: 0;
        }
      }
      &:after{
        content: "";
        background-color: var(${theme.edgecolor});
        width: 15px;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
      }

      &.linked{
        background: linear-gradient(90deg, ${theme.chart2} -50%, ${theme.chart3} 150%);
        .value{
          span{
            color:var(${theme.textcolreverse})
          }
        }
      }
      .cover{
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        cursor:pointer;
      }
    }

  `
)

export const Records = ({ records, scope, licenses, theme }: RecordsProps) => {
  return (
    <RecordWrap className={styles.records}>
      {RECORD_ITEMS.filter(
        (item) =>
          !item.licenses ||
          item.licenses.some((license) => licenses.includes(license)),
      ).map((item, i) =>
        <RecordItem item={item} scope={scope} records={records} key={i} />
      )}
      {licenses.length === 0 && (
        <div className={styles.blank}>
          <img src="/img/record_blank.png" alt="" />
        </div>
      )}
    </RecordWrap>

  );
};

type RecordItemField = {
  label: string;
  key: keyof RecordsProps['records'];
  format?: (value: RecordsProps['records']) => string;
  unit?: string;
  url?: string;
  licenses?: number[];
};

const format_number = (n: number | string | undefined | null) => {
  n = Number(n)
  if (n == undefined || n == null) {
    n = 0
  }
  return n.toFixed(1)
}

const RecordItem = ({
  item,
  scope,
  records,
}: {
  item: RecordItemField;
  scope: string;
  records: RecordsProps['records'];
}) => (
  // <div className={clsx(styles.item, item.url && styles.linked)}>
  <div className={item.url ? 'item linked' : 'item'}>
    <div className='caption'>
      <div>{item.label}</div>
      <div>{`${scope}平均`}</div>
    </div>
    <div className='value'>
      <span>
        {item.key in records &&
          (item.format ? item.format(records) : format_number(records[item.key]))}
      </span>
      {item.unit && <div className='unit'>{item.unit}</div>}
    </div>
    {item.url && (
      <div className='icon'>        
          <img src="/img/icon-arrow-right.svg" />        
      </div>
    )}
    {item.url && (
      <Link className="cover" to={item.url} />
    )}
  </div>
);

const RECORD_ITEMS: RecordItemField[] = [
  {
    label: '通算ログイン回数',
    key: 'total_number_logins',
    unit: '回',
  },
  {
    label: '通算利用時間',
    key: 'total_study_time',
    format: ({ total_study_time }) => formatDuration(total_study_time || 0),
  },
  {
    label: '漢字スキル 学習字数',
    key: 'number_kanji_skill',
    unit: '字',
    url: '/teacherhistorykanji',
    licenses: [
      ...LICENSES.learning_contents.kanji,
      ...LICENSES.learning_contents.hiragana_suuji,
      ...LICENSES.learning_contents.katakana,
    ],
  },
  {
    label: '国語辞典 調べた言葉の数',
    key: 'number_japanese_dictionary',
    unit: '語',
    url: '/teacherhistorydictionary',
    licenses: LICENSES.learning_contents.dictionary,
  },
  {
    label: '漢字辞典 調べた漢字の数',
    key: 'number_kanji_dictionary',
    unit: '字',
    url: '/teacherhistorydictionarykanji',
    licenses: LICENSES.learning_contents.dictionarykanji,
  },
  {
    label: '形成評価CBT 取組単元数',
    key: 'number_cbt',
    url: '/teacherhistorycbt',
    licenses: LICENSES.learning_contents.cbt,
  },
];
