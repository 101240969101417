import MainButton from '@/src/components/parts/MainButton';
import clsx from 'clsx';
import { useFormState } from 'react-hook-form';
import styles from '../styles.module.css';

type SubmitButtonProps = {
  className?: string;
  noRuby?: boolean;
  alt?: boolean;
};
export const SubmitButton = ({ className, noRuby, alt }: SubmitButtonProps) => {
  const { isLoading, isValidating, isSubmitting } = useFormState();
  return (
    <MainButton
      type="submit"
      className={clsx(className, styles.submit, !noRuby && styles.ruby)}
      disabled={isLoading || isValidating || isSubmitting}
    >
      {noRuby ? (
        <>
          {alt ? '再表示' : '表示'}
        </>
      ) : (
        <>
          <ruby>
            表<rt>ひょう</rt>
          </ruby>
          <ruby>
            示<rt>じ</rt>
          </ruby>
        </>
      )}
    </MainButton>
  );
};
