import clsx from 'clsx';
import { ReactNode } from 'react';
import { SubmitButton } from './items/SubmitButton';
import styles from './styles.module.css';
import styled, { DefaultTheme } from 'styled-components';
import Kanji from '../auth/Kanji';

type HistoryQueryFromProps = {
  onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
  errorMessage?: ReactNode;
  children: ReactNode;
  className?: string;
  theme?: DefaultTheme;
};

const Form = styled('form')(
  ({ theme }) => `
    position: relative;
    color: var(${theme.textcolor});
  `
)

export const HistoryQueryFormStudent = ({
  onSubmit,
  errorMessage,
  children,
  className,
}: HistoryQueryFromProps) => {
  return (
    <Form
      className={clsx(className)}
      onSubmit={onSubmit}
    >
      <div className={styles.items}>{children}</div>
      {/* {onSubmit && <SubmitButton />} */}
      {errorMessage && (
        errorMessage === '終了日が開始日より前の日付になっています。' ?
          <div className={styles['date-range-error-message']}><Kanji kana='しゅうりょうび'>終了日</Kanji>が<Kanji kana='かいしび'>開始日</Kanji>より<Kanji kana='まえ'>前</Kanji>の<Kanji kana='ひづけ'>日付</Kanji>になっています。</div>
          : <div className={styles['date-range-error-message']}>{errorMessage}</div>
      )}
    </Form>
  );
};
