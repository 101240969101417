/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

interface Props {
  className: any;
}

export const Fifteen = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={`fifteen ${className}`}
      fill="none"
      height="72"
      viewBox="0 0 224 72"
      width="224"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_11_75165)">
        <path
          className="path-2"
          d="M13.2797 21.8807H1.52928C0.684683 21.8807 0 22.5654 0 23.41V69.7205C0 70.5651 0.684683 71.2497 1.52928 71.2497H13.2797C14.1243 71.2497 14.809 70.5651 14.809 69.7205V23.41C14.809 22.5654 14.1243 21.8807 13.2797 21.8807Z"
          fill="#FA7355"
        />
        <path
          className="path-2"
          d="M29.2584 52.9893C33.7891 52.9893 37.4621 49.3164 37.4621 44.7856C37.4621 40.2548 33.7891 36.5819 29.2584 36.5819C24.7276 36.5819 21.0547 40.2548 21.0547 44.7856C21.0547 49.3164 24.7276 52.9893 29.2584 52.9893Z"
          fill="#FA7355"
        />
        <path
          className="path-2"
          d="M98.0168 31.3804V23.41C98.0168 22.5633 97.3299 21.8807 96.4875 21.8807H55.1191C54.2724 21.8807 53.5898 22.5676 53.5898 23.41V31.3804C53.5898 32.2271 54.2767 32.9097 55.1191 32.9097H62.4804C63.0895 32.9097 63.5863 33.4065 63.5863 34.0156V38.0505C63.5863 38.6596 63.0895 39.1564 62.4804 39.1564H55.1191C54.2724 39.1564 53.5898 39.8433 53.5898 40.6857V48.6561C53.5898 49.5028 54.2767 50.1854 55.1191 50.1854H62.4804C63.0895 50.1854 63.5863 50.6822 63.5863 51.2913V55.931C63.5863 64.3853 70.4422 71.2411 78.8964 71.2411H96.4875C97.3342 71.2411 98.0168 70.5542 98.0168 69.7118V61.7414C98.0168 60.8947 97.3299 60.2121 96.4875 60.2121H82.7715C80.3566 60.2121 78.3953 58.2552 78.3953 55.836V51.287C78.3953 50.6779 78.8921 50.1811 79.5012 50.1811H96.4875C97.3342 50.1811 98.0168 49.4942 98.0168 48.6518V40.6814C98.0168 39.8347 97.3299 39.1521 96.4875 39.1521H79.5012C78.8921 39.1521 78.3953 38.6553 78.3953 38.0462V34.0113C78.3953 33.4022 78.8921 32.9054 79.5012 32.9054H96.4875C97.3342 32.9054 98.0168 32.2185 98.0168 31.3761V31.3804Z"
          fill="#FA7355"
        />
        <path
          className="path-2"
          d="M222.204 21.8807H180.831C179.986 21.8807 179.302 22.5654 179.302 23.41V31.3804C179.302 32.225 179.986 32.9097 180.831 32.9097H222.204C223.048 32.9097 223.733 32.225 223.733 31.3804V23.41C223.733 22.5654 223.048 21.8807 222.204 21.8807Z"
          fill="#FA7355"
        />
        <path
          className="path-2"
          d="M180.835 39.1608C179.988 39.1608 179.306 39.8476 179.306 40.69V48.6605C179.306 49.5072 179.993 50.1897 180.835 50.1897H207.818C208.427 50.1897 208.924 50.6865 208.924 51.2957V55.8446C208.924 58.2595 206.967 60.2208 204.547 60.2208H198.201C197.355 60.2208 196.672 60.9077 196.672 61.7501V69.7205C196.672 70.5672 197.359 71.2498 198.201 71.2498H208.422C216.877 71.2498 223.733 64.3939 223.733 55.9397V40.6944C223.733 39.8476 223.046 39.1651 222.203 39.1651H180.835V39.1608Z"
          fill="#FA7355"
        />
        <path
          className="path-2"
          d="M114.359 21.8807C113.513 21.8807 112.83 22.5676 112.83 23.41V31.3804C112.83 32.2271 113.517 32.9097 114.359 32.9097H141.342C141.951 32.9097 142.448 33.4065 142.448 34.0156V55.8403C142.448 58.2552 140.491 60.2165 138.072 60.2165H131.76C130.914 60.2165 130.231 60.9033 130.231 61.7457V69.7161C130.231 70.5629 130.918 71.2454 131.76 71.2454H141.947C150.401 71.2454 157.257 64.3896 157.257 55.9353V23.41C157.257 22.5633 156.57 21.8807 155.728 21.8807H114.359Z"
          fill="#FA7355"
        />
        <path
          className="path-2"
          d="M169.413 13.8845C169.413 13.8845 169.413 13.8715 169.413 13.8629L169.422 13.8283C169.448 13.6771 169.465 13.5216 169.465 13.3661C169.465 11.9751 168.338 10.8475 166.951 10.8475C166.644 10.8475 166.355 10.9037 166.087 11.003L166.199 10.9642C166.022 10.9987 165.845 11.016 165.659 11.016C164.056 11.016 162.756 9.71569 162.756 8.11297C162.756 7.45201 162.976 6.84289 163.348 6.35473C163.452 6.20785 163.547 6.05233 163.629 5.89249L163.637 5.87953C163.927 5.32224 164.091 4.69584 164.091 4.02624C164.091 1.80144 162.29 0 160.065 0C158.95 0 157.944 0.4536 157.214 1.18368L146.159 12.7224C146.008 12.8693 145.865 13.0248 145.722 13.1803L145.541 13.3704H145.554C143.623 15.5866 142.452 18.4767 142.452 21.6432C142.452 28.5941 148.085 34.2274 155.036 34.2274C161.063 34.2274 166.096 29.9895 167.327 24.3346L167.344 24.2568C167.357 24.1963 167.37 24.1359 167.379 24.0754L169.418 13.8888L169.413 13.8845Z"
          fill="#FA7355"
        />
        <path
          className="path-2"
          d="M159.166 18.8223H158.721C158.241 18.8223 157.853 18.4335 157.853 17.9539V17.509C157.853 15.984 156.712 14.6146 155.187 14.5368C153.563 14.4504 152.219 15.7421 152.219 17.3491V17.9539C152.219 18.4335 151.83 18.8223 151.351 18.8223H150.906C149.381 18.8223 148.012 19.9627 147.934 21.4877C147.847 23.112 149.139 24.4555 150.746 24.4555H151.351C151.83 24.4555 152.219 24.8443 152.219 25.3239V25.7688C152.219 27.2938 153.36 28.6632 154.885 28.741C156.509 28.8274 157.853 27.5357 157.853 25.9287V25.3239C157.853 24.8443 158.241 24.4555 158.721 24.4555H159.326C160.933 24.4555 162.224 23.112 162.138 21.4877C162.06 19.9627 160.691 18.8223 159.166 18.8223Z"
          fill="#FAFFBE"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_11_75165">
          <rect
            className="rect"
            fill="white"
            height="71.2498"
            width="223.733"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
