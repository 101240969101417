import clsx from 'clsx';
import { ReactNode } from 'react';
import {
  DictionaryQueryForm,
  DictionaryQueryFormProps,
} from './DictionaryQueryForm';
import { LABEL, WordListRandom, WordListRanking } from './WordList';
import styles from './styles.module.css';
import styled, { DefaultTheme } from 'styled-components';

type WordListsProps = DictionaryQueryFormProps & {
  rankingItems: { rank: number; word: ReactNode }[];
  randomItems: { caption: ReactNode; word: ReactNode }[];
  className?: string;
  onSubmit: VoidFunction;
  queryRandom: VoidFunction;
  theme?:DefaultTheme;
};

const WordList = styled('div')(
  ({theme})=>`
    width: 100%;
    border-radius: 8px;
    border: 5px solid var(${theme.maincolor});
    overflow: hidden;
    margin-top: 52px;
    .label {
      padding: 3px 0 24px;
      background-color: var(${theme.maincolor});
      color: var(${theme.textcolreverse});    
      font-size: 34px;
      @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none)  {font-size: 2.125rem;}
      font-family: var(--main-font-family-db);
      text-align: center;
    }
    .content {
      display: flex;
      justify-content: space-between;
      padding: 30px 50px 50px;
      background-color: var(${theme.backgroud});
      color: var(${theme.textcolor});
      > div:first-child {
        display: flex;
        flex-direction: column;
        row-gap: 35px;
        .dictionary-form {
          display: flex;
          flex-direction: column;
          row-gap: 20px;
          .select {
            justify-content: space-between;
          }
        }
        .word-list{
          ol{
            border-color:var(${theme.textcolor})!important;
            li{
              border-color:var(${theme.textcolor})!important;
              .content{
                background-color: var(${theme.lightcolor})!important;
              }
            }
          }          
        }
      }
      > div:last-child {
        margin-top: 3px;
        width: 754px;
      }
    }

  `
)

export const WordLists = ({
  rankingItems,
  randomItems,
  onSubmit,
  queryRandom,
  className,
  theme
}: WordListsProps) => (
  <WordList className={clsx(className)}>
    <div className='label'>{LABEL}</div>
    <div className='content'>
      <div>
        <DictionaryQueryForm onSubmit={onSubmit} />
        <WordListRanking items={rankingItems} theme={theme} />
      </div>
      <WordListRandom items={randomItems} reload={queryRandom} />
    </div>
  </WordList>
);
