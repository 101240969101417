import { ButtonHTMLAttributes } from 'react';
import './style.scss';

type Props = Partial<{
  type: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  disabled: ButtonHTMLAttributes<HTMLButtonElement>['disabled'];
  tabIndex: ButtonHTMLAttributes<HTMLButtonElement>['tabIndex'];
  className?: any;
  onClick?: () => void;
}>;

/**
 * TBD:計算スキルボタン学年別展開
 */
export const AppMenuButton: React.FC<Props> = ({ type, disabled, tabIndex, className, onClick }) => {

  //紙付録クラス
  const furoku = ['KKJSMK', 'KKJSTO', 'KKJSKY', 'KROMHY', 'KKE1TO', 'KKE2TO', 'KKE3TO','KKE4TO','KKE5TO','KKE6TO','KKE1KE','KKE2KE','KKE3KE','KKE4KE','KKE5KE','KKE6KE','KKE1HY','KKE2HY','KKE3HY','KKE4HY','KKE5HY','KKE6HY'];

  return (
    <button
      type={type}
      disabled={disabled}
      tabIndex={tabIndex}
      onClick={onClick}
      className={`box ${className}`}>
      <div className="group">
        <div className="div">
          <img src={`/img/${className}-text.svg`} />
        </div>
      </div>
      <img className="img-2" alt="Img" src={`/img/${className}-3.svg`} />
      {furoku.includes(className) &&
        <img className='furoku' alt='furoku' src={'/img/furoku.svg'} />
      }
    </button>
  );
};
