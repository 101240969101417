.body {
  margin-top: 33px;
  margin-left: 35px;
  width: 1580px;
}
/* .body .table {
  margin-top: 28px;
} */
.body .word-lists {
  margin-top: 52px;
}

/* .table {
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--line-color);
  overflow: hidden;
} */

.table th {
  height: 89px;
  font-size: 20px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none)  {font-size: 1.25rem;}
}
.table td {
  font-size: 18px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none)  {font-size: 1.125rem;}
}
.table td:nth-child(1) {
  padding: 16px 2px;
  letter-spacing: 0;
}

.history-select-container {
  height: fit-content;
  margin-bottom: 28px;
}
/*
.word-lists {
  width: 100%;
  border-radius: 8px;
  border: 5px solid var(--tomopla-color);
  overflow: hidden;
}

.word-lists > .label {
  padding: 3px 0 24px;
  background-color: var(--tomopla-color);
  color: var(--white);

  font-size: 34px;
  
  font-family: var(--main-font-family-db);
  text-align: center;
}

.word-lists > .content {
  display: flex;
  justify-content: space-between;
  padding: 30px 50px 50px;
  background-color: var(--tomopla-color-light);
}
.word-lists > .content > :first-child {
  display: flex;
  flex-direction: column;
  row-gap: 35px;
}
.word-lists > .content > :last-child {
  margin-top: 3px;
  width: 754px;
} 
*/
.dictionary-form {
  display: flex;
  flex-direction: column;
  row-gap: 25px;
}

.dictionary-form .select {
  justify-content: space-between;
}

.word-list {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.word-list .label {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  font-size: 20px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none)  {font-size: 1.25rem;}
  font-family: var(--main-font-family-db);
  letter-spacing: 0.05em;
  line-height: 25px;
}

/* .word-list ol {
  border-radius: 4px;
  border: 1px solid var(--line-color);
  overflow: hidden;
} */

/* .word-list li {
  height: 65px;
  display: flex;
}
.word-list li:not(:last-child) {
  border-bottom: 1px solid var(--line-color);
}

.word-list li > * {
  display: flex;
  align-items: flex-end;
  padding-bottom: 22px;
} */

/* .word-list .caption {
  justify-content: center;
  height: 100%;
  border-right: 1px solid var(--line-color);
  background-color: var(--tomopla-color);
  color: var(--white);

  font-size: 20px;
  
  font-family: var(--main-font-family-db);
  letter-spacing: 0.05em;
  line-height: 1;
} */
/* .word-list .caption-ranking {
  width: 79px;
} */
/* .word-list .caption-random {
  width: 170px;
  column-gap: 0.75em;
} */

/* .word-list .content {
  width: 585px;
  height: 100%;
  padding-left: 20px;
  background-color: var(--white);

  font-size: 25px;
  
  font-family: var(--main-font-family-db);
  letter-spacing: 0.1em;
  line-height: 1;
} */
