import { RHFPasswordField } from '@/src/components/RHFPasswordField';
import Kanji from '@/src/components/auth/Kanji';
import MainButton from '@/src/components/parts/MainButton';
import SubButton from '@/src/components/parts/SubButton';
import { useOneTimeCodeCheck } from '@/src/hooks/useOneTimeCodeCheck';
import usePwdForm from '@/src/hooks/usePwdForm';
import { IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './style.css';

export const PasswordResetInput = (): JSX.Element => {
  const [isRevealPassword, setIsRevealPassword] = useState(false);
  const [isRevealPassword1, setIsRevealPassword1] = useState(false);
  const [shouldDisplayForm, setShouldDisplayForm] = useState(false);
  const navigate = useNavigate();
  const { isValid, isLoading } = useOneTimeCodeCheck();

  // useOneTimeCodeCheckがfalseの場合、リダイレクト
  useEffect(() => {
    if (isValid === false && !isLoading) {
      navigate('/passwordresettimeout');
    } else if (isValid && !isLoading) {
      setTimeout(() => {
        setShouldDisplayForm(true);
      }, 500); // Adjust the delay as needed
    }
  }, [navigate, isValid, isLoading]);

  const togglePassword = () => {
    setIsRevealPassword((prevState: boolean) => !prevState);
  };

  const togglePassword1 = () => {
    setIsRevealPassword1((prevState: boolean) => !prevState);
  };

  const loadingPage = () => (
    <>
      <div className="password-reset-input loading">
        <img src="/img/type_d.gif" alt="読み込み中" className="loading" />
      </div>
    </>
  );

  const {
    form: { control, handleSubmit, onSubmit, autoGenerate, errors, isWait },
    options: {},
  } = usePwdForm();

  return (
    <>
      {isWait ? (
        <div className="password-reset-input">
          <div className="overlap-wrapper-7">
            <div className="overlap-42">
              <div className="div-14">
                <div className="group-123">
                  <div className="frame-15">
                    <div className="frame-16">
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                    </div>
                    <div className="frame-16">
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                    </div>
                    <div className="frame-16">
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                    </div>
                    <div className="frame-16">
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                    </div>
                    <div className="frame-16">
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                    </div>
                    <div className="frame-16">
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                    </div>
                    <div className="frame-16">
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                    </div>
                    <div className="frame-16">
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                    </div>
                  </div>
                </div>
                <div className="group-124">
                  <div className="frame-15">
                    <div className="frame-16">
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                    </div>
                    <div className="frame-16">
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                    </div>
                    <div className="frame-16">
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                    </div>
                    <div className="frame-16">
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                    </div>
                    <div className="frame-16">
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                    </div>
                    <div className="frame-16">
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                    </div>
                    <div className="frame-16">
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                    </div>
                    <div className="frame-16">
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                    </div>
                  </div>
                </div>
                <div className="group-125">
                  <div className="frame-15">
                    <div className="frame-16">
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                    </div>
                    <div className="frame-16">
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                    </div>
                    <div className="frame-16">
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                    </div>
                    <div className="frame-16">
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                    </div>
                    <div className="frame-16">
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                    </div>
                    <div className="frame-16">
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                    </div>
                    <div className="frame-16">
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                    </div>
                    <div className="frame-16">
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                    </div>
                  </div>
                </div>
                <div className="group-126">
                  <div className="frame-15">
                    <div className="frame-16">
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                    </div>
                    <div className="frame-16">
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                    </div>
                    <div className="frame-16">
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                    </div>
                    <div className="frame-16">
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                    </div>
                    <div className="frame-16">
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                    </div>
                    <div className="frame-16">
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                    </div>
                    <div className="frame-16">
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                    </div>
                    <div className="frame-16">
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                      <div className="ellipse-16" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isLoading ? (
            loadingPage()
          ) : (
            <>
              {shouldDisplayForm && (
                <form onSubmit={handleSubmit(onSubmit)}>
                  <MainButton className="main-button-6" type="submit">
                    <div className="text-wrapper-225">
                      <Kanji kana="さいせってい">再設定</Kanji>
                    </div>
                  </MainButton>
                  <div className="block-password">
                    <RHFPasswordField
                      name="newPasswordConfirm"
                      label=""
                      control={control}
                      type={isRevealPassword1 ? 'text' : 'password'}
                    />
                    <div className="label-password">
                      <Kanji kana="あたら">新</Kanji>しいパスワード（
                      <Kanji kana="かくにん">確認</Kanji>）
                    </div>
                    <IconButton className="eye-icon" onClick={togglePassword1}>
                      <img
                        alt="Eye icon"
                        src={
                          isRevealPassword1
                            ? '/img/eyeicon_close.png'
                            : '/img/eyeicon.png'
                        }
                      />
                    </IconButton>
                  </div>
                  <SubButton className="sub-button-3" onClick={autoGenerate}>
                    <div className="text-wrapper-232">
                      <Kanji kana="じどうせいせい">自動生成</Kanji>
                    </div>
                  </SubButton>

                  <div className="block-password-2">
                    <RHFPasswordField
                      name="newPassword"
                      label=""
                      control={control}
                      type={isRevealPassword ? 'text' : 'password'}
                    />
                    <div className="label-password">
                      <Kanji kana="あたら">新</Kanji>しいパスワード
                    </div>
                    <IconButton className="eye-icon" onClick={togglePassword}>
                      <img
                        alt="Eye icon"
                        src={
                          isRevealPassword
                            ? '/img/eyeicon_close.png'
                            : '/img/eyeicon.png'
                        }
                      />
                    </IconButton>
                  </div>
                  <div className="message-warning-3">
                    {errors.newPassword?.message ===
                      '新しいパスワードが入力されていません。' && (
                      <>
                        <div className="message-warning-RHF">
                          「<Kanji kana="あたら">新</Kanji>しいパスワード」が
                          <Kanji kana="にゅうりょく" ls="-2">
                            入力
                          </Kanji>
                          されていません。
                        </div>
                      </>
                    )}
                    {errors.newPassword?.message ===
                      '新しいパスワードの形式が正しくありません。' && (
                      <>
                        <div className="message-warning-RHF">
                          「<Kanji kana="あたら">新</Kanji>しいパスワード」の
                          <Kanji kana="けいしき">形式</Kanji>が
                          <Kanji kana="ただ">正</Kanji>しくありません。
                        </div>
                      </>
                    )}
                    {errors.newPassword?.message === '正規表現エラー' && (
                      <>
                        <div className="message-warning-RHF">
                          パスワードは<Kanji kana="えいじ">英字</Kanji>・
                          <Kanji kana="すうじ">数字</Kanji>・
                          <Kanji kana="きごう">記号</Kanji>をすべて
                          <Kanji kana="しよう">使用</Kanji>して、10
                          <Kanji kana="もじいじょう">文字以上</Kanji>で
                          <Kanji kana="にゅうりょく" ls="-1.5">
                            入力
                          </Kanji>
                          してください。
                          <br />
                          「,」「?」「/」「\」「'」「|」「¥」「"」は
                          <Kanji kana="しよう">使用</Kanji>できません。
                        </div>
                      </>
                    )}
                    {!errors.newPassword &&
                      errors.newPasswordConfirm?.message ===
                        '新しいパスワード（確認）が入力されていません。' && (
                        <>
                          <div className="message-warning-RHF">
                            「<Kanji kana="あたら">新</Kanji>しいパスワード（
                            <Kanji kana="かくにん">確認</Kanji>）」が
                            <Kanji kana="にゅうりょく">入力</Kanji>
                            されていません。
                          </div>
                        </>
                      )}
                    {!errors.newPassword &&
                      errors.newPasswordConfirm?.message ===
                        '新しいパスワードと新しいパスワード（確認）が一致していません。' && (
                        <>
                          <div className="message-warning-RHF">
                            「<Kanji kana="あたら">新</Kanji>
                            しいパスワード」と「
                            <Kanji kana="あたら">新</Kanji>しいパスワード（
                            <Kanji kana="かくにん">確認</Kanji>
                            ）」がちがいます。
                          </div>
                        </>
                      )}
                    {errors.newPassword?.message ===
                      '以前のパスワードと同じです' && (
                      <>
                        <div className="message-warning-RHF">
                          <Kanji kana="にゅうりょく">入力</Kanji>された「
                          <Kanji kana="あたら">新</Kanji>しいパスワード」は
                          <Kanji kana="いぜん">以前</Kanji>のパスワードと
                          <Kanji kana="おな">同</Kanji>じです。
                          <br />
                          <Kanji kana="べつ">別</Kanji>のパスワードを
                          <Kanji kana="にゅうりょく">入力</Kanji>して
                          <Kanji kana="さいせってい">再設定</Kanji>
                          してください。
                        </div>
                      </>
                    )}
                  </div>
                  <div className="main-message-2">
                    <div className="overlap-43">
                      <div className="text-wrapper-239">
                        パスワード<Kanji kana="へんこう">変更</Kanji>を
                        <Kanji kana="おこな">行</Kanji>います
                        <br />
                        <Kanji kana="あたら">新</Kanji>しいパスワードを
                        <Kanji kana="にゅうりょく">入力</Kanji>してください
                      </div>
                    </div>
                  </div>
                  <div className="main-header-4">
                    <div className="main-header-5">
                      パスワードを<Kanji kana="さいせってい">再設定</Kanji>
                    </div>
                  </div>
                  <div className="title-4" />
                </form>
              )}
            </>
          )}
        </div>
      ) : (
        loadingPage()
      )}
    </>
  );
};
