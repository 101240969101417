import { AxiosResponse } from 'axios';
import Encoding from 'encoding-japanese';
import { saveAs } from 'file-saver';

const getContentType = (h: string) => {
  if (h.match(/base64/)) {
    return h;
  }
  return h + "; base64";
};

const getFilename = (h: string | undefined, contentType: string) => {
  if (h == undefined) {
    if (contentType.match(/csv/)) {
      return "download.csv";
    }
    if (contentType.match(/application\/vnd.openxml/)) {
      return "download.xlsx";
    }
    return "download.txt";
  }
  const matched = h.match(/attachment; filename="([^"]+)"/);
  if (matched) {
    return matched[1];
  }
  const matched2 = h.match(/attachment; filename=([^"]+)$/);
  if (matched2) {
    return matched2[1];
  }
  return h;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const convertToBlob = (data: any, contentType: string) => {
  contentType = contentType || '';
  if (contentType.match(/text\/csv;.*charset=utf-8/)) {
    // 文字コード変換
    return utfToCsvBlob(data, "text/csv; charset=cp932");
  }
  return base64ToBlob([data], contentType);
}

const utfToCsvBlob = (data: any, contentType: string) => {
  contentType = contentType || '';
  const utf_text = data.replace(/〜/g, "～")
  const utf_array = new TextEncoder().encode(utf_text);
  const sjis_array = Encoding.convert(utf_array, { to: 'SJIS', from: 'UTF8'});
  const output = Uint8Array.from(sjis_array);
  return new Blob([output], { type: contentType });
}

const base64ToBlob = (base64Data: any, contentType: string) => {
  contentType = contentType || '';
  const sliceSize = 1024;
  const byteCharacters = atob(base64Data);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    console.log(bytes);
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType });
}

export const saveFile = (res: AxiosResponse) => {
  if (res.status != 200) {
    return;
  }
  const type = getContentType(res.headers['content-type']);
  const name = getFilename(res.headers['content-disposition'], type);
  const blob = convertToBlob(res.data, type);
  return saveAs(blob, name);
};
export const withSaveFile = (promise: Promise<AxiosResponse>) => {
  return promise.then(saveFile);
};
