import {
  FormItemCategorySelect,
  FormItemDateRangePicker,
  HistoryQueryFormStudent,
  KanjiToggleStudent,
  StudentHistorySelect,
  styles as formStyles,
} from '@/src/components/HistoryQueryForm';
import {
  profileAtom,
  themeAtom,
} from '@/src/components/ModalContents/modalAtom';
import { TableWithScroll } from '@/src/components/Table';
import { StudentFrame } from '@/src/components/layouts/StudentFrame';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useRecoilState } from 'recoil';
import styled, { useTheme } from 'styled-components';
import { useToppageStudent } from '../StudentTop/hook';
import { useLearningHistoryKanjiSkill } from './hooks';
import styles from './styles.module.css';

type Props = {
  onThemeChange: (status: number) => void;
};

const CoverDiv = styled('div')(
  ({ theme }) => `
    width:499px;
    height:39px;
    background-color: var(${theme.backgroud});
    position:absolute;
    z-index:99;
  `
)

const ErrorCoverDiv = styled('div')(
  ({ theme }) => `
    width:411px;
    height:84px;
    background-color: #fff;
    position:absolute;
    right:0;
    bottom:-47px;
    z-index:98;
    opacity:40%;
  `
)

export const StudentHistoryKanji = (props: Props): JSX.Element => {
  const { currentUser } = useToppageStudent();
  const [themenum, setThemenum] = useRecoilState(themeAtom);
  const [profiles, setProfiles] = useRecoilState(profileAtom);
  const {
    table,
    conditions: { categories },
    type,
    methods,
    onSubmit,
    errorMessage,
    hasLoaded,
  } = useLearningHistoryKanjiSkill();

  //console.log('テーマカラー',currentUser?.preference)

  useEffect(() => {
    if (currentUser) {
      if (!themenum) {
        setThemenum(currentUser?.preference.theme_color);
      }
      
      const _profiles = {
        nick_name: profiles.nick_name
          ? profiles.nick_name
          : currentUser?.nick_name,
        profile_icon:
          profiles.profile_icon &&
          profiles.profile_icon !== currentUser?.preference.profile_icon
            ? profiles.profile_icon
            : currentUser?.preference.profile_icon,
      };
  
      if (
        _profiles.nick_name !== profiles.nick_name ||
        _profiles.profile_icon !== profiles.profile_icon
      ) {
        setProfiles(_profiles);
      }
    }
  }, [currentUser, profiles, themenum]);

  useEffect(() => {
    if (currentUser && typeof themenum === 'number') {
      props.onThemeChange(themenum);
    }
  }, [themenum, currentUser]);

  const [once, setOnce] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setOnce(false)
    }, 3000);
  }, [])

  const theme = useTheme();

  return (
    <StudentFrame title="学習りれき">
      <div className={clsx(styles['student-history-kanji'], styles.body)}>
        <FormProvider {...methods}>
          <HistoryQueryFormStudent
            onSubmit={onSubmit}
            errorMessage={errorMessage}
          >
            <div className={formStyles['row']}>
              <StudentHistorySelect labelWidth={180} />
              {categories.length > 0 && (
                <FormItemCategorySelect categories={categories} />
              )}
            </div>
            <FormItemDateRangePicker labelWidth={180} />

            {type && <KanjiToggleStudent type={type} />}
            
            {errorMessage && <ErrorCoverDiv />}
            
            {once && <CoverDiv />}


          </HistoryQueryFormStudent>
        </FormProvider>
        {hasLoaded && (
          <TableWithScroll
            className={styles.table}
            table={table}
            maxWidth={1580}
            maxHeight={760}
          />
        )}
      </div>
    </StudentFrame>
  );
};
