import React, { useEffect } from 'react';
import { ImgWrapper } from '../../ImgWrapper';
import { StudentTopBar } from '../StudentTopBar';
import './style.css';

// types
import { TopBarFrameProps } from '@/src/types/TopBarFrame';
import { useAuthContext } from '@/src/middleware/auth/AuthContext';
import styled from 'styled-components';
import { useRecoilState, useRecoilValue } from 'recoil';
import { menuAtom, modalAtom, profileAtom } from '../../ModalContents/modalAtom';

const Div = styled('div')(
  ({ theme }) => `
    background-color: var(${theme.backgroud});
  `
)

const Wrap = styled('div')(
  ({ theme }) => `
    background-color: var(${theme.backgroud});
    z-index: 10;
    width: 1690px;
    height: 1080px;
    top:0;
    left:0;
    right:0;
    margin:auto;
    position: absolute;
    .MuiInputBase-root{
      input{
        color:var(${theme.textcolor})
      }
    }
  `
)

export const StudentFrame: React.FC<TopBarFrameProps> = ({
  children,
  className,
  title
}) => {
  const { getCurrentUser } = useAuthContext();

  const [profiles, setProfiles] = useRecoilState(profileAtom)

  const topBar = {
    className: 'student-top-bar',
    name: '山田 一郎',
    avator: '/img/group-2.png',
  };

  if (getCurrentUser) {
    const user = getCurrentUser();
    if (user) {
      topBar.name = profiles.nick_name;
      topBar.avator = `/img/avatar-${profiles.profile_icon + 1}.png`;
    }
  }

  return (
    <Div className={`student-frame ${className}`}>
      <Wrap>
        <StudentTopBar
          className={`student-top-bar-instance ${topBar.className}`}
          title={title}
          name={topBar.name}
          avator={topBar.avator}
          override={
            <ImgWrapper
              src="/img/tomopla-logo-1.png" className='tomopla-logo' />
          }
          vector="/img/vector-2.svg"
        />
        {children}
      </Wrap>
    </Div>
  );
};
