import {
  DownloadButton,
  SubmitButton,
  TeacherHistorySelect,
  styles as formStyles,
} from '@/src/components/HistoryQueryForm';
import { HistoryQueryFormTeacher } from '@/src/components/HistoryQueryForm/HistoryQueryFormTeacher';
import { TableWithInfniteScroll } from '@/src/components/Table';
import { TeacherFrame } from '@/src/components/layouts/TeacherFrame';
import { FormProvider } from 'react-hook-form';
import { RankingQueryForm } from './RankingQueryForm';
import { useLearningHistoryJapaneseDictionary } from './hooks';
import styles from './styles.module.css';
import { themeAtom, profileAtom } from '@/src/components/ModalContents/modalAtom';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { useTheme } from 'styled-components';
import { useToppageTeachers } from '../TeacherTop/hooks';


type Props = {
  onThemeChange: (status: number) => void
}

export const TeacherHistoryDictionary = (props:Props): JSX.Element => {
  const {
    refs,
    hasLoaded,
    table,
    rankingItems,
    methods,
    onSubmit,
    download,
    rankingQueryForm,
  } = useLearningHistoryJapaneseDictionary();

  const { currentUser } = useToppageTeachers();
  const [themenum, setThemenum] = useRecoilState(themeAtom)
  const [profiles, setProfiles] = useRecoilState(profileAtom)

  useEffect(() => {
    if (currentUser) {
      if (!themenum) {
        setThemenum(currentUser?.preference.theme_color);
      }
      const _profiles = {
        nick_name: profiles.nick_name ? profiles.nick_name : currentUser?.nick_name,
        profile_icon: profiles.profile_icon && profiles.profile_icon !== currentUser?.preference.profile_icon ? profiles.profile_icon : currentUser?.preference.profile_icon
      }
      if (
        _profiles.nick_name !== profiles.nick_name ||
        _profiles.profile_icon !== profiles.profile_icon
      ) {
        setProfiles(_profiles);
      }
    }
    
  }, [currentUser, profiles, themenum]);

  useEffect(() => {
    if (currentUser && typeof themenum === 'number') {
      props.onThemeChange(themenum);
    }
  }, [themenum, currentUser]);

  const theme = useTheme();
  
  return (
    <TeacherFrame title="学習履歴 教師用">
      {hasLoaded && (
        <div className={styles.body}>
          <FormProvider {...methods}>
            <HistoryQueryFormTeacher
              onSubmit={onSubmit}
              states={{
                studentSelectDisabled: true,
                dateRangeDisabled: true,
                timeSlotDisabled: true,
              }}
            >
              <div className={formStyles.row}>
                <TeacherHistorySelect />
                <div className={styles.buttons}>
                  {/* <SubmitButton noRuby /> */}
                  <DownloadButton onClick={download} />
                </div>
              </div>
            </HistoryQueryFormTeacher>
          </FormProvider>
          <div className={styles['table-container']}>
            <TableWithInfniteScroll
              ref={refs}
              className={styles.table}
              table={table}
              maxWidth={1480}
              maxHeight={419}
            />
          </div>
          <FormProvider {...rankingQueryForm.methods}>
            <RankingQueryForm
              items={rankingItems}
              onSubmit={rankingQueryForm.onSubmit}
              noClass={(currentUser && currentUser.class_name == null) || false}
            />
          </FormProvider>
        </div>
      )}
    </TeacherFrame>
  );
};
