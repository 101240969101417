import React from 'react';
import ButtonSSOLogin, { ButtonSSOLoginProps } from '../ButtonSSOLogin';
import './style.css';

const ButtonMicrosoftLogin: React.FC<ButtonSSOLoginProps> = (
  props: ButtonSSOLoginProps,
) => {
  return (
    <>
      <ButtonSSOLogin
        {...props}
        className="microsoft"
      />
    </>
  );
};

export default ButtonMicrosoftLogin;
