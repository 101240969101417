.body {
  margin-top: 20px;
  margin-left: 40px;
}

.conditions {
  display: flex;
  column-gap: 26px;
  position: absolute;
}

.table {
  margin-top: 23px;
  position: absolute;
  top: 164px;
  z-index: 0;
}

.table td {
  padding: 14px;
}

.table td button {
  font-size: 16px !important;
  height: fit-content !important;
  width: fit-content !important;
  padding: 12px 19px !important;
  line-height: 1 !important;
  margin: 0 10px !important;
}
.table td button:first-child {
  margin-left: 0 !important;
}
.table td button:last-child {
  margin-right: 0 !important;
}

.modal-header {
  justify-content: flex-start;
}
.modal-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 62px 140px;
}
.modal-content::after {
  content: '';
  position: absolute;
  top: 108px;
  bottom: 108px;
  left: 460px;
  border-left: 1px solid var(--line-color);
}

.modal-content li {
  display: flex;
  align-items: center;
  width: 100%;

  font-size: 40px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 2.5rem;};
  font-family: var(--main-font-family-m);
  
  letter-spacing: 0.05em;
  line-height: 1.25;
}

.modal-content li > div:first-child {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
  padding: 22px 0 23px;
  width: 320px;
  padding-right: 60px;
  font-family: var(--main-font-family-b);
  white-space: nowrap;
  /*  */
}

.modal-content li > div:last-child {
  flex: 1;
  padding: 22px 0 23px 60px;
  line-break: anywhere;
}
.modal-content li:last-child > div:last-child {
  padding: 6px 0 6px 60px;
}

.modal-content button {
  font-size: 34px !important;
  font-family: var(--main-font-family-m) !important;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) {font-size: 2.125rem!important;};
  height: fit-content !important;
  width: fit-content !important;
  padding: 21px 14px 21px 16px !important;
  line-height: 1 !important;
  margin: 0 10px !important;
}
