export const ROUTES = {
  teacher: {
    romaji: '/teacherhistoryromaji',
    video: '/teacherhistoryvideo',
    kanji: '/teacherhistorykanji',
    kanjitest: '/teacherhistorykanjitest',
    dictionary: '/teacherhistorydictionary',
    dictionarykanji: '/teacherhistorydictionarykanji',
    cbt: '/teacherhistorycbt',
    keisan: '/teacherhistorykeisan',
    studentlist: '/teacherstudentlist',
  },
  student: {
    romaji: '/studenthistoryromaji',
    kanji: '/studenthistorykanji',
    kanjitest: '/studenthistorykanjitest',
    dictionary: '/studenthistorydictionary',
    dictionarykanji: '/studenthistorydictionarykanji',
    keisan: '/studenthistorykeisan',
  },
};

export const KEISAN_LICENSES = {
  standard: [15, 16, 17, 18, 19, 20],
  tokyoshoseki: [21, 22, 23, 24, 25, 26],
  keirinkan: [27, 28, 29, 30, 31, 32],
};

export const LICENSES = {
  learning_contents: {
    video: [1],
    cbt: [2],
    pbt: [3],
    dictionary: [4],
    dictionarykanji: [5],
    romaji: [6, 7],
    kanji: [8, 9, 10,],
    hiragana_suuji: [11, 12, 13],
    katakana: [14],
    keisan: [
      ...KEISAN_LICENSES.standard,
      ...KEISAN_LICENSES.tokyoshoseki,
      ...KEISAN_LICENSES.keirinkan,
    ],
  },
};
