import { Notification } from '@/src/components/Notification';
import { TeacherFrame } from '@/src/components/layouts/TeacherFrame';
import { AppMenuButton } from '@/src/components/parts/AppMenuButton';
import {
  formatToDateTimeDisplayLong2,
  formatToTimeDisplay2,
} from '@/src/helpers/date';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Chart } from './Chart';
import { Label } from './Label';
import { Records } from './Records';
import { useToppageTeachers } from './hooks';
import './style.css';
import styles from './styles.module.css';
import { useRecoilState } from 'recoil';
import { profileAtom, themeAtom } from '@/src/components/ModalContents/modalAtom';
import styled, { useTheme } from 'styled-components';
import { useElementVisibility } from '@/src/hooks/useElementVisibility';

const Body = styled('div')(
  ({ theme }) => `
    .header{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 10px;
      border-bottom: 2px solid var(--gray);

      font-family: var(--main-font-family-m);
      @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.25rem;};
      letter-spacing: 0.05em;
      color:var(${theme.textcolor});
      &:last-child {
        font-size: 20px;
        @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.25rem;};
      }
    }
    .nav{
      position: relative;
      background-image: url(/img/${theme.menubg});
      // min-width: 1550px;
      height: 210px;
      .menu{
        position: relative;
      }
    }
  `,
);

type Props = {
  onThemeChange: (status: number) => void;
};

export const TeacherTop = (props: Props): JSX.Element => {
  const navigate = useNavigate();
  const { currentUser, data, school_class, last_login_date, time_difference_from_utc } =
    useToppageTeachers(true);
  const [scope, setScope] = useState("");

  const [themenum, setThemenum] = useRecoilState(themeAtom);

  const [profiles, setProfiles] = useRecoilState(profileAtom);

  const [license, setLicense] = useState<any[]>([]);

  useElementVisibility({ url: "/teachertop", title: "教師トップ" });

  useEffect(() => {
    if (currentUser) {
      if (!themenum) {
        setThemenum(currentUser?.preference.theme_color);
      }
      const _profiles = {
        nick_name: profiles.nick_name
          ? profiles.nick_name
          : currentUser?.nick_name,
        profile_icon:
          profiles.profile_icon &&
            profiles.profile_icon !== currentUser?.preference.profile_icon
            ? profiles.profile_icon
            : currentUser?.preference.profile_icon,
      };
      if (
        _profiles.nick_name !== profiles.nick_name ||
        _profiles.profile_icon !== profiles.profile_icon
      ) {
        setProfiles(_profiles);
      }
      setLicense(currentUser.license.filter(({ status }) => status === 2));

      setScope(!school_class?.grade
        ? '学校全体'
        : !school_class.class_name
          ? `${school_class.grade}年`
          : `${school_class.grade}年${school_class.class_name}組`)
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser && typeof themenum === 'number') {
      props.onThemeChange(themenum);
      currentUser.preference.theme_color = themenum;
    }
  }, [themenum, currentUser]);

  const redirect = (path: string) => {
    if (path.match(/^http/)) {
      window.location.href = path;
    } else {
      window.location.href =
        window.location.protocol + '//' + window.location.host + path;
    }
  };

  const theme = useTheme();

  return (
    <TeacherFrame title="ホーム 教師用" className="teacher-top">
      <Body className={styles.body}>
        <div className={styles.content}>
          <Notification html={data?.notice} />
          <div className={styles.statistics}>
            <div className={`${styles.header} header`}>
              <div>
                前日までの集計{' '}
                {data?.update_time
                  ? `${formatToTimeDisplay2(data.update_time, time_difference_from_utc)}更新`
                  : '00:00更新'}
              </div>
              <div>
                前回ログイン：
                {last_login_date
                  ? formatToDateTimeDisplayLong2(last_login_date, time_difference_from_utc)
                  : '-'}
              </div>
            </div>
            <div className={styles.body}>
              <Chart
                scope={scope}
                data={data?.graph_data_study_time || []}
                theme={theme}
              />
              <Records
                records={data || {}}
                scope={scope}
                theme={theme}
                licenses={
                  currentUser?.license
                    .filter(({ status }) => status !== 0)
                    .map((license) => license.learning_contents) || []
                }
              />
            </div>
          </div>
        </div>
        <div className={styles.nav && 'nav'}>
          <Label>アプリメニュー</Label>
          <div className="app-menu-wrapper">
            {license.map(
              (item: any, i: number) =>
                item.button_name &&
                new Date(item.expiration_date) >= new Date(new Date().toDateString()) && (
                  <AppMenuButton
                    key={i}
                    className={item.button_name}
                    onClick={() => redirect(`${item.path}`)}
                  />
                ),
            )}
          </div>
        </div>
      </Body>
    </TeacherFrame>
  );
};
