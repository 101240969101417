import dayGridPlugin from '@fullcalendar/daygrid';
import mkCalendarPlugin from '@/src/helpers/fullcalendar-holiday';
import FullCalendar from '@fullcalendar/react';
import './StudentTopCalender.css';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { DatesSetArg, EventInput } from '@fullcalendar/core';

type Props = {
  setCalendarRef?: any;
  view?: any;
  date?: any;
  initialView?: any;
  theme: any;
  data?: any | undefined;
  onChange: (arg: any) => void;
};

const GroupWrap = styled('div')(
  ({ theme }) => `
    border: 1px solid;
    border-color: var(${theme.maincolor});
    background-color: var(${theme.lightcolor});
    border-radius: 16px;
    width: 478px;
    height: 534px;
    position: relative;
    margin-top: 11px;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.4);
    .fc-group40 {
      height: 90px;
      left: 0;
      position: relative;
      top: -11px;
      width: 480px;
      .overlap-6 {
        height: 90px;
        position: relative;
        width: 478px;
        .text-wrapper-28 {
          color: var(--white);
          font-family: var(--main-font-family-db);
          letter-spacing: 1.2px;
          text-align: center;
          width: 370px;
          font-size: 24px;
          @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.5rem};
          
          line-height: 36px;
          position: absolute;
          top: 30px;
          left: 54px;
        }
      }
      .rectangle-2 {
        background-color: var(${theme.maincolor});
        border-radius: 16px 16px 0px 0px;
        height: 79px;
        left: 0;
        position: absolute;
        top: 11px;
        width: 478px;
      }
    }
    .fc-day {
      color:var(${theme.textcolor})
    }
    .fc-toolbar-title{
      color:var(${theme.textcolor})
    }
    .fc-icon{
      color:var(${theme.textcolor})
    }
    .stitle{
      font-size: 1.56em;
      position: absolute;
      top: 87px;
      left: 0;
      right: 0;
      margin: auto;
      width: 169px;
      text-align: center;
      color:var(${theme.textcolor})
    }
    .wk{
      font-size: 0.75em;
      position: absolute;
      top:138px;
      @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {
       top:129px;
      }
      @media only screen and (any-hover:none) and (min-width:1200px) {
        top:144px;
      }
      color:var(${theme.textcolor})
    }
    .wk.mon{
      left: 33px;
    }
    .wk.tue{
      left: 103px;
    }
    .wk.wed{
      left: 162px;
    }
    .wk.thu{
      left: 228px;
    }
    .wk.fri{
      left: 293px;
    }
    .wk.sat{
      left: 365px;
      color: var(--light-blue);
    }
    .wk.sun{
      left: 424px;
      color: var(--tomopla-color);
    }
  `
)

const StudentTopCalender = ({
  setCalendarRef,
  view,
  date,
  theme,
  data,
  onChange,
  ...otherProps
}: Props) => {

  const [events, setEvents] = useState<EventInput[]>([]);
  const [title, setTitle] = useState<any>();
  const medalType = ['bronze', 'silver', 'gold'];

  useEffect(() => {
    setEvents(
      data?.reduce((acc: any, d: any) => {
        d.date_data.map((e: any) => {
          acc.push({
            start: new Date(d.year, d.month - 1, e.day),
            className: medalType[e.medal_type - 1]
          })
        })
        return acc;
      }, [data])
    );
    const dom1_list = document.getElementsByClassName("fc-toolbar-title");
    if (dom1_list) {
      const dom1 = dom1_list[0];
      console.log(dom1?.innerHTML.split('年')[0].split('/'))
      const _ttls = dom1?.innerHTML.split('年')[0].split('/');
      if (_ttls) {
        //setTitle(`<span>${_ttls[0]}<Kanji kana="ねん">年</Kanji>${_ttls[1]}<Kanji kana="がつ">月</Kanji></span>`)
        setTitle(`${_ttls[0]}<ruby>年<rt>ねん</rt></ruby>${_ttls[1]}<ruby>月<rt>がつ</rt></ruby>`)
      }
    }
  }, [data])

  useEffect(() => {
    const ua = navigator.userAgent.toLocaleLowerCase();
    if (ua.indexOf("macintosh") > -1 && "ontouchend" in document) {
      console.log(ua);
    }

  }, [])

  console.log(events)

  const eventDidMount = (e: any) => {
    const el = e.el;
    //Google Calender 日本祝日（APIキーは暫定）
    if (el.classList.contains('holiday-event')) {
      if (e.view.type == 'dayGridMonth') {
        el.closest('.fc-daygrid-day').classList.add('is_holiday');
      }
    }
    //
    if (el.classList.contains('silver')) {
      if (e.view.type == 'dayGridMonth') {
        el.closest('.fc-daygrid-day').classList.add('medal-silver');
      }
    }
    if (el.classList.contains('bronze')) {
      if (e.view.type == 'dayGridMonth') {
        el.closest('.fc-daygrid-day').classList.add('medal-bronze');
      }
    }
    if (el.classList.contains('gold')) {
      if (e.view.type == 'dayGridMonth') {
        el.closest('.fc-daygrid-day').classList.add('medal-gold');
      }
    }
  };

  return (
    <>
      <GroupWrap className='fc'>
        <div className="fc-group40">
          <div className="overlap-6">
            <div className="rectangle-2" />
            <div className="group-41">
              <div className="overlap-group-8">
                <div className="ellipse" />
                <div className="rectangle-3" />
              </div>
            </div>
            <div className="group-42">
              <div className="overlap-group-8">
                <div className="ellipse" />
                <div className="rectangle-3" />
              </div>
            </div>
            <div className="text-wrapper-28">ログインカレンダー</div>
          </div>
        </div>
        <FullCalendar
          plugins={[dayGridPlugin, mkCalendarPlugin]}
          initialView="dayGridMonth"
          weekends={true}
          //events={() => getEvents()}
          events={events}
          eventDidMount={eventDidMount}
          datesSet={(arg: DatesSetArg) => {
            console.log(arg.start)
            onChange(arg.start)
          }}
          firstDay={1}
          titleFormat={{ year: 'numeric', month: 'numeric' }}
          locale="ja"
          dayCellContent={(event: any) =>
            (event.dayNumberText = event.dayNumberText.replace('日', ''))
          }
          eventClick={(info) => {
            info.jsEvent.preventDefault();
          }}
          eventSources={[
            {
              className: 'holiday-event',
            },
          ]}
        />
        <div className='stitle' dangerouslySetInnerHTML={{ __html: title }}></div>
        <span className="wk mon">げつ</span>
        <span className="wk tue">か</span>
        <span className="wk wed">すい</span>
        <span className="wk thu">もく</span>
        <span className="wk fri">きん</span>
        <span className="wk sat">ど</span>
        <span className="wk sun">にち</span>
      </GroupWrap>
    </>
  );
};

export default StudentTopCalender;
