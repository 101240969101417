import { z } from 'zod';

export const learningHistoryKanjiDictionaryQueryFormSchema = z.object({
  class_index: z.number().nullish().or(z.enum([""])),
  page: z.number().optional(),
});

export type LearningHistoryKanjiDictionaryQueryFormSchema = z.infer<
  typeof learningHistoryKanjiDictionaryQueryFormSchema
>;
