/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import './style.css';

interface Props {
  className: any;
  src: string;
}

export const ImgWrapper = ({ className, src }: Props): JSX.Element => {
  return <img className={`img-wrapper ${className}`} alt="Img" src={src} />;
};
