import { ButtonHTMLAttributes, ReactNode } from 'react';
import './StudentHistoryKnjButton.scss';

type Props = Partial<{
  type: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  disabled: ButtonHTMLAttributes<HTMLButtonElement>['disabled'];
  tabIndex: ButtonHTMLAttributes<HTMLButtonElement>['tabIndex'];
  className: any;
  onClick?: () => void;
  children: ReactNode;
}>;

export const StudentHistoryKnjButton = ({
  type,
  disabled,
  tabIndex,
  className,
  onClick,
  children,
}: Props) => {
  return (
    <button
      className={`shkb-element-default-wrapper ${className}`}
      type={type}
      disabled={disabled}
      tabIndex={tabIndex}
      onClick={onClick}
    >
      <div className="overlap-group">
        <div className="group">
          <div className="rectangle" />
        </div>
        <div className="div">
          <div className="text-wrapper">{children}</div>
        </div>
      </div>
    </button>
  );
};
