import { KanjiTestData } from '@/src/__generated__';
import {
  FormItemCategorySelect,
  HistoryQueryFormStudent,
  KanjiToggleStudent,
  StudentHistorySelect,
  styles as formStyles,
} from '@/src/components/HistoryQueryForm';
import {
  profileAtom,
  themeAtom,
} from '@/src/components/ModalContents/modalAtom';
import { Table, TableScrollCotainer } from '@/src/components/Table';
import { StudentFrame } from '@/src/components/layouts/StudentFrame';
import { KanjiApplicationType } from '@/src/types/KanjiApplicationType';
import {
  GroupColumnDef,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useEffect, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { useRecoilState } from 'recoil';
import styled, { useTheme } from 'styled-components';
import { useToppageStudent } from '../StudentTop/hook';
import {
  FREQUENCY_TYPES,
  RowData,
  captionColumns,
  teachingUnitColumn,
} from './columns';
import { useTableContext, withTableContext } from './context';
import { useLearningHistoryKanjiSkillTest } from './hooks';
import clsx from 'clsx';
import styles from './styles.module.css';

type Props = {
  onThemeChange: (status: number) => void;
};

export const StudentHistoryKanjiTest = (props: Props): JSX.Element => {
  const { currentUser } = useToppageStudent();
  const [themenum, setThemenum] = useRecoilState(themeAtom);
  const [profiles, setProfiles] = useRecoilState(profileAtom);
  const {
    data,
    hasLoaded,
    conditions: { categories },
    type,
    methods,
    onSubmit,
  } = useLearningHistoryKanjiSkillTest();

  useEffect(() => {
    if (currentUser) {
      if (!themenum) {
        setThemenum(currentUser?.preference.theme_color);
      }
      const _profiles = {
        nick_name: profiles.nick_name
          ? profiles.nick_name
          : currentUser?.nick_name,
        profile_icon:
          profiles.profile_icon &&
          profiles.profile_icon !== currentUser?.preference.profile_icon
            ? profiles.profile_icon
            : currentUser?.preference.profile_icon,
      };
      if (
        _profiles.nick_name !== profiles.nick_name ||
        _profiles.profile_icon !== profiles.profile_icon
      ) {
        setProfiles(_profiles);
      }
    }
  }, [currentUser, profiles, themenum]);

  useEffect(() => {
    if (currentUser && typeof themenum === 'number') {
      props.onThemeChange(themenum);
    }
  }, [themenum, currentUser]);

  const theme = useTheme();

  return (
    <StudentFrame title="学習りれき">
      <div className={styles.body}>
        <FormProvider {...methods}>
          <HistoryQueryFormStudent onSubmit={onSubmit}>
            <div className={formStyles['row']}>
              <StudentHistorySelect labelWidth={180} />
              {categories.length > 0 && (
                <FormItemCategorySelect categories={categories} />
              )}
            </div>
            {type && <KanjiToggleStudent type={type} />}
          </HistoryQueryFormStudent>
        </FormProvider>
        {hasLoaded && type && (
          <TableScrollCotainer className={styles.tables}>
            <KanjiSkillTestTableCaption
              type={type}
              className={clsx('sthistory-head',styles['caption-table'])}
            />
            {data?.learning_histories.map((history, i) => {
              const detail = history.details[0];
              const teachingUnit = data.teaching_units.find(
                (e) =>
                  e.teaching_unit_id === history.teaching_unit_serial_number,
              );
              if (!detail || !teachingUnit) return <></>;
              return (
                <KanjiSkillTestTable
                  className={clsx('sthistory', i === 0 && styles.collapsed)}
                  key={i}
                  data={[
                    ...(detail.pretest
                      ? toTableRow('プレテスト', detail.pretest)
                      : []),
                    ...(detail.test ? toTableRow('テスト', detail.test) : []),
                    ...(detail.matome
                      ? toTableRow('まとめ', detail.matome)
                      : []),
                  ]}
                  column={teachingUnitColumn(teachingUnit, history)}
                />
              );
            })}
          </TableScrollCotainer>
        )}
      </div>
    </StudentFrame>
  );
};

const KanjiSkillTestTable = withTableContext<{
  className?: string;
  column: GroupColumnDef<any>;
  data: RowData[];
}>(({ className, column, data }) => {
  const { columnExpanded } = useTableContext();
  const columns = useMemo(
    () =>
      columnExpanded
        ? [column]
        : [
            {
              ...column,
              columns:
                column.columns &&
                [
                  column.columns[0],
                  column.columns[column.columns.length - 1],
                ].filter(Boolean),
            },
          ],
    [columnExpanded, column],
  );
  const table = useReactTable({
    columns,
    data,
    getSubRows: (row) => row.values,
    getCoreRowModel: getCoreRowModel(),
  });
  return <Table className={className} table={table} />;
});

const KanjiSkillTestTableCaption = ({
  type,
  className,
}: {
  type: KanjiApplicationType;
  className?: string;
}) => {
  const columns = useMemo(() => captionColumns(type), [type]);
  const data = useMemo(
    () =>
      type === KanjiApplicationType.Kanji
        ? [
            {
              type: 'プレテスト',
              values: FREQUENCY_TYPES.map((f) => ({ frequency_type: f })),
            },
            {
              type: 'テスト',
              values: FREQUENCY_TYPES.map((f) => ({ frequency_type: f })),
            },
          ]
        : [
            {
              type: 'まとめ',
              values: FREQUENCY_TYPES.map((f) => ({ frequency_type: f })),
            },
          ],
    [type],
  );

  const table = useReactTable({
    columns,
    data,
    getSubRows: (row) => row.values,
    getCoreRowModel: getCoreRowModel(),
  });

  return <Table className={className} table={table} />;
};

const NUM_OF_ROWS = 4;
const parseResults = (results: any) => {
  return results.map((r: any, _i: number) =>{
    if (r["judgement"] == "×") {
      return "×"
    }
    if (r["judgement"] == "丸") {
      return "○"
    }
    if (r["judgement"] == "花丸") {
      return "◎"
    }
    return r["judgement"]
  })
}
const toTableRow = (type: RowData['type'], data: KanjiTestData): RowData[] => {
  return Array.from({ length: NUM_OF_ROWS }).map((_, i) => ({
    type,
    // @ts-ignore
    frequency_type: data[`frequency_type_${i + 1}`] || '',
    // @ts-ignore
    historical_date: data[`historical_date_${i + 1}`] || '',
    // @ts-ignore
    results: parseResults(data[`result_${i + 1}`]) || [],
    // @ts-ignore
    total: data[`total_${i + 1}`] || '',
  }));
};
