import { useEffect, useLayoutEffect } from 'react'
import './style.scss';
import useContactForm from './hooks';
import { Confirm } from './Confirm';
import { Rule } from './Rule';
import { Policy } from './Policy';
import { Thanks } from './Thanks';

import { Helmet } from 'react-helmet-async';

export const ContactForm = (): JSX.Element => {
  const componentName = "ご意見・お問い合わせ｜光村教育図書";

  const {
    form: { register, handleSubmit, onSubmit, errors, isEmail, formData, pageState, onEdit, onSendMail, onShowPolicy, onShowRule, onTop }
  } = useContactForm();

  useLayoutEffect(() => {
    const scroller = document.querySelector("#contactform");
    scroller?.scrollBy({ left: 0, top: -10000, behavior: 'smooth' })
  }, [pageState])

  return (
    <div className="contact-form" id="contactform">
      <Helmet>
        <title>{componentName}</title>
        <meta name="description" content="ご意見・お問い合わせはこちら。光村教育図書" />
        <meta name="keywords" content="光村教育図書,ご意見,お問い合わせ" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="ご意見・お問い合わせ｜光村教育図書" />
        <meta property="og:description" content="ご意見・お問い合わせはこちら。光村教育図書" />
        <meta property="og:site_name" content="ご意見・お問い合わせ｜光村教育図書" />
        <meta property="og:image" content="/img/ogp.png" />    
      </Helmet>
      <div className="Wrapper">
        <div className="Container">
          <header>
            <h1>ご意見・お問い合わせ</h1>
          </header>
          <div className="Contents">
            {pageState === 0 &&
              (
                <form onSubmit={handleSubmit(onSubmit)}>
                  <table className="FormList">
                    <tbody>
                      <tr>
                        <th>内容<span className="required">必須</span></th>
                        <td>
                          <div className="radio_custom">
                            <span className="radio_custom_field">
                              <label>
                                <input {...register("type")} type="radio" name="type" value="ご意見" />
                                <span className="radio_custom_field-text">ご意見</span>
                              </label>
                            </span>
                            <span className="radio_custom_field">
                              <label>
                                <input {...register("type")} type="radio" name="type" value="お問い合わせ" />
                                <span className="radio_custom_field-text">お問い合わせ</span>
                              </label>
                            </span>
                          </div>
                          {errors.type?.message && <p className='err'>選択してください。</p>}
                        </td>
                      </tr>
                      <tr>
                        <th>お名前</th>
                        <td>
                          <input type="text" {...register("name")} />
                        </td>
                      </tr>
                      <tr>
                        <th>年齢</th>
                        <td>
                          <div className="select-wrap">
                            <select {...register("age")}>
                              <option value="">選択してください</option>
                              <option value="10代以下">10代以下</option>
                              <option value="20代">20代</option>
                              <option value="30代">30代</option>
                              <option value="40代">40代</option>
                              <option value="50代">50代</option>
                              <option value="60代以降">60代以降</option>
                            </select>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>ご職業</th>
                        <td>
                          <div className="select-wrap">
                            <select {...register("business")}>
                              <option value="">選択してください</option>
                              <option value="教員（教育関係者）">教員（教育関係者）</option>
                              <option value="保護者">保護者</option>
                              <option value="小学生">小学生</option>
                              <option value="中学生">中学生</option>
                              <option value="その他">その他</option>
                            </select>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <th>都道府県</th>
                        <td>
                          <div className="select-wrap">
                            <select {...register("prefecture_name")}>
                              <option value="">選択してください</option>
                              <option value="北海道">北海道</option>
                              <option value="青森県">青森県</option>
                              <option value="岩手県">岩手県</option>
                              <option value="宮城県">宮城県</option>
                              <option value="秋田県">秋田県</option>
                              <option value="山形県">山形県</option>
                              <option value="福島県">福島県</option>
                              <option value="茨城県">茨城県</option>
                              <option value="栃木県">栃木県</option>
                              <option value="群馬県">群馬県</option>
                              <option value="埼玉県">埼玉県</option>
                              <option value="千葉県">千葉県</option>
                              <option value="東京都">東京都</option>
                              <option value="神奈川県">神奈川県</option>
                              <option value="新潟県">新潟県</option>
                              <option value="富山県">富山県</option>
                              <option value="石川県">石川県</option>
                              <option value="福井県">福井県</option>
                              <option value="山梨県">山梨県</option>
                              <option value="長野県">長野県</option>
                              <option value="岐阜県">岐阜県</option>
                              <option value="静岡県">静岡県</option>
                              <option value="愛知県">愛知県</option>
                              <option value="三重県">三重県</option>
                              <option value="滋賀県">滋賀県</option>
                              <option value="京都府">京都府</option>
                              <option value="大阪府">大阪府</option>
                              <option value="兵庫県">兵庫県</option>
                              <option value="奈良県">奈良県</option>
                              <option value="和歌山県">和歌山県</option>
                              <option value="鳥取県">鳥取県</option>
                              <option value="島根県">島根県</option>
                              <option value="岡山県">岡山県</option>
                              <option value="広島県">広島県</option>
                              <option value="山口県">山口県</option>
                              <option value="徳島県">徳島県</option>
                              <option value="香川県">香川県</option>
                              <option value="愛媛県">愛媛県</option>
                              <option value="高知県">高知県</option>
                              <option value="福岡県">福岡県</option>
                              <option value="佐賀県">佐賀県</option>
                              <option value="長崎県">長崎県</option>
                              <option value="熊本県">熊本県</option>
                              <option value="大分県">大分県</option>
                              <option value="宮崎県">宮崎県</option>
                              <option value="鹿児島県">鹿児島県</option>
                              <option value="沖縄県">沖縄県</option>
                              <option value="国外">国外</option>
                            </select>

                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>メールアドレス{isEmail && (<span className="required">必須</span>)}</th>
                        <td>
                          <input type="email" {...register("mail_address")} />
                          {errors.mail_address?.message && <p className='err'>{errors.mail_address?.message}</p>}
                        </td>
                      </tr>
                      <tr>
                        <th>電話番号</th>
                        <td>
                          <input type="tel" {...register("tel")} />
                        </td>
                      </tr>
                      <tr>
                        <th>メッセージ<span className="required">必須</span></th>
                        <td>
                          <textarea {...register("message")}></textarea>
                          {errors.message?.message && <p className='err'>{errors.message?.message}</p>}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <div className="btn_wrap">
                    <div className="btn"><button type="submit">確認する</button></div>

                  </div>

                </form>
              )}
            {pageState === 1 && (
              <>
                <Confirm data={formData} />
                <div className="btn_wrap">
                  <div className="btn"><button type="button" onClick={onSendMail}>送信する</button></div>
                  <div className="btn btn_back"><button type="button" onClick={onEdit}>内容を変更する</button></div>
                </div>
              </>
            )}
            {pageState === 2 && (
              <>
                <Thanks />
              </>
            )}
            {pageState === 3 && (
              <>
                <Rule />
              </>
            )}
            {pageState === 4 && (
              <>
                <Policy />
              </>
            )}
          </div>

          <footer>
            {pageState === 2 &&
              <div className="btn_wrap thanks">
                <p className="btn_return"><a href="#" onClick={onTop}>← トップへもどる</a></p>
              </div>
            }
            {pageState === 3 &&
              <div className="btn_wrap">
                <p className="btn_return"><a href="#" onClick={onEdit}>← トップへもどる</a></p>
              </div>
            }
            {pageState === 4 &&
              <div className="btn_wrap">
                <p className="btn_return"><a href="#" onClick={onEdit}>← トップへもどる</a></p>
              </div>
            }
            <ul className="foot_nav">
              <li><a href="#" onClick={onShowRule}>利用規約</a></li>
              <li><a href="#" onClick={onShowPolicy}>個人情報保護に関する基本方針</a></li>
            </ul>
          </footer>
        </div>
      </div>
    </div>
  )
}
