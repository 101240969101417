import { Category } from "@/src/__generated__";
import { LICENSES } from "@/src/components/HistoryQueryForm/constants";
import { CurrentUser } from "@/src/middleware/auth/AuthContext";

export const get_category_selection = () => {
  const storedCategorySelection = sessionStorage.getItem('category_selection');
  if (storedCategorySelection) {
    return JSON.parse(storedCategorySelection);
  }
  return {
    'category_index': 0,
    'teaching_unit_selection': {},
  };
}
export  const save_category_selection = (category_index: number, type: number, category_id: number|null, teaching_unit_id: number | null | undefined = undefined) => {
  const categorySelection = get_category_selection();
  categorySelection.category_index = category_index;
  categorySelection.type = type;
  categorySelection.category_id = category_id;
  if (category_id !== null && teaching_unit_id !== undefined) {
    if (teaching_unit_id !== null) {
      categorySelection.teaching_unit_selection[category_id] = teaching_unit_id;
    }
    else {
      delete categorySelection.teaching_unit_selection[category_id];
    }
  }
  sessionStorage.setItem('category_selection', JSON.stringify(categorySelection));
}

export const get_default_type = (user: CurrentUser|null) => {
  if (!user) return 1;
  let default_type = 1;
  if (user) {
    const { license } = user;
    const hasKanjiLicense = license.some((l) =>
      LICENSES.learning_contents.kanji.includes(l.learning_contents),
    );
    const hasHiraganaLicense = license.some((l) =>
      LICENSES.learning_contents.hiragana_suuji.includes(l.learning_contents),
    );
    const hasKatakanaLicense = license.some((l) =>
      LICENSES.learning_contents.katakana.includes(l.learning_contents),
    );

    if (!hasKanjiLicense) {
      if (hasHiraganaLicense) {
        default_type = 2; // ひらがな
      } else if (hasKatakanaLicense) {
        default_type = 3; // カタカナ
      }
    }
  }
  return default_type;
}

export const get_current_type_category_id = (user: CurrentUser|null, category_index: number, categories: Category[] = []) => {
  const category_selection = get_category_selection();
  let type = null;
  let category_id = null;
  if (categories.length > 0) {
    if (categories[category_index]) {
      const category = categories[category_index];
      type = category.type;
      category_id = category.category_id;
    }
    else {
      type = get_default_type(user);
      category_id = 0;
    }
  }
  else {
    category_id = category_selection.category_id || 0;
    type = category_selection.type || get_default_type(user);
  }
  const teaching_unit_id = category_selection.teaching_unit_selection[category_id] || null;
  return [type, category_id, teaching_unit_id];
}

export const get_category_index_from_type_id = (type: number, category_id: number, categories: Category[]) => {
  for (const i in categories) {
    if (categories[i].type == type && categories[i].category_id == category_id) {
      return parseInt(i);
    }
  }
  return 0;
}