import './style.css';
import { useNavigate } from 'react-router-dom';

import SubButton from '@/src/components/parts/SubButton';

export const Logout = (): JSX.Element => {
  const navigate = useNavigate();
  return (
    <div className="logout">
      <div className="overlap-wrapper-11">
        <div className="overlap-58">
          <div className="message-2">
            <div className="overlap-60">
              <div className="text-wrapper-327">
                ログアウトしました。
              </div>
            </div>
          </div>
          <div className="button-wrapper">
            <SubButton
              className="sub-button-back-2"
              onClick={() => {
                navigate('/login');
              }}
            >
              <div className="text-wrapper-back">ログインページへもどる</div>
            </SubButton>
          </div>

        </div>
      </div>
    </div>
  );
};
