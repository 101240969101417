import { KanjiApplicationType } from '@/src/types/KanjiApplicationType';
import clsx from 'clsx';
import { useLocation, useNavigate } from 'react-router-dom';
import Kanji from '../../auth/Kanji';
import { StudentHistoryKnjButton } from '../../parts/StudentHistoryKnjButton';
import { StudentHistoryTestButton } from '../../parts/StudentHistoryTestButton';
import { ROUTES } from '../constants';
import styles from '../styles.module.css';

export const KanjiToggleStudent = ({
  type,
}: {
  type: KanjiApplicationType;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div className={clsx(styles['toggle-container'], styles.student)}>
      <StudentHistoryKnjButton
        className={location.pathname === ROUTES.student.kanji && 'active'}
        onClick={() => navigate(ROUTES.student.kanji)}
      >
        {type === KanjiApplicationType.Kanji ? (
          <>
            <Kanji kana="かん">漢</Kanji>
            <Kanji kana="じ">字</Kanji>
            スキル
          </>
        ) : (
          LABELS[type]
        )}
      </StudentHistoryKnjButton>
      <StudentHistoryTestButton
        className={location.pathname === ROUTES.student.kanjitest && 'active'}
        onClick={() => navigate(ROUTES.student.kanjitest)}
      >
        {LABELS_TEST[type]}
      </StudentHistoryTestButton>
    </div>
  );
};

export const KanjiToggleTeacher = ({
  type,
}: {
  type: KanjiApplicationType;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div className={styles['toggle-container']}>
      <StudentHistoryKnjButton
        className={location.pathname === ROUTES.teacher.kanji && 'active'}
        onClick={() => navigate(ROUTES.teacher.kanji)}
      >
        {LABELS[type]}
      </StudentHistoryKnjButton>
      <StudentHistoryTestButton
        className={location.pathname === ROUTES.teacher.kanjitest && 'active'}
        onClick={() => navigate(ROUTES.teacher.kanjitest)}
      >
        {LABELS_TEST[type]}
      </StudentHistoryTestButton>
    </div>
  );
};

const LABELS = {
  [KanjiApplicationType.Kanji]: '漢字スキル',
  [KanjiApplicationType.Hiragana_Suji]: 'ひらがな・すうじスキル',
  [KanjiApplicationType.Katakakana]: 'カタカナスキル',
};
const LABELS_TEST = {
  [KanjiApplicationType.Kanji]: 'プレテスト・テスト',
  [KanjiApplicationType.Hiragana_Suji]: 'まとめ',
  [KanjiApplicationType.Katakakana]: 'まとめ',
};
