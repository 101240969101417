import { csvMovieLearningHistoriesCsvGet } from '@/src/__generated__';
import { withSaveFile } from '@/src/helpers/file';
import { useSyncHistoryQueryConditions } from '@/src/hooks/useHistoryQueryConditions';
import { useTeacherContext } from '@/src/hooks/useTeacherContext';
import {
  LearningHistoryMovieQueryFormSchema,
  learningHistoryMovieQueryFormSchema,
} from '@/src/schema/learning-history-movie-query-form-schema';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';

export const useLearningHistoryVisualMaterials = () => {
  const {
    hasLoaded,
    childrenList,
    schoolClassList,
    historyQueryConditions,
    classIndexToClass,
  } = useTeacherContext();

  const download = ({
    class_index,
    ...params
  }: LearningHistoryMovieQueryFormSchema) => {
    return withSaveFile(
      csvMovieLearningHistoriesCsvGet({
        ...params,
        ...(params.user_uuid ? {} : classIndexToClass(class_index)),
      }),
    );
  };

  const methods = useForm<LearningHistoryMovieQueryFormSchema>({
    defaultValues: {
      class_index: historyQueryConditions.class_index,
      user_uuid: historyQueryConditions.user_uuid,
      from_date: historyQueryConditions.from_date,
      to_date: historyQueryConditions.to_date,
    },
    resolver: zodResolver(learningHistoryMovieQueryFormSchema),
  });

  useSyncHistoryQueryConditions(
    ['class_index', 'user_uuid', 'from_date', 'to_date'],
    methods.watch,
  );

  return {
    hasLoaded,
    schoolClassList,
    childrenList,
    methods,
    onSubmit: methods.handleSubmit(download),
  };
};
