import {
  LibsDomainModelInterfaceToppageGetToppageForChildrenResponse,
  toppageChildrenCalendarGet,
  toppageChildrenGet,
} from '@/src/__generated__';
import { CurrentUser, useAuthContext } from '@/src/middleware/auth/AuthContext';
import { useEffect, useState } from 'react';

export const useToppageStudent = () => {
  const { getCurrentUser } = useAuthContext();
  const currentUser: CurrentUser | null =
    (getCurrentUser ? getCurrentUser() : null) || null;
  const [data, setData] =
    useState<LibsDomainModelInterfaceToppageGetToppageForChildrenResponse>();
  const [calender, setCalender] = useState<any>();

  useEffect(() => {
    if (
      window.location.pathname === '/' ||
      window.location.pathname === '/studenttop'
    ) {
      toppageChildrenGet().then((res) => {
        setData(res.data);
        setCalender(res.data.login_calendar);
      });
    }
  }, []);

  const queryCalender = async (e: any) => {
    const dt = e;
    dt.setDate(dt.getDate() + 6); //月曜始まりのため6日を加算
    const params = {
      year: dt.getFullYear(),
      month: dt.getMonth() + 1,
    };
    const res = await toppageChildrenCalendarGet(params);
    if (res.status !== 200) {
      return;
    }
    const array = [];
    array.push(res.data);
    setCalender(array);
  };

  return {
    currentUser,
    data,
    last_login_date: currentUser?.last_login_date
      ? currentUser.last_login_date
      : undefined,
    calender,
    queryCalender,
    time_difference_from_utc: currentUser?.time_difference_from_utc,
  };
};

const MOCK_NOTIFICATION_ITEMS: {
  date: Date;
  message: string;
  url: string;
}[] = [
  { date: new Date(), message: 'アップデートしました', url: '' },
  { date: new Date(), message: 'アップデートしました', url: '' },
  { date: new Date(), message: 'アップデートしました', url: '' },
  { date: new Date(), message: 'アップデートしました', url: '' },
  { date: new Date(), message: 'アップデートしました', url: '' },
];
