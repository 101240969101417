// useController: https://react-hook-form.com/docs/usecontroller
import { Stack, TextField, ThemeProvider, Typography, createTheme } from '@mui/material';
import { FieldValues, useController } from 'react-hook-form'
import { RHFTextFieldProps } from '../../types/RHFTextFieldProps';

export const RHFTextFieldPW = <T extends FieldValues>({
  name,
  control,
  type,
  placeholder,
  multiline,
  rows,
  tabIndex,
  disabled
}: RHFTextFieldProps<T>) => {
  // 入力フォームを共通化したいので、RHFのControllerの代わりにuseControllerを使用しています
  const {
    field,
    formState: { errors },
  } = useController({ name, control })

  const errorMessage = errors?.[name]?.message as string

  return (
    <ThemeProvider theme={theme}>
      <Stack direction="row" alignItems="center" m={2}>
        {multiline ?
          <TextField
            value={field.value ?? ''}
            inputProps={{ maxLength: 200, pattern: "^[a-zA-Z0-9_]+$" }}
            inputRef={field.ref}
            name={field.name}
            onChange={field.onChange}
            onBlur={field.onBlur}
            type={type}
            placeholder={placeholder}
            multiline
            rows={rows}
            tabIndex={tabIndex}
            disabled={disabled}
          /> :
          <TextField
            value={field.value ?? ''}
            inputRef={field.ref}
            name={field.name}
            onChange={field.onChange}
            onBlur={field.onBlur}
            type={type}
            placeholder={placeholder}
            tabIndex={tabIndex}
            disabled={disabled}
          />
        }
        {/* {errorMessage && (
          <Typography variant="body1" ml={3} color="red">
            {errorMessage}
          </Typography>
        )} */}
      </Stack>
    </ThemeProvider>

  )
}

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '22px',
          backgroundColor: 'white',
          fontFamily: ['"A-OTF UD Shin Maru Go Pr6N-DB"', '"Shin Maru Go DemiBold"', 'Helvetica'].join(',') + ' !important',
        },
        input: {
          boxShadow: '0px 1px 5px 0px #00000070 inset',
          borderRadius: '17px',
          border: '5px solid #D9D9D9',
          backgroundColor: 'white',
          padding: '15px 19px',
          fontSize: '25px',
          fontFamily: ['"A-OTF UD Shin Maru Go Pr6N-DB"', '"Shin Maru Go DemiBold"', 'Helvetica'].join(',') + ' !important',
          width: '100vw',
          "&::placeholder": {
            fontFamily: ['"A-OTF UD Shin Maru Go Pr6N-DB"', '"Shin Maru Go DemiBold"', 'Helvetica'].join(',') + ' !important',
          },
          "&[name='memo']": {
            padding: '23px 25px',
            lineHeight: '1.3',
            fontFamily: ['"A-OTF UD Shin Maru Go Pr6N-DB"', '"Shin Maru Go DemiBold"', 'Helvetica'].join(',') + ' !important',
          },
          "&[name='memo'].Mui-disabled": {
            boxShadow: 'none'
          },
          //ブラウザの補完機能で背景色を変えない
          "&:-webkit-autofill": {
            transition: 'background-color 5000s ease-in-out 0s !important'
          },
          "&.Mui-disabled": {
            boxShadow: 'none',
            WebkitTextFillColor: 'rgba(0,0,0,1)'
          },
          "&.Mui-disabled::placeholder": {
            WebkitTextFillColor: 'rgba(0,0,0,0)'
          }
        },
        notchedOutline: {
          border: 'none',
        },
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          position: 'absolute',
          top: '110px',
          left: '-4px',
          fontFamily: ['"A-OTF UD Shin Maru Go Pr6N-DB"', '"Shin Maru Go DemiBold"', 'Helvetica'].join(',') + ' !important',
          fontSize: '24px',
          letterSpacing: '-1px'
        },
      },
    },
  },
});