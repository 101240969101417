import {
  LibsDomainModelInterfaceToppageGetToppageForTeachersResponse,
  toppageTeachersGet,
} from '@/src/__generated__';
import { CurrentUser, useAuthContext } from '@/src/middleware/auth/AuthContext';
import { useEffect, useState } from 'react';

export const useToppageTeachers = (load_data: boolean = false) => {
  const { getCurrentUser } = useAuthContext();
  const currentUser: CurrentUser | null =
    (getCurrentUser ? getCurrentUser() : null) || null;
  const [data, setData] =
    useState<LibsDomainModelInterfaceToppageGetToppageForTeachersResponse>();

  useEffect(() => {
    if (load_data) {
      toppageTeachersGet().then((res) => setData(res.data));
    }
  }, []);

  return {
    school_class: {
      grade: currentUser?.grade,
      class_name: currentUser?.class_name,
    },
    last_login_date: currentUser?.last_login_date
      ? currentUser.last_login_date
      : undefined,
    data,
    currentUser,
    time_difference_from_utc: currentUser?.time_difference_from_utc
  };
};
