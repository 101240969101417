import {
  FormItemSelect,
  SubmitButton,
} from '@/src/components/HistoryQueryForm';
import clsx from 'clsx';
import styles from './styles.module.css';

/**
 *  target_name: 1：クラス　2：学校　3：全国
 *  term_name: 1：今月 2：前月　3：2か月前 ...　13：12か月前
 */
export type DictionaryQueryFormProps = {
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  className?: string;
};
export const DictionaryQueryForm = ({
  onSubmit,
  className,
}: DictionaryQueryFormProps) => {
  return (
    <form
      className={clsx(className, styles['dictionary-form'])}
      onSubmit={onSubmit}
    >
      <FormItemSelect
        name="target_name"
        className={styles.select}
        label={TARGET_LABEL}
        options={TARGET_OPTIONS}
      />
      <FormItemSelect
        name="term_name"
        className={styles.select}
        label={MONTH_LABEL}
        options={MONTH_OPTIONS}
      />
      {/*不要と思われるのでコメントアウトしましたMM　 <SubmitButton /> */}
    </form>
  );
};

const TARGET_LABEL = (
  <div>
    <ruby>
      対<rt>たい</rt>
    </ruby>
    <ruby>
      象<rt>しょう</rt>
    </ruby>
    <ruby>
      指<rt>し</rt>
    </ruby>
    <ruby>
      定<rt>てい</rt>
    </ruby>
  </div>
);

const TARGET_OPTIONS = [
  { label: 'クラス', value: 1 },
  {
    label: (
      <div>
        <ruby>
          学<rt>がっ</rt>
        </ruby>
        <ruby>
          校<rt>こう</rt>
        </ruby>
      </div>
    ),
    value: 2,
  },
  {
    label: (
      <div>
        <ruby>
          全<rt>ぜん</rt>
        </ruby>
        <ruby>
          国<rt>こく</rt>
        </ruby>
      </div>
    ),
    value: 3,
  },
];

const MONTH_LABEL = (
  <div>
    <ruby>
      月<rt>つき</rt>
    </ruby>
    <ruby>
      指<rt>し</rt>
    </ruby>
    <ruby>
      定<rt>てい</rt>
    </ruby>
  </div>
);

const MONTH_OPTIONS = [
  {
    label: (
      <div>
        <ruby>
          今<rt>こん</rt>
        </ruby>
        <ruby>
          月<rt>げつ</rt>
        </ruby>
      </div>
    ),
    value: 1,
  },
  {
    label: (
      <div>
        <ruby>
          前<rt>ぜん</rt>
        </ruby>
        <ruby>
          月<rt>げつ</rt>
        </ruby>
      </div>
    ),
    value: 2,
  },
  ...Array.from({ length: 11 }).map((_, i) => ({
    label: (
      <div>
        {i + 2}か
        <ruby>
          月<rt>げつ</rt>
        </ruby>
        <ruby>
          前<rt>まえ</rt>
        </ruby>
      </div>
    ),
    value: i + 3,
  })),
];
