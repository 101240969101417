.container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 140px;
  padding: 16px 4px 20px;
  z-index: 10;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  /* background-color: var(--tomopla-color-mid); */
}

.items {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 34px;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 2px;

  color: var(--white);
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1rem;};
  font-size: 16px;
  
  font-family: var(--main-font-family-m);
  
  line-height: 17px;
  letter-spacing: 0.05em;
  text-align: center;
}
