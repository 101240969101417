import { useEffect, useState } from 'react';
import { GetLoginUser } from '../middleware/auth/AuthContext/AuthTypes';
import { LICENSES, ROUTES } from './../components/HistoryQueryForm/constants';
import { useAuthContext } from './../middleware/auth/AuthContext';

type StudentRoutes = {
  romaji: string;
  kanji: string;
  kanjitest: string;
  dictionary: string;
  dictionarykanji: string;
  keisan: string;
};

/**
 * ライセンスデータを取得する
 * @returns レスポンスデータ
 */
const getUserLicenseData = (getCurrentUser: GetLoginUser | undefined) => {
  if (getCurrentUser) {
    const currentUser = getCurrentUser();
    if (currentUser) {
      return currentUser.license;
    }
  }
  return [];
};

/**
 * リダイレクト処理
 */
export const useLicenseNavigator = () => {
  const [redirectPath, setRedirectPathState] = useState<string | null>(null);
  const [hasCalculation, setHasCalculation] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { getCurrentUser } = useAuthContext();

  const setRedirectPath = (path: string | null, remote: boolean = false) => {
    if (remote) {
      const url = window.location.protocol + '//' + window.location.host + path;
      setRedirectPathState(url);
    } else {
      setRedirectPathState(path);
    }
  };
  useEffect(() => {
    // ライセンスデータを取得
    const fetchUserLicenseData = async () => {
      const licenseData = getUserLicenseData(getCurrentUser);
      if (licenseData) {
        const nonCalculationSkills = [
          'kanji',
          'kanjitest',
          'romaji',
          'dictionary',
          'dictionarykanji',
        ] as (keyof StudentRoutes)[];
        let hasNonCalculationSkill = false;

        // 計算スキル以外のスキルが存在するか確認
        for (const skill of nonCalculationSkills) {
          if (
            skill in ROUTES.student &&
            licenseData.some(
              (license) =>
                (LICENSES.learning_contents as any)[skill]?.includes(
                  license.learning_contents,
                ),
            )
          ) {
            setRedirectPath(ROUTES.student[skill]);
            setHasCalculation(false);
            hasNonCalculationSkill = true;
            break;
          }
        }

        // 計算スキル以外のスキルが存在しない場合、計算スキルの存在を確認
        if (!hasNonCalculationSkill) {
          const hasCalculationSkills = licenseData.some((license) =>
            LICENSES.learning_contents.keisan.includes(
              license.learning_contents,
            ),
          );

          if (hasCalculationSkills) {
            setRedirectPath('/studenthistorykeisan');
            setHasCalculation(true);
          }
        }
      } else {
        // ライセンスデータが取得できなかった場合
        setRedirectPath(null);
        setHasCalculation(null);
      }
      console.log('リダイレクトパス', redirectPath);
      setIsLoading(false);
    };

    fetchUserLicenseData();
  }, []);

  if (isLoading) {
    // ライセンスデータの取得中はローディング状態を返す
    return { redirectPath: null, hasCalculation: null, isLoading: true };
  }

  return { redirectPath, hasCalculation, isLoading: false };
};
