import clsx from 'clsx';
import styles from '../styles.module.css';
import { FormItemSelect, FormItemSelectProps } from './FormItemSelect';

type FormItemTimeSlotSelectProps = Omit<
  FormItemSelectProps<number>,
  'options' | 'name'
> & {
  name?: string;
};
export const FormItemTimeSlotSelect = ({
  name = 'time_slot',
  className,
  ...props
}: FormItemTimeSlotSelectProps) => {
  return (
    <FormItemSelect<number>
      className={clsx(className, styles['time-slot'])}
      name={name}
      options={TIME_SLOT_OPTIONS}
      noRuby
      {...props}
    />
  );
};

const TIME_SLOT_OPTIONS = [
  { label: '全時間帯', value: null },
  ...Array.from({ length: 24 }).map((_, i) => ({
    label: `${i}時台`,
    value: i,
  })),
];
