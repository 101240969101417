import { Notification } from '@/src/components/Notification';
import { StudentFrame } from '@/src/components/layouts/StudentFrame';
import { AppMenuButton } from '@/src/components/parts/AppMenuButton';
import StudentTopCalender from '@/src/components/parts/StudentTopCalender';
import StudentTopGreeting from '@/src/components/parts/StudentTopGreeting';
import StudentTopRecords from '@/src/components/parts/StudentTopRecords';
import { formatToDateDisplayShort } from '@/src/helpers/date';
import styled, { useTheme } from 'styled-components';
import { Chart } from './Chart';
import { useToppageStudent } from './hook';
import './style.css';

import {
  profileAtom,
  themeAtom,
} from '@/src/components/ModalContents/modalAtom';
import Kanji from '@/src/components/auth/Kanji';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useElementVisibility } from '@/src/hooks/useElementVisibility';
import { LicenseEntity } from '@/src/__generated__';

const Div2 = styled('div')(
  ({ theme }) => `
    background-color: var(${theme.backgroud});
    height: 1080px;
    position: relative;

  `,
);

const Label0 = styled('div')(
  ({ theme }) => `
    background-image: url(/img/${theme.label0});
    background-size: 100% 100%;
    height: 48px;
    position: relative;
    width: 196px;
    display:flex;
    align-items:center;
    padding-left:0.4em;
  `,
);

const AppMenuLabel = styled('div')(
  ({ theme }) => `
    background-image: url(/img/${theme.label2});
    background-size: 100% 100%;
    height: 32px;
    position: relative;
    width: 176px;
  `,
);

const AppMenuBg = styled('div')(
  ({ theme }) => `
    background-image: url(/img/${theme.menubg});
    background-size: 100% 100%;
    width: 1690px;
    height: 210px;
  `,
);

type Props = {
  onThemeChange: (status: number) => void;
};

export const StudentTop = (props: Props): JSX.Element => {
  const {
    currentUser,
    data,
    last_login_date,
    calender,
    queryCalender,
    time_difference_from_utc,
  } = useToppageStudent();
  const [themenum, setThemenum] = useRecoilState(themeAtom);
  const [profiles, setProfiles] = useRecoilState(profileAtom);
  const [license, setLicense] = useState<LicenseEntity[]>([]);

  useElementVisibility({ url: "/studenttop", title: "教師トップ" });

  useEffect(() => {
    if (currentUser) {
      if (!themenum) {
        setThemenum(currentUser?.preference.theme_color);
      }
      const _profiles = {
        nick_name: profiles.nick_name
          ? profiles.nick_name
          : currentUser?.nick_name,
        profile_icon:
          profiles.profile_icon &&
            profiles.profile_icon &&
            profiles.profile_icon !== currentUser?.preference.profile_icon
            ? profiles.profile_icon
            : currentUser?.preference.profile_icon,
      };
      if (
        _profiles.nick_name !== profiles.nick_name ||
        _profiles.profile_icon !== profiles.profile_icon
      ) {
        setProfiles(_profiles);
      }
      setLicense(currentUser.license.filter(({ status }) => status === 2));
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser && typeof themenum === 'number') {
      props.onThemeChange(themenum);
      currentUser.preference.theme_color = themenum;
    }
  }, [themenum, currentUser]);

  const redirect = (path: string) => {
    if (path.match(/^http/)) {
      window.location.href = path;
    } else {
      window.location.href =
        window.location.protocol + '//' + window.location.host + path;
    }
  };

  const theme = useTheme();

  return (
    <StudentFrame className="student-top" title="ホーム">
      <Div2>
        <div className="overlap-wrapper">
          <div className="overlap">
            <div className="group-wrapper">
              <div className="overlap-group-wrapper">
                <AppMenuBg>
                  <div className="app-menu-wrapper">
                    {license.map(
                      (item: any, i: number) =>
                        item.button_name &&
                        new Date(item.expiration_date) >= new Date(new Date().toDateString()) && (
                          <AppMenuButton
                            key={i}
                            className={item.button_name}
                            onClick={() => redirect(`${item.path}`)}
                          />
                        ),
                    )}
                    <AppMenuButton
                      className="qa"
                      onClick={() => window.open('https://help.tomosplus.jp/')}
                    />
                  </div>
                </AppMenuBg>
              </div>
            </div>
            <div className="group-3">
              <div className="group-4">
                <AppMenuLabel>
                  <div className="text-wrapper-3">アプリメニュー</div>
                </AppMenuLabel>
              </div>
            </div>
          </div>
        </div>
        <div className="group-5">
          {currentUser && (
            <StudentTopGreeting
              name={profiles.nick_name}
              last_login_date={last_login_date}
              theme={theme}
              time_difference_from_utc={time_difference_from_utc}
            />
          )}
          <div className="group-8">
            <Notification html={data?.notice} theme={theme} />
          </div>
        </div>
        <div className="group-23">
          <StudentTopCalender
            theme={theme}
            data={calender}
            onChange={queryCalender}
          />
        </div>
        <div className="group-43">
          {data && (
            <Chart
              data={data.graph_data_study_time.map((d) => ({
                key: formatToDateDisplayShort(d.date),
                value: d.study_time,
              }))}
              from={data.graph_data_study_time.reduce(
                (acc, d) => (d.date < acc ? d.date : acc),
                data.graph_data_study_time[0].date,
              )}
              to={data.graph_data_study_time.reduce(
                (acc, d) => (d.date > acc ? d.date : acc),
                data.graph_data_study_time[0].date,
              )}
              theme={theme}
            />
          )}
          <div className="group-49">
            <div className="group-50">
              <Label0>
                <div className="text-wrapper-300">
                  <Kanji kana="がくしゅう">学習</Kanji>した
                  <Kanji kana="じかん">時間</Kanji>
                </div>
              </Label0>
            </div>
          </div>
        </div>

        <div className="group-61">
          {data && (
            <StudentTopRecords
              total_number_logins={data.total_number_logins}
              total_study_time={data.total_study_time}
              number_kanji_skill={data.number_kanji_skill}
              number_Japanese_dictionary={data.number_japanese_dictionary}
              number_kanji_dictionary={data.number_kanji_dictionary}
              license={license}
              theme={theme}
            />
          )}
        </div>
      </Div2>
    </StudentFrame>
  );
};
