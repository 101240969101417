import { SchoolClass, UserCore } from '@/src/__generated__';
import { InputSearchForm } from '../../InputSearch/InputSearch';
import { FormItemSchoolClassSelect, FormItemStudentSelect } from '../items';
import styles from '../styles.module.css';

export type StudentConditionsGroupProps = {
  schoolClassList: Partial<SchoolClass>[];
  childrenList: UserCore[];
  searchStudent: (name: string) => void;
  defaultValues?: {
    studentName?: string | null;
  };
  states?: {
    schoolClassSelectDisabled?: boolean;
    schoolClassSelectAllDisabled?: boolean;
    studentSelectDisabled?: boolean;
  };
};
export const StudentConditionsGroup = ({
  schoolClassList,
  childrenList,
  defaultValues,
  searchStudent,
  states = {},
}: StudentConditionsGroupProps) => (
  <div className={styles.row}>
    <FormItemSchoolClassSelect
      className={styles['school-class-select']}
      name="class_index"
      schoolClassList={schoolClassList}
      disabled={states.schoolClassSelectDisabled}
      allDisabled={states.schoolClassSelectAllDisabled}
    />
    <FormItemStudentSelect
      name="user_uuid"
      childrenList={childrenList}
      disabled={states.studentSelectDisabled}
    />
    <InputSearchForm
      containerClassName={styles['student-search']}
      search={searchStudent}
      defaultValue={defaultValues?.studentName || undefined}
      placeholder="児童名検索"
    />
  </div>
);
