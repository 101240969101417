import {
  FormItemDateRangePicker,
  HistoryQueryFormStudent,
  StudentHistorySelect,
  styles as formStyles,
} from '@/src/components/HistoryQueryForm';
import { TableWithScroll } from '@/src/components/Table';
import { StudentFrame } from '@/src/components/layouts/StudentFrame';
import { FormProvider } from 'react-hook-form';
import { useLearningHistoryRomajiSkill } from './hooks';
import styles from './styles.module.css';
import styled, { useTheme } from 'styled-components';
import { themeAtom, profileAtom } from '@/src/components/ModalContents/modalAtom';
import { useRecoilState } from 'recoil';
import { useToppageStudent } from '../StudentTop/hook';
import { useEffect } from 'react';
import clsx from 'clsx';

type Props = {
  onThemeChange: (status: number) => void
}

export const StudentHistoryRomaji = (props: Props): JSX.Element => {
  const { currentUser } = useToppageStudent();
  const [themenum, setThemenum] = useRecoilState(themeAtom)
  const [profiles, setProfiles] = useRecoilState(profileAtom)
  const { table, onSubmit, methods, errorMessage, hasLoaded } =
    useLearningHistoryRomajiSkill();
  useEffect(() => {
    if (currentUser) {
      if (!themenum) {
        setThemenum(currentUser?.preference.theme_color);
      }
      const _profiles = {
        nick_name: profiles.nick_name ? profiles.nick_name : currentUser?.nick_name,
        profile_icon: profiles.profile_icon && profiles.profile_icon !== currentUser?.preference.profile_icon ? profiles.profile_icon : currentUser?.preference.profile_icon
      }
      if (
        _profiles.nick_name !== profiles.nick_name ||
        _profiles.profile_icon !== profiles.profile_icon
      ) {
        setProfiles(_profiles);
      }
    }

  }, [currentUser, profiles, themenum]);

  useEffect(() => {
    if (currentUser && typeof themenum === 'number') {
      props.onThemeChange(themenum);
    }
  }, [themenum, currentUser]);

  const theme = useTheme();
  const WrapDiv = styled('div')(
    ({ theme }) => `
    .romaji{
      table{
        tbody{
          tr:last-child {
            td{
              background-color: var(${theme.backgroud});
              color: var(${theme.textcolor});
            }
          }
        }
      }
    }
  `
  )


  return (
    <StudentFrame title="学習りれき">
      <div className={styles.body}>
        <FormProvider {...methods}>
          <HistoryQueryFormStudent
            onSubmit={onSubmit}
            errorMessage={errorMessage}
          >
            <div className={formStyles['row']}>
              <StudentHistorySelect labelWidth={180} />
            </div>
            <FormItemDateRangePicker labelWidth={180} />
          </HistoryQueryFormStudent>
        </FormProvider>
        <WrapDiv>
          <TableWithScroll
            className={clsx(styles.table, 'romaji')}
            table={table}
            maxWidth={1580}
            maxHeight={760}
          />
        </WrapDiv>
      </div>
    </StudentFrame>
  );
};
