import { ReactNode, ButtonHTMLAttributes } from "react";
import "./style.scss";

type Props = Partial<{
  type: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  disabled: ButtonHTMLAttributes<HTMLButtonElement>['disabled'];
  tabIndex: ButtonHTMLAttributes<HTMLButtonElement>['tabIndex'];
  className: any;
  onClick?: (e?:any) => void;
}>

export const ModalCloseButton = ({ type, disabled, tabIndex, className, onClick }: Props) => {
  return (
    <div className="element-active-a-wrapper">
      <button 
        type={type}
        disabled={disabled}
        tabIndex={tabIndex}
        onClick={onClick}
        className={`overlap ${className}`}>
        <div className="active-a">
          <div className="overlap-group">
            <div className="group">
              <img className="img" alt="Group" src="/img/group-691.png" />
              <img className="img" alt="Group" src="/img/group-691-2.png" />
            </div>
            <div className="text-wrapper">とじる</div>
          </div>
        </div>
        
      </button>
    </div>
  );
};

