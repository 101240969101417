import {
  HistoricalDataForTest,
  TeachingUnitEntity,
} from '@/src/__generated__';
import { ExpandToggle, styles as tableStyles } from '@/src/components/Table';
import { formatToDateDisplay } from '@/src/helpers/date';
import { KanjiApplicationType } from '@/src/types/KanjiApplicationType';
import { CellContext, ColumnDef, GroupColumnDef } from '@tanstack/react-table';
import { useTableContext } from './context';
import styles from './styles.module.css';
import Kanji from '@/src/components/auth/Kanji';
import { Cell } from 'recharts';

export const captionColumns = (
  type: KanjiApplicationType,
): ColumnDef<any>[] => [
    {
      size: 225,
      header:
        type === KanjiApplicationType.Kanji ? 'プレテスト/テスト' : 'まとめ',
      meta: { thRowSpan: 2, tdRowSpan: 4, stickyLeft: 0 },
      cell: ({ row }) => {
        const type = row.getParentRow()?.original.type;
        return (
          <div className={styles['test-type']}>
            <img
              src={
                type === 'テスト'
                  ? '/img/vector-test.svg'
                  : '/img/vector-pretest.svg'
              }
              alt=""
            />
            {type}
          </div>
        );
      },
    },
    {
      id: 'frequency_type',
      size: 101,
      header: () => (
        <div>
          <ruby>
            段<rt>だん</rt>
          </ruby>
          <ruby>
            階<rt>かい</rt>
          </ruby>
        </div>
      ),
      cell: ({ row: { original: data } }) => (
        data.frequency_type === '初回' ? <Kanji kana="しょかい">初回</Kanji> :
          data.frequency_type === '初回再' ? <Kanji kana="しょかいさい" ls="-0.5">初回再</Kanji> :
            data.frequency_type === '直近' ? <Kanji kana="ちょっきん" ls="-1">直近</Kanji> :
              data.frequency_type === '直近再' ? <Kanji kana="ちょっきんさい" ls="-1">直近再</Kanji> : ''
      ),
      meta: { thRowSpan: 2, stickyLeft: 225 },
    },
  ];

export const FREQUENCY_TYPES = ['初回', '初回再', '直近', '直近再'] as const;

export type RowData = {
  type: 'プレテスト' | 'テスト' | 'まとめ';
  frequency_type: (typeof FREQUENCY_TYPES)[number];
  historical_date: Date | string;
  results: string[];
  total: string;
};

export const teachingUnitColumn = (
  teaching_unit: TeachingUnitEntity,
  data: HistoricalDataForTest | undefined,
): GroupColumnDef<RowData> => ({
  id: 'teaching_unit',
  header: () => teaching_unit.teaching_unit_type == 2 ? `(${teaching_unit.teaching_unit_number}) ${teaching_unit.teaching_unit_name}`:`【${teaching_unit.teaching_unit_number}】${teaching_unit.teaching_unit_name}`,
  columns: [
    {
      id: 'test_date',
      accessorKey: 'historical_date',
      size: 258,
      header: () => (
        <div>
          <ruby>
            取<rt>と</rt>
          </ruby>
          り
          <ruby>
            組<rt>く</rt>
          </ruby>
          んだ
          <ruby>
            日<rt>ひ</rt>
          </ruby>
        </div>
      ),
      cell: (cell) =>
        typeof cell.row.original.historical_date === 'string'
          ? cell.row.original.historical_date
          : formatToDateDisplay(cell.row.original.historical_date),
    },
    ...(data?.questions.map((q, i) => ({
      id: `${i}`,
      accessorKey: `results.${i}`,
      size: 175,
      header: () => q.word,
    })) || []),
    {
      id: 'total',
      accessorKey: 'total',
      size: 162,
      meta: { align: 'right' },
      header: () => {
        const { columnExpanded, toggleColumnExpanded } = useTableContext();
        return (
          <div>
            <ruby>
              合<rt>ごう</rt>
            </ruby>
            <ruby>
              計<rt>けい</rt>
            </ruby>
            <ExpandToggle
              className={tableStyles['th-button']}
              onClick={toggleColumnExpanded}
              expanded={columnExpanded}
            />
          </div>
        );
      },
      cell: (cell: CellContext<RowData, any>) => {
        const value = cell.getValue();
        const matched = value.match(/^(.*)点$/);
        if (matched) {
          return <>{matched[1]}<ruby>点<rt>てん</rt></ruby></>
        }
        return value;
      }
    },
  ],
});
